(function () {
    'use strict';

    var watcherCache = {};
    var generateCacheKey = function generateCacheKey(state, stateParams) {
        return state.$current.name + '-' + JSON.stringify(stateParams);
    };
    var setCacheKey = function setCacheKey(key, watcher) {
        watcherCache[key] = watcher;
    };
    var isInCache = function isInCache(key) {
        return watcherCache[key];
    };
    var getCurrentWatcher = function getCurrentWatcher(key) {
        return watcherCache[key];
    };

    function unsavedAlert($rootScope, $state, $stateParams, LoadingIndicator, unsavedAlert) {

        return {
            restrict: 'A',

            require: 'form',

            link: function unsavedAlertLink(scope, iElement, iAttrs, formCtrl) {

                unsavedAlert.register(iAttrs.unsavedAlert, function () {
                    formCtrl.$setPristine();
                });

                var watcherKey = generateCacheKey($state, $stateParams);
                if (isInCache(watcherKey)) {
                    getCurrentWatcher(watcherKey)();
                } // If we already have a watcher for this state unset it and make a new one
                var stateWatcher;
                stateWatcher = $rootScope.$on('$stateChangeStart', function (event, toState, toParams /* , fromState, fromParams */) {
                    // Check user is loggedin
                    if (!Gecko.user) {
                        unsavedAlert.clear(iAttrs.unsavedAlert);
                        return;
                    }

                    // $state.dragDropChange listenes for change on the email template drag drop
                    if (formCtrl.$dirty) {

                        event.preventDefault();
                        LoadingIndicator.hide();

                        return GeckoUI.dialog.confirmCustom('You have unsaved changes. Do you wish to discard these?', [{ base: 'YES', text: 'Discard changes' }, { base: 'NO' }], function (result) {
                            if (result) {
                                unsavedAlert.clear(iAttrs.unsavedAlert);
                                stateWatcher(); // Unsubscribe this $rootScope event
                                $state.go(toState.name, toParams);
                                return;
                            }
                        });
                    } else {
                        // Unsubscribe this $rootScope event
                        stateWatcher();
                        // Clear out any registered forms on state change
                        unsavedAlert.clear(iAttrs.unsavedAlert);
                    }
                });
                setCacheKey(watcherKey, stateWatcher);

                // Handle external links 
                function dirtyBeforeUnloadHandler(event) {
                    // Ignore when the user is logged out
                    if (!Gecko.user) {
                        window.removeEventListener('beforeunload', dirtyBeforeUnloadHandler);
                        return;
                    }

                    if (formCtrl.$dirty) {
                        event.preventDefault();
                        // Dont show vex dialog when native dialog is triggered
                        try {
                            window.vex.closeAll();
                        } catch (err) {}
                        return 'Leaving this page will cause any unsaved changes to be lost, do you want to proceed?';
                    }
                }

                window.addEventListener('beforeunload', dirtyBeforeUnloadHandler);

                // Remove the event listener when naviagting away from the page
                scope.$on('$destroy', function () {
                    window.removeEventListener('beforeunload', dirtyBeforeUnloadHandler);
                });
            }
        };
    }

    function unsavedAlertService($rootScope) {

        var cleanFns = {};

        return {
            /**
             * Register a form controller to the unsaved service
             * @param  {String} name                    The name of the form. Used to reference the form from the controller
             * @param  {angular FormController} form    Should be an angular FormController.
             * @return {undefined}
             */
            register: function register(name, cleanFn) {
                cleanFns[name || ''] = cleanFn;
            },

            /**
             * Clear the unsaved (dirty) status for a form
             * @param  {[String]} name (optional) Set the named form pristine. If not specifed then sets default form
             * @return {undefined}
             */
            clear: function clear(name) {
                var cleanFn = cleanFns[name || ''];
                if (cleanFn) cleanFn();
                return Promise.resolve();
            }
        };
    }

    angular.module('GeckoEngage').directive('unsavedAlert', unsavedAlert).factory('unsavedAlert', unsavedAlertService);
})();