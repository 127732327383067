(function () {
    'use strict';

    function geckoSideMenu() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-side-menu/gecko-side-menu.html',
            scope: true,
            controller: function controller($scope, $location, $state, $stateParams, geckoSideMenuService, geckoStore, $filter, geckoSideMenuChatNotificationService) {
                $scope.geckoSideMenuService = geckoSideMenuService;
                $scope.menu = geckoSideMenuService.get();
                $scope.hasUnreadConversations = false;

                $scope.menu = $scope.menu.map(function (item) {
                    if (item.state) {
                        item.uiSref = '' + item.state;
                        if (item.stateParams) item.uiSref = item.uiSref + ('(' + JSON.stringify(item.stateParams) + ')');
                    }
                    return item;
                });

                // Open when child is active
                $scope.$watch('$state.$current', function (newVal) {
                    $scope.menu = $scope.menu.map(function (item) {
                        // Open when child is active
                        item.isOpen = (item.children || []).some(function (child) {
                            //return $state.includes(child.state);
                            return $state.$current.name.includes(child.state);
                        });

                        return item;
                    });
                });

                // Iniitialise unread conversations indicator
                geckoSideMenuChatNotificationService.init(function (hasUnreadConversations) {
                    $scope.menu.find(function (item) {
                        return item.key === 'conversations';
                    }).showIndicator = hasUnreadConversations;
                    // TODO: Update document.title 
                    $scope.$digest();
                });

                $scope.isActive = function (item) {
                    if (item.active) {
                        return item.active();
                    }

                    // Only add active class if the menu is expanded
                    if (!geckoSideMenuService.expanded && (item.children || []).length) {
                        return item.children.some(function (child) {
                            if (child.state) {
                                return $state.includes(child.state);
                            }
                            return false;
                        });
                    }

                    if (item.activeWhen && Array.isArray(item.activeWhen)) {
                        return item.activeWhen.some(function (path) {
                            if (typeof path === 'string') {
                                return $location.path().indexOf(path) === 0;
                            }
                            if (path && path.state) {
                                return $state.includes(path.state, path.params || {});
                            }
                            return false;
                        });
                    }

                    return item.state ? $state.includes(item.state) : false;
                };

                $scope.getItemClasses = function (item) {
                    return {
                        'active': $scope.isActive(item),
                        'divider-top': item.dividerTop,
                        'divider-bottom': item.dividerBottom
                    };
                };

                $scope.toggleSubmenu = function (item) {
                    $scope.menu.forEach(function (menuItem) {
                        if (menuItem !== item && menuItem.isOpen) {
                            menuItem.isOpen = false;
                        }
                    });
                    item.isOpen = !item.isOpen;

                    if (!$scope.$$phase) {
                        $scope.$apply();
                    }
                };

                $scope.setFlyoutPosition = function (event, item) {
                    if (!item.children) return;

                    var parentItem = event.currentTarget;
                    var submenu = parentItem.querySelector('.nav-sidebar-children');

                    if (geckoSideMenuService.expanded) {
                        submenu.style.removeProperty('position');
                        submenu.style.removeProperty('top');
                        submenu.style.removeProperty('left');
                        return;
                    }

                    if (submenu.classList.contains('flyout')) {
                        var rect = parentItem.getBoundingClientRect();
                        var windowHeight = window.innerHeight;
                        var flyoutHeight = submenu.offsetHeight;

                        var top = rect.top;

                        if (top + flyoutHeight > windowHeight) {
                            top = windowHeight - flyoutHeight - 10;
                        }

                        if (top < 10) {
                            top = 10;
                        }

                        submenu.style.top = Math.round(top) + 'px';
                        submenu.style.position = 'fixed';
                        submenu.style.left = '50px';
                    }
                };

                $scope.$watch('geckoSideMenuService.expanded', function (isExpanded) {
                    if (isExpanded) {
                        var flyouts = document.querySelectorAll('.nav-sidebar-children.flyout');
                        flyouts.forEach(function (flyout) {
                            flyout.style.removeProperty('position');
                            flyout.style.removeProperty('top');
                            flyout.style.removeProperty('left');
                            flyout.classList.remove('show');
                        });
                    }
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoSideMenu', geckoSideMenu);
})();