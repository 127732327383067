function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

(function () {
    'use strict';

    var _taskStatusIconMap;

    function contactTasks() {
        return {
            restrict: 'E',
            template: '\n                <div class="contact-tasks">\n\n                    <div ng-if="!ctrl.tasks" class="alert alert-info contact-tasks-loading">\n                        Currently loading the list of tasks and objectives, please wait.\n                    </div>\n\n                    <div ng-if="!ctrl.fields.length" class="alert alert-info contact-tasks-no-options">\n                        No tasks and objectives found for this contact.\n                    </div>\n\n                    <gecko-fields inline=""\n                        fields="ctrl.fields"\n                        values="ctrl.values"\n                        entity-id="ctrl.contact.id">\n                    </gecko-fields>\n\n                </div>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {
                contactId: '=',
                contactValues: '='
            },
            controller: contactTasksCtrl
        };
    }

    var taskStatusTitleMap = Gecko.Task.task_statuses;
    var taskStatusIconMap = (_taskStatusIconMap = {}, _defineProperty(_taskStatusIconMap, Gecko.Task.STATUS_INCOMPLETE, '<i class="fa fa-times-circle text-muted"></i>'), _defineProperty(_taskStatusIconMap, Gecko.Task.STATUS_INPROGRESS, '<i class="fa fa-info-circle text-warning"></i>'), _defineProperty(_taskStatusIconMap, Gecko.Task.STATUS_COMPLETE, '<i class="fa fa-check-circle text-success"></i>'), _taskStatusIconMap);

    var buildTaskValue = function buildTaskValue(taskId, status) {
        return {
            field_id: String(taskId),
            safe: taskStatusIconMap[status] + ' ' + taskStatusTitleMap[status],
            display: taskStatusTitleMap[status],
            value: status
        };
    };

    var taskPassesContactConditions = function taskPassesContactConditions(contactValueMap, taskConfig) {
        var conditions = (taskConfig || {}).conditions || [];
        var operator = (taskConfig || {}).operator || 'AND';

        if (Array.isArray(conditions) && conditions.length) {
            return conditions.reduce(function (result, condition) {
                var fieldId = condition.field_id;
                var value = condition.value || [];
                var conditionValue = Array.isArray(value) ? value.map(function (v) {
                    return typeof v !== 'string' && v && v.value ? v.value : v;
                }) : [value];
                var contactValue = contactValueMap[fieldId];
                if (operator === 'AND') {
                    return result && conditionValue.includes(contactValue);
                } else {
                    return result || conditionValue.includes(contactValue);
                }
            }, operator === 'AND' ? true : false);
        }
        return true;
    };

    function contactTasksCtrl($scope, Gecko) {
        var ctrl = this;
        ctrl.tasks = null;
        ctrl.values = {};
        ctrl.fields = [];

        ctrl.buildValues = function (tasks) {
            return tasks.reduce(function (result, task) {
                result[String(task.id)] = buildTaskValue(task.id, task.status);
                return result;
            }, {});
        };

        ctrl.prepareSaveTask = function (taskId) {
            return function (status) {
                return new Gecko.BaseModel().call('/tasks/' + taskId + '/statuses/' + ctrl.contactId, 'POST', { status: status }, true).then(function (res) {
                    return buildTaskValue(taskId, status);
                });
            };
        };

        ctrl.buildFields = function (tasks) {
            return (tasks || []).map(function (task) {
                return {
                    id: String(task.id),
                    label: 'Change task status',
                    displayLabel: task.task_name,
                    type: Gecko.Field.TYPE_SELECT,
                    data_type: 'string',
                    colClass: 'col-xs-6 none',
                    optionsKey: 'value',
                    optionsLabelKey: 'label',
                    options: Object.keys(taskStatusTitleMap).map(function (status) {
                        return {
                            value: status,
                            label: taskStatusTitleMap[status]
                        };
                    }),
                    noBlank: true, // Dont show empty option
                    saveFn: ctrl.prepareSaveTask(task.id),
                    // Show field when contact values pass task conditions
                    visible: taskPassesContactConditions(ctrl.contactValueMap, task.config)
                };
            });
        };

        ctrl.saveTasks = function () {
            // Build saveable consent values
            ctrl.contact.consent_data = ctrl.buildValues(ctrl.reasons, ctrl.values, true);

            ctrl.contact.save().then(function () {
                GeckoUI.messenger.success('Updated contact\'s consents');
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);
            });
        };

        ctrl.getData = function () {
            return new Gecko.BaseModel().call('/contacts/' + ctrl.contactId + '/tasks', 'GET', { per_page: 10000 }, true).then(function (res) {
                return res.data;
            });
        };

        $scope.$watch('ctrl', function () {
            ctrl.contactValueMap = (ctrl.contactValues || []).reduce(function (result, value) {
                if (value.related_field) {
                    var contactFieldId = value.related_field.contact_field_id || value.related_field.id;
                    result[contactFieldId] = value.safe;
                }
                return result;
            }, {});

            ctrl.getData().then(function (data) {
                ctrl.tasks = data || [];
                ctrl.fields = ctrl.buildFields(ctrl.tasks).filter(function (field) {
                    return field.visible;
                });
                ctrl.values = ctrl.buildValues(ctrl.tasks);
                $scope.$digest();
            }).catch(function (e) {
                return console.log(e);
            });
        });
    }

    angular.module('GeckoEngage').directive('contactTasks', contactTasks).controller('contactTasksCtrl', contactTasksCtrl);
})();