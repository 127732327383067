(function () {
    'use strict';

    function ChatWorkflowsRoute($stateProvider) {
        $stateProvider.state('chat_workflows', {
            url: "/settings/chat_workflows",
            templateUrl: "/modules/chat_workflows/views/index.483c4a0d.html",
            controller: 'ChatWorkflowsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Chat Workflows',
            redirectKey: 'module_chat_workflows',
            resolve: {
                storedData: function storedData(geckoDataService) {
                    var models = ['fields', 'campaigns', 'consents', 'labels', 'users', 'senders', 'templates', 'integrations', 'tasks', 'channels', 'teams'];
                    return geckoDataService.fetch(models);
                },

                multiData: function multiData(storedData) {
                    return {
                        fields: storedData.fields,
                        contact_fields: storedData.fields,
                        labels: storedData.labels,
                        users: storedData.users,
                        senders: storedData.senders,
                        templates: storedData.templates,
                        campaigns: storedData.campaigns,
                        consents: storedData.consents,
                        integrations: storedData.integrations,
                        tasks: storedData.tasks,
                        channels: storedData.channels,
                        teams: storedData.teams
                    };
                },
                workflows: function workflows() {
                    return new Gecko.BaseModel().call('/workflows', 'GET', { perPage: 1000 }, true).then(function (res) {
                        return res.data;
                    });
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(ChatWorkflowsRoute);
})();