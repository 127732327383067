(function () {
    'use strict';

    function quicksearch() {
        return {
            restrict: 'E',
            scope: {
                filterBtn: '=',
                filterActive: '=',
                filterName: '=',
                filterOpen: '=',
                filterReset: '=',
                extraParams: '&'
            },
            templateUrl: '/components/quick-search/quick-search.html',

            bindToController: true,
            controllerAs: 'ctrl',
            controller: function controller($state, $stateParams, $attrs) {

                var ctrl = this;

                // Initialise search term
                ctrl.quickSearchTerm = $stateParams.search ? $stateParams.search : "";

                // Add extra parameters
                var params = angular.extend({ page: null }, typeof ctrl.extraParams === 'function' ? ctrl.extraParams() : {});

                // Performs search
                ctrl.quickSearch = function () {
                    if (ctrl.quickSearchTerm === undefined || $attrs.state === undefined || !ctrl.quickSearchTerm && !$stateParams.search) return false;
                    return $state.go($attrs.state, angular.extend(params, { search: ctrl.quickSearchTerm }), { reload: true });
                };

                // Open filter
                ctrl.open = function () {
                    if (ctrl.filterOpen) return ctrl.filterOpen();
                };

                // Reset filter
                ctrl.reset = function () {
                    if (ctrl.filterReset) return ctrl.filterReset();
                };

                ctrl.checkFilter = function (filter) {
                    if (!filter || !Object.keys(filter).length) return false;
                    return true;
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('quicksearch', quicksearch);
})();