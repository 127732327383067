(function () {
    'use strict';

    function DashboardsRoute($stateProvider) {
        $stateProvider.state('dashboard', {
            url: "/dashboard",
            templateUrl: "/modules/dashboards/views/overview.fbb1abfc.html",
            controller: 'DashboardCtrl',
            controllerAs: 'vm',
            //This will stop people from logging in (SIGH!)
            //requires        : [Gecko.ABILITY_DASHBOARDS_VIEW],
            page_title: 'Overview',
            resolve: {
                form_dashboard: function form_dashboard(data) {
                    return data.form_dashboard ? data.form_dashboard : null;
                    // if (Gecko.package.feature_form && Gecko.able(Gecko.ABILITY_DASHBOARDS_VIEW)) {
                    //     return new Gecko.Dashboard().rfields({'dashboard': ['name']}).display('overview', {module: Gecko.Dashboard.MODULE_FORM});
                    // }
                    // else {
                    //     return null;
                    // }
                },
                call_dashboard: function call_dashboard(data) {
                    return data.call_dashboard ? data.call_dashboard : null;
                    // if (Gecko.package.feature_call && Gecko.able(Gecko.ABILITY_DASHBOARDS_VIEW)) {
                    //     return new Gecko.Dashboard().rfields({'dashboard': ['name']}).display('overview', {module: Gecko.Dashboard.MODULE_CALL});
                    // }
                    // else {
                    //     return null;
                    // }
                },
                campaignsRfields: function campaignsRfields() {
                    return {
                        campaign: ['title', 'module', 'created_at']
                    };
                },
                responsesRfields: function responsesRfields() {
                    return {
                        response: ['first_field', 'second_field', 'created_at']
                    };
                },
                formsRfields: function formsRfields() {
                    return {
                        form: ['name', 'internal_name', 'created_at']
                    };
                },
                callsRfields: function callsRfields() {
                    return {
                        call: ['contact_id', 'call_time', 'started_at'],
                        user: ['full_name'],
                        outcome: ['name', 'type']
                    };
                },
                data: function data(campaignsRfields, responsesRfields, formsRfields, callsRfields) {
                    var _query = {};

                    if (!Gecko.able(Gecko.ABILITY_DASHBOARDS_VIEW)) return {};

                    // Form Dashboard
                    if (Gecko.package.feature_form) {
                        _query.form_dashboard = new Gecko.Dashboard().rfields({ 'dashboard': ['name'] }).multiConfig({
                            method: 'POST',
                            module: Gecko.Dashboard.MODULE_FORM
                        }).path('overview/display');
                    }

                    // Call Dashboard
                    if (Gecko.package.feature_call) {
                        _query.call_dashboard = new Gecko.Dashboard().rfields({ 'dashboard': ['name'] }).multiConfig({
                            method: 'POST',
                            module: Gecko.Dashboard.MODULE_CALL
                        }).path('overview/display');
                    }

                    // Campaigns
                    if (Gecko.able(Gecko.ABILITY_CALL_CAMPAIGNS_VIEW)) _query.campaigns = new Gecko.Campaign().rfields(campaignsRfields).limit(5);
                    // Calls
                    if (Gecko.able(Gecko.ABILITY_CALLS_VIEW)) _query.calls = new Gecko.Call().include('user', 'outcome').rfields(callsRfields).where('status', Gecko.Call.COMPLETE).limit(5);
                    // Forms
                    if (Gecko.able(Gecko.ABILITY_FORMS_VIEW)) _query.forms = new Gecko.Form().rfields(formsRfields).where('module', 'form').limit(5);
                    // Responses
                    if (Gecko.able(Gecko.ABILITY_RESPONSES_VIEW)) _query.responses = new Gecko.Response().rfields(responsesRfields).limit(5);

                    return Gecko.multi(_query);
                }
            }
        }).state('dashboards', {
            url: "/dashboards?{page}",
            templateUrl: "/modules/dashboards/views/index.ac6944b8.html",
            controller: 'DashboardsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Dashboards',
            requires: [Gecko.ABILITY_DASHBOARDS_VIEW],
            resolve: {
                dashboards: function dashboards($stateParams) {
                    return new Gecko.Dashboard().includeCount('widgets').rfields({ dashboard: ['created_at', 'name', 'type'] }).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).get();
                }
            }
        });

        /*
         * Dashboard / Snapshot Views
         */

        var dashboardViewResolves = {
            dashboard: function dashboard($stateParams, snapshot) {

                var config = {};
                if ($stateParams.module) config.module = $stateParams.module;

                if (snapshot) {
                    return new Gecko.Dashboard().fill(snapshot.clean.data.dashboard);
                }

                if ($stateParams.override_date) config.override_date = $stateParams.override_date;

                if ($stateParams.date_from && $stateParams.date_to) {
                    config.override_date = $stateParams.date_from + ':' + $stateParams.date_to;
                }

                if ($stateParams.filter_id) {
                    config.filter = {
                        filter_id: $stateParams.filter_id
                    };
                }

                if ($stateParams.call_type) {
                    config.call_type = $stateParams.call_type;
                } else {
                    config.call_type = [Gecko.Call.TYPE_INBOUND, Gecko.Call.TYPE_OUTBOUND];
                }

                var id;
                if ($stateParams.module == 'voip') {
                    id = 'voip';
                    delete config.module;
                } else if ($stateParams.campaign_id) {
                    id = 'campaign';
                    config.campaign_id = $stateParams.campaign_id;
                } else if ($stateParams.form_id) {
                    id = 'form';
                    config.form_id = $stateParams.form_id;
                    config.event_id = $stateParams.event_id;
                } else if ($stateParams.script_id) {
                    id = 'script';
                    config.form_id = $stateParams.script_id;
                } else if ($stateParams.event_id) {
                    id = 'event';
                    config.event_id = $stateParams.event_id;
                } else {
                    id = $stateParams.dashboard_id;
                }

                return new Gecko.Dashboard().include('snapshots').display(id, config);
            },

            model: function model($stateParams) {
                if ($stateParams.campaign_id) {
                    return new Gecko.Campaign().get($stateParams.campaign_id);
                } else if ($stateParams.form_id) {
                    return new Gecko.Form().where('module', 'form').rfields({ form: ['name'] }).get($stateParams.form_id);
                } else if ($stateParams.script_id) {
                    return new Gecko.Form().where('module', 'call').rfields({ form: ['name'] }).get($stateParams.script_id);
                } else if ($stateParams.event_id) {
                    return new Gecko.Event().get($stateParams.event_id);
                }
                return false;
            },

            campaigns: function campaigns($stateParams, dashboard) {
                if (dashboard.type == 'campaign') {
                    return new Gecko.Campaign().perPage('all').get();
                }
                return false;
            },

            forms: function forms($stateParams, dashboard) {
                if (dashboard.type == 'form') {
                    return new Gecko.Form().where('module', 'form').rfields({ form: ['name'] }).perPage('all').get();
                }
                return false;
            },

            scripts: function scripts($stateParams, dashboard) {
                if (dashboard.type == 'script') {
                    return new Gecko.Form().where('module', 'call').rfields({ form: ['name'] }).perPage('all').get();
                }
                return false;
            },

            filters: function filters() {
                return new Gecko.Filter().where('saved', '1').rfields({ filter: ['name'] }).perPage('all').get();
            },

            snapshot: function snapshot($stateParams) {
                if ($stateParams.snapshot_id) {
                    return new Gecko.Snapshot().get($stateParams.snapshot_id);
                } else {
                    return null;
                }
            }

        };

        $stateProvider.state('dashboardview', {
            url: "/dashboards/{dashboard_id}?{module}&{event_id}&{form_id}&{script_id}&{campaign_id}&{call_type}&{override_date}&{date_from}&{date_to}${filter_id}",
            templateUrl: "/modules/dashboards/views/view.5ad7c0bb.html",
            controller: 'DashboardViewCtrl',
            controllerAs: 'ctrl',
            page_title: 'Dashboard',
            requires: [Gecko.ABILITY_DASHBOARDS_VIEW],
            resolve: dashboardViewResolves
        }).state('dashboardsnapshotview', {
            url: "/dashboards/{dashboard_id}/snapshot/{snapshot_id}",
            templateUrl: "/modules/dashboards/views/view.5ad7c0bb.html",
            controller: 'DashboardViewCtrl',
            controllerAs: 'ctrl',
            page_title: 'Dashboard',
            requires: [Gecko.ABILITY_DASHBOARDS_VIEW],
            resolve: dashboardViewResolves
        });
    }

    angular.module('GeckoEngage.routes').config(DashboardsRoute);
})();