(function () {
    'use strict';

    /**
     * @typedef SavedSearchProvider
     * @type {object}
     * @property {{saturate: (function(): *)}}
     * @property {{getById: (function(): *)}}
     */

    /**
     * @param portalRequestService
     * @param geckoDataService
     * @param searchServiceProvider
     * @param engageFilterProvider
     * @returns {SavedSearchProvider}
     */

    function savedSearchService(portalRequestService, geckoDataService, searchServiceProvider, engageFilterProvider) {

        /** @type {SavedSearchProvider} **/
        var provider = function provider() {
            return useSearchService() ? searchServiceProvider : engageFilterProvider;
        };

        var _this = {
            saturate: function saturate() {
                return provider().saturate().then(function (resp) {
                    var filtersStored = geckoDataService.setStore('filters', resp);
                    // Add new data to storeByIds Obj
                    geckoDataService.setStoreByIds('filters', filtersStored);
                    // Set model lastFetched
                    geckoDataService.setLastFetched('filters');
                });
            },
            getById: function getById(id) {
                return provider().getById(id);
            },
            getProviderName: function getProviderName() {
                return useSearchService() ? 'searchService' : 'engage';
            }
        };

        return _this;
    }

    angular.module('GeckoEngage').factory('savedSearchService', savedSearchService);
})();

var useSearchService = function useSearchService() {
    return false;
};