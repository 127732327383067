(function () {
    'use strict';

    function geckoDropdown() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-dropdown/gecko-dropdown.html',
            scope: {
                elementId: '@id',

                title: '@title',
                iconLeft: '@iconLeft',
                iconRight: '@iconRight',
                btnClass: '@btnClass',
                btnTooltip: '@btnTooltip',
                btnType: '@btnType',
                btnSize: '@btnSize',
                hideWhen: '=hideWhen',
                data: '=data',
                index: '=index',
                items: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($scope) {
                $scope.GeckoUI = GeckoUI;
                var ctrl = this;

                ctrl.render = function (item, data) {
                    if (item.render) return item.render(item, data);
                    return item.title;
                };

                ctrl.dropdownBtnClass = function () {
                    return 'gecko-dropdown-btn ' + GeckoUI.prepareIndexClass('gecko-dropdown-toggle', ctrl.index);
                };
                ctrl.dropdownMenuClass = function () {
                    return '' + GeckoUI.prepareIndexClass('gecko-dropdown', ctrl.index);
                };
                ctrl.dropdownItemClasses = function () {
                    return (ctrl.items || []).map(function (item, index) {
                        return 'gecko-dropdown-item ' + GeckoUI.prepareIndexClass('gecko-dropdown-item', index) + ' ' + GeckoUI.prepareNameClass('gecko-dropdown-item', ctrl.render(item, ctrl.data));
                    });
                };

                // Close drop after click
                ctrl.afterAction = function ($event) {
                    $event.stopPropagation();
                    $("body").trigger("click");
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoDropdown', geckoDropdown);
})();