(function () {
    'use strict';

    function sessionClosedBadge() {
        return {
            restrict: 'E',
            template: '\n                <span ng-if="ctrl.isClosed" class="label label-default">\n                    Closed\n                </span>\n            ',
            scope: {
                session: '='
            },
            bindToController: true,
            controllerAs: 'ctrl',
            controller: function controller($scope) {
                var ctrl = this;

                $scope.$watch('ctrl', function () {
                    if (!ctrl.session) return;
                    ctrl.isClosed = ctrl.session.closed;
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('sessionClosedBadge', sessionClosedBadge);
})();