var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

(function () {
    'use strict';

    var _CHAT_PUSHER_KEYS, _CHAT_PUSHER_CLUSTERS;

    var CHAT_PUSHER_KEYS = (_CHAT_PUSHER_KEYS = {}, _defineProperty(_CHAT_PUSHER_KEYS, 'local', '1b1988be7d00fffb17fc'), _defineProperty(_CHAT_PUSHER_KEYS, 'stage', '1b1988be7d00fffb17fc'), _defineProperty(_CHAT_PUSHER_KEYS, 'eu', 'd231e6bc93e6b32ffb89'), _defineProperty(_CHAT_PUSHER_KEYS, 'us-e', 'a5297bab5dc2c01290c1'), _CHAT_PUSHER_KEYS);

    var CHAT_PUSHER_CLUSTERS = (_CHAT_PUSHER_CLUSTERS = {}, _defineProperty(_CHAT_PUSHER_CLUSTERS, 'local', 'eu'), _defineProperty(_CHAT_PUSHER_CLUSTERS, 'stage', 'eu'), _defineProperty(_CHAT_PUSHER_CLUSTERS, 'eu', 'eu'), _defineProperty(_CHAT_PUSHER_CLUSTERS, 'us-e', 'mt1'), _CHAT_PUSHER_CLUSTERS);

    function initConversationSocketService() {
        if (!window.TokenManager) {
            console.error('TokenManager is not set');
            return;
        }

        var accessToken = window.TokenManager.fromLocalStorage().AccessToken;

        var conf = {
            // cluster: clusters[window.TokenManager.getRegion()], //us-e was previously set as undefined
            // encrypted: true,
            // authEndpoint: Gecko.base_url+'/conversations/socket/auth',
            // authTransport: 'ajax',
            // // TODO: This probably needs updated with customHandler
            // auth :{
            //     headers: {
            //         Accept: 'application/json',
            //         'Content-Type': 'application/x-www-form-urlencoded',
            //         Authorization: `Bearer ${accessToken}`,
            //     },
            // },
            cluster: CHAT_PUSHER_CLUSTERS[window.TokenManager.getRegion()], //us-e was previously set as undefined
            channelAuthorization: {
                transport: 'ajax',
                endpoint: Gecko.base_url + '/conversations/socket/auth',
                customHandler: function customHandler(payload, callback) {
                    var socketId = payload.socketId,
                        channelName = payload.channelName;


                    try {
                        var body = new URLSearchParams({
                            socket_id: socketId,
                            channel_name: channelName
                        });

                        return Promise.resolve().then(function () {
                            return Gecko.refreshToken();
                        }).then(function () {
                            return fetch(Gecko.base_url + '/conversations/socket/auth', {
                                method: 'post',
                                body: body,
                                headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'application/x-www-form-urlencoded',
                                    Authorization: 'Bearer ' + accessToken
                                }
                            }).then(function (response) {
                                return response.json();
                            }).then(function (data) {
                                return callback(null, data);
                            });
                        });
                    } catch (error) {
                        console.error('Pusher channel auth error', error);
                        callback(error, null);
                    }
                }
            }
        };

        var pusher = new Pusher(CHAT_PUSHER_KEYS[window.TokenManager.getRegion()], conf);

        var channels = function () {
            try {
                var isRestrictedVisibility = Boolean(Gecko.user.only_assigned_conversations);
                var accountUuid = Gecko.account.uuid;
                var userAuthId = Gecko.user.auth_id;
                return ['private-account-' + accountUuid, isRestrictedVisibility ? 'private-restricted-' + accountUuid + '-' + userAuthId : 'private-unrestricted-' + accountUuid];
            } catch (error) {
                console.error('Error getting channelName', error);
                return [];
            }
        }().map(function (channel) {
            return pusher.subscribe(channel);
        });

        // TEMP: For debug purposes
        // pusher.connection.bind('connected', (res) => {
        //     console.log('CONVERSATION PUSHER: CONNECTED', res);
        // });
        // pusher.connection.bind('disconnected', (res) => {
        //     console.log('CONVERSATION PUSHER: DISCONNECTED', res);
        // });
        pusher.connection.bind('error', function (res) {
            console.error('CONVERSATION PUSHER: ERROR', res);
        });

        return Promise.resolve(channels);
    }

    var currentUserId = null;
    var currentUserTeamsIds = [];
    var unreadConversationIdsStore = [];
    var restrictedVisibility = false;

    function geckoSideMenuChatNotificationService($geckoSocket) {

        var _this = {

            initConversationSocketService: initConversationSocketService,

            getTeamsBelongingToUser: function getTeamsBelongingToUser() {
                return new Gecko.BaseModel().call('/chat_teams', 'GET', {
                    perPage: 10000,
                    orderBy: 'name',
                    sort: 'ASC'
                }, true).then(function (_ref) {
                    var data = _ref.data;

                    return data.filter(function (_ref2) {
                        var assignedUsers = _ref2.assignedUsers;
                        return assignedUsers.includes(Gecko.user.auth_id);
                    });
                });
            },

            getAllConversations: function getAllConversations() {
                return new Gecko.BaseModel().call('/conversations/all', 'POST', {
                    perPage: 50000,
                    assigned: 'me'
                }, true).then(function (_ref3) {
                    var data = _ref3.data;

                    return data;
                });
            },

            getUnreadConversations: function getUnreadConversations() {
                return Promise.all([_this.getTeamsBelongingToUser(), _this.getAllConversations()]).then(function (_ref4) {
                    var _ref5 = _slicedToArray(_ref4, 2),
                        teams = _ref5[0],
                        allConversations = _ref5[1];

                    // Store current user id and teams ids for later use
                    currentUserId = Gecko.user.auth_id;
                    currentUserTeamsIds = teams.map(function (_ref6) {
                        var teamId = _ref6.teamId;
                        return teamId;
                    });
                    restrictedVisibility = Boolean(Gecko.user.only_assigned_conversations);

                    // Filter conversations based on current user id and teams ids
                    var visibleConversations = (allConversations || []).filter(function (_ref7) {
                        var closedAt = _ref7.closedAt,
                            assignments = _ref7.assignments;

                        if (restrictedVisibility) {
                            return !closedAt && _this.userIsInAssignmentsArray(assignments);
                        }
                        return !closedAt;
                    });

                    // Filter conversations based on unread messages
                    var unreadConversations = visibleConversations.filter(function (_ref8) {
                        var unreadMessages = _ref8.unreadMessages;
                        return unreadMessages;
                    });

                    return {
                        unreadConversationIds: unreadConversations.map(function (_ref9) {
                            var conversationId = _ref9.conversationId;
                            return conversationId;
                        }),
                        hasUnreadConversations: Boolean(unreadConversations.length)
                    };
                });
            },

            // TODO: Make sound always work
            playNotificationSound: function playNotificationSound() {
                try {
                    var audio = new Audio('https://audio.geckoform.com/chat-notification.mp3');
                    var playPromise = audio.play();

                    if (playPromise !== undefined) {
                        playPromise.catch(function (error) {
                            // Auto-play was prevented
                            // Show a UI element to let the user manually start playback
                            console.error('Error playing notification sound', error);
                        });
                    }
                } catch (error) {
                    console.error('Error playing notification sound', error);
                }
            },

            updateFavicon: function updateFavicon(hasUnreadConversations) {
                try {
                    var faviconElement = document.getElementById('app-favicon');
                    faviconElement.href = hasUnreadConversations ? '/favicon-notification.868b0792.png' : '/favicon.d5c74a97.png';
                } catch (error) {
                    console.error('Error updating favicon', error);
                }
            },

            execute: function execute(hasUnreadConversations) {
                var toggleUnreadConversationsIndicatorFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
                var playSound = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

                if (hasUnreadConversations) {
                    toggleUnreadConversationsIndicatorFn(true);
                    if (playSound) _this.playNotificationSound();
                    _this.updateFavicon(true);
                    return;
                }

                toggleUnreadConversationsIndicatorFn(false);
                _this.updateFavicon(false);
            },

            addUnreadConversationAndExecute: function addUnreadConversationAndExecute(conversationId) {
                var toggleUnreadConversationsIndicatorFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};

                unreadConversationIdsStore.push(conversationId);
                if (unreadConversationIdsStore.length > 0) {
                    _this.execute(true, toggleUnreadConversationsIndicatorFn);
                }
            },

            removeUnreadConversationAndExecute: function removeUnreadConversationAndExecute(conversationId) {
                var toggleUnreadConversationsIndicatorFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};

                unreadConversationIdsStore = unreadConversationIdsStore.filter(function (id) {
                    return id !== conversationId;
                });
                if (unreadConversationIdsStore.length === 0) {
                    _this.execute(false, toggleUnreadConversationsIndicatorFn);
                }
            },

            userIsInAssignmentsArray: function userIsInAssignmentsArray(assignments) {
                return assignments.some(function (_ref10) {
                    var userId = _ref10.userId,
                        teamId = _ref10.teamId;
                    return userId === currentUserId || currentUserTeamsIds.includes(teamId);
                });
            },

            init: function init() {
                var toggleUnreadConversationsIndicatorFn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {};

                // Dont do anything when user does not have CHAT_CONVERSATIONS_VIEW ability
                if (!Gecko.able(Gecko.ABILITY_CHAT_CONVERSATIONS_VIEW)) return;

                // Watch for user.chat_notifications_enabled changes and reload when it does
                $geckoSocket.registerEvent('user:updated', function (data) {
                    var nextId = data.id;
                    var currentId = Gecko.user.id;
                    var nextChatNotificationsEnabled = data.chat_notifications_enabled;
                    var currentChatNotificationsEnabled = Gecko.user.chat_notifications_enabled;

                    if (nextId === currentId && nextChatNotificationsEnabled !== currentChatNotificationsEnabled) {
                        // Update the appData cache (in SessionStorage)
                        Gecko.Token.appData.updateCache({
                            user: {
                                chat_notifications_enabled: nextChatNotificationsEnabled
                            }
                        });
                        window.location.reload();
                    }
                }, true);

                // Stop if user chat notifications are DISABLED
                if (!Boolean(Gecko.user.chat_notifications_enabled)) return;

                try {
                    // Fetch unread conversations
                    _this.getUnreadConversations().then(function (_ref11) {
                        var hasUnreadConversations = _ref11.hasUnreadConversations,
                            unreadConversationIds = _ref11.unreadConversationIds;

                        if (!hasUnreadConversations) return;
                        _this.execute(true, toggleUnreadConversationsIndicatorFn, false);

                        unreadConversationIdsStore = unreadConversationIds;
                    });

                    // Bind events to Pusher channels
                    _this.initConversationSocketService().then(function (channels) {
                        channels.forEach(function (channel) {
                            // No need to check assignments when restrictedVisibility is ON, users are subscribed the restricted socket channel
                            channel.bind('messageWasAdded', function (_ref12) {
                                var conversationId = _ref12.conversationId,
                                    isExternalMessage = _ref12.isExternalMessage;

                                if (isExternalMessage && !unreadConversationIdsStore.includes(conversationId)) {
                                    _this.addUnreadConversationAndExecute(conversationId, toggleUnreadConversationsIndicatorFn);
                                }
                            });

                            // No need to check assignments when restrictedVisibility is ON, users are subscribed the restricted socket channel
                            channel.bind('conversationWasRead', function (_ref13) {
                                var conversationId = _ref13.conversationId;

                                // Only action when tracking conversationId
                                if (unreadConversationIdsStore.includes(conversationId)) {
                                    _this.removeUnreadConversationAndExecute(conversationId, toggleUnreadConversationsIndicatorFn);
                                }
                            });

                            channel.bind('assignmentsWereUpdated', function (_ref14) {
                                var conversationId = _ref14.conversationId,
                                    assignmentsAdded = _ref14.assignmentsAdded,
                                    assignmentsRemoved = _ref14.assignmentsRemoved;

                                // ONLY check assignments when restrictedVisibility is ON
                                if (!restrictedVisibility) return;

                                var userWasAddedToConversation = _this.userIsInAssignmentsArray(assignmentsAdded);
                                var userWasRemovedFromConversation = _this.userIsInAssignmentsArray(assignmentsRemoved);

                                if (userWasAddedToConversation) {
                                    _this.addUnreadConversationAndExecute(conversationId, toggleUnreadConversationsIndicatorFn);
                                } else if (userWasRemovedFromConversation) {
                                    _this.removeUnreadConversationAndExecute(conversationId, toggleUnreadConversationsIndicatorFn);
                                }
                            });

                            // Update user data when restricted visibility changes
                            channel.bind('conversationVisibilityWasUpdated', function (_ref15) {
                                var userId = _ref15.userId,
                                    only_assigned_conversations = _ref15.only_assigned_conversations;

                                if (userId !== currentUserId) return;

                                // Update the appData cache (in SessionStorage)
                                Gecko.Token.appData.updateCache({
                                    user: {
                                        only_assigned_conversations: only_assigned_conversations
                                    }
                                });

                                // Reload the page to reflect the new restricted visibility
                                window.location.reload();
                            });
                        });
                    });
                } catch (err) {
                    console.error('geckoSideMenuChatNotificationService.initConversationSocketService', err);
                }
            }
        };

        return _this;
    }

    angular.module('GeckoEngage').factory('geckoSideMenuChatNotificationService', geckoSideMenuChatNotificationService);
})();