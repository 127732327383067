(function () {
    'use strict';

    function geckoStatWidgetTable() {
        return {
            restrict: 'EA',
            template: '\n                <gecko-table\n                    cols="ctrl.cols"\n                    rows="ctrl.rows"\n                    hide-table-header="true">\n                </gecko-table>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {
                widget: '='
            },
            controller: function controller($scope) {
                var ctrl = this;

                $scope.$watch('ctrl', function () {

                    ctrl.rows = angular.isArray(ctrl.widget.related_stats[0].result) ? ctrl.widget.related_stats[0].result : [];

                    ctrl.cols = [{
                        key: 'title',
                        colClass: 'col-xs-9'
                    }, {
                        key: 'aggregate',
                        colClass: 'col-xs-3'
                    }];
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoStatWidgetTable', geckoStatWidgetTable);
})();