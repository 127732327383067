(function () {
    'use strict';

    var CHAT_AVAILABILITY_BANNER_DISMISSED = 'chat-availability-banner-dismissed';

    function chatAvailabilityService($geckoSocket) {
        var _this = {};

        _this.updateUserChatPressence = function (nextChatPresence) {
            // Update user object on window object
            Gecko.user.chat_presence = nextChatPresence;

            // Update the appData cache (in SessionStorage)
            Gecko.Token.appData.updateCache({
                user: {
                    chat_presence: Gecko.user.chat_presence
                }
            });

            // Show banner again when chat_presence changes (if it has been dismissed)
            if (nextChatPresence !== Gecko.User.PRESENCE_AVAILABLE) {
                sessionStorage.removeItem(CHAT_AVAILABILITY_BANNER_DISMISSED);
            }
        };

        _this.toggleLoggedInUserPresence = function (nextChatPresence) {
            if (Gecko.user) {

                return new Gecko.User().fill(Object.assign({}, Gecko.user, {
                    chat_presence: nextChatPresence
                })).save().then(function () {
                    // 1. Update user object on window object
                    // 2. Update the appData cache (in SessionStorage)
                    _this.updateUserChatPressence(nextChatPresence);
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                });
            }
            return Promise.reject();
        };

        _this.listenAndActionLoggedInUsersChatPresenceChanging = function () {
            $geckoSocket.registerEvent('user:updated', function (data) {
                var nextId = data.id;
                var currentId = Gecko.user.id;
                var nextChatPresence = data.chat_presence;
                var currentChatPresence = Gecko.user.chat_presence;

                if (nextId !== currentId || nextChatPresence === currentChatPresence) return;

                _this.updateUserChatPressence(nextChatPresence);
            }, true);
        };

        return _this;
    }

    function chatAvailabilityBanner() {
        return {
            restrict: 'E',
            template: '\n            \t<div class="chat-availability-banner" ng-if="ctrl.showAvailabilityBanner()">\n                    <div>\n                        <i class="fa fa-exclamation-circle"></i>\n                        Your status is currently set to \'Unavailable\'. <a ng-click="ctrl.togglePresence()">Click here</a> to make yourself available.\n                    </div>\n                    <button ng-click="ctrl.dismiss()">\n                        <i class="fa fa-times"></i>\n                    </button>\n                </div>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {},
            controller: function controller(chatAvailabilityService) {
                var ctrl = this;

                ctrl.showAvailabilityBanner = function () {
                    return Gecko.user.chat_presence !== Gecko.User.PRESENCE_AVAILABLE && !Boolean(sessionStorage.getItem(CHAT_AVAILABILITY_BANNER_DISMISSED));
                };

                ctrl.togglePresence = function () {
                    chatAvailabilityService.toggleLoggedInUserPresence(Gecko.User.PRESENCE_AVAILABLE);
                };

                ctrl.dismiss = function () {
                    sessionStorage.setItem(CHAT_AVAILABILITY_BANNER_DISMISSED, true);
                };

                // Listen and update user chat_presence when changed via sockets
                chatAvailabilityService.listenAndActionLoggedInUsersChatPresenceChanging();
            }
        };
    }

    angular.module('GeckoEngage').factory('chatAvailabilityService', chatAvailabilityService).directive('chatAvailabilityBanner', chatAvailabilityBanner);
})();