(function () {
    'use strict';

    function geckoCardEdit() {
        function resolveTemplate(tElement, tAttrs) {
            var templateUrl = '/components/gecko-card-edit/';
            templateUrl += tAttrs.tabs ? 'gecko-card-edit-tabs.html' : 'gecko-card-edit.html';
            return templateUrl;
        }

        return {
            restrict: 'E',
            scope: {
                // Normal attributes
                title: '@title',
                icon: '@icon',
                noHeaderTransclude: '@noHeaderTransclude',
                headerBtns: '=headerBtns',
                headerLabels: '=headerLabels',
                headerLabelsAction: '=headerLabelsAction',
                optionsBtn: '=optionsBtn',
                fields: '=fields',
                values: '=values',
                noFooterBtns: '=noFooterBtns',
                footerBtns: '=footerBtns',
                options: '=',
                action: '=action',
                padded: '@',
                paddedVertical: '@',
                paddedHorizontal: '@',
                // Tabbed attributes
                tabs: '=?tabs'
            },
            templateUrl: resolveTemplate,
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($scope) {
                var ctrl = this;

                $scope.$watch('ctrl', function () {
                    if (ctrl.optionsBtn && !Array.isArray(ctrl.optionsBtn)) {
                        ctrl.optionsBtn = [ctrl.optionsBtn];
                    }
                    // Padding defaults
                    ctrl.padded = typeof ctrl.padded === 'undefined' ? 'true' : ctrl.padded;
                });

                $scope.$watch('ctrl.tabs', function () {
                    // Tabs setup
                    if (GeckoUI.isArrayWithItems(ctrl.tabs)) {
                        // Returns the active tab
                        ctrl.activeTab = function () {
                            return ctrl.tabs.filter(function (tab) {
                                if (tab.activeWhen) return tab.activeWhen() === true;
                            })[0] || ctrl.tabs[0];
                        };

                        // Runs the tabs action, if there isn't one it just sets the clicked tab to active
                        ctrl.runAction = function (tab) {
                            if (tab.disabledWhen && tab.disabledWhen()) return;
                            if (tab.action) {
                                tab.action();
                            }
                        };
                    }
                });
            },
            transclude: true
        };
    }

    angular.module('GeckoEngage').directive('geckoCardEdit', geckoCardEdit);
})();