(function () {
    'use strict';

    function geckoCallPane() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-call/views/gecko-call-pane.html',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {},
            controller: function controller($rootScope, $scope, $state, $stateParams, $geckoCall, $geckoVoip, $geckoChannel, $geckoWatch, $interval) {
                var ctrl = this;
                $scope.$geckoCall = $geckoCall;

                ctrl.toggle = function (reset) {
                    // Close
                    if (reset || $geckoCall.hasCompleted()) {
                        $geckoCall.reset(true);
                    } else {
                        $geckoCall.close();
                    }
                };

                ctrl.data = $geckoCall.data;

                // Contact
                ctrl.contact = ctrl.data.contact;

                // Campaign
                ctrl.campaign = ctrl.data.campaign ? ctrl.data.campaign : false;
                ctrl.outcomes = ctrl.campaign ? ctrl.campaign.related_outcomes : [];

                // Numbers
                if (ctrl.contact && ctrl.contact.related_numbers) {
                    ctrl.numbers = ctrl.contact.related_numbers;
                } else {
                    ctrl.numbers = [];
                }

                // Prepare numbers from contact values references
                ctrl.numbers = $geckoCall.prepareNumbers(ctrl.numbers);

                if ($geckoCall.numberString) {
                    // Console.log('NumberString', ctrl.numberString);
                    ctrl.numberString = angular.copy($geckoCall.numberString);
                    $geckoCall.numberString = null;
                }

                // Number
                ctrl.number = $geckoCall.call ? $geckoCall.call.related_number : null;

                // Call result
                ctrl.result = {};

                // Watch for contact updates
                $geckoWatch($geckoCall.call, 'related_contact', function (value) {
                    if (value) ctrl.contact = value;
                });
                // Watch for campaign
                $geckoWatch($geckoCall.call, 'related_campaign', function (value) {
                    if (value) {
                        ctrl.campaign = value;
                        ctrl.outcomes = value.related_outcomes || [];
                    }
                });

                // Possible contacts (inbound only)
                if (ctrl.number && ctrl.number.related_contacts) {
                    ctrl.possibleContacts = ctrl.number.related_contacts;
                } else {
                    ctrl.possibleContacts = [];
                }
                // Possible campaigns (inbound only)
                if ($geckoCall.call && $geckoCall.call.related_sender && $geckoCall.call.related_sender.inbound_campaigns) {
                    ctrl.possibleCampaigns = $geckoCall.call.related_sender.inbound_campaigns;
                } else {
                    ctrl.possibleCampaigns = [];
                }

                // Showing cards when they're meant to be shown
                ctrl.showIndentifyCard = function () {
                    return (!ctrl.contact || $geckoCall.isInbound() && !ctrl.campaign && ctrl.possibleCampaigns && ctrl.possibleCampaigns.length) && Gecko.user && $geckoCall.call && $geckoCall.call.user_id === Gecko.user.id;
                };
                ctrl.showStartCard = function () {
                    return !$geckoCall.onCall && !$geckoCall.hasCompleted() && !ctrl.numberFix;
                };
                ctrl.showEndCard = function () {
                    return $geckoCall.hasStarted() || $geckoCall.isListening() || $geckoCall.isReconnecting();
                };
                ctrl.showCompleteCard = function () {
                    return $geckoCall.hasEnded() && !ctrl.updatingCallInfo && !$geckoCall.isReconnecting();
                };
                ctrl.showScriptCard = function () {
                    return ctrl.campaign && ctrl.campaign.form_id;
                };

                // Pane title
                ctrl.getPaneTitle = function () {
                    var title;

                    // Inbound
                    if ($geckoCall.isInbound() && $geckoCall.call.related_sender) {
                        title = $geckoCall.call.related_sender.name + ' - ' + $geckoCall.call.related_sender.tel;
                    }

                    // Outbound
                    if (!$geckoCall.call || $geckoCall.isOutbound()) {
                        if ($geckoCall.call) {
                            title = $geckoCall.call.related_contact && $geckoCall.call.related_contact.full_name ? $geckoCall.call.related_contact.full_name : 'Unknown contact';
                            if ($geckoCall.call.related_number) title += ' - ' + ($geckoCall.call.related_number.cleaned || $geckoCall.call.related_number.original);
                        } else {
                            title = 'New Call';
                        }
                    }

                    return title;
                };

                // Pane class
                ctrl.getPaneClass = function () {
                    return {
                        new: !$geckoCall.call || $geckoCall.hasPrepared(),
                        active: $geckoCall.hasStarted(),
                        ended: $geckoCall.hasEnded(),
                        completed: $geckoCall.hasCompleted()
                    };
                };

                // Timer
                ctrl.timerSetup = function (start) {
                    // Cancel running timers
                    if (ctrl.timer) $interval.cancel(ctrl.timer);

                    // Decide which call object
                    var call = $geckoCall.call;

                    var now = new Date().getTime() / 1000;
                    ctrl.call_time = Math.round(now - call.started_at);

                    // Reset time if less than grace
                    if (start && ctrl.call_time < 10) {
                        ctrl.call_time = 0;
                    }

                    ctrl.timer = $interval(function () {
                        ctrl.call_time++;
                    }, 1000);
                };

                // Start call
                ctrl.startCall = function () {

                    // Check number is set
                    if (!ctrl.numberId) return GeckoUI.messenger.error('A number is required to call a contact.');

                    // Check campaign status
                    if (ctrl.campaign && ctrl.campaign.status != Gecko.Campaign.ACTIVE) {
                        return GeckoUI.messenger.error('Sorry, this campaign is not currently active.');
                    }

                    // Check number is available
                    if (!ctrl.numbers.length) {
                        return GeckoUI.messenger.error('Sorry, no number available for this contact.');
                    }

                    // Check number is formatted (VOIP only)
                    var number = GeckoUI.gobk(ctrl.numbers, 'id', ctrl.numberId);
                    if ($geckoVoip.enabled && number && !number.valid) {
                        // Alias number
                        ctrl.numberFix = {
                            number: {
                                number: number.original,
                                original: number.original,
                                cleaned: number.cleaned, // This is makes the telephone field pick the correct flag.
                                country_code: number.country_code ? number.country_code.toLowerCase() : null,
                                dial_code: null
                            }
                        };
                        return GeckoUI.messenger.error('Amend number before calling this contact.');
                    }

                    // Show loading indicator
                    $geckoCall.loadingIndicator(true);

                    $geckoCall.start(ctrl.contact, ctrl.numberId).then(function (call) {
                        try {
                            console.log('START CALL DATA:', call);

                            // Check call status is correct
                            if (call.status !== Gecko.Call.STARTED) {
                                console.log('Start Call Data Fixed');
                                call.status = Gecko.Call.STARTED;
                                call.started_at = moment.utc().format('X');
                            }

                            $geckoCall.restore(call);
                            // Connect to VOIP
                            $geckoCall.startVoip();

                            // Start timer
                            try {
                                ctrl.timerSetup(true);
                            } catch (e) {
                                console.log(e); // eslint-disable-line no-console
                            }

                            // Sync subscriber
                            if (call.subscriber_id && call.subscriber_id == $stateParams.subscriber_id) {
                                $geckoChannel.set('subscriber', call.related_subscriber);
                            }

                            // Hide loading indicator
                            $geckoCall.loadingIndicator(false);

                            // Fire Intercom Event
                            GeckoUI.triggerIntercomEvent('Call Started Success: Contact', {
                                'call id': call.id,
                                'contact id': ctrl.contact.id,
                                'contact name': ctrl.contact.full_name,
                                'number id': ctrl.numberId
                            });
                        } catch (err) {
                            console.log('START CALL ERR:', err);
                        }
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err.errors);
                        // Hide loading indicator
                        $geckoCall.loadingIndicator(false);
                        // Fire Intercom Event
                        GeckoUI.triggerIntercomEvent('Call Started Error: Contact', {
                            'contact id': ctrl.contact.id,
                            'contact name': ctrl.contact.full_name,
                            'number id': ctrl.numberId,
                            'error': GeckoUI.renderError(err.errors)
                        });
                    });
                };

                // End Call
                ctrl.endCall = function (update) {

                    // Check call has not already been ended
                    if (ctrl.callEnded && !update) return;

                    // Show loading indicator
                    $geckoCall.loadingIndicator(true);

                    $geckoCall.end().then(function (call) {
                        try {
                            console.log('END CALL DATA:', call);

                            // Check call status is correct
                            if (call.status !== Gecko.Call.ENDED) {
                                console.log('End Call Data Fixed');
                                call.status = Gecko.Call.ENDED;
                                call.ended_at = moment.utc().format('X');
                            }

                            $geckoCall.restore(call);

                            // Sync subscriber
                            if (call.subscriber_id && call.subscriber_id == $stateParams.subscriber_id) {
                                $geckoChannel.set('subscriber', call.related_subscriber);
                            }

                            // Hide loading indicator
                            $geckoCall.loadingIndicator(false);
                        } catch (err) {
                            console.log('END CALL ERR:', err);
                        }
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err.errors);
                        // Hide loading indicator
                        $geckoCall.loadingIndicator(false);

                        // Fire Intercom Event
                        GeckoUI.triggerIntercomEvent('Call Ended Error', {
                            'error': GeckoUI.renderError(err.errors)
                        });
                    });

                    // Mark call as ended
                    ctrl.callEnded = true;

                    // Disconnect VOIP
                    $geckoCall.endVoip();
                };

                // Complete call
                ctrl._completeCall = function () {

                    // There is a contact selection / creation NOT saved
                    if (!$geckoCall.call.contact_id) {
                        var unsavedContactSelection = ctrl.contactId;
                        var unsavedContactCreate = ((ctrl.contactNew || {}).name || {}).first_name || ((ctrl.contactNew || {}).name || {}).last_name || (ctrl.contactNew || {}).email;

                        if (unsavedContactSelection || unsavedContactCreate) {
                            return ctrl.outcomeError = 'You must save the contact selection before completing the call';
                        }
                    }

                    // Make sure call connection has been destroyed
                    if ($geckoVoip.connectionExists()) $geckoVoip.destroy();

                    ctrl.outcomeError = false;

                    // Check for outcome
                    if (!ctrl.result.outcome) return ctrl.outcomeError = 'Select an outcome to complete call.';

                    // Check to see if a note field is required
                    if (!ctrl.result.note && GeckoUI.gobk(ctrl.outcomes, 'id', ctrl.result.outcome).require_note == 1) {
                        return ctrl.outcomeError = 'Enter a note to complete call.';
                    }

                    // Mark call as do not call
                    if (ctrl.result.do_not_call || ctrl.result.outcome && GeckoUI.gobk(ctrl.outcomes, 'id', ctrl.result.outcome).action == Gecko.Call.ACTION_DO_NOT_CALL) {
                        ctrl.result.action = Gecko.Call.ACTION_DO_NOT_CALL;
                    } else {
                        ctrl.result.action = null;
                    }

                    // Check Call at date
                    ctrl.result.call_from = null;
                    if (GeckoUI.gobk(ctrl.outcomes, 'id', ctrl.result.outcome).action == Gecko.Outcome.ACTION_RESCHEDULE && ctrl.result.call_at) {
                        ctrl.result.call_from = ctrl.result.call_at;
                    }

                    // Show loading indicator
                    $geckoCall.loadingIndicator(true);

                    $geckoCall.complete(ctrl.result.outcome, ctrl.result.action, ctrl.result.call_from, ctrl.result.note).then(function (call) {

                        // Hide loading indicator
                        $geckoCall.loadingIndicator(false);

                        // Refresh Subscriber
                        if (call.subscriber_id && call.subscriber_id == $stateParams.subscriber_id) {
                            call.related_subscriber.related_calls = [call];
                            $geckoChannel.set('subscriber', call.related_subscriber);
                        }

                        // Refresh contact activity section
                        if (['contact.overview', 'contact.subscriber'].indexOf($state.$current.name) !== -1 && call.contact_id == $stateParams.contact_id) {
                            $state.go($state.$current.name, $stateParams, { reload: true });
                        }

                        // Add to call list
                        if ($state.$current.name == 'contact.calls' && call.contact_id == $stateParams.contact_id) {
                            $geckoChannel.set('calls', [call].concat($geckoChannel.get('calls')));
                        }

                        // Reset call is no campaign
                        if (!call.subscriber_id) {
                            $geckoCall.reset(true);
                        } else {
                            //$geckoCall.restore(call); // This was causing issue when navigating and using 'next call'
                            $geckoCall.reset(true);
                            $geckoCall.onCall = false;
                        }

                        $scope.$digest();

                        // Get next in the queue
                        $geckoVoip.accept = true;
                    }).catch(function (err) {
                        // Hide loading indicator
                        $geckoCall.loadingIndicator(false);
                        ctrl.outcomeError = GeckoUI.renderError(err.errors);
                        $scope.$digest();

                        // Fire Intercom Event
                        GeckoUI.triggerIntercomEvent('Call Completed Error', {
                            'error': GeckoUI.renderError(err.errors)
                        });
                    });
                };

                // Check for unsubmitted scripts
                ctrl.completeCall = function () {

                    // Check that the response form isn't dirty
                    if (ctrl.formCtrl && ctrl.formCtrl.$dirty) {
                        GeckoUI.dialog.confirmCustom('You have entered data in the call response. Do you want to submit now?', [{ base: 'YES', text: 'Save and complete' }, GeckoUI.dialog.customButton('Discard response', 'discard'), { base: 'NO' }], function (result) {

                            if (result === 'discard') {
                                ctrl.discardForm();
                                ctrl._completeCall();
                                return;
                            } else if (result) {
                                ctrl.submitForm().then(function () {
                                    ctrl._completeCall();
                                });
                                return;
                            }
                        });
                    } else {
                        return ctrl._completeCall();
                    }
                };

                // Abort call
                ctrl.abortCall = function () {
                    GeckoUI.dialog.confirm('Are you sure you want to abort this call?', function (value) {
                        if (value) {

                            // Make sure call connection has been destroyed
                            if ($geckoVoip.connectionExists()) $geckoVoip.destroy();

                            // Show loading indicator
                            $geckoCall.loadingIndicator(true);

                            $geckoCall.cancel().then(function (call) {
                                $geckoCall.reset(true);

                                // Clear aborted subscriber
                                if ($geckoCall.isOutbound() && call.subscriber_id && call.related_subscriber) {
                                    $geckoCall.initSubscriber(call.related_subscriber).then(function (call) {
                                        $state.go($state.$current.name, $stateParams, { relaod: true });
                                        $geckoCall.restore(call);
                                    });
                                }

                                $scope.$digest();

                                // Get next in the queue
                                $geckoVoip.accept = true;

                                // Hide loading indicator
                                $geckoCall.loadingIndicator(false);
                            }).catch(function (err) {
                                GeckoUI.messenger.error(err.errors);
                                // Hide loading indicator
                                $geckoCall.loadingIndicator(false);

                                // Fire Intercom Event
                                GeckoUI.triggerIntercomEvent('Call Aborted Error', {
                                    'error': GeckoUI.renderError(err.errors)
                                });
                            });
                        }
                    });
                };

                /* =======================================================
                    VOIP Events
                ======================================================= */

                // VOIP is enabled
                if ($geckoVoip.enabled) {

                    // Deregister
                    if ($geckoCall.voipEnd != undefined) $geckoCall.voipEnd();
                    if ($geckoCall.voipCancel != undefined) $geckoCall.voipCancel();
                    if ($geckoCall.voipIncoming != undefined) $geckoCall.voipIncoming();

                    // Register
                    // VOIP call ends
                    $geckoCall.voipEnd = $rootScope.$on('voip:ended', function () {
                        // Only fire if user is logged in
                        if (Gecko.user) {
                            // End call if call belongs to user (reset call pane if it does not)
                            if ($geckoCall.call && $geckoCall.call.user_id === Gecko.user.id) {
                                ctrl.endCall();
                            } else {
                                $geckoCall.reset(true);
                            }
                        }
                    });

                    // VOIP call cancelled
                    $geckoCall.voipCancel = $rootScope.$on('voip:cancelled', function () {
                        // Reset call (and hide call menu)
                        $geckoCall.reset(true);
                    });

                    // VOIP call incoming
                    $geckoCall.voipIncoming = $rootScope.$on('voip:incoming', function () {
                        // Reset call (and hide call menu)
                        if (!$geckoCall.onCall) $geckoCall.reset(true);
                    });
                }
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCallPane', geckoCallPane);
})();