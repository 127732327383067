var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

(function () {

    var getMessageType = function getMessageType(additionalParams) {
        if (additionalParams && (typeof additionalParams === 'undefined' ? 'undefined' : _typeof(additionalParams)) === 'object' && additionalParams.type) {
            return additionalParams.type;
        }
        if (additionalParams && (typeof additionalParams === 'undefined' ? 'undefined' : _typeof(additionalParams)) !== 'object') {
            return additionalParams;
        }
        return Gecko.Message.TYPE_EMAIL;
    };

    function MessageModals($geckoModalProvider) {

        $geckoModalProvider.modalAdd('messageViewModal', function (_message) {
            return {
                controllerAs: 'ctrl',
                templateUrl: '/components/_modals/message/modal_view.html',
                controller: 'MessageViewModalCtrl',
                resolve: {
                    message: function message() {
                        return _message;
                    }
                }
            };
        }).modalAdd('messageSendModal', function (contactOrConditions, _additionalParams) {
            return {
                templateUrl: '/components/_modals/message/modal_send.html',
                controller: 'MessageSendModalCtrl',
                controllerAs: 'ctrl',
                size: 'lg',
                resolve: {
                    contact: function contact() {
                        return contactOrConditions;
                    },
                    additionalParams: function additionalParams() {
                        if (_additionalParams && (typeof _additionalParams === 'undefined' ? 'undefined' : _typeof(_additionalParams)) === 'object') {
                            return _additionalParams;
                        }
                        return {};
                    },
                    type: function type() {
                        return getMessageType(_additionalParams);
                    },
                    accountSender: function accountSender() {
                        if (contactOrConditions instanceof Gecko.Contact && getMessageType(_additionalParams) === Gecko.Message.TYPE_EMAIL) {
                            return;
                        } else if (getMessageType(_additionalParams) === Gecko.Message.TYPE_SMS) {
                            return new Gecko.Sender().rfields({ sender: ['name', 'tel'] }).get(Gecko.account.sms_sender_id).catch(function (err) {
                                return GeckoUI.messenger.error(err);
                            });
                        }
                        return;
                    }
                }
            };
        });
    }

    function MessageViewModalCtrl(Gecko, $modalInstance, message) {
        var ctrl = this;
        ctrl.message = message;

        if (ctrl.message.type == Gecko.Message.TYPE_SMS) {
            ctrl.subject = 'SMS Message';
        } else {
            ctrl.subject = ctrl.message.subject || 'Email Message';
        }

        // Prepare message content
        ctrl.body = ctrl.message.body;

        // Modal buttons
        ctrl.footerBtns = [{
            id: 'close',
            title: 'Close',
            icon: 'fa-times',
            btnClass: 'btn-default',
            action: function action() {
                $modalInstance.dismiss();
            }
        }];
    }

    function MessageSendModalCtrl($scope, $state, $filter, $modalInstance, $q, contact, type, additionalParams, asyncOptions, asyncCache, accountSender, handleMassAction, messageService, openTemplatePreviewModal) {
        var ctrl = this;
        ctrl.contact = contact;
        ctrl.contactCount = '—';
        ctrl.msgType = type === Gecko.Message.TYPE_SMS ? "SMS" : "Email";
        ctrl.non_gsm_words = null;

        // Convert contact Id array to conditions object
        if (angular.isArray(ctrl.contact) && !angular.isObject(ctrl.contact[0])) {
            ctrl.contact = [{ model: 'contact_ids', contact_ids: ctrl.contact }];
        }

        if (!ctrl.contact.sendEmail) {
            $q.when(new Gecko.Contact().searchCount(ctrl.contact)).then(function (count) {
                ctrl.message.to = count + ' contacts';
                ctrl.contactCount = count;
            });
        }

        var defaultSenderId = type === Gecko.Message.TYPE_SMS ? Gecko.account.sms_sender_id : !ctrl.contact.sendEmail ? Gecko.account.sender_id : 0;
        ctrl.message = {
            to: ctrl.contact.full_name ? ctrl.contact.full_name + (type === Gecko.Message.TYPE_EMAIL ? ' <' + ctrl.contact.email + '>' : '') : '...',
            subject: null,
            message: null,
            template_id: null,
            sender_id: defaultSenderId,
            ignore_consents: 0
        };

        ctrl.loadingTemplate = false;
        ctrl.loadingTemplate = false;

        // Populate message with template
        $scope.$watch('ctrl.message.template_id', function (newId, oldId) {
            if (newId !== oldId) {
                if (newId === null) {
                    ctrl.message.subject = '';
                    ctrl.message.message = '';
                    // Check contact consents
                    ctrl.checkContactConsent();
                } else {
                    ctrl.loadingTemplate = true;
                    var templateRfields = {
                        template: ['subject', 'body', 'include_wrapper', 'disable_editor', 'editor_type'],
                        consent: ['title', 'group_email', 'group_sms']
                    };
                    asyncCache.get(new Gecko.Template().include('current_granted_consents').rfields(templateRfields), newId).then(function (template) {
                        ctrl.message.subject = template.subject;
                        ctrl.message.message = template.body;
                        ctrl.loadingTemplate = false;
                        ctrl.disableEditor = template.disable_editor || template.editor_type === Gecko.Template.EDITOR_TYPE_DRAGDROP || false;
                        // Check template consents
                        ctrl.checkContactTemplateConsent(template);
                    });
                }
            }
        });

        // Send email
        ctrl.sendEmail = function () {

            // Dont send blank email
            if (type === Gecko.Message.TYPE_SMS && !ctrl.message.message) {
                return ctrl.errorMessage = "A message is required.";
            } else if (type === Gecko.Message.TYPE_EMAIL && (!ctrl.message.subject || !ctrl.message.message)) {
                return ctrl.errorMessage = 'A subject line and message is required.';
            } else {
                ctrl.errorMessage = '';
            }

            if (type === Gecko.Message.TYPE_SMS || !ctrl.contact.sendEmail) {
                // Confirmation message text
                if (type === Gecko.Message.TYPE_SMS) {
                    var confirmMessage = 'You are about to message ' + ctrl.contactCount + ' contact' + (ctrl.contactCount !== 1 ? 's' : '') + '. This action is not reversible. Please note that messages will not be sent to contacts without a valid mobile number.';
                } else {
                    var confirmMessage = 'Send Email to ' + ctrl.contactCount + ' contacts?';
                }

                GeckoUI.dialog.confirm(confirmMessage, function (confirmed) {
                    if (confirmed) {

                        if (type === Gecko.Message.TYPE_SMS && ctrl.contact.id) {
                            var conditions = [{
                                model: 'contact_ids',
                                contact_ids: [ctrl.contact.id]
                            }];
                        } else {
                            var conditions = ctrl.contact;
                        }

                        // Disable send btn
                        ctrl.sendBtnIsDisabled = true;

                        new Gecko.Contact().massAction(conditions, Gecko.Workflow.Actions.messageCustom(type, ctrl.message.subject, ctrl.message.message, ctrl.message.sender_id, ctrl.message.template_id, additionalParams.event_id, ctrl.message.ignore_consents, ctrl.message.field_id, ctrl.message.attach_ics)).then(function (data) {
                            // Var number = /\d+/.exec(data.message)[0];
                            // If (type === Gecko.Message.TYPE_SMS) {
                            //     GeckoUI.messenger.success("SMS will be sent to all contacts with valid phone numbers.");
                            // }
                            // Else {
                            //     GeckoUI.messenger.success('Email will be sent to ' + number + ' contacts shortly.');
                            // }

                            // Handle mass action
                            handleMassAction(data);
                            // Enable send btn
                            ctrl.sendBtnIsDisabled = false;
                            // Close modal
                            $modalInstance.close(data);
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err.errors);
                            // Enable send btn
                            ctrl.sendBtnIsDisabled = false;
                            $modalInstance.close();
                        });
                    }
                });
            } else {
                // Disable send btn
                ctrl.sendBtnIsDisabled = true;

                ctrl.contact.include('messages.sender', 'messages.user').sendEmail(ctrl.message.subject, ctrl.message.message, ctrl.message.sender_id, ctrl.message.template_id, additionalParams.event_id, ctrl.message.ignore_consents).then(function (raw_message) {
                    var message = new Gecko.Message().fill(raw_message.message);
                    if ([Gecko.Message.STATUS_DELIVERED, Gecko.Message.STATUS_SENDING, Gecko.Message.STATUS_SENT].indexOf(message.status) !== -1) {
                        GeckoUI.messenger.success('Email is being sent');
                    } else if (message.status === Gecko.Message.STATUS_NON_VERIFIED_SENDER) {
                        GeckoUI.messenger.error('Email has not been sent. The sender has not been verified.');
                    } else {
                        GeckoUI.messenger.error('Email failed to send.');
                    }
                    // Enable send btn
                    ctrl.sendBtnIsDisabled = false;
                    $modalInstance.close(message);
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                    // Enable send btn
                    ctrl.sendBtnIsDisabled = false;
                    $modalInstance.close();
                });
            }
        };

        var senderRequest = new Gecko.Sender().rfields({ sender: ['name', 'email', 'type', 'verified'] });
        if (type && type === Gecko.Message.TYPE_SMS) {
            senderRequest.where('outgoing_sms', 1).perPage('all');
        } else {
            senderRequest.where('type', Gecko.Sender.EMAIL);
        }

        var initialSenderOptions;

        if (ctrl.contact instanceof Gecko.Contact && type === Gecko.Message.TYPE_EMAIL) {
            initialSenderOptions = [{ id: 0, name: 'You', email: Gecko.user.email }];
        } else {
            initialSenderOptions = [accountSender];
        }

        var checkBodyGSM = function checkBodyGSM(value) {
            var non_gsm_words = messageService.nonGSMCharacters(value);
            ctrl.non_gsm_words = non_gsm_words ? non_gsm_words.join(', ') : null;
        };

        ctrl.contactConsents = [];

        var buildConsentAlert = function buildConsentAlert(consents) {
            if (consents && consents.length) consents = consents.map(function (c) {
                return c.title;
            }).join(', ');

            if (!(ctrl.contact instanceof Gecko.Contact)) {
                if (consents && consents.length) {
                    var str = 'The message will only be sent if the contact has consented to one of the following: <strong>' + consents + '</strong>.';
                } else {
                    var str = 'The message will only be sent if the contact has consented to receive ' + type + ' messages.';
                }
            } else {
                if (consents && consents.length) {
                    var str = 'This contact has not consented to <strong>' + consents + '</strong>. This is required in order to send messages with this template.';
                } else {
                    var str = 'This contact has not consented to receive ' + type + ' messages.';
                }
            }
            str = '<div class="alert alert-warning" style="margin:0;"><p>' + str + '</p></div>';

            // Update the alert field
            if (ctrl.fields && ctrl.fields.length) {
                ctrl.fields.find(function (f) {
                    return f.id === 'no_template_consent';
                }).description = str;
            }

            return str;
        };

        ctrl.checkContactConsent = function () {
            // Only run if individual contact
            if (!(ctrl.contact instanceof Gecko.Contact) || !Gecko.able(Gecko.ABILITY_CONSENTS_VIEW) || Gecko.able(Gecko.ABILITY_SKIP_GDPR_BLOCK)) return;

            new Gecko.Contact().include('consents').rfields({ contact: ['id', 'consent_data'], consent: ['group_email', 'group_sms'] }).get(ctrl.contact.id).then(function (contact) {

                ctrl.contactConsents = contact.related_consents;

                // Check for email consents
                if (type === Gecko.Message.TYPE_EMAIL && !ctrl.contactConsents.filter(function (c) {
                    return c.group_email;
                }).length) {
                    ctrl.contactHasConsent = false;
                }
                // Check for sms consents
                if (type === Gecko.Message.TYPE_SMS && !ctrl.contactConsents.filter(function (c) {
                    return c.group_sms;
                }).length) {
                    ctrl.contactHasConsent = false;
                }
                $scope.$digest();
            });
            ctrl.contactHasConsent = true;
        };

        ctrl.checkContactTemplateConsent = function (template) {

            if (!Gecko.able(Gecko.ABILITY_CONSENTS_VIEW) || Gecko.able(Gecko.ABILITY_SKIP_GDPR_BLOCK)) return;

            // Only run if individual contact
            if (!(ctrl.contact instanceof Gecko.Contact)) {
                return ctrl.checkMassActionTemplateConsent(template);
            }

            if (ctrl.message.template_id && template && template.related_current_granted_consents.length) {
                ctrl.contactHasConsent = true;

                templateConsents = template.related_current_granted_consents;
                // Check for contact has consents specified by templaye
                var templateConsentsIds = templateConsents.map(function (tc) {
                    return tc.id;
                });
                var contactHasTemplateConsent = ctrl.contactConsents.filter(function (cc) {
                    return templateConsentsIds.indexOf(cc.id) !== -1;
                }).length;

                // Show alert if contact does not have template consents required
                if (!contactHasTemplateConsent) {
                    buildConsentAlert(templateConsents);
                    // Hacky hideWhen fix (sigh)
                    setTimeout(function () {
                        return ctrl.contactHasConsent = false;
                    });
                }
            } else {
                buildConsentAlert();
                ctrl.checkContactConsent();
            }
        };

        ctrl.checkMassActionTemplateConsent = function (template) {
            // Only run for mass action
            if (ctrl.contact instanceof Gecko.Contact || !Gecko.able(Gecko.ABILITY_CONSENTS_VIEW) || Gecko.able(Gecko.ABILITY_SKIP_GDPR_BLOCK)) return;

            ctrl.contactHasConsent = true;

            if (ctrl.message.template_id && template && template.related_current_granted_consents && template.related_current_granted_consents.length) {
                buildConsentAlert(template.related_current_granted_consents);
            } else {
                buildConsentAlert();
            }

            // Hacky hideWhen fix (sigh)
            setTimeout(function () {
                return ctrl.contactHasConsent = false;
            });
        };

        // Check consents when modal is first opened (in order to show a warning)
        ctrl.checkContactConsent();
        ctrl.checkMassActionTemplateConsent();

        ctrl.goTo = function (state) {
            $state.go(state);
            $modalInstance.close();
        };

        // Only include event tags when sending from an event
        var tagModels = [Gecko.Template.TAG_CONTACT, Gecko.Template.TAG_SENDER, Gecko.Template.TAG_ACCOUNT];
        if (additionalParams.event_id) tagModels.push(Gecko.Template.TAG_EVENTS);

        // Field list
        ctrl.fields = [{
            id: 'to',
            label: 'To',
            disabledWhen: function disabledWhen() {
                return true;
            },
            colClass: 'none'
        }, {
            id: 'sender_id',
            label: 'From',
            description: type === Gecko.Message.TYPE_EMAIL ? 'Choose who will send this email. Senders can be managed in <a ui-sref="verifiedsenders.senders">Verified Senders & Domains</a>.' : 'Choose who will send this SMS. Senders can be managed in <a ui-sref="voipnumbers">VoIP Numbers</a>.',
            placeholder: 'Search and Select',
            type: Gecko.Field.TYPE_SELECT,
            options: initialSenderOptions,
            getOptions: asyncOptions.create(senderRequest.orderBy('name'), function () {
                if (ctrl.contact instanceof Gecko.Contact && type === Gecko.Message.TYPE_EMAIL) this.options.unshift({ id: 0, name: 'You', email: Gecko.user.email });
            }),
            optionsGroup: function optionsGroup(item) {
                if (type === Gecko.Message.TYPE_SMS) {
                    return item && item.type && item.type === Gecko.Sender.GECKOCHAT ? 'GeckoChat' : undefined;
                } else if (type === Gecko.Message.TYPE_EMAIL) {
                    return item.verified ? 'Verified' : 'Not Verified';
                }
                return;
            },
            choiceTemplate: 'name-email-choice',
            matchTemplate: 'name-email-match',
            noBlank: true,
            colClass: 'none'
        }, {
            id: 'template_id',
            label: 'Template',
            description: '(Optional) Choose a template for this message. Need to create a new template? <a ui-sref="settingstemplate.settings({ template_id: \'new\' })">Click here</a>.',
            type: Gecko.Field.TYPE_SELECT,
            options: [],
            getOptions: asyncOptions.create(new Gecko.Template().where('type', type === Gecko.Message.TYPE_SMS ? Gecko.Template.TYPE_SMS : Gecko.Template.TYPE_EMAIL).orderBy('name').rfields({ template: ['name'] })),
            optionsKey: 'id',
            optionsLabelKey: 'name',
            colClass: 'none'
        }, {
            type: Gecko.Field.TYPE_BUTTON,
            colClass: 'none gecko-field-template-preview-btn-container',
            btnLabel: 'Preview Template',
            icon: 'fa-eye',
            hideWhen: function hideWhen() {
                return !ctrl.message.template_id;
            },
            action: function action() {
                return ctrl.message.template_id ? openTemplatePreviewModal(ctrl.message.template_id) : null;
            }
        }, {
            id: 'field_id',
            label: 'Number to SMS',
            description: 'The preferred contact field to SMS.',
            placeholder: 'Automatically choose number',
            type: Gecko.Field.TYPE_SELECT,
            options: [],
            getOptions: asyncOptions.create(new Gecko.Field().rfields({ field: ['label'] }).where('field_type', Gecko.Field.FIELD_TYPE_CONTACT).where('type', Gecko.Field.TYPE_TEL).orderBy('label'), null, { search: 'keywordSearch' }),
            optionsKey: 'id',
            optionsLabelKey: 'label',
            colClass: 'none',
            hideWhen: function hideWhen() {
                return type !== Gecko.Message.TYPE_SMS;
            }
        }, {
            // Contact consent warning
            id: 'no_contact_consent',
            type: Gecko.Field.TYPE_SCRIPT,
            colClass: 'none',
            description: buildConsentAlert(),
            hideWhen: function hideWhen() {
                return ctrl.message.template_id || ctrl.contactHasConsent || ctrl.message.ignore_consents || Gecko.able(Gecko.ABILITY_SKIP_GDPR_BLOCK);
            }
        }, {
            // TEMPLATE consent warning
            id: 'no_template_consent',
            type: Gecko.Field.TYPE_SCRIPT,
            colClass: 'none',
            description: buildConsentAlert(),
            hideWhen: function hideWhen() {
                return !ctrl.message.template_id || ctrl.contactHasConsent || ctrl.message.ignore_consents || Gecko.able(Gecko.ABILITY_SKIP_GDPR_BLOCK);
            }
        }, {
            id: 'attach_ics',
            label: 'Attach ICS File',
            type: Gecko.Field.TYPE_TOGGLE,
            colClass: 'none',
            description: 'When enabled, an ICS calendar file for the event will be attached to the email.',
            hideWhen: function hideWhen() {
                return !additionalParams.event_id;
            }
        }, {
            id: 'ignore_consents',
            label: 'Ignore Consent Preferences',
            type: Gecko.Field.TYPE_TOGGLE,
            colClass: 'none',
            description: 'When enabled, messages will be sent without checking the contact\'s consent preferences.',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CONSENTS_VIEW) || Gecko.able(Gecko.ABILITY_SKIP_GDPR_BLOCK);
            }
        }, {
            id: 'subject',
            label: 'Email Subject',
            description: 'This will be shown in the Subject area of the email client.',
            type: Gecko.Field.TYPE_TEXT,
            colClass: 'none',
            required: true,
            hideWhen: function hideWhen() {
                return type === Gecko.Message.TYPE_SMS;
            },
            disabledWhen: function disabledWhen() {
                return ctrl.loadingTemplate;
            }
        }, {
            id: 'message',
            label: 'Message',
            type: Gecko.Field.TYPE_TEXTAREA,
            counter: 160,
            description: 'Please note using template tags in your SMS template may cause your message to be significantly longer than the currently indicated character count. This will result in your messages being sent across multiple texts.',
            colClass: 'none',
            required: true,
            templateTagBuilderRef: 'message_input_field',
            onChange: function onChange(obj) {
                return checkBodyGSM(obj.value);
            },
            hideWhen: function hideWhen() {
                return type === Gecko.Message.TYPE_EMAIL;
            },
            disabledWhen: function disabledWhen() {
                return ctrl.loadingTemplate;
            }
        }, {
            id: 'message',
            label: 'Message',
            type: Gecko.Field.TYPE_RICHTEXT,
            format: 'fullpage',
            colClass: 'none',
            required: true,
            templateTagBuilderRef: 'message_input_field',
            hideWhen: function hideWhen() {
                return type === Gecko.Message.TYPE_SMS || ctrl.disableEditor;
            },
            disabledWhen: function disabledWhen() {
                return ctrl.loadingTemplate;
            }
        }, {
            id: 'message',
            id2: 'message_editor_disabled',
            label: 'Message',
            type: Gecko.Field.TYPE_TEXTAREA,
            colClass: 'none',
            required: true,
            templateTagBuilderRef: 'message_input_field',
            hideWhen: function hideWhen() {
                return !(type === Gecko.Message.TYPE_EMAIL && ctrl.disableEditor);
            },
            disabledWhen: function disabledWhen() {
                return ctrl.loadingTemplate;
            },
            description: 'The WYSIWYG editor has been disabled for this template.'
        }, {
            id: 'message',
            label: 'Input Template Tags',
            type: Gecko.Field.TYPE_TAG_BUILDER,
            models: tagModels,
            inputId: 'message_input_field',
            description: 'Select from each dropdown and then hit the <code>+</code> button to insert the tag into your message. For more information, <a href="https://academy.geckoengage.com/en/articles/6715486-template-tags" title="Email & SMS Template Tags">click here</a>.',
            colClass: 'none',
            required: false
        }];

        // Modal buttons
        ctrl.footerBtns = [{
            id: 'send',
            title: 'Send',
            icon: 'fa-check',
            btnClass: 'btn-primary',
            disabledWhen: function disabledWhen() {
                return ctrl.sendBtnIsDisabled;
            },
            action: function action() {
                ctrl.sendEmail();
            }
        }];
    }

    angular.module('GeckoEngage').config(MessageModals).controller('MessageViewModalCtrl', MessageViewModalCtrl).controller('MessageSendModalCtrl', MessageSendModalCtrl);
})();