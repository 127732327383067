(function () {
    'use strict';

    function geckoReport() {
        var sum = function sum(keyList) {
            return function (item) {
                return keyList.reduce(function (sum, key) {
                    return sum += item[key] || 0;
                }, 0);
            };
        };
        var avg = function avg(keyList) {
            return function (item) {
                return sum(keyList)(item) / keyList.length;
            };
        };

        return {
            restrict: 'EA',
            templateUrl: '/components/gecko-report/gecko-report.html',
            scope: {
                cols: '=cols',
                groups: '=groups'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($scope) {
                var ctrl = this;

                ctrl.tableColWidth = function (cols) {
                    return 100 / cols.length + '%';
                };
                ctrl.isGroupShowing = function (group) {
                    return group.showWhen ? group.showWhen() : group.showing;
                };
                ctrl.toggleGroupShow = function (group) {
                    group.showing = !group.showing;
                };
                ctrl.renderItem = function (item, col) {
                    var value = item[col.key];
                    if (col.aggregate) {
                        value = col.aggregate(item, col);
                    }
                    if (col.sum) {
                        value = sum(col.sum)(item);
                    }
                    if (col.avg) {
                        value = avg(col.avg)(item);
                    }
                    if (!col.ignoreRender && item.render) {
                        return item.render(value, item) || 0;
                    } else {
                        return value || 0;
                    }
                };

                $scope.$watch('ctrl', function () {
                    ctrl.vtables = ctrl.cols.reduce(function (tables, col) {
                        if (col.static) {
                            // If the column is static push a new table to the array with just that column
                            tables.push({ type: 'static', cols: [col], staticWidth: col.staticWidth });
                        } else {
                            if (tables.length > 0) {
                                // If there is at least a table in the array get the last one
                                var lastTable = tables[tables.length - 1];
                                if (lastTable.type === 'static') {
                                    // If the last table is static push a new table with this column
                                    tables.push({ cols: [col] });
                                } else {
                                    // If it's not static add this column to the last table
                                    lastTable.cols.push(col);
                                }
                            } else {
                                // If there aren't any tables yet push one with this column
                                tables.push({ cols: [col] });
                            }
                        }
                        return tables;
                    }, []);

                    if (ctrl.groups[0].showing === undefined) {
                        ctrl.groups[0].showing = true;
                    }
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoReport', geckoReport);
})();