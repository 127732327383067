(function () {
    'use strict';

    var integration_designs = [{
        id: "radius",
        image: "/images/integration-logo-images/anthology.d2af8900.png",
        color: "#005b82"
    }, {
        id: "connect",
        image: "/images/integration-logo-images/anthology.d2af8900.png",
        color: "#007a7b"
    }, {
        id: "engage-dynamics",
        image: "/images/integration-logo-images/dynamics.6808f1cf.png",
        color: "#001f53"
    }, {
        id: "newdynamics",
        image: "/images/integration-logo-images/dynamics.6808f1cf.png",
        color: "#104c96"
    }, {
        id: "salesforce",
        image: "/images/integration-logo-images/salesforce.a258c428.png",
        color: "#00a2e5"
    }, {
        id: "ems",
        image: "/images/integration-logo-images/enrollment-solutions.779fcd79.png",
        color: "#ffaf00"
    }, {
        id: "geckochat",
        image: "/images/integration-logo-images/enrollment-solutions.779fcd79.png",
        color: "#28464f"
    }, {
        id: "twillio",
        image: "/images/integration-logo-images/dotdigital.f5b29f24.png",
        color: "#ff003c"
    }, {
        id: "nottingham",
        image: "/images/integration-logo-images/dotdigital.f5b29f24.png",
        color: "#18266b"
    }, {
        id: "dotmailer",
        image: "/images/integration-logo-images/dotdigital.f5b29f24.png",
        color: "#EC018B"
    }, {
        id: "webhook",
        image: "/images/integration-logo-images/webhooks.40c8be27.png",
        color: "#d82562"
    }, {
        id: "ellucian",
        image: "/images/integration-logo-images/ellucian.17a7422c.png",
        color: "#711877"
    }, {
        id: "sftp",
        image: "/images/integration-logo-images/sftp.60a6f83f.png",
        color: "#005b82"
    }, {
        id: "slate",
        image: "/images/integration-logo-images/slate.8b010de2.png",
        color: "#006da5"
    }, {
        id: "tribal",
        image: "/images/integration-logo-images/tribal.3deb9a22.png",
        color: "#0d4c9c"
    }, {
        id: "zapier",
        image: "/images/integration-logo-images/zapier.44c5f2b3.png",
        color: "#ff3300"
    }];

    var webhookIntegrations = [{
        configurable: 1,
        group: "Other",
        id: "ellucian",
        title: "Ellucian",
        type: Gecko.Integration.TYPE_WEBHOOK
    }, {
        configurable: 1,
        group: "Other",
        id: "sftp",
        title: "SFTP",
        type: Gecko.Integration.TYPE_WEBHOOK
    }, {
        configurable: 1,
        group: "Other",
        id: "slate",
        title: "Slate",
        type: Gecko.Integration.TYPE_WEBHOOK
    }, {
        configurable: 1,
        group: "Other",
        id: "tribal",
        title: "Tribal",
        type: Gecko.Integration.TYPE_WEBHOOK
    }, {
        configurable: 1,
        group: "Other",
        id: "zapier",
        title: "Zapier",
        type: Gecko.Integration.TYPE_WEBHOOK
    }];

    function SettingsIntegrationsCtrl($state, $filter, integrations, $scope, $location, geckoStore) {
        var ctrl = this;
        ctrl.integrations = integrations.toArray();
        ctrl.pagination = integrations.pagination;

        // applies color and images to available integrations list
        ctrl.integrations = ctrl.integrations.map(function (int) {
            var currentType = int.config.type ? int.config.type : int.type;
            var integration_design = integration_designs.find(function (x) {
                return x.id === currentType;
            });
            var helpText = new moment(int.created_at * 1000).format("MMM Do YYYY");
            if (integration_design) {
                return Object.assign({ img: integration_design.image, color: integration_design.color, helpText: helpText }, int);
            }
            return Object.assign({ helpText: helpText }, int);
        });

        // If it is Salesforce or Engage Dynamics, then we have to create the integration now
        if ($location.search().integration_token) {
            var code = $location.search().integration_token;
            var type = $location.search().type;
            var id = $location.search().integration_id;

            $scope.integration = id ? new Gecko.Integration().fill({ 'id': id }) : new Gecko.Integration();

            $scope.integration.code = code;
            $scope.integration.type = type;
            if (type === Gecko.Integration.TYPE_ENGAGE_DYNAMICS) {
                var dynamics = geckoStore.get('engage-dynamics');
                if (id) {
                    $scope.integration.refresh_token = true;
                    $scope.integration.config = dynamics;
                } else {
                    $scope.integration.name = dynamics.name ? dynamics.name : null;
                    $scope.integration.config = {
                        resource: dynamics.config.url,
                        client_id: dynamics.config.appId,
                        client_secret: dynamics.config.passwordKey,
                        root_module: dynamics.config.root_module,
                        event_module: dynamics.config.event_module
                    };
                }
            }
            if (type === Gecko.Integration.TYPE_SALEFORCE) {
                var salesforce = geckoStore.get('salesforce');
                $scope.integration.name = salesforce.name ? salesforce.name : null;
                $scope.integration.config = {
                    root_module: salesforce.config.root_module,
                    event_module: salesforce.config.event_module,
                    environment: salesforce.config.environment || 'live'
                };
            }
            $scope.integration.save().then(function (integration) {
                if (type === Gecko.Integration.TYPE_ENGAGE_DYNAMICS) {
                    geckoStore.remove('engage-dynamics');
                }
                $state.go('integration', {
                    id: integration.id
                });
                $scope.$digest();
            });
        } else if (!ctrl.integrations.length) {
            return $state.go('integration.fields', { id: 'new' });
        }

        // Table structure
        ctrl.cols = [{
            title: 'Name',
            key: 'title_with_date'
        }];

        // Table row action
        ctrl.rowAction = function (integration) {
            if (integration.type == 'twilio') return $state.go('voipnumbers');
            if (!integration.id) return $state.go('integration.fields', { id: 'new' });
            return $state.go('integration.fields', { id: integration.id });
        };

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Integrations',
            active: true
        }];
    }

    function SettingsIntegrationTabsCtrl($state, $filter, integration) {
        var tabsCtrl = this;

        if ([Gecko.Integration.TYPE_CONNECT, Gecko.Integration.TYPE_RADIUS, Gecko.Integration.TYPE_NEW_DYNAMICS, Gecko.Integration.TYPE_SALEFORCE].indexOf(integration.type) !== -1) {
            tabsCtrl.tabs = [{
                title: 'Fields',
                state: 'integration.fields'
            }, {
                title: 'Events',
                state: 'integration.events',
                lockedWhen: function lockedWhen() {
                    return !integration.sync_attendances;
                },
                lockedMessage: 'Contact support to enable this feature'
            }, {
                title: 'Consents',
                state: 'integration.consents',
                lockedWhen: function lockedWhen() {
                    return !Gecko.able(Gecko.ABILITY_CONSENTS_VIEW) || [Gecko.Integration.TYPE_CONNECT, Gecko.Integration.TYPE_RADIUS, Gecko.Integration.TYPE_SALEFORCE].indexOf(integration.type) === -1;
                }
            }];
        }

        // Breadcrumbs

        tabsCtrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Integrations',
            click: function click() {
                $state.go('settingsintegrations');
            }
        }, {
            label: integration.type ? integration.title : 'Add Integration',
            active: true
        }];

        if ($state.current.name === 'integration') $state.go('integration.fields');
    }

    var date_format_options = [{
        label: "dd/mm/yy  --  03/08/17",
        id: "d/m/y"
    }, {
        label: "dd/mm/yyyy  --  03/08/2017",
        id: "d/m/Y"
    }, {
        label: "d/m/yy  --  3/8/17",
        id: "j/n/y"
    }, {
        label: "d/m/yyyy  --  3/8/2017",
        id: "j/n/Y"
    }, {
        label: "ddmmyy  --  030817",
        id: "dmy"
    }, {
        label: "ddmmyyyy  --  03082017",
        id: "dmY"
    }, {
        label: "ddmmmyy  --  03Aug17",
        id: "dMy"
    }, {
        label: "ddmmmyyyy  --  03Aug2017",
        id: "dMY"
    }, {
        label: "dd-mmm-yy  --  03-Aug-17",
        id: "d-M-y"
    }, {
        label: "dd-mmm-yyyy  --  03-Aug-2017",
        id: "d-M-Y"
    }, {
        label: "dmmmyy  --  3Aug17",
        id: "jMy"
    }, {
        label: "dmmmyyyy  --  3Aug2017",
        id: "jMY"
    }, {
        label: "d-mmm-yy  --  3-Aug-17",
        id: "j-M-y"
    }, {
        label: "d-mmm-yyyy  --  3-Aug-2017",
        id: "j-M-Y"
    }, {
        label: "d-mmmm-yy  --  3-August-17",
        id: "j-F-y"
    }, {
        label: "d-mmmm-yyyy  --  3-August-2017",
        id: "j-F-Y"
    }, {
        label: "yymmdd  --  170803",
        id: "ymd"
    }, {
        label: "yyyymmdd  --  20170803",
        id: "Ymd"
    }, {
        label: "yy/mm/dd  --  17/08/03",
        id: "y/m/d"
    }, {
        label: "yyyy/mm/dd  --  2017/08/03",
        id: "Y/m/d"
    }, {
        label: "mmddyy  --  080317",
        id: "mdy"
    }, {
        label: "mmddyyyy  --  08032017",
        id: "mdY"
    }, {
        label: "mm/dd/yy  --  08/03/17",
        id: "m/d/y"
    }, {
        label: "mm/dd/yyyy  --  08/03/2017",
        id: "m/d/Y"
    }, {
        label: "mmm-dd-yy  --  Aug-03-17",
        id: "m-d-y"
    }, {
        label: "mmm-dd-yyyy  --  Aug-03-2017",
        id: "M-d-Y"
    }, {
        label: "yyyy-mm-dd  --  2017-08-03",
        id: "Y-m-d"
    }, {
        label: "mmm-yy  --  Aug-17",
        id: "M-y"
    }, {
        label: "yyyy  --  2017",
        id: "Y"
    }];

    var humanReadableType = function humanReadableType(type) {
        if (!type) {
            return;
        }

        if (type === Gecko.Integration.TYPE_ENGAGE_DYNAMICS) {
            return 'Dynamics';
        }

        return type.charAt(0).toUpperCase() + type.slice(1);
    };

    function getWebhookFields(integration) {
        return [{
            id: 'name',
            label: 'Integration Name',
            required: true
        }, {
            id: 'url',
            label: 'URL',
            required: true
        }, {
            id: 'auth_key',
            label: 'Auth key',
            required: false
        }, {
            id: 'auth_value',
            label: 'Auth value',
            required: false
        }, {
            type: Gecko.Field.TYPE_SELECT,
            id: 'encoding_type',
            label: 'Encoding type',
            options: [{ id: 'json', label: 'JSON' }, { id: 'form', label: 'Form' }, { id: 'xml', label: 'XML' }],
            optionsKey: 'id',
            optionsLabelKey: 'label',
            noBlank: true,
            required: true
        }, {
            id: 'structure',
            label: 'XML Structure',
            type: Gecko.Field.TYPE_TEXTAREA,
            required: true,
            hideWhen: function hideWhen() {
                return $scope.integration.encoding_type !== 'xml';
            }

        }, {
            type: Gecko.Field.TYPE_SELECT,
            id: 'array_format',
            label: 'Array format',
            options: [{ id: 'default', label: 'Default' }, { id: 'comma', label: 'Comma separated' }],
            optionsKey: 'id',
            optionsLabelKey: 'label',
            noBlank: true,
            required: true
        }, {
            type: Gecko.Field.TYPE_SELECT,
            id: 'date_format',
            obj: integration.config,
            label: 'Date format',
            options: date_format_options,
            optionsKey: 'id',
            optionsLabelKey: 'label',
            noBlank: true,
            required: true
        }];
    }

    function SettingsIntegrationCtrl($scope, $stateParams, $state, $filter, LoadingIndicator, integration, integration_fields, contact_fields, $q, ContactFieldOptions, geckoStore, $geckoModal) {

        $scope.avaliableIntegrations = Gecko.Integration.type_titles.filter(function (int) {
            if (int.id === 'webhook') return Gecko.able(Gecko.ABILITY_WEBHOOK_INTEGRATION);
            return int.configurable !== 0 && int.id !== 'nottingham';
        });

        // temp removes dynamic EOT and Legacy as new integration option (needs references removed from html, api & js)
        $scope.avaliableIntegrations = $scope.avaliableIntegrations.filter(function (int) {
            return int.id !== 'newdynamics' && int.id !== 'dynamics';
        });

        // adds additional webhook integrations to available integrations list
        webhookIntegrations.forEach(function (int) {
            return $scope.avaliableIntegrations.push(int);
        });

        // applies color and images to available integrations list
        $scope.avaliableIntegrations = $scope.avaliableIntegrations.map(function (int) {
            var integration_design = integration_designs.find(function (x) {
                return x.id === int.id;
            });
            if (integration_design) {
                return Object.assign({ img: integration_design.image, color: integration_design.color }, int);
            }
        });

        $scope.sender_options = [];
        if (integration.type === Gecko.Integration.TYPE_ENGAGE_DYNAMICS) {
            new Gecko.Integration().rfields({ integration: ['sender_options'] }).get(integration.id).then(function (integration) {
                $scope.sender_options = integration.sender_options;
            });
        }

        // sets the type based on the config.type set from the tile radio inputs
        var setTypeFromConfig = function setTypeFromConfig() {
            if ((integration || {}).config && [Gecko.Integration.TYPE_WEBHOOK, 'ellucian', 'sftp', 'slate', 'tribal', 'zapier'].indexOf(integration.config.type) !== -1) {
                return Gecko.Integration.TYPE_WEBHOOK;
            } else {
                return ((integration || {}).config || {}).type;
            }
        };

        // sets the title based on the config.type set from the tile radio inputs
        var setTitleFromConfig = function setTitleFromConfig(type) {
            if (!type) return;
            return $scope.avaliableIntegrations.filter(function (int) {
                return int.id === type;
            })[0].title;
        };

        // scroll user to configure the integration section
        $scope.scrollToElement = function () {
            integration.type = setTypeFromConfig();
            integration.title = setTitleFromConfig(integration.config ? integration.config.type : null);
            $('html,body').animate({
                scrollTop: $('#configure-settings').offset().top
            }, 150);
        };

        var configureWebhookSyncOptions = function configureWebhookSyncOptions(integration) {
            if (integration.type !== 'webhook') {
                return integration;
            };

            if (integration.sync_responses === 0 && integration.sync_contacts === 0) {
                integration['webhook_sync_options'] = 'nosync';
            } else if (integration.sync_responses === 0 && integration.sync_contacts === 1) {
                integration['webhook_sync_options'] = 'synccontacts';
            } else if (integration.sync_responses === 1 && integration.sync_contacts === 0) {
                integration['webhook_sync_options'] = 'syncresponses';
            }
            if (integration.config.auth) {
                integration['auth_key'] = integration.config.auth.auth_key;
                integration['auth_value'] = integration.config.auth.auth_value;
            } else {
                integration['auth_key'] = '';
                integration['auth_value'] = '';
            }
            integration['encoding_type'] = integration.config.encoding_type;
            integration['array_format'] = integration.config.array_format;
            integration['date_format'] = integration.config.date_format;
            return integration;
        };

        $scope.tabsCtrl.showFieldButton = function () {
            return $stateParams.id !== 'new' && $state.$current.name === 'integration.fields';
        };
        $scope.currentPage = 'integrations';
        $scope.integration = configureWebhookSyncOptions(integration);
        $scope.pagination = integration_fields.pagination;
        $scope.integration_fields = integration_fields.toInstances();
        $scope.contact_fields = contact_fields;
        $scope.data = {};
        if ($scope.integration.type == Gecko.Integration.TYPE_DOTMAILER) {
            $scope.integration.getFilters().then(function (data) {
                $scope.dotmailer_address_books = data;
            });
        }

        $scope.webhookFields = [{
            id: 'name',
            label: 'Integration Name',
            required: true
        }, {
            id: 'url',
            label: 'URL',
            required: true
        }, {
            id: 'auth_key',
            label: 'Auth key',
            required: false
        }, {
            id: 'auth_value',
            label: 'Auth value',
            required: false
        }, {
            type: Gecko.Field.TYPE_SELECT,
            id: 'encoding_type',
            label: 'Encoding type',
            options: [{ id: 'json', label: 'JSON' }, { id: 'form', label: 'Form' }, { id: 'xml', label: 'XML' }],
            optionsKey: 'id',
            optionsLabelKey: 'label',
            noBlank: true,
            required: true
        }, {
            id: 'structure',
            label: 'XML Structure',
            type: Gecko.Field.TYPE_TEXTAREA,
            required: true,
            hideWhen: function hideWhen() {
                return $scope.integration.encoding_type !== 'xml';
            }
        }, {
            type: Gecko.Field.TYPE_SELECT,
            id: 'array_format',
            label: 'Array format',
            options: [{ id: 'default', label: 'Default' }, { id: 'comma', label: 'Comma separated' }],
            optionsKey: 'id',
            optionsLabelKey: 'label',
            noBlank: true,
            required: true
        }, {
            type: Gecko.Field.TYPE_SELECT,
            id: 'date_format',
            label: 'Date format',
            options: date_format_options,
            optionsKey: 'id',
            optionsLabelKey: 'label',
            noBlank: true,
            required: true
        }];

        $scope.radiusOrder = function (item) {
            return item['Display Label'];
        };
        $scope.newDynamicsOrder = function (item) {
            return item['label'];
        };

        $scope.checkUpdatePermissions = function (integration) {
            // Check the users permissions
            if (integration.id) {
                // Update permissions
                if (!Gecko.able(Gecko.ABILITY_INTEGRATIONS_UPDATE)) {
                    GeckoUI.messenger.error(Gecko.unableReason(Gecko.ABILITY_INTEGRATIONS_UPDATE));
                    return false;
                }
            }
            return true;
        };

        $scope.findField = function (fields, id) {
            return GeckoUI.getField(fields, id);
        };

        $scope.fieldFormats = function () {
            if ($scope.contactFieldActionType('create') && $scope.data.contact_field.type) {
                return $scope.field_format[$scope.data.contact_field.type] ? $scope.field_format[$scope.data.contact_field.type] : [];
            } else {
                var field = $scope.findField($scope.contact_fields, $scope.data.contact_field_id);
                if (field) {
                    if ($scope.field_format[field.type]) return $scope.field_format[field.type];
                    return [];
                }
            }
        };

        $scope.webhookSettingsBtns = [{
            preset: 'save',
            action: function action() {
                var type = integration.config.type;
                integration.config = {};
                integration.config.auth = {};
                $scope.integration.config.type = type;
                $scope.integration.config.auth.auth_key = $scope.integration['auth_key'];
                $scope.integration.config.auth.auth_value = $scope.integration['auth_value'];
                $scope.integration.config.encoding_type = $scope.integration['encoding_type'];
                $scope.integration.config.array_format = $scope.integration['array_format'];
                $scope.integration.config.date_format = $scope.integration['date_format'];
                $scope.integration.save().then(function () {
                    GeckoUI.messenger.success('Configuration options updated');
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                });
            }
        }];

        $scope.$watch('webhook_sync_options', function (val, old_val) {
            if (integration.type !== Gecko.Integration.TYPE_WEBHOOK) {
                return;
            };
            if (val !== old_val) {
                if (val === 'nosync') {
                    integration.sync_contacts = 0;
                    integration.sync_responses = 0;
                } else if (val === 'synccontacts') {
                    integration.sync_contacts = 1;
                    integration.sync_responses = 0;
                } else {
                    integration.sync_responses = 1;
                    integration.sync_contacts = 0;
                };
            };
        });

        $scope.webhook_sync_options = [{
            label: "Don't sync anything",
            val: 'nosync'
        }, {
            label: "Sync contacts",
            val: 'synccontacts'
        }, {
            label: "Sync responses",
            val: 'syncresponses'
        }];

        $scope.connect_regions = [{
            'url': 'https://services01.askadmissions.co.uk/ws/bridge.asmx?wsdl',
            'label': 'EMEA'
        }, {
            'url': 'https://services01.askadmissions.net/ws/bridge.asmx?wsdl',
            'label': 'US - West'
        }, {
            'url': 'https://services02.askadmissions.net/ws/bridge.asmx?wsdl',
            'label': 'US - East'
        }];

        $scope.radius_regions = [{
            'url': 'https://apacapi.radiusbycampusmgmt.com',
            'label': 'APAC'
        }, {
            'url': 'https://emeaapi.radiusbycampusmgmt.com',
            'label': 'EMEA'
        }, {
            'url': 'https://emeastagingapi.radiusbycampusmgmt.com',
            'label': 'EMEA - Staging'
        }, {
            'url': 'https://api.radiusbycampusmgmt.com',
            'label': 'US'
        }, {
            'url': 'https://useastapi.radiusbycampusmgmt.com/',
            'label': 'US - East'
        }];
        $scope.ems_regions = [{
            'url': 'https://enquiryapi-apac.qses.systems/',
            'label': 'APAC Production'
        }, {
            'url': 'https://enquiryapi-apac.qses-uat.com/',
            'label': 'APAC UAT'
        }, {
            'url': 'https://connect.enquiries.uk.com/EnquiryAPI/',
            'label': 'EMEA Production'
        }, {
            'url': 'https://enquiryapi-emea.qses-uat.com/EnquiryApi/',
            'label': 'EMEA UAT'
        }];
        $scope.field_format = {
            name: [{
                'value': 'first_name',
                'label': 'First Name'
            }, {
                'value': 'last_name',
                'label': 'Last Name'
            }],
            address: [{
                'value': 'street_address',
                'label': 'Street Address'
            }, {
                'value': 'address_line_2',
                'label': 'Address Line 2'
            }, {
                'value': 'city',
                'label': 'City'
            }, {
                'value': 'state',
                'label': 'State'
            }, {
                'value': 'postal_code',
                'label': 'Postal Code'
            }, {
                'value': 'country',
                'label': 'Country'
            }]
        };
        if ($scope.integration.type === Gecko.Integration.TYPE_RADIUS) {
            $scope.modules = [{ name: 'Contacts' }, { name: 'Cases' }, { name: 'Inquiries' }, { name: 'LifeCycles' }, { name: 'Educations' }, { name: 'Experiences' }, { name: 'Attendees' }, { name: 'Registrations' }, { name: 'Decisions' }];
        }

        if ($scope.integration.type === Gecko.Integration.TYPE_NEW_DYNAMICS) {
            $scope.modules = [{ name: 'contact', label: 'Contacts' }, { name: 'email', label: 'Emails' },
            // {name: 'account', label: 'Organisations'},
            // {name: 'annotation', label: 'Notes'},
            { name: 'lead', label: 'Enquiries' }];
        }

        $scope.is_root_module_lookup_options = $scope.integration.config && typeof $scope.integration.config.root_module === 'string' ? [{ value: 0, label: 'Populate with form field value' }, { value: 1, label: 'Populate with ' + $scope.integration.config.root_module + ' ID' }] : [];

        $scope.is_potential_root_module_lookup = false;
        $scope.is_root_module_lookup = { confirmed: 0 };
        $scope.is_existing_root_module_lookup = { confirmed: 1 };

        $scope.salesforceAttributeChangeFn = function () {
            var root_module = $scope.integration.config.root_module;
            var reference_to = $scope.returned_fields.find(function (f) {
                return f.name === $scope.data.external_id;
            }).referenceTo;
            $scope.is_potential_root_module_lookup = $scope.data.module !== root_module && (reference_to === root_module || Array.isArray(reference_to) && reference_to.find(function (m) {
                return m === root_module;
            }));
            if ($scope.is_potential_root_module_lookup) {
                $scope.is_root_module_lookup = { confirmed: 1 };
            } else {
                $scope.is_root_module_lookup = { confirmed: 0 };
            }
        };

        $scope.salesforceLookupChangeFn = function () {
            if ($scope.is_root_module_lookup.confirmed) {
                $scope.data.contact_field_id = null;
                $scope.data.contact_field = null;
            }
        };

        if ($scope.integration.type === Gecko.Integration.TYPE_SALEFORCE) {
            $scope.integration.getModules().then(function (modules) {
                $scope.modules = modules.map(function (item) {
                    return { label: item.sf_label, name: item.sf_name };
                });
                $scope.$apply();
            });
        }

        if ($scope.integration.type === Gecko.Integration.TYPE_ENGAGE_DYNAMICS) {
            $scope.integration.getModules().then(function (modules) {
                $scope.modules = modules.map(function (mod) {
                    return { label: mod.label, name: mod.name };
                });
                $scope.$apply();
            });
        }

        // Get table column class
        var tableColClass = 'col-xs-' + $scope.integration.type !== Gecko.Integration.TYPE_NEW_DYNAMICS ? '4' : '3';

        // Table structure
        $scope.cols = [{
            title: 'Module',
            key: 'module',
            render: GeckoUI.cachedFunc(function (module) {
                return $filter('capitalize')(module);
            }),
            colClass: tableColClass,
            hideWhen: GeckoUI.cachedFunc(function () {
                return [Gecko.Integration.TYPE_EMS, Gecko.Integration.TYPE_DOTMAILER, Gecko.Integration.TYPE_WEBHOOK].indexOf($scope.integration.type) !== -1 || Gecko.Integration.isCustom($scope.integration.type);
            })
        }, {
            title: $scope.integration.type === Gecko.Integration.TYPE_WEBHOOK ? 'Label' : 'Attribute Field',
            key: 'label',
            colClass: tableColClass
        }, {
            title: 'Contact field',
            key: 'contact_field_id',
            colClass: tableColClass,
            render: GeckoUI.cachedFunc(function (value, col, row) {
                var format = row.format ? ' (' + row.format + ')' : '';
                return (GeckoUI.getField($scope.contact_fields, value).label || '') + format;
            })
        }, {
            title: 'External Id',
            key: 'external_id',
            colClass: tableColClass
        }, {
            title: 'Writeable',
            key: 'can_push',
            colClass: tableColClass,
            render: GeckoUI.cachedFunc(function (pushable) {
                return pushable ? 'Yes' : 'No';
            }),
            hideWhen: GeckoUI.cachedFunc(function () {
                return Gecko.Integration.TYPE_SALEFORCE !== $scope.integration.type;
            }),
            helpText: "This column indicates whether a field can be updated in " + humanReadableType($scope.integration.type) + ".\n                        If yes, Gecko can update the value in " + humanReadableType($scope.integration.type) + ".\n                        If no, this field is read-only and the value in " + humanReadableType($scope.integration.type) + " can not be updated.\n                        This can be changed within " + humanReadableType($scope.integration.type) + " settings."
        }];

        // Table row action
        $scope.rowAction = {
            action: function action(row, index) {
                if (!$scope.checkUpdatePermissions($scope.integration)) return;
                $scope.attributeDisabled = true;
                $scope.data = $scope.integration_fields[index];
                showAddAttributeModal();
            }
        };

        // Cog drop down
        $scope.optionsBtn = {
            iconRight: 'fa-cogs',
            btnTooltip: 'Actions'
        };

        var showRefreshTokenModal = function showRefreshTokenModal() {
            if (!$scope.integration.originalConfig) {
                $scope.integration.originalConfig = angular.copy($scope.integration.config);
            }

            var modalData = {
                integration: $scope.integration,
                client_id: $scope.integration.config.client_id,
                client_secret: $scope.integration.config.client_secret,
                application_user: $scope.integration.config.application_user || false,
                tenant_id: $scope.integration.config.tenant_id || ''
            };
            $geckoModal.refreshTokenModal(modalData, '').result.then(function (data) {
                $scope.integration.config.client_id = data.client_id;
                $scope.integration.config.client_secret = data.client_secret;
                $scope.integration.config.application_user = data.application_user;
                if (data.application_user && data.tenant_id) {
                    $scope.integration.config.tenant_id = data.tenant_id;
                } else if (!data.application_user && $scope.integration.config.tenant_id) {
                    delete $scope.integration.config.tenant_id;
                }
                if ($scope.integration.config.application_user === false) {
                    $scope.integration.getOauthURL($scope.integration.type, $scope.integration.config.environment || 'live').then(function (response) {
                        GeckoUI.messenger.success("Refreshing integration token");
                        geckoStore.set($scope.integration.type, $scope.integration.config);
                        window.location.href = buildResponseUrl(response, $scope.integration.config.client_id, $scope.integration.config.resource, '&state=' + encodeURIComponent('integration_id=' + $scope.integration.id));
                        $scope.$apply();
                    }).catch(function (err) {
                        $scope.integration.config = $scope.integration.originalConfig;
                        GeckoUI.messenger.error(err.errors);
                    });
                } else {
                    $scope.integration.save().then(function () {
                        GeckoUI.messenger.success('Your integration token has been refreshed');
                        $state.go($state.$current.name, $stateParams, { reload: true });
                        $scope.$apply();
                    }).catch(function (err) {
                        $scope.integration.config = $scope.integration.originalConfig;
                        GeckoUI.messenger.error(err.error);
                    });
                }
            });
        };

        var showRefreshTokenDialog = function showRefreshTokenDialog() {
            GeckoUI.dialog.confirm('Are you sure you want to refresh the token?', function (value) {
                if (value) {
                    $scope.integration.getOauthURL($scope.integration.type, $scope.integration.config.environment || 'live').then(function (response) {
                        geckoStore.set($scope.integration.type, { config: $scope.integration.config });
                        window.location.href = buildResponseUrl(response, $scope.integration.config.client_id, $scope.integration.config.resource, '&state=' + encodeURIComponent('integration_id=' + $scope.integration.id));

                        $scope.$apply();
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err.errors);
                    });
                    GeckoUI.messenger.success("Refreshing integration token");
                }
            });
        };

        $scope.optionsBtn.items = [{
            title: 'Refresh integration',
            action: function action() {
                $scope.integration.save().then(function () {
                    GeckoUI.messenger.success("Integration refreshed");
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                });
                GeckoUI.messenger.success("Integration refreshing");
            }
        }, {
            title: 'Refresh field options',
            action: function action() {
                GeckoUI.dialog.confirm('Warning! You will lose all changes users have made to field options linked to these integration fields. Are you sure you want to refresh all linked field options?', function (value) {
                    if (value) {
                        $scope.integration.refreshOptions().then(function () {
                            GeckoUI.messenger.success("Field options refreshed");
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err.errors);
                        });
                        GeckoUI.messenger.success("Refreshing field options");
                    }
                });
            },
            hideWhen: function hideWhen() {
                return $scope.integration.type !== Gecko.Integration.TYPE_ENGAGE_DYNAMICS;
            }
        }, {
            title: 'Rename integration',
            action: function action() {
                $geckoModal.inputModal('Rename Integration', $scope.integration.title, '').result.then(function (val) {
                    $scope.integration.name = val;
                    $scope.integration.save().then(function () {
                        GeckoUI.messenger.success('Your integration has been saved');
                        $state.go($state.$current.name, $stateParams, { reload: true });
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err.error);
                    });
                });
            }
        }, {
            title: 'Remove integration',
            action: function action() {
                // Remove permissions
                if (!Gecko.able(Gecko.ABILITY_INTEGRATIONS_DELETE)) {
                    return GeckoUI.messenger.error(Gecko.unableReason(Gecko.ABILITY_INTEGRATIONS_DELETE));
                }
                GeckoUI.dialog.confirm("Are you sure you want to delete this integration?", function (value) {
                    if (value) {
                        $scope.integration.remove().then(function () {
                            GeckoUI.messenger.success("Integration successfully deleted");
                            $state.go("settingsintegrations");
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err.errors[0]);
                        });
                    }
                });
            }
        }, {
            title: 'Refresh token',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_INTEGRATIONS_UPDATE) || ![Gecko.Integration.TYPE_ENGAGE_DYNAMICS, Gecko.Integration.TYPE_SALEFORCE].includes(integration.type);
            },
            action: function action() {
                if (integration.type === Gecko.Integration.TYPE_ENGAGE_DYNAMICS) {
                    showRefreshTokenModal();
                } else {
                    showRefreshTokenDialog();
                }
            }
        }];

        // //Table row dropdown
        $scope.rowOptionsBtn = {};
        $scope.rowOptionsBtn.items = [{
            title: 'Edit field',
            hideWhen: function hideWhen(integration_field, index) {
                return $scope.integration.type === Gecko.Integration.TYPE_SALEFORCE && integration_field.module !== $scope.integration.config.root_module;
            },
            action: function action(integration_field, index) {
                return $scope.rowAction.action(integration_field, index);
            }
        }, {
            title: 'Import field options',
            hideWhen: function hideWhen(field) {
                return !field.options;
            },
            action: function action(field) {
                if (!$scope.checkUpdatePermissions($scope.integration)) return;
                var values = [],
                    name = field.label;

                if ($scope.integration.type == Gecko.Integration.TYPE_CONNECT) {
                    angular.forEach(field.options, function (option) {
                        values.push({
                            value: option.name,
                            code: option.value,
                            selected: false
                        });
                    });
                } else if ($scope.integration.type == Gecko.Integration.TYPE_RADIUS) {
                    angular.forEach(field.options, function (option) {
                        values.push({
                            value: option,
                            code: option,
                            selected: false
                        });
                    });
                } else if ($scope.integration.type == Gecko.Integration.TYPE_SALEFORCE) {
                    angular.forEach(field.options, function (option) {
                        values.push({
                            value: option.label,
                            code: option.value,
                            selected: false
                        });
                    });
                } else if ($scope.integration.type == Gecko.Integration.TYPE_NEW_DYNAMICS) {
                    angular.forEach(field.options, function (option) {
                        values.push({
                            value: option.name,
                            code: option.value,
                            selected: false
                        });
                    });
                }

                // Save the Option
                new Gecko.Option().create({
                    name: name,
                    values: values
                }).save().then(function () {
                    GeckoUI.messenger.success('Field options successfully imported');
                });
            }

        }, {
            title: 'Remove field',
            action: function action(integration_field, index) {
                if (!$scope.checkUpdatePermissions($scope.integration)) return;
                GeckoUI.dialog.confirm('Are you sure you want to untrack this attribute?', function (value) {
                    if (value) {
                        integration_field.remove().then(function () {
                            GeckoUI.messenger.success('We have untracked that integration field');
                            $scope.integration_fields.splice(index, 1);

                            $state.go($state.$current.name, $stateParams, { reload: true });

                            $scope.$apply();
                        });
                    }
                });
            }
        }];

        var objectsToOptions = function objectsToOptions(objects) {
            return function (labelKey, valueKey) {
                return (objects || []).map(function (object) {
                    return { label: object[labelKey], value: object[valueKey || 'id'] };
                });
            };
        };

        $scope.formats = [];
        angular.forEach($scope.field_format, function (value) {
            $scope.formats = $scope.formats.concat(value);
        });

        $scope.tableFilters = [{
            title: 'Contact Field',
            type: 'dropdown',
            options: objectsToOptions($scope.contact_fields)('label'),
            stateParam: 'contact_field'
        }, {
            title: 'Format',
            type: 'dropdown',
            options: objectsToOptions($scope.formats)('label', 'value'),
            stateParam: 'format'
        }];

        $scope.openOauth = function () {
            if (!$scope.integration.config.root_module) {
                GeckoUI.messenger.error('Please select a contact module');
            } else if (!$scope.integration.config.event_module) {
                GeckoUI.messenger.error('Please select an event module');
            } else if (Gecko.has(Gecko.Package.FEATURE_CHAT) && !$scope.integration.config.conversation_module) {
                GeckoUI.messenger.error('Please select a conversation module');
            } else {
                geckoStore.set('salesforce', { config: $scope.integration.config, name: $scope.integration.name });
                $scope.integration.getOauthURL('salesforce', $scope.integration.config.environment || 'live').then(function (response) {
                    window.location.href = response.url.replace('{{state}}', '');
                    $scope.$apply();
                });
            }
        };

        $scope.dynamicsOpenOauth = function () {
            geckoStore.set('engage-dynamics', { config: $scope.integration.config, name: $scope.integration.name });
            $scope.integration.getOauthURL(Gecko.Integration.TYPE_ENGAGE_DYNAMICS).then(function (response) {
                window.location.href = buildResponseUrl(response, $scope.integration.config.appId, $scope.integration.config.url);

                $scope.$apply();
            });
        };

        $scope.dynamicsCreate = function () {
            function getApplicationUser() {
                if (!$scope.integration.config.application_user) {
                    return true;
                }
                return $scope.integration.config.application_user === 'true';
            }
            var originalConfig = $scope.integration.config;
            var updatedConfig = {
                resource: originalConfig.url,
                client_id: originalConfig.appId,
                client_secret: originalConfig.passwordKey,
                root_module: originalConfig.root_module,
                event_module: originalConfig.event_module,
                application_user: getApplicationUser(),
                tenant_id: originalConfig.tenantId
            };
            $scope.integration.config = updatedConfig;
            if (!$scope.checkUpdatePermissions($scope.integration)) return;
            LoadingIndicator.resolve($scope.integration.save()).then(function (integration) {
                GeckoUI.messenger.success("Integration successfully saved.");
                $state.go('integration', { id: integration.id });
            }).catch(function (err) {
                $scope.integration.config = originalConfig;
                GeckoUI.messenger.error(err.errors);
            });
        };

        $scope.deleteIntegrationField = function (integration_field, index) {
            vex.dialog.confirm({
                className: 'vex-theme-plain',
                message: 'Are you sure you want to untrack this attribute?',
                callback: function callback(value) {
                    if (value) {
                        integration_field.remove().then(function () {
                            new Messenger().post('We have untracked that integration field');
                            $scope.integration_fields.splice(index, 1);
                            $scope.$apply();
                        });
                    }
                }
            });
        };

        var buildResponseUrl = function buildResponseUrl(response, clientId, resource) {
            var state = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';

            return response.url.replace('{{client_id}}', clientId).replace('{{resource}}', resource).replace('{{state}}', state);
        };

        var attributeModalIsLoading = false;

        var showAddAttributeModal = function showAddAttributeModal() {
            // Prevent double clicks
            if (attributeModalIsLoading) return;

            // Default contact field action
            $scope.contactFieldAction = 'map';

            if ((!$scope.returned_fields || $scope.returned_fields.length === 0) && $scope.integration.type !== Gecko.Integration.TYPE_WEBHOOK) {
                attributeModalIsLoading = true;
                LoadingIndicator.resolve($scope.getModuleFields()).then(function () {
                    // Open modal
                    $('#addAttribute').modal('show');
                    attributeModalIsLoading = false;
                }).catch(function () {
                    attributeModalIsLoading = false;
                });
            } else {
                $('#addAttribute').modal('show');
            }
        };

        $scope.getModuleFields = function () {
            if (!$scope.data.module) return $q.when(null);
            $scope.returned_fields = [];
            return $scope.integration.getAllAttributes($scope.data.module).then(function (result) {
                $scope.returned_fields = result.attributes;
                $scope.$apply();
            });
        };

        $scope.tabsCtrl.integrationFieldTrackNew = $scope.integrationFieldTrackNew = function () {
            if (!$scope.checkUpdatePermissions($scope.integration)) return;
            $scope.attributeDisabled = false;

            $scope.data = {};

            if ([Gecko.Integration.TYPE_CONNECT, Gecko.Integration.TYPE_RADIUS, Gecko.Integration.TYPE_DOTMAILER].indexOf($scope.integration.type) !== -1) {
                $scope.data.module = 'Contacts';
            } else if ($scope.integration.type == Gecko.Integration.TYPE_NEW_DYNAMICS) {
                $scope.data.module = 'contact';
            } else if ($scope.integration.type == Gecko.Integration.TYPE_SALEFORCE) {
                if ($scope.integration.config.root_module && $scope.integration.config.root_module === 'Contact') {
                    $scope.data.module = 'Contact';
                } else {
                    $scope.data.module = 'Lead';
                }
            } else if ($scope.integration.type == Gecko.Integration.TYPE_WEBHOOK) {
                $scope.data.module = null;
                $scope.data.contact_field_id = '';
            } else if ($scope.integration.type == Gecko.Integration.TYPE_ENGAGE_DYNAMICS) {
                if ($scope.integration.config.root_module && $scope.integration.config.root_module === 'lead') {
                    $scope.data.module = 'lead';
                } else {
                    $scope.data.module = 'contact';
                }
            }

            showAddAttributeModal();
        };

        $scope.$watch('data.module', function (newValue, oldValue) {
            if (newValue !== oldValue) {
                $scope.getModuleFields();
            }
        });

        $scope.validField = function (attribute) {
            if ($scope.integration.type === Gecko.Integration.TYPE_RADIUS) {
                return attribute['Display Label'] === 'Created Time' ? true : attribute['Create allowed'];
            } else if ($scope.integration.type === Gecko.Integration.TYPE_CONNECT) {
                return typeof attribute.mappingname == 'string' ? true : false;
            } else if ($scope.integration.type === Gecko.Integration.TYPE_ENGAGE_DYNAMICS) {
                return attribute['is_supported'];
            } else {
                return true;
            }
        };

        $scope.fieldIsType = function (id, type) {
            if (GeckoUI.getField(id).type == type) return true;
            return false;
        };

        // var closeAttributeModalFn   = () => {
        //     $('#addAttribute').modal('hide');
        //     $scope.$digest();
        // };

        $scope.attributeModalIsClosing = false;

        $scope.trackAttr = function () {

            if (!$scope.data.external_id || $scope.attributeModalIsClosing) return false;

            // Prevent double clicks
            $scope.attributeModalIsClosing = true;

            // Get Field label
            var label;
            var can_push = true;
            if ($scope.integration.type === Gecko.Integration.TYPE_SALEFORCE) {
                label = GeckoUI.getObjectByKey($scope.returned_fields, 'name', $scope.data.external_id)['label'];
                can_push = GeckoUI.getObjectByKey($scope.returned_fields, 'name', $scope.data.external_id)['updateable'];
            } else if ($scope.integration.type === Gecko.Integration.TYPE_RADIUS) {
                label = GeckoUI.getObjectByKey($scope.returned_fields, 'Field ID', $scope.data.external_id)['Display Label'];
            } else if ($scope.integration.type === Gecko.Integration.TYPE_CONNECT) {
                label = GeckoUI.getObjectByKey($scope.returned_fields, 'name', $scope.data.external_id)['mappingname'];
            } else if ($scope.integration.type === Gecko.Integration.TYPE_DOTMAILER) {
                label = GeckoUI.getObjectByKey($scope.returned_fields, 'name', $scope.data.external_id)['name'];
            } else if ($scope.integration.type === Gecko.Integration.TYPE_NEW_DYNAMICS) {
                label = GeckoUI.getObjectByKey($scope.returned_fields, 'name', $scope.data.external_id)['label'];
            } else if ($scope.integration.type === Gecko.Integration.TYPE_ENGAGE_DYNAMICS) {
                label = GeckoUI.getObjectByKey($scope.returned_fields, 'external_id', $scope.data.external_id)['label'];
            } else {
                label = $scope.data.label ? $scope.data.label : "";
            }
            // Add label to contact field (if it's being created)
            if ($scope.contactFieldActionType('create')) {
                $scope.data.contact_field.label = label;

                // Default values array (so it can be saved)
                if (['radio', 'checkbox', 'select'].indexOf($scope.data.contact_field.type) !== -1) {
                    $scope.data.contact_field.values = [{ "value": "Choice 1", "selected": false }];
                }
            }

            // Check format
            if ($scope.data.contact_field_id && GeckoUI.getField($scope.contact_fields, $scope.data.contact_field_id).type != 'name' && GeckoUI.getField($scope.contact_fields, $scope.data.contact_field_id).type != 'address') {
                $scope.data.format = "";
            }

            // Add the required integration field type and lookup type to scope data for engage-dynamics
            if ($scope.integration.type === Gecko.Integration.TYPE_ENGAGE_DYNAMICS) {
                var dynamics_field = GeckoUI.getObjectByKey($scope.returned_fields, 'external_id', $scope.data.external_id);
                $scope.data.type = dynamics_field['int_field_type'];
                $scope.data.lookup_type = dynamics_field['lookup_type'];
            }

            if ($scope.is_root_module_lookup.confirmed) {
                $scope.data.type = 'rootlookup';
                $scope.data.lookup_type = $scope.integration.config.root_module;
            }

            // Save the Option
            if ($scope.data.id) {
                $scope.data.label = label;
                $scope.data.can_push = can_push;
                $scope.data.save().then(function () {
                    GeckoUI.messenger.success('Field successfully tracked');
                    // Refresh page if creating
                    $state.go($state.$current.name, $stateParams, { reload: true });
                    $scope.attributeModalIsClosing = false;
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                    $scope.attributeModalIsClosing = false;
                });
            } else {
                var int_field_data = {
                    contact_field: $scope.data.contact_field,
                    import_option_list: $scope.data.import_option_list,
                    contact_field_id: $scope.data.contact_field_id,
                    external_id: $scope.data.external_id,
                    integration_id: $scope.integration.id,
                    module: $scope.data.module,
                    label: label,
                    format: $scope.data.format ? $scope.data.format : null,
                    lookup_type: $scope.data.lookup_type ? $scope.data.lookup_type : null,
                    can_push: can_push
                };
                if ($scope.data.type) {
                    int_field_data.type = $scope.data.type;
                }
                new Gecko.IntegrationField().create(int_field_data).include('options').save().then(function (integration_field) {
                    GeckoUI.messenger.success('Field successfully tracked');

                    // Refresh page if creating
                    $state.go($state.$current.name, $stateParams, { reload: true });

                    $scope.integration_fields.push(integration_field);
                    $scope.attributeModalIsClosing = false;
                    $scope.is_potential_root_module_lookup = false;
                    $scope.is_root_module_lookup = { confirmed: 0 };
                }).catch(function (err) {
                    GeckoUI.messenger.error(err);
                    $scope.attributeModalIsClosing = false;
                });
            }

            // Close modal
            $('#addAttribute').modal('hide');
        };

        var setSyncModelFields = function setSyncModelFields() {
            switch ($scope.integration.webhook_sync_options) {
                case 'nosync':
                    $scope.integration.sync_contacts = 0;
                    $scope.integration.sync_responses = 0;
                    break;
                case 'synccontacts':
                    $scope.integration.sync_contacts = 1;
                    $scope.integration.sync_responses = 0;
                    break;
                case 'syncresponses':
                    $scope.integration.sync_contacts = 0;
                    $scope.integration.sync_responses = 1;
                    break;
                default:
                    $scope.integration.sync_contacts = 0;
                    $scope.integration.sync_responses = 0;
            }
            var type = integration.config.type;
            integration.config = {};
            integration.config.auth = {};
            $scope.integration.config.type = type;
            $scope.integration.config.auth.auth_key = $scope.integration['auth_key'];
            $scope.integration.config.auth.auth_value = $scope.integration['auth_value'];
            $scope.integration.config.encoding_type = $scope.integration['encoding_type'];
            $scope.integration.config.array_format = $scope.integration['array_format'];
            $scope.integration.config.date_format = $scope.integration['date_format'];
        };

        $scope.saveIntegration = function () {
            if ($scope.integration.type === 'webhook') {
                setSyncModelFields();
            }

            if (!$scope.checkUpdatePermissions($scope.integration)) return;
            LoadingIndicator.resolve($scope.integration.save()).then(function (integration) {
                GeckoUI.messenger.success("Integration successfully saved.");
                if ($stateParams.id === 'new') {
                    $state.go('integration', { id: integration.id });
                }
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);
            });
        };

        // Button set
        $scope.footerBtns = [{
            preset: 'save',
            safeMode: true,
            action: function action() {
                $scope.saveIntegration();
            },
            hideWhen: function hideWhen() {
                return integration.type === Gecko.Integration.TYPE_ENGAGE_DYNAMICS || integration.type === Gecko.Integration.TYPE_SALEFORCE;
            }
        }];

        $scope.isCustomIntegration = Gecko.Integration.isCustom;

        // Event Integration Field options
        new Gecko.IntegrationField().where('contact_field_id', $stateParams.contact_field).perPage('all').where('integration_id', $stateParams.id).rfields({ integrationfield: ['label', 'external_id', 'module'] }).get().then(function (event_integration_fields) {
            $scope.unselectedClass = '';
            $scope.event_integration_fields = event_integration_fields.toArray();
            $scope.$digest();
        }).catch(function () {});

        // Campaign Response Field options
        new Gecko.IntegrationField().perPage('all').where('module', 'campaignresponse').rfields({ integrationfield: ['label', 'external_id', 'module'] }).get().then(function (campaign_response_fields) {
            $scope.unselectedClass = '';
            $scope.campaign_response_fields = campaign_response_fields.toArray();
            $scope.$digest();
        }).catch(function () {});

        // Show the contact field section when these types are true
        $scope.showRadiusContactField = function (data) {
            return $scope.integration.type === Gecko.Integration.TYPE_RADIUS && ['Contacts', 'Attendees', 'Registrations', 'Decisions'].indexOf(data.module) !== -1;
        };
        $scope.showConnectorEmsContactField = function (data) {
            return [Gecko.Integration.TYPE_CONNECT, Gecko.Integration.TYPE_EMS].indexOf($scope.integration.type) !== -1;
        };
        $scope.showDotmailerContactField = function (data) {
            return $scope.integration.type === Gecko.Integration.TYPE_DOTMAILER;
        };
        $scope.showNewDynamicsContactField = function (data) {
            return $scope.integration.type === Gecko.Integration.TYPE_NEW_DYNAMICS && ['contact', 'Contacts'].indexOf(data.module) !== -1;
        };
        $scope.showSalesforceContactField = function () {
            return $scope.integration.type === Gecko.Integration.TYPE_SALEFORCE;
        };
        $scope.showWebhookFormatFields = function () {
            return $scope.integration.type === Gecko.Integration.TYPE_WEBHOOK;
        };
        $scope.showEngageDynamicsContactField = function () {
            return $scope.integration.type === Gecko.Integration.TYPE_ENGAGE_DYNAMICS;
        };
        $scope.engageDynamicsHasOptions = function (data) {
            return GeckoUI.getObjectByKey($scope.returned_fields, 'external_id', $scope.data.external_id)['has_options'];
        };
        $scope.contactFieldTypeCanHaveOptions = function () {
            return [Gecko.Field.TYPE_SELECT, Gecko.Field.TYPE_MULTI, Gecko.Field.TYPE_CHECKBOX, Gecko.Field.TYPE_RADIO].indexOf($scope.data.contact_field.type) !== -1;
        };

        // Contact field action
        $scope.toggleContactFieldActionType = function (type) {
            if (type === 'create') {
                $scope.data.contact_field = {
                    type: Gecko.Field.TYPE_TEXT,
                    label: null
                };
                $scope.data.import_option_list = false;
                $scope.data.contact_field_id = null;
            } else {
                if ($scope.data.contact_field) delete $scope.data.contact_field;
                if ($scope.data.import_option_list !== undefined) delete $scope.data.import_option_list;
            }

            return $scope.contactFieldAction = type;
        };
        $scope.contactFieldActionType = function (type) {
            return $scope.contactFieldAction === type;
        };

        // Contact field types
        $scope.fieldTypes = ContactFieldOptions;

        $scope.salesforceEmDisableFb = function () {
            var contactModule = ($scope.integration.config || {}).root_module || null;
            return !contactModule || contactModule === 'Lead';
        };

        $scope.salesforceCmChangeFn = function () {
            var contactModule = ($scope.integration.config || {}).root_module || null;
            var eventModule = ($scope.integration.config || {}).event_module || null;

            if (contactModule === 'Lead' && eventModule !== 'Campaign') {
                $scope.integration.config.event_module = 'Campaign';
            }
        };

        $scope.showMarketingEvents = false;

        $scope.dynamicsChangeRootFn = function () {
            var contactModule = ($scope.integration.config || {}).root_module || null;
            $scope.showMarketingEvents = contactModule === 'contact';

            if (!$scope.showMarketingEvents && ($scope.integration.config || {}).event_module === 'msevtmgt_event') {
                $scope.integration.config.event_module = null;
            }
        };
    }

    angular.module('GeckoEngage').controller('SettingsIntegrationCtrl', SettingsIntegrationCtrl).controller('SettingsIntegrationTabsCtrl', SettingsIntegrationTabsCtrl).controller('SettingsIntegrationsCtrl', SettingsIntegrationsCtrl);
})();