(function () {
    'use strict';

    function ContactCtrl($scope, $state, $stateParams, $filter, $geckoCall, $geckoModal, contactIncludes, contact, subscriber, campaign, call, outcomes, $geckoChannel) {
        var ctrl = this;
        ctrl.contactIncludes = contactIncludes;
        ctrl.contact = contact;
        ctrl.subscriber = subscriber;
        ctrl.campaign = campaign;
        ctrl.call = call;
        ctrl.outcomes = outcomes;

        // Go to overview
        if ($state.$current.name == 'contact') $state.go('contact.overview');

        // Tabs
        ctrl.tabs = [{
            title: 'Overview',
            state: 'contact.overview'
        }, {
            title: 'Organisations',
            state: 'contact.organisations',
            lockedWhen: function lockedWhen() {
                return !Gecko.able(Gecko.ABILITY_ORGANISATIONS_VIEW);
            }
        }, {
            title: 'Notes',
            state: 'contact.notes'
        }, {
            title: 'Responses',
            state: 'contact.responses',
            activeWhen: function activeWhen() {
                return ['contact.responses', 'contact.response'].indexOf($state.$current.name) !== -1;
            },
            lockedWhen: function lockedWhen() {
                return !Gecko.able(Gecko.ABILITY_RESPONSES_VIEW);
            }
        }, {
            title: 'Calls',
            state: 'contact.calls',
            lockedWhen: function lockedWhen() {
                return !Gecko.able(Gecko.ABILITY_CALLS_VIEW);
            }
        }, {
            title: 'Messages',
            state: 'contact.messages',
            activeWhen: function activeWhen() {
                return ['contact.messages', 'contact.message_events'].indexOf($state.$current.name) !== -1;
            }
        }, {
            title: 'Campaigns',
            state: !Gecko.able(Gecko.ABILITY_USER) ? 'contact.subscriber({subscriber_id:' + $stateParams.subscriber_id + '})' : 'contact.campaigns',
            activeWhen: function activeWhen() {
                return $state.$current.name == 'contact.campaigns' || ctrl.campaign.module == Gecko.Campaign.MODULE_CALL && $state.$current.name == 'contact.subscriber';
            },
            lockedWhen: function lockedWhen() {
                return !Gecko.able(Gecko.ABILITY_CALL_CAMPAIGNS_VIEW);
            }
        }, {
            title: 'Broadcasts',
            state: Gecko.User.isGroup(Gecko.Group.AGENT) ? 'contact.subscriber({subscriber_id:' + $stateParams.subscriber_id + '})' : 'contact.broadcasts',
            activeWhen: function activeWhen() {
                return $state.$current.name == 'contact.broadcasts' || ctrl.campaign.module == Gecko.Campaign.MODULE_SMS && $state.$current.name == 'contact.subscriber';
            },
            lockedWhen: function lockedWhen() {
                return !Gecko.able(Gecko.ABILITY_BROADCASTS_VIEW);
            }
        }, {
            title: 'Events',
            state: 'contact.events'
        }, {
            title: 'Attachments',
            state: 'contact.attachments',
            lockedWhen: function lockedWhen() {
                return !Gecko.able(Gecko.ABILITY_ATTACHMENTS_VIEW);
            }
        }];

        // Action button
        ctrl.actionBtn = {
            title: 'Actions',
            iconLeft: 'fa-cogs',
            iconRight: 'fa-angle-down',
            btnClass: 'btn-primary'
        };
        ctrl.actionBtn.items = [{
            title: 'Call contact',
            hideWhen: function hideWhen() {
                return !Gecko.User.hasOutbound() || $geckoCall.onCall || ctrl.campaign;
            },
            action: function action() {
                if (GeckoUI.ableWithReason(Gecko.ABILITY_CALLS_CREATE)) {
                    return $geckoCall.open(ctrl.contact.id);
                }
            }
        }, {
            title: 'Log a call',
            hideWhen: function hideWhen() {
                return !(Gecko.User.hasOutbound() || Gecko.User.hasInbound()) || $state.$current.name != 'contact.subscriber' || !ctrl.campaign || ctrl.subscriber && [Gecko.Subscriber.SUCCESS, Gecko.Subscriber.REMOVED].indexOf(ctrl.subscriber.status) != -1;
            },
            action: function action() {
                if (GeckoUI.ableWithReason(Gecko.ABILITY_CALLS_CREATE)) {
                    $geckoModal.CallOutcomeModal(false, ctrl.contact, ctrl.subscriber);
                }
            }
        }, {
            title: 'Manage label(s)',
            action: function action() {
                if (GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_UPDATE)) {
                    return ctrl.labelsAction();
                }
            }
        }, {
            title: 'Email this contact',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_EMAIL)) {
                    return;
                }

                var openEmailModal = function openEmailModal() {
                    $geckoModal.messageSendModal(ctrl.contact).result.then(function (message) {
                        if ($state.$current.name == 'contact.messages') {
                            return $geckoChannel.set('messages', [message].concat($geckoChannel.get('messages')));
                        }
                    });
                };

                // Check for contact email
                if (ctrl.contact.email) {
                    openEmailModal();
                } else {
                    return new Gecko.Contact().get(contact.id).then(function (contact) {
                        if (contact.email) {
                            openEmailModal();
                        } else {
                            GeckoUI.dialog.alert('Add an email address to this contact to send a message.');
                        }
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err.errors);
                    });
                }
            }
        }, {
            title: 'SMS this contact',
            action: function action() {
                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_SMS)) {
                    return;
                }

                $geckoModal.messageSendModal(ctrl.contact, Gecko.Message.TYPE_SMS).result.then(function (message) {
                    if ($state.$current.name == 'contact.messages') {
                        return $geckoChannel.set('messages', [message].concat($geckoChannel.get('messages')));
                    }
                });
            }
        }, {
            title: 'Add a note',
            action: function action() {

                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_UPDATE)) return;

                $geckoModal.noteAddModal(ctrl.contact).result.then(function (note) {
                    if ($state.$current.name == 'contact.notes') {
                        $geckoChannel.set('notes', [note].concat($geckoChannel.get('notes')));
                    }
                    GeckoUI.messenger.success('Note added');
                });
            }
            // }, {
            //     title    : 'Add to Event',
            //     hideWhen : function() {
            //         return !Gecko.able(Gecko.ABILITY_ATTENDANCES_CREATE);
            //     },
            //     action: function() {

            //         if (!GeckoUI.ableWithReason(Gecko.ABILITY_ATTENDANCES_CREATE)) return;

            //         $geckoModal.eventAddContactModal(ctrl.contact, ctrl.attendances).result.then(function(attendance) {
            //             if ($state.$current.name == 'contact.events') {
            //                 $geckoChannel.set('attendances', [attendance].concat($geckoChannel.get('attendances')));
            //             }
            //             GeckoUI.messenger.success('Contact added to event');
            //         });
            //     },
        }, {
            title: 'Add to an organisation',
            action: function action() {

                if (!GeckoUI.ableWithReason(Gecko.ABILITY_ENROLMENTS_CREATE) || !GeckoUI.ableWithReason(Gecko.ABILITY_ORGANISATIONS_UPDATE)) return;

                $geckoModal.organisationAddModal([ctrl.contact.id]).result.then(function () {
                    if ($state.$current.name == 'contact.notes') $state.go($state.$current.name, $stateParams);
                    GeckoUI.messenger.success('Organisation added');
                });
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_ORGANISATIONS_UPDATE);
            }
        }, {
            title: 'View phone numbers',
            action: function action() {

                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_VIEW)) return;

                $geckoModal.contactNumbersModal(ctrl.contact, ctrl.contactIncludes).result.then(function (contact) {
                    ctrl.contact = contact;
                });
            }

        }, {
            title: 'Merge this contact',
            hideWhen: function hideWhen() {
                return ctrl.campaign;
            },
            action: function action() {

                if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_UPDATE)) return;

                $geckoModal.contactFind(1).result.then(function (contactId) {
                    // Dont allow merging contact into the same contact
                    if (ctrl.contact.id === contactId) return GeckoUI.messenger.error('You cannot merge a contact with its self.');

                    GeckoUI.dialog.confirm('Merging contacts cannot be undone. Are you sure you want to continue?', function (ok) {
                        if (ok) {
                            ctrl.contact.include(ctrl.contactIncludes).merge(contactId).then(function (contact) {
                                GeckoUI.messenger.success('Contacts merged');
                                ctrl.contact = contact;
                                $scope.$digest();
                                // Display new values without having to refresh manually
                                $state.go($state.$current.name, $stateParams, { reload: true });
                            }).catch(function (error) {
                                GeckoUI.messenger.error(error.errors);
                                $scope.$digest();
                            });
                        }
                    });
                });
            }

        }, {
            title: 'View contact portal',
            action: function action() {
                new Gecko.BaseModel().call('/contacts/' + ctrl.contact.id, 'GET', {
                    contact_rfields: 'portal_url'
                }, true).then(function (res) {
                    window.open(res.contact.portal_url, true);
                }).catch(console.error);
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_PORTAL_CONTACT);
            }
        }, {
            title: 'View student portal',
            action: function action() {
                new Gecko.BaseModel().call('/contacts/' + ctrl.contact.id, 'GET', {
                    contact_rfields: 'portal_url,student_portal_url'
                }, true).then(function (res) {
                    window.open(res.contact.student_portal_url, true);
                }).catch(console.error);
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_STUDENT_PORTAL);
            }
        }, {
            title: ctrl.campaign ? 'Remove from campaign' : 'Delete this contact',
            hideWhen: function hideWhen() {
                return ctrl.on_call || !Gecko.able(Gecko.ABILITY_USER) || ctrl.campaign && [Gecko.Subscriber.PENDING, Gecko.Subscriber.AVAILABLE, Gecko.Subscriber.ASSIGNED, Gecko.Subscriber.SCHEDULED].indexOf(subscriber.status) == -1;
            },
            action: function action() {

                // If this is a subscriber
                if (ctrl.campaign) {

                    if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_UPDATE)) return; // TODO Ability

                    var moduleTitle = [Gecko.Campaign.MODULE_SMS, Gecko.Campaign.MODULE_EMAIL].indexOf(ctrl.campaign.module) !== -1 ? 'broadcast' : 'campaign';

                    GeckoUI.dialog.confirm('Are you sure you want to remove this contact from this ' + moduleTitle + '?', function (value) {
                        if (value) {
                            // Cancel prepared call before reomving subscriber
                            var removeSubscriber = function removeSubscriber() {
                                ctrl.subscriber.remove().then(function () {
                                    GeckoUI.messenger.success('Contact removed from ' + moduleTitle);
                                    // Redirect to broadcast overview
                                    if ([Gecko.Campaign.MODULE_SMS, Gecko.Campaign.MODULE_EMAIL].indexOf(ctrl.campaign.module) !== -1) {
                                        return $state.go('broadcast.overview', { broadcast_id: ctrl.campaign.id });
                                        // Redirect to campaign overview
                                    } else {
                                        return $state.go('campaignsedit.view', { campaign_id: ctrl.campaign.id, module: ctrl.campaign.module });
                                    }
                                }).catch(function (err) {
                                    GeckoUI.messenger.error(err.errors);
                                });
                            };
                            // Call is undefined if the campaign is pending.
                            if (call) {
                                ctrl.call.cancel().then(function () {
                                    removeSubscriber();
                                }).catch(function (err) {
                                    GeckoUI.messenger.error(err.errors);
                                });
                            } else {
                                removeSubscriber();
                            }
                        }
                    });
                }
                // If this is a contact
                else {

                        if (!GeckoUI.ableWithReason(Gecko.ABILITY_CONTACTS_DELETE)) return;

                        GeckoUI.dialog.confirm('Are you sure you want to delete this contact?', function (value) {
                            if (value) {
                                ctrl.contact.remove().then(function () {
                                    GeckoUI.messenger.success('Contact removed');
                                    $state.go('contacts');
                                }).catch(function (err) {
                                    GeckoUI.messenger.error(err.errors);
                                });
                            }
                        });
                    }
            }
        }];

        // Open labels modal
        ctrl.labelsAction = function () {
            return $geckoModal.labelModal(ctrl.contact).result.then(function () {
                GeckoUI.messenger.success('Contact labels updated.');
            });
        };

        // Start call
        ctrl.startCall = function () {
            // Open call
            return $geckoCall.open(ctrl.contact.id, ctrl.campaign.id);
        };

        // End Call
        ctrl.endCall = function () {
            // Dont end if call doesnt match contact
            if (ctrl.call.contact_id != ctrl.contact.id) return false;
            // End call
            return $geckoCall.end();
        };

        // Call setup
        if (ctrl.call && Gecko.user && ctrl.call.user_id == Gecko.user.id) {
            // Set PREPARED call on $geckoCall
            $geckoCall.restore(ctrl.call);
            // Open call if started or complete
            // If (ctrl.campaign && [Gecko.Call.STARTED, Gecko.Call.ENDED].indexOf($geckoCall.getStatus()) != -1) $geckoCall.open();
            if (ctrl.campaign && ($geckoCall.hasStarted() || $geckoCall.hasEnded())) $geckoCall.open();
        }

        // Subscriber buttons tests
        ctrl.hasOtherActiveCall = function () {
            // Return $geckoCall.call && (ctrl.subscriber && $geckoCall.call.subscriber_id != ctrl.subscriber.id || ctrl.contact && $geckoCall.call.contact_id != ctrl.contact.id) && ([Gecko.Call.PREPARING, Gecko.Call.STARTED, Gecko.Call.ENDED].indexOf($geckoCall.getStatus()) > -1);
            return $geckoCall.call && $geckoCall.call.contact_id && (ctrl.subscriber && $geckoCall.call.subscriber_id != ctrl.subscriber.id || ctrl.contact && $geckoCall.call.contact_id != ctrl.contact.id) && ($geckoCall.hasPrepared() || $geckoCall.hasStarted() || $geckoCall.hasEnded());
        };

        ctrl.callBelongsToOtherSubscriber = function () {
            return ctrl.subscriber && $geckoCall.call.subscriber_id != ctrl.subscriber.id;
        };

        ctrl.callBelongsToOtherUser = function () {
            return ctrl.subscriber && [Gecko.Subscriber.ASSIGNED, Gecko.Subscriber.IN_PROGRESS].indexOf(ctrl.subscriber.status) !== -1 && ctrl.subscriber.user_id != Gecko.user.id;
        };

        ctrl.contactHasValidNumber = function () {
            return ctrl.contact && ctrl.contact.related_numbers && $filter('filter')(ctrl.contact.related_numbers, { do_not_call: 0 }).length > 0;
        };

        ctrl.contactHasValidSMSNumber = function () {
            return ctrl.contact && ctrl.contact.related_numbers && $filter('filter')(ctrl.contact.related_numbers, { do_not_sms: 0 }).length > 0;
        };

        ctrl.isSubscriber = function () {
            return ctrl.campaign && ctrl.campaign.module == Gecko.Campaign.MODULE_CALL && ctrl.campaign.status == Gecko.Campaign.ACTIVE;
        };

        ctrl.callerIsInCampaign = function () {
            var userIds = ctrl.campaign.related_users ? ctrl.campaign.related_users.map(function (user) {
                return user.id;
            }) : [];
            if (!Gecko.user) return false;
            return userIds.indexOf(Gecko.user.id) !== -1;
        };

        // Show / hide subscriber buttons
        ctrl.showStartCallBtn = function () {
            return ctrl.callerIsInCampaign() && Gecko.User.hasOutbound() && ctrl.isSubscriber() && $geckoCall.hasPrepared() && ctrl.subscriber.can_call && ctrl.contactHasValidNumber();
        };

        ctrl.showEndCallBtn = function () {
            return Gecko.User.hasOutbound() && ctrl.isSubscriber() && $geckoCall.hasStarted() && !ctrl.callBelongsToOtherUser() && !ctrl.callBelongsToOtherSubscriber();
        };

        ctrl.showCompleteCallBtn = function () {
            return Gecko.User.hasOutbound() && ctrl.isSubscriber() && $geckoCall.hasEnded() && !ctrl.callBelongsToOtherUser() && !ctrl.callBelongsToOtherSubscriber();
        };

        ctrl.showNextCallBtn = function () {
            return ctrl.callerIsInCampaign() && Gecko.User.hasOutbound() && ctrl.isSubscriber() && !ctrl.showStartCallBtn() && !ctrl.showEndCallBtn() && !ctrl.showCompleteCallBtn() && !ctrl.showReturnToCallBtn();
        };

        ctrl.showReturnToCallBtn = function () {
            return Gecko.User.hasOutbound() && ctrl.hasOtherActiveCall();
        };

        // Breadcrumbs
        if (ctrl.campaign) {
            if (ctrl.campaign.module === Gecko.Campaign.MODULE_EMAIL || ctrl.campaign.module === Gecko.Campaign.MODULE_SMS) {
                ctrl.breadcrumbs = [{
                    label: 'Broadcasts',
                    click: function click() {
                        $state.go('broadcasts');
                    }
                }, {
                    label: ctrl.campaign.title,
                    click: function click() {
                        $state.go('broadcast.overview', { broadcast_id: ctrl.campaign.id });
                    }
                }, {
                    label: ctrl.subscriber.related_contact.full_name || ctrl.subscriber.related_contact.email,
                    active: true
                }];
            } else {
                ctrl.breadcrumbs = [{
                    label: 'Campaigns',
                    click: function click() {
                        $state.go('campaigns', { module: ctrl.campaign.module });
                    }
                }, {
                    label: ctrl.campaign.title,
                    click: function click() {
                        if (ctrl.campaign && ctrl.campaign.id && ctrl.campaign.module) {
                            $state.go('campaignsedit.view', { campaign_id: ctrl.campaign.id, module: ctrl.campaign.module });
                        }
                    }
                }, {
                    label: ctrl.subscriber.related_contact.full_name || ctrl.subscriber.related_contact.email,
                    active: true
                }];
            }
        } else {
            ctrl.breadcrumbs = [{
                label: 'Contacts',
                click: function click() {
                    $state.go('contacts');
                }
            }, {
                label: ctrl.contact.full_name || ctrl.contact.email || 'No Name',
                active: true
            }];
        }

        // Register channel
        $geckoChannel.register('contact', ['subscriber'], ctrl);
        $geckoChannel.set('subscriber', ctrl.subscriber);

        // Cleanup $geckoChannel
        $scope.$on('$destroy', function () {
            $geckoChannel.unregister('contact');
        });
    }

    angular.module('GeckoEngage').controller('ContactCtrl', ContactCtrl);
})();