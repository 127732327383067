(function () {
    'use strict';

    function geckoFields() {

        var fieldWidths = {
            script: 'col-xs-12',
            matrix: 'col-xs-12',
            map: 'col-xs-12',
            panel_options: 'col-xs-12',
            repeatable: 'col-xs-12',
            title: 'col-xs-12',
            textarea: 'col-xs-12'
        };

        return {
            restrict: 'E',
            scope: {
                fields: '=',
                values: '=',
                filter: '=',
                entityId: '=',
                cardType: '@?'
            },
            template: function template(tElement, tAttrs) {

                if (typeof tAttrs.inline !== 'undefined') {
                    return '<div ng-class="ctrl.fieldClass(field)" class="form-group" ng-repeat="field in ctrl.fields | filter: ctrl.itemFilter">\n                               <gecko-field-inline field="field" value="ctrl.values[field.id]" entity-id="{{ctrl.entityId}}"></gecko-field-inline>\n                            </div>';
                } else {
                    return '<div ng-class="ctrl.fieldClass(field)" class="form-group" ng-repeat="field in ctrl.fields | filter: ctrl.itemFilter">\n                                <gecko-field field="field" values="ctrl.values"></gecko-field>\n                            </div>';
                }
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($scope, $attrs, geckoFieldValueLink) {
                var ctrl = this;

                // Render CSS
                ctrl.fieldClass = function (field) {

                    var fieldClasses = [];

                    // Width
                    if (field.colClass) {
                        fieldClasses.push(field.colClass);
                    } else if (fieldWidths[field.type]) {
                        fieldClasses.push(fieldWidths[field.type]);
                    } else {
                        fieldClasses = fieldClasses.concat(['col-xs-12', 'col-md-6', 'col-lg-4']);
                    }

                    // Break (new line)
                    if (field.break) {
                        fieldClasses.push('break');
                    }

                    // Error
                    if (field.hasError) fieldClasses.push('field-has-error');

                    return fieldClasses;
                };

                ctrl.itemFilter = function (field, index, array) {

                    var isShown = true;

                    if (field.hideWhen) {
                        isShown = isShown && !field.hideWhen(ctrl.values);
                    }

                    if (ctrl.filter) {
                        isShown = isShown && ctrl.filter(field, index, array);
                    }

                    return isShown;
                };

                $scope.$watch('ctrl', function () {

                    // Convert the value array to an object
                    if (typeof $attrs.inline !== 'undefined') {
                        if (ctrl.values instanceof Array) {
                            var obj = {};

                            ctrl.values.forEach(function (value) {

                                var fieldKey;
                                if (value.related_field && value.related_field.field_type === Gecko.Field.FIELD_TYPE_FORM && ctrl.cardType != 'response') {
                                    fieldKey = value.related_field.contact_field_id;
                                } else {
                                    fieldKey = value.field_id;
                                }
                                obj[fieldKey] = value;
                            });

                            ctrl.values = obj;
                        }
                    }

                    // Map linked fields (this is aimed at parent/child functionality in the response page) ONLY do if any field has a parent_id set
                    if (ctrl.fields && ctrl.fields.length && ctrl.fields.filter(function (field) {
                        return field.parent_id;
                    }).length) {
                        ctrl.fields.forEach(function (field) {
                            if ([Gecko.Field.TYPE_SELECT, Gecko.Field.TYPE_MULTI].indexOf(field.type) !== -1 && ctrl.values[field.id] && ctrl.values[field.id].safe) {
                                geckoFieldValueLink.update(field.id, ctrl.values[field.id].safe);
                            }
                        });
                    }
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoFields', geckoFields);
})();