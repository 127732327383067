var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

;(function () {

    function FileViewerModal($geckoModalProvider) {

        $geckoModalProvider.modalAdd('fileView', function (_files) {
            return {
                size: 'lg',
                controllerAs: 'ctrl',
                template: '\n                        <gecko-modal class="file-viewer-modal" data-title="Files">\n                            <div class="row">\n                                <div ng-if="ctrl.files.length > 1" class="col-xs-3 files-panel">\n                                    <ul>\n                                        <li\n                                            ng-repeat="file in ctrl.files"\n                                            ng-click="ctrl.setFile(file, $index)"\n                                            ng-class="{\'active\': ctrl.currentFileIndex === $index}">\n                                            {{file.filename}}\n                                        </li>\n                                    </ul>\n                                </div>\n                                <div class="file-area" ng-class="{\'col-xs-9\': ctrl.files.length > 1, \'col-xs-12\': ctrl.files.length < 2}">\n                                    <file-viewer-content\n                                        ng-if="ctrl.currentFile"\n                                        file="ctrl.currentFile">\n                                    </file-viewer-content>\n                                </div>\n                            </div>\n                        </gecko-modal>\n                    ',
                controller: 'FileViewModalCtrl',
                resolve: {
                    files: function files() {
                        return _files;
                    }
                }
            };
        });
    }

    function FileViewModalCtrl($scope, $modalInstance, files) {
        var ctrl = this;

        ctrl.files = (files || []).map(function (file) {
            if (typeof file === 'string') {
                return {
                    url: file,
                    filename: file.replace(/\?.+$/, '').split('/').pop()
                };
            } else if ((typeof file === 'undefined' ? 'undefined' : _typeof(file)) === 'object') {
                return {
                    url: file.url,
                    filename: file.filename
                };
            }
        });

        ctrl.setFile = function (file, index) {
            ctrl.currentFile = null;
            ctrl.currentFileIndex = null;
            return Promise.resolve().finally(function () {
                ctrl.currentFile = file;
                ctrl.currentFileIndex = index;
            });
        };

        // Set first item as current
        ctrl.setFile(ctrl.files[0], 0);
    }

    function fileViewerContent() {
        return {
            template: '\n                <div class="file-viewer-content">\n\n                    <file-picker-alert file-url="ctrl.file.url" pending></file-picker-alert>\n\n                    <div class="file-viewer-content-loading-wrapper">\n                        <i class="file-viewer-content-icon fa fa-sync fa-spin"></i>\n                        <p class="file-viewer-content-filename">Loading...</p>\n                    </div>\n                    <div class="file-viewer-content-processing-wrapper">\n                        <i class="file-viewer-content-icon fa fa-exclamation-triangle"></i>\n                        <p class="file-viewer-content-filename">You must save to view the file...</p>\n                    </div>\n                    <div class="file-viewer-content-error-wrapper">\n                        <i class="file-viewer-content-icon fa fa-file-alt"></i>\n                        <p class="file-viewer-content-filename">{{ctrl.fileName}}</p>\n                        <span class="btn btn-default gecko-ui-material" ng-click="ctrl.downloadFile()">\n                            <i class="fa fa-download"></i>\n                            Download File\n                        </span>\n                    </div>\n                </div>\n            ',
            restrict: 'E',
            scope: {
                file: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($scope, $element, fileAvService) {
                var ctrl = this;

                $scope.$watch('ctrl', function () {
                    var wrapper = $element.find('> div');
                    ctrl.fileName = ctrl.file.filename;
                    ctrl.fileUrl = ctrl.file.url;

                    var isFileAtUrl = function isFileAtUrl(url) {
                        return new Promise(function (resolve, reject) {
                            fetch(url, {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'multipart/byteranges',
                                    'Range': 'bytes=0-0'
                                }
                            }).then(function (res) {
                                return res.status > 199 && res.status < 300 ? resolve() : reject();
                            }).catch(reject);
                        });
                    };

                    ctrl.downloadFile = function () {
                        return fileAvService.downloadFileWithAvStatusCheck(ctrl.fileUrl);
                    };

                    var image = function () {
                        var img = document.createElement('img');
                        img.src = ctrl.fileUrl;
                        img.onload = function () {
                            wrapper.removeClass('file-viewer-content-error');
                            wrapper.removeClass('file-viewer-content-processing');
                            wrapper.addClass('file-viewer-content-success');
                            wrapper.append('<p class="file-viewer-content-filename">' + ctrl.fileName + '</p>');
                        };
                        img.onerror = function () {
                            isFileAtUrl(ctrl.fileUrl).then(function () {
                                wrapper.removeClass('file-viewer-content-success');
                                wrapper.removeClass('file-viewer-content-processing');
                                wrapper.addClass('file-viewer-content-error');
                            }).catch(function () {
                                wrapper.removeClass('file-viewer-content-success');
                                wrapper.removeClass('file-viewer-content-error');
                                wrapper.addClass('file-viewer-content-processing');
                            });
                        };
                        return img;
                    }();

                    wrapper.append(image);
                });
            }
        };
    }

    angular.module('GeckoEngage').config(FileViewerModal).controller('FileViewModalCtrl', FileViewModalCtrl).directive('fileViewerContent', fileViewerContent);
})();