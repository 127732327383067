(function () {
    'use strict';

    function BroadcastsRoute($stateProvider) {
        $stateProvider.state('broadcasts', {
            url: "/broadcasts?{page}&{search}&{module}&{status}&{order}&{sort}",
            templateUrl: "/modules/broadcasts/views/index.4ef3a7bd.html",
            controller: 'BroadcastsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Broadcasts',
            requires: [Gecko.ABILITY_BROADCASTS_VIEW],
            resolve: {
                broadcastsQuery: function broadcastsQuery($stateParams, Gecko) {
                    var campaigns = new Gecko.Campaign().include('user', 'users', 'form', 'all_campaigns').includeCount('subscribers', 'subscribers_scheduled_due');

                    // Module
                    if ($stateParams.module) {
                        campaigns.where('module', $stateParams.module);
                    } else {
                        campaigns.where('module', [Gecko.Campaign.MODULE_EMAIL, Gecko.Campaign.MODULE_SMS]);
                    }

                    // Status
                    campaigns.where('campaign_status', $stateParams.status);

                    if ($stateParams.search) campaigns.where('keyword', $stateParams.search);

                    if ($stateParams.order) campaigns.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');

                    return campaigns.perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1).rfields({ campaign: ['status', 'created_at', 'title', 'start_date', 'end_date', 'form_id'], user: ['full_name', 'color'], form: ['name', 'internal_name'] });
                },
                data: function data($stateParams, Gecko, broadcastsQuery) {
                    var _query = {};

                    // Broadcasts
                    _query.broadcasts = broadcastsQuery;

                    // Senders
                    _query.senders = new Gecko.Sender().where('outgoing_sms', 1).limit(1).rfields({ sender: ['id'] });

                    // Templates
                    _query.templates = new Gecko.Template().where('type', Gecko.Campaign.MODULE_SMS).limit(1).rfields({ template: ['id'] });

                    return Gecko.multi(_query);
                },
                broadcasts: function broadcasts(data) {
                    return data.broadcasts;
                },
                senders: function senders(data) {
                    return data.senders ? data.senders : null;
                },
                templates: function templates(data) {
                    return data.templates ? data.templates : null;
                }
            }
        });

        var broadcastCreateUpdateRequires = function broadcastCreateUpdateRequires($stateParams) {
            if ($stateParams.broadcast_id === 'new') {
                return [Gecko.ABILITY_BROADCASTS_CREATE];
            }
            return [Gecko.ABILITY_BROADCASTS_UPDATE];
        };

        $stateProvider.state('broadcast', {
            url: "/broadcasts/{broadcast_id}?{wizard}",
            templateUrl: '/modules/broadcasts/views/broadcast.c25737e3.html',
            controller: 'BroadcastCtrl',
            controllerAs: 'ctrl',
            page_title: 'Broadcast',
            requires: broadcastCreateUpdateRequires,
            resolve: {
                campaignRfields: function campaignRfields() {
                    return {
                        campaign: ['module', 'status', 'title', 'refreshing'],
                        filter: ['refreshed_at']
                    };
                },
                campaign: function campaign($stateParams, campaignRfields) {
                    if ($stateParams.broadcast_id != 'new') {
                        return new Gecko.Campaign().rfields(campaignRfields).include('filter').get($stateParams.broadcast_id);
                    } else {
                        return new Gecko.Campaign();
                    }
                }
            }
        }).state('broadcast.overview', {
            url: "/overview?{page}&{status}&{order}&{sort}&{search}",
            templateUrl: "/modules/broadcasts/views/overview.c35e4a09.html",
            controller: 'BroadcastOverviewCtrl',
            controllerAs: 'ctrl',
            page_title: 'View Broadcast',
            requires: [Gecko.ABILITY_BROADCASTS_VIEW],
            resolve: {
                subscribersQuery: function subscribersQuery($stateParams) {
                    var order = $stateParams.order !== undefined ? $stateParams.order : 'sort_value',
                        sort = $stateParams.sort !== undefined ? $stateParams.sort : null;

                    var subscribers = new Gecko.Subscriber().rfields({ subscriber: ['status', 'field_1', 'field_2', 'field_3', 'field_4', 'field_5', 'field_6', 'contact_id', 'call_at'] }).include('all_campaigns').where('campaign_id', $stateParams.broadcast_id);
                    // Status
                    if ($stateParams.status) subscribers.where('status', $stateParams.status);
                    // Search
                    if ($stateParams.search) subscribers.search($stateParams.search);
                    return subscribers.orderBy(order, sort).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page ? $stateParams.page : 1);
                },
                broadcastRfields: function broadcastRfields() {
                    return {
                        campaign: ['module', 'refreshing', 'processing_eta', 'status', 'sms_sender_id', 'template_id', 'title', 'start_date', 'end_date'],
                        campaignfield: ['list_view'],
                        field: ['type', 'data_type', 'label', 'system', 'is_calculated']
                    };
                },
                data: function data($stateParams, subscribersQuery, broadcastRfields) {
                    var _query = {};

                    // Broadcast
                    _query.broadcast = new Gecko.Campaign().include('campaign_fields', 'campaign_fields.field', 'all_campaigns').where('campaignfield_list_view', 1).rfields(broadcastRfields).path($stateParams.broadcast_id);

                    // Subscriber
                    _query.subscribers = subscribersQuery;

                    // Stats (cant be used because of the segment stuff for now)
                    _query.stat = new Gecko.Stat().multiConfig({
                        method: 'POST',
                        model: 'subscriber',
                        count: '*',
                        group_by: 'status',
                        filter: { campaign_id: $stateParams.broadcast_id },
                        no_cache: 1
                    }).path('generate');

                    return Gecko.multi(_query);
                },
                broadcast: function broadcast(data) {
                    return data.broadcast;
                },
                subscribers: function subscribers(data) {
                    return data.subscribers;
                },
                stat: function stat(data) {
                    return data.stat;
                }
                // fieldList: function(data) {
                //     return data.fieldList;
                // },
            }
        }).state('broadcast.settings', {
            url: "/settings",
            templateUrl: '/modules/broadcasts/views/settings.42a4eeaa.html',
            controllerAs: 'ctrl',
            page_title: 'Broadcast Settings',
            controller: 'BroadcastSettingsCtrl',
            requires: broadcastCreateUpdateRequires,
            resolve: {
                campaignIncludes: function campaignIncludes() {
                    return ['campaign_fields:all', 'campaign_fields.field'];
                },
                campaign: function campaign($stateParams, campaignIncludes) {
                    if ($stateParams.broadcast_id !== 'new') {
                        return new Gecko.Campaign().include(campaignIncludes).get($stateParams.broadcast_id);
                    } else {
                        // Init with correct module
                        if (Gecko.able(Gecko.ABILITY_EMAIL_BROADCASTS_CREATE)) {
                            var module = Gecko.Campaign.MODULE_EMAIL;
                        } else if (Gecko.able(Gecko.ABILITY_SMS_BROADCASTS_CREATE)) {
                            var module = Gecko.Campaign.MODULE_SMS;
                        } else {
                            var module = null;
                        }
                        return new Gecko.Campaign().create({
                            sort_field_id: Gecko.account.contact_sort_field_id,
                            sort_direction: '' + (Gecko.account.contact_sort_direction || '').toLowerCase(),
                            email_sender_id: Gecko.account.sender_id,
                            sms_sender_id: Gecko.account.sms_sender_id,
                            module: module
                        });
                    }
                },
                data: function data(geckoDataService) {
                    return geckoDataService.fetch(['senders', 'templates', 'fields']);
                },
                email_senders: function email_senders(data) {
                    return data.senders.filter(function (s) {
                        return s.type === Gecko.Sender.EMAIL;
                    });
                },
                email_templates: function email_templates(data) {
                    return data.templates.filter(function (t) {
                        return t.type === Gecko.Template.TYPE_EMAIL;
                    });
                },
                email_recipients: function email_recipients(data) {
                    return data.fields.filter(function (f) {
                        return f.type === Gecko.Field.TYPE_EMAIL;
                    });
                },
                sms_senders: function sms_senders(data) {
                    return data.senders.filter(function (s) {
                        return s.outgoing_sms === 1;
                    });
                },
                sms_templates: function sms_templates(data) {
                    return data.templates.filter(function (t) {
                        return t.type === Gecko.Template.TYPE_SMS;
                    });
                },
                tel_fields: function tel_fields(data) {
                    return data.fields.filter(function (f) {
                        return f.type === Gecko.Field.TYPE_TEL;
                    });
                }
            }
        }).state('broadcast.filter', {
            url: "/filter?{page}&{status}&{order}&{sort}",
            templateUrl: '/modules/broadcasts/views/filter.0334c64c.html',
            controller: 'BroadcastFiltersCtrl',
            controllerAs: 'ctrl',
            page_title: 'Select Contacts',
            requires: [Gecko.ABILITY_BROADCASTS_UPDATE],
            resolve: {
                data: function data($stateParams, contactsRfields) {
                    var _query = {};
                    // Campaign
                    _query.campaign = new Gecko.Campaign().include('filter.conditions').rfields({ filter: ['requirement'] }).path($stateParams.broadcast_id);

                    return Gecko.multi(_query);
                },
                campaign: function campaign(data) {
                    return data.campaign;
                },
                fieldList: function fieldList(geckoDataService) {
                    return geckoDataService.fetch(['field_list_contacts']).then(function (data) {
                        return data.field_list_contacts;
                    });
                },
                contactFields: function contactFields(geckoDataService) {
                    return geckoDataService.fetch(['contact_fields']).then(function (data) {
                        return data.contact_fields;
                    });
                },
                contactsRfields: function contactsRfields() {
                    return {
                        contact: ['field_1', 'field_2', 'field_3', 'field_4', 'field_5', 'field_6']
                    };
                },
                contacts: function contacts($stateParams, contactsRfields, campaign, contactFields, fieldList, getContactsService) {
                    var conditions = campaign.related_filter && campaign.related_filter.related_conditions ? Gecko.convertInstancesToObjects(campaign.related_filter.related_conditions) : [];
                    var requirement = campaign.related_filter && campaign.related_filter.requirement ? campaign.related_filter.requirement : 'ANY';
                    var order = $stateParams.order !== undefined ? $stateParams.order : 'field_1';
                    var sort = $stateParams.sort !== undefined ? $stateParams.sort : 'ASC';

                    var page = $stateParams.page > 0 ? $stateParams.page : 1;
                    var perPage = GeckoUI.getPageLimit(this.self.name);

                    // Avoid loading all contacts for no reason
                    if (!conditions || !conditions.length) {
                        return Promise.resolve({
                            data: [],
                            pagination: {}
                        });
                    }

                    return getContactsService.getContacts({
                        order: order,
                        sort: sort,
                        page: page,
                        perPage: perPage,
                        // filterId: null,
                        conditions: conditions,
                        // search: null,
                        conditionOperator: requirement,
                        includes: [],
                        rFields: contactsRfields,
                        number: $stateParams.number,
                        // searchLabels: null,
                        // labelOperator: null,
                        contactFields: contactFields,
                        fieldList: fieldList
                        // labelList: null
                    }, true);
                }
            }
        }).state('broadcast.workflows', {
            url: "/workflows",
            templateUrl: "/modules/broadcasts/views/workflows.b875bfee.html",
            controller: 'BroadcastWorkflowsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Broadcast Workflows',
            requires: [Gecko.ABILITY_BROADCASTS_UPDATE],
            resolve: {
                campaign: function campaign($stateParams) {
                    return new Gecko.Campaign().include('outcomes:all', 'workflows:all').get($stateParams.broadcast_id);
                },
                multiData: function multiData(geckoDataService) {
                    return geckoDataService.fetch(['fields', 'campaigns', 'consents', 'labels', 'users', 'senders', 'templates', 'integrations', 'outcomes', 'tasks']);
                }
            }
        }).state('broadcast.launch', {
            url: "/launch",
            templateUrl: "/modules/broadcasts/views/launch.2a119b48.html",
            controller: 'BroadcastLaunchCtrl',
            controllerAs: 'ctrl',
            page_title: 'Broadcast Launch',
            requires: [Gecko.ABILITY_BROADCASTS_UPDATE],
            resolve: {
                broadcastRfields: function broadcastRfields() {
                    return {
                        campaign: ['module', 'email_sender_id', 'sms_sender_id', 'template_id', 'start_date', 'end_date', 'refreshing']
                        // filter: [],
                        // condition: []
                    };
                },
                broadcast: function broadcast($stateParams, broadcastRfields) {
                    return new Gecko.Campaign().includeCount('subscribers').rfields(broadcastRfields).get($stateParams.broadcast_id);
                },
                selectedData: function selectedData(asyncOptions, broadcast) {
                    return asyncOptions.getSelectedValues({
                        template: { model: 'Template', id: broadcast.template_id, fields: { template: ['name'] } },
                        sender: { model: 'Sender', id: broadcast.sms_sender_id || broadcast.email_sender_id, fields: { sender: ['name'] } }
                    });
                }
            }
        }).state('broadcast.stats', {
            url: "/stats?{date_from}&{date_to}&{new_metrics}",
            templateUrl: "/modules/broadcasts/views/stats.78bc4393.html",
            controller: 'BroadcastStatsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Broadcast Stats',
            requires: [Gecko.ABILITY_BROADCASTS_VIEW, Gecko.ABILITY_MESSAGE_STATS],
            resolve: {
                dashboard: function dashboard($stateParams, campaign) {
                    var id = campaign.module + '_broadcast';
                    var config = {
                        new_metrics: $stateParams.new_metrics ? $stateParams.new_metrics : ''
                    };
                    config.campaign_id = $stateParams.broadcast_id;

                    // Filter by date ranger
                    if ($stateParams.date_from && $stateParams.date_to) {
                        config.override_date = $stateParams.date_from + ':' + $stateParams.date_to;
                    }

                    return new Gecko.Dashboard().display(id, config);
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(BroadcastsRoute);
})();