(function () {
    'use strict';

    function ChatWorkflowsCtrl($state, workflows, multiData, unsavedAlert) {
        var ctrl = this;

        ctrl.workflows = workflows;
        ctrl.multiData = multiData;

        ctrl.save = function () {
            return new Gecko.BaseModel().call('/workflows', 'POST', { workflows: ctrl.workflows }, true).then(function (response) {
                GeckoUI.messenger.success('Chat Workflows saved successfully');

                unsavedAlert.clear();
            }).catch(function (err) {
                try {
                    GeckoUI.messenger.error(err.errors[0].detail);
                } catch (err) {
                    console.error(err);
                }
            });
        };

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Chat Settings',
            click: function click() {
                window.location.href = '/admin/settings?section=chat';
            }
        }, {
            label: 'Chat Workflows',
            active: true
        }];
    }

    angular.module('GeckoEngage').controller('ChatWorkflowsCtrl', ChatWorkflowsCtrl);
})();