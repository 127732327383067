var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/*!
 * Bootstrap NES v3.4.5 (https://herodevs.com/)
 * Copyright 2023-2024 HeroDevs, Inc
 * Licensed under the HeroDevs NES License license
 */
if ("undefined" == typeof jQuery) throw new Error("Bootstrap's JavaScript requires jQuery");!function () {
  "use strict";
  var t = jQuery.fn.jquery.split(" ")[0].split(".");if (t[0] < 2 && t[1] < 9 || 1 == t[0] && 9 == t[1] && t[2] < 1 || 3 < t[0]) throw new Error("Bootstrap's JavaScript requires jQuery version 1.9.1 or higher, but lower than version 4");
}(), function (o) {
  "use strict";
  o.fn.emulateTransitionEnd = function (t) {
    var e = !1,
        i = this;o(this).one("bsTransitionEnd", function () {
      e = !0;
    });return setTimeout(function () {
      e || o(i).trigger(o.support.transition.end);
    }, t), this;
  }, o(function () {
    o.support.transition = function () {
      var t,
          e = document.createElement("bootstrap"),
          i = { WebkitTransition: "webkitTransitionEnd", MozTransition: "transitionend", OTransition: "oTransitionEnd otransitionend", transition: "transitionend" };for (t in i) {
        if (e.style[t] !== undefined) return { end: i[t] };
      }return !1;
    }(), o.support.transition && (o.event.special.bsTransitionEnd = { bindType: o.support.transition.end, delegateType: o.support.transition.end, handle: function handle(t) {
        if (o(t.target).is(this)) return t.handleObj.handler.apply(this, arguments);
      } });
  });
}(jQuery), function (s) {
  "use strict";
  var e = '[data-dismiss="alert"]',
      a = function a(t) {
    s(t).on("click", e, this.close);
  };a.VERSION = "3.4.1", a.TRANSITION_DURATION = 150, a.prototype.close = function (t) {
    var e = s(this),
        i = e.attr("data-target"),
        o = (i || (i = e.attr("href"), i = s.bs.helpers.extractSelector(i)), i = "#" === i ? [] : i, s(document).find(i));function n() {
      o.detach().trigger("closed.bs.alert").remove();
    }t && t.preventDefault(), (o = o.length ? o : e.closest(".alert")).trigger(t = s.Event("close.bs.alert")), t.isDefaultPrevented() || (o.removeClass("in"), s.support.transition && o.hasClass("fade") ? o.one("bsTransitionEnd", n).emulateTransitionEnd(a.TRANSITION_DURATION) : n());
  };var t = s.fn.alert;s.fn.alert = function (i) {
    return this.each(function () {
      var t = s(this),
          e = t.data("bs.alert");e || t.data("bs.alert", e = new a(this)), "string" == typeof i && e[i].call(t);
    });
  }, s.fn.alert.Constructor = a, s.fn.alert.noConflict = function () {
    return s.fn.alert = t, this;
  }, s(document).on("click.bs.alert.data-api", e, a.prototype.close);
}(jQuery), function (l) {
  "use strict";
  var h = function h(t, e) {
    this.$element = l(t), this.options = l.extend({}, h.DEFAULTS, e), this.isLoading = !1;
  };function i(i) {
    return this.each(function () {
      var t = l(this),
          e = t.data("bs.button");e || t.data("bs.button", e = new h(this, "object" == (typeof i === "undefined" ? "undefined" : _typeof(i)) && i)), "toggle" == i ? e.toggle() : i && e.setState(i);
    });
  }h.hasPurifyWarningShown = !1, h.VERSION = "3.4.1", h.DEFAULTS = { loadingText: "loading..." }, h.prototype.setState = function (i) {
    var o = "disabled",
        n = this.$element,
        s = n.is("input") ? "val" : "html",
        a = n.data(),
        r = window.DOMPurify;i += "Text", null == a.resetText && n.data("resetText", n[s]()), setTimeout(l.proxy(function () {
      var t = void 0 !== r && r.sanitize,
          e = (t || h.hasPurifyWarningShown || (window.console.warn("Could not locate DOMPurify to sanitize button content. This warning will only show once."), h.hasPurifyWarningShown = !0), (null == a[i] ? this.options : a)[i]),
          t = t ? r.sanitize(e) : e;n[s](t), "loadingText" == i ? (this.isLoading = !0, n.addClass(o).attr(o, o).prop(o, !0)) : this.isLoading && (this.isLoading = !1, n.removeClass(o).removeAttr(o).prop(o, !1));
    }, this), 0);
  }, h.prototype.toggle = function () {
    var t,
        e = !0,
        i = this.$element.closest('[data-toggle="buttons"]');i.length ? ("radio" == (t = this.$element.find("input")).prop("type") ? (t.prop("checked") && (e = !1), i.find(".active").removeClass("active"), this.$element.addClass("active")) : "checkbox" == t.prop("type") && (t.prop("checked") !== this.$element.hasClass("active") && (e = !1), this.$element.toggleClass("active")), t.prop("checked", this.$element.hasClass("active")), e && t.trigger("change")) : (this.$element.attr("aria-pressed", !this.$element.hasClass("active")), this.$element.toggleClass("active"));
  };var t = l.fn.button;l.fn.button = i, l.fn.button.Constructor = h, l.fn.button.noConflict = function () {
    return l.fn.button = t, this;
  }, l(document).on("click.bs.button.data-api", '[data-toggle^="button"]', function (t) {
    var e = l(t.target).closest(".btn");i.call(e, "toggle"), l(t.target).is('input[type="radio"], input[type="checkbox"]') || (t.preventDefault(), (e.is("input,button") ? e : e.find("input:visible,button:visible").first()).trigger("focus"));
  }).on("focus.bs.button.data-api blur.bs.button.data-api", '[data-toggle^="button"]', function (t) {
    l(t.target).closest(".btn").toggleClass("focus", /^focus(in)?$/.test(t.type));
  });
}(jQuery), function (h) {
  "use strict";
  var d = function d(t, e) {
    this.$element = h(t), this.$indicators = this.$element.find(".carousel-indicators"), this.options = e, this.paused = null, this.sliding = null, this.interval = null, this.$active = null, this.$items = null, this.options.keyboard && this.$element.on("keydown.bs.carousel", h.proxy(this.keydown, this)), "hover" != this.options.pause || "ontouchstart" in document.documentElement || this.$element.on("mouseenter.bs.carousel", h.proxy(this.pause, this)).on("mouseleave.bs.carousel", h.proxy(this.cycle, this));
  };function s(n) {
    return this.each(function () {
      var t = h(this),
          e = t.data("bs.carousel"),
          i = h.extend({}, d.DEFAULTS, t.data(), "object" == (typeof n === "undefined" ? "undefined" : _typeof(n)) && n),
          o = "string" == typeof n ? n : i.slide;e || t.data("bs.carousel", e = new d(this, i)), "number" == typeof n ? e.to(n) : o ? e[o]() : i.interval && e.pause().cycle();
    });
  }d.VERSION = "3.4.1", d.TRANSITION_DURATION = 600, d.DEFAULTS = { interval: 5e3, pause: "hover", wrap: !0, keyboard: !0 }, d.prototype.keydown = function (t) {
    if (!/input|textarea/i.test(t.target.tagName)) {
      switch (t.which) {case 37:
          this.prev();break;case 39:
          this.next();break;default:
          return;}t.preventDefault();
    }
  }, d.prototype.cycle = function (t) {
    return t || (this.paused = !1), this.interval && clearInterval(this.interval), this.options.interval && !this.paused && (this.interval = setInterval(h.proxy(this.next, this), this.options.interval)), this;
  }, d.prototype.getItemIndex = function (t) {
    return this.$items = t.parent().children(".item"), this.$items.index(t || this.$active);
  }, d.prototype.getItemForDirection = function (t, e) {
    var i = this.getItemIndex(e);return ("prev" == t && 0 === i || "next" == t && i == this.$items.length - 1) && !this.options.wrap ? e : (e = (i + ("prev" == t ? -1 : 1)) % this.$items.length, this.$items.eq(e));
  }, d.prototype.to = function (t) {
    var e = this,
        i = this.getItemIndex(this.$active = this.$element.find(".item.active"));if (!(t > this.$items.length - 1 || t < 0)) return this.sliding ? this.$element.one("slid.bs.carousel", function () {
      e.to(t);
    }) : i == t ? this.pause().cycle() : this.slide(i < t ? "next" : "prev", this.$items.eq(t));
  }, d.prototype.pause = function (t) {
    return t || (this.paused = !0), this.$element.find(".next, .prev").length && h.support.transition && (this.$element.trigger(h.support.transition.end), this.cycle(!0)), this.interval = clearInterval(this.interval), this;
  }, d.prototype.next = function () {
    if (!this.sliding) return this.slide("next");
  }, d.prototype.prev = function () {
    if (!this.sliding) return this.slide("prev");
  }, d.prototype.slide = function (t, e) {
    var i,
        o,
        n,
        s = this.$element.find(".item.active"),
        a = e || this.getItemForDirection(t, s),
        e = this.interval,
        r = "next" == t ? "left" : "right",
        l = this;return a.hasClass("active") ? this.sliding = !1 : (i = a[0], o = h.Event("slide.bs.carousel", { relatedTarget: i, direction: r }), this.$element.trigger(o), o.isDefaultPrevented() ? void 0 : (this.sliding = !0, e && this.pause(), this.$indicators.length && (this.$indicators.find(".active").removeClass("active"), o = h(this.$indicators.children()[this.getItemIndex(a)])) && o.addClass("active"), n = h.Event("slid.bs.carousel", { relatedTarget: i, direction: r }), h.support.transition && this.$element.hasClass("slide") ? (a.addClass(t), "object" == (typeof a === "undefined" ? "undefined" : _typeof(a)) && a.length && a[0].offsetWidth, s.addClass(r), a.addClass(r), s.one("bsTransitionEnd", function () {
      a.removeClass([t, r].join(" ")).addClass("active"), s.removeClass(["active", r].join(" ")), l.sliding = !1, setTimeout(function () {
        l.$element.trigger(n);
      }, 0);
    }).emulateTransitionEnd(d.TRANSITION_DURATION)) : (s.removeClass("active"), a.addClass("active"), this.sliding = !1, this.$element.trigger(n)), e && this.cycle(), this));
  };var t = h.fn.carousel,
      e = (h.fn.carousel = s, h.fn.carousel.Constructor = d, h.fn.carousel.noConflict = function () {
    return h.fn.carousel = t, this;
  }, function (t) {
    var e,
        i = h(this),
        o = i.attr("href"),
        n = h.bs.helpers.extractSelector(o),
        n = i.attr("data-target") || n,
        n = h(document).find(n);n.hasClass("carousel") ? (e = h.extend({}, n.data(), i.data()), (i = i.attr("data-slide-to")) && (e.interval = !1), s.call(n, e), i && n.data("bs.carousel").to(i), t.preventDefault()) : /^javascript:/.test(o) && (window.console.warn("Potential XSS attack detected. Aborting."), t.preventDefault());
  });h(document).on("click.bs.carousel.data-api", "[data-slide]", e).on("click.bs.carousel.data-api", "[data-slide-to]", e), h(window).on("load", function () {
    h('[data-ride="carousel"]').each(function () {
      var t = h(this);s.call(t, t.data());
    });
  });
}(jQuery), function (n) {
  "use strict";
  var s = function s(t, e) {
    this.$element = n(t), this.options = n.extend({}, s.DEFAULTS, e), this.$trigger = n('[data-toggle="collapse"][href="#' + t.id + '"],[data-toggle="collapse"][data-target="#' + t.id + '"]'), this.transitioning = null, this.options.parent ? this.$parent = this.getParent() : this.addAriaAndCollapsedClass(this.$element, this.$trigger), this.options.toggle && this.toggle();
  };function i(t) {
    t = t.attr("data-target") || (t = t.attr("href")) && t.replace(/.*(?=#[^\s]+$)/, "");return n(document).find(t);
  }function a(o) {
    return this.each(function () {
      var t = n(this),
          e = t.data("bs.collapse"),
          i = n.extend({}, s.DEFAULTS, t.data(), "object" == (typeof o === "undefined" ? "undefined" : _typeof(o)) && o);!e && i.toggle && /show|hide/.test(o) && (i.toggle = !1), e || t.data("bs.collapse", e = new s(this, i)), "string" == typeof o && e[o]();
    });
  }s.VERSION = "3.4.1", s.TRANSITION_DURATION = 350, s.DEFAULTS = { toggle: !0 }, s.prototype.dimension = function () {
    return this.$element.hasClass("width") ? "width" : "height";
  }, s.prototype.show = function () {
    if (!this.transitioning && !this.$element.hasClass("in")) {
      var t = this.$parent && this.$parent.children(".panel").children(".in, .collapsing");if (!(t && t.length && (o = t.data("bs.collapse")) && o.transitioning)) {
        var e = n.Event("show.bs.collapse");if (this.$element.trigger(e), !e.isDefaultPrevented()) {
          t && t.length && (a.call(t, "hide"), o || t.data("bs.collapse", null));var i = this.dimension(),
              e = (this.$element.removeClass("collapse").addClass("collapsing")[i](0).attr("aria-expanded", !0), this.$trigger.removeClass("collapsed").attr("aria-expanded", !0), this.transitioning = 1, function () {
            this.$element.removeClass("collapsing").addClass("collapse in")[i](""), this.transitioning = 0, this.$element.trigger("shown.bs.collapse");
          });if (!n.support.transition) return e.call(this);var o = n.camelCase(["scroll", i].join("-"));this.$element.one("bsTransitionEnd", n.proxy(e, this)).emulateTransitionEnd(s.TRANSITION_DURATION)[i](this.$element[0][o]);
        }
      }
    }
  }, s.prototype.hide = function () {
    if (!this.transitioning && this.$element.hasClass("in")) {
      var t = n.Event("hide.bs.collapse");if (this.$element.trigger(t), !t.isDefaultPrevented()) {
        var t = this.dimension(),
            e = (this.$element[t](this.$element[t]())[0].offsetHeight, this.$element.addClass("collapsing").removeClass("collapse in").attr("aria-expanded", !1), this.$trigger.addClass("collapsed").attr("aria-expanded", !1), this.transitioning = 1, function () {
          this.transitioning = 0, this.$element.removeClass("collapsing").addClass("collapse").trigger("hidden.bs.collapse");
        });if (!n.support.transition) return e.call(this);this.$element[t](0).one("bsTransitionEnd", n.proxy(e, this)).emulateTransitionEnd(s.TRANSITION_DURATION);
      }
    }
  }, s.prototype.toggle = function () {
    this[this.$element.hasClass("in") ? "hide" : "show"]();
  }, s.prototype.getParent = function () {
    return n(document).find(this.options.parent).find('[data-toggle="collapse"][data-parent="' + this.options.parent + '"]').each(n.proxy(function (t, e) {
      e = n(e);this.addAriaAndCollapsedClass(i(e), e);
    }, this)).end();
  }, s.prototype.addAriaAndCollapsedClass = function (t, e) {
    var i = t.hasClass("in");t.attr("aria-expanded", i), e.toggleClass("collapsed", !i).attr("aria-expanded", i);
  };var t = n.fn.collapse;n.fn.collapse = a, n.fn.collapse.Constructor = s, n.fn.collapse.noConflict = function () {
    return n.fn.collapse = t, this;
  }, n(document).on("click.bs.collapse.data-api", '[data-toggle="collapse"]', function (t) {
    var e = n(this),
        t = (e.attr("data-target") || t.preventDefault(), i(e)),
        e = t.data("bs.collapse") ? "toggle" : e.data();a.call(t, e);
  });
}(jQuery), function (n) {
  "use strict";
  var s = '[data-toggle="dropdown"]',
      o = function o(t) {
    n(t).on("click.bs.dropdown", this.toggle);
  };function a(t) {
    var e = t.attr("data-target"),
        e = (e || (e = t.attr("href"), e = /#[A-Za-z]/.test(e) && n.bs.helpers.extractSelector(e)), "#" !== e ? n(document).find(e) : null);return e && e.length ? e : t.parent();
  }function r(o) {
    o && 3 === o.which || (n(".dropdown-backdrop").remove(), n(s).each(function () {
      var t = n(this),
          e = a(t),
          i = { relatedTarget: this };!e.hasClass("open") || o && "click" == o.type && /input|textarea/i.test(o.target.tagName) && n.contains(e[0], o.target) || (e.trigger(o = n.Event("hide.bs.dropdown", i)), o.isDefaultPrevented()) || (t.attr("aria-expanded", "false"), e.removeClass("open").trigger(n.Event("hidden.bs.dropdown", i)));
    }));
  }o.VERSION = "3.4.1", o.prototype.toggle = function (t) {
    var e = n(this);if (!e.is(".disabled, :disabled")) {
      var i = a(e),
          o = i.hasClass("open");if (r(), !o) {
        "ontouchstart" in document.documentElement && !i.closest(".navbar-nav").length && n(document.createElement("div")).addClass("dropdown-backdrop").insertAfter(n(this)).on("click", r);o = { relatedTarget: this };if (i.trigger(t = n.Event("show.bs.dropdown", o)), t.isDefaultPrevented()) return;e.trigger("focus").attr("aria-expanded", "true"), i.toggleClass("open").trigger(n.Event("shown.bs.dropdown", o));
      }return !1;
    }
  }, o.prototype.keydown = function (t) {
    if (/(38|40|27|32)/.test(t.which) && !/input|textarea/i.test(t.target.tagName)) {
      var e = n(this);if (t.preventDefault(), t.stopPropagation(), !e.is(".disabled, :disabled")) {
        var i = a(e),
            o = i.hasClass("open");if (!o && 27 != t.which || o && 27 == t.which) return 27 == t.which && i.find(s).trigger("focus"), e.trigger("click");o = i.find(".dropdown-menu li:not(.disabled):visible a");o.length && (e = o.index(t.target), 38 == t.which && 0 < e && e--, 40 == t.which && e < o.length - 1 && e++, o.eq(e = ~e ? e : 0).trigger("focus"));
      }
    }
  };var t = n.fn.dropdown;n.fn.dropdown = function (i) {
    return this.each(function () {
      var t = n(this),
          e = t.data("bs.dropdown");e || t.data("bs.dropdown", e = new o(this)), "string" == typeof i && e[i].call(t);
    });
  }, n.fn.dropdown.Constructor = o, n.fn.dropdown.noConflict = function () {
    return n.fn.dropdown = t, this;
  }, n(document).on("click.bs.dropdown.data-api", r).on("click.bs.dropdown.data-api", ".dropdown form", function (t) {
    t.stopPropagation();
  }).on("click.bs.dropdown.data-api", s, o.prototype.toggle).on("keydown.bs.dropdown.data-api", s, o.prototype.keydown).on("keydown.bs.dropdown.data-api", ".dropdown-menu", o.prototype.keydown);
}(jQuery), function (s) {
  "use strict";
  var a = function a(t, e) {
    this.options = e, this.$body = s(document.body), this.$element = s(t), this.$dialog = this.$element.find(".modal-dialog"), this.$backdrop = null, this.isShown = null, this.originalBodyPad = null, this.scrollbarWidth = 0, this.ignoreBackdropClick = !1, this.fixedContent = ".navbar-fixed-top, .navbar-fixed-bottom", this.options.remote && this.$element.find(".modal-content").load(this.options.remote, s.proxy(function () {
      this.$element.trigger("loaded.bs.modal");
    }, this));
  };function r(o, n) {
    return this.each(function () {
      var t = s(this),
          e = t.data("bs.modal"),
          i = s.extend({}, a.DEFAULTS, t.data(), "object" == (typeof o === "undefined" ? "undefined" : _typeof(o)) && o);e || t.data("bs.modal", e = new a(this, i)), "string" == typeof o ? e[o](n) : i.show && e.show(n);
    });
  }a.VERSION = "3.4.1", a.TRANSITION_DURATION = 300, a.BACKDROP_TRANSITION_DURATION = 150, a.DEFAULTS = { backdrop: !0, keyboard: !0, show: !0 }, a.prototype.toggle = function (t) {
    return this.isShown ? this.hide() : this.show(t);
  }, a.prototype.show = function (i) {
    var o = this,
        t = s.Event("show.bs.modal", { relatedTarget: i });this.$element.trigger(t), this.isShown || t.isDefaultPrevented() || (this.isShown = !0, this.checkScrollbar(), this.setScrollbar(), this.$body.addClass("modal-open"), this.escape(), this.resize(), this.$element.on("click.dismiss.bs.modal", '[data-dismiss="modal"]', s.proxy(this.hide, this)), this.$dialog.on("mousedown.dismiss.bs.modal", function () {
      o.$element.one("mouseup.dismiss.bs.modal", function (t) {
        s(t.target).is(o.$element) && (o.ignoreBackdropClick = !0);
      });
    }), this.backdrop(function () {
      var t = s.support.transition && o.$element.hasClass("fade"),
          e = (o.$element.parent().length || o.$element.appendTo(o.$body), o.$element.show().scrollTop(0), o.adjustDialog(), t && o.$element[0].offsetWidth, o.$element.addClass("in"), o.enforceFocus(), s.Event("shown.bs.modal", { relatedTarget: i }));t ? o.$dialog.one("bsTransitionEnd", function () {
        o.$element.trigger("focus").trigger(e);
      }).emulateTransitionEnd(a.TRANSITION_DURATION) : o.$element.trigger("focus").trigger(e);
    }));
  }, a.prototype.hide = function (t) {
    t && t.preventDefault(), t = s.Event("hide.bs.modal"), this.$element.trigger(t), this.isShown && !t.isDefaultPrevented() && (this.isShown = !1, this.escape(), this.resize(), s(document).off("focusin.bs.modal"), this.$element.removeClass("in").off("click.dismiss.bs.modal").off("mouseup.dismiss.bs.modal"), this.$dialog.off("mousedown.dismiss.bs.modal"), s.support.transition && this.$element.hasClass("fade") ? this.$element.one("bsTransitionEnd", s.proxy(this.hideModal, this)).emulateTransitionEnd(a.TRANSITION_DURATION) : this.hideModal());
  }, a.prototype.enforceFocus = function () {
    s(document).off("focusin.bs.modal").on("focusin.bs.modal", s.proxy(function (t) {
      document === t.target || this.$element[0] === t.target || this.$element.has(t.target).length || this.$element.trigger("focus");
    }, this));
  }, a.prototype.escape = function () {
    this.isShown && this.options.keyboard ? this.$element.on("keydown.dismiss.bs.modal", s.proxy(function (t) {
      27 == t.which && this.hide();
    }, this)) : this.isShown || this.$element.off("keydown.dismiss.bs.modal");
  }, a.prototype.resize = function () {
    this.isShown ? s(window).on("resize.bs.modal", s.proxy(this.handleUpdate, this)) : s(window).off("resize.bs.modal");
  }, a.prototype.hideModal = function () {
    var t = this;this.$element.hide(), this.backdrop(function () {
      t.$body.removeClass("modal-open"), t.resetAdjustments(), t.resetScrollbar(), t.$element.trigger("hidden.bs.modal");
    });
  }, a.prototype.removeBackdrop = function () {
    this.$backdrop && this.$backdrop.remove(), this.$backdrop = null;
  }, a.prototype.backdrop = function (t) {
    var e,
        i = this,
        o = this.$element.hasClass("fade") ? "fade" : "";this.isShown && this.options.backdrop ? (e = s.support.transition && o, this.$backdrop = s(document.createElement("div")).addClass("modal-backdrop " + o).appendTo(this.$body), this.$element.on("click.dismiss.bs.modal", s.proxy(function (t) {
      this.ignoreBackdropClick ? this.ignoreBackdropClick = !1 : t.target === t.currentTarget && ("static" == this.options.backdrop ? this.$element[0].focus() : this.hide());
    }, this)), e && this.$backdrop[0].offsetWidth, this.$backdrop.addClass("in"), t && (e ? this.$backdrop.one("bsTransitionEnd", t).emulateTransitionEnd(a.BACKDROP_TRANSITION_DURATION) : t())) : !this.isShown && this.$backdrop ? (this.$backdrop.removeClass("in"), o = function o() {
      i.removeBackdrop(), t && t();
    }, s.support.transition && this.$element.hasClass("fade") ? this.$backdrop.one("bsTransitionEnd", o).emulateTransitionEnd(a.BACKDROP_TRANSITION_DURATION) : o()) : t && t();
  }, a.prototype.handleUpdate = function () {
    this.adjustDialog();
  }, a.prototype.adjustDialog = function () {
    var t = this.$element[0].scrollHeight > document.documentElement.clientHeight;this.$element.css({ paddingLeft: !this.bodyIsOverflowing && t ? this.scrollbarWidth : "", paddingRight: this.bodyIsOverflowing && !t ? this.scrollbarWidth : "" });
  }, a.prototype.resetAdjustments = function () {
    this.$element.css({ paddingLeft: "", paddingRight: "" });
  }, a.prototype.checkScrollbar = function () {
    var t,
        e = window.innerWidth;e || (e = (t = document.documentElement.getBoundingClientRect()).right - Math.abs(t.left)), this.bodyIsOverflowing = document.body.clientWidth < e, this.scrollbarWidth = this.measureScrollbar();
  }, a.prototype.setScrollbar = function () {
    var t = parseInt(this.$body.css("padding-right") || 0, 10),
        n = (this.originalBodyPad = document.body.style.paddingRight || "", this.scrollbarWidth);this.bodyIsOverflowing && (this.$body.css("padding-right", t + n), s(this.fixedContent).each(function (t, e) {
      var i = e.style.paddingRight,
          o = s(e).css("padding-right");s(e).data("padding-right", i).css("padding-right", parseFloat(o) + n + "px");
    }));
  }, a.prototype.resetScrollbar = function () {
    this.$body.css("padding-right", this.originalBodyPad), s(this.fixedContent).each(function (t, e) {
      var i = s(e).data("padding-right");s(e).removeData("padding-right"), e.style.paddingRight = i || "";
    });
  }, a.prototype.measureScrollbar = function () {
    var t = document.createElement("div"),
        e = (t.className = "modal-scrollbar-measure", this.$body.append(t), t.offsetWidth - t.clientWidth);return this.$body[0].removeChild(t), e;
  };var t = s.fn.modal;s.fn.modal = r, s.fn.modal.Constructor = a, s.fn.modal.noConflict = function () {
    return s.fn.modal = t, this;
  }, s(document).on("click.bs.modal.data-api", '[data-toggle="modal"]', function (t) {
    var e = s(this),
        i = e.attr("href"),
        o = e.attr("data-target") || i && i.replace(/.*(?=#[^\s]+$)/, ""),
        n = s(document).find(o),
        o = n.data("bs.modal") ? "toggle" : s.extend({ remote: !/#/.test(i) && i }, n.data(), e.data());e.is("a") && t.preventDefault(), n.one("show.bs.modal", function (t) {
      t.isDefaultPrevented() || n.one("hidden.bs.modal", function () {
        e.is(":visible") && e.trigger("focus");
      });
    }), r.call(n, o, this);
  });
}(jQuery), function (f) {
  "use strict";
  var o = ["sanitize", "whiteList", "sanitizeFn"],
      u = ["background", "cite", "href", "itemtype", "longdesc", "poster", "src", "xlink:href"],
      t = { "*": ["class", "dir", "id", "lang", "role", /^aria-[\w-]*$/i], a: ["target", "href", "title", "rel"], area: [], b: [], br: [], col: [], code: [], div: [], em: [], hr: [], h1: [], h2: [], h3: [], h4: [], h5: [], h6: [], i: [], img: ["src", "alt", "title", "width", "height"], li: [], ol: [], p: [], pre: [], s: [], small: [], span: [], sub: [], sup: [], strong: [], u: [], ul: [] },
      g = /^(?:(?:https?|mailto|ftp|tel|file):|[^&:/?#]*(?:[/?#]|$))/gi,
      m = /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+/]+=*$/i;function n(t, e, i) {
    if (0 === t.length) return t;if (i && "function" == typeof i) return i(t);if (!document.implementation || !document.implementation.createHTMLDocument) return t;for (var i = document.implementation.createHTMLDocument("sanitization"), o = (i.body.innerHTML = t, f.map(e, function (t, e) {
      return e;
    })), n = f(i.body).find("*"), s = 0, a = n.length; s < a; s++) {
      var r = n[s],
          l = r.nodeName.toLowerCase();if (-1 === f.inArray(l, o)) r.parentNode.removeChild(r);else for (var h = f.map(r.attributes, function (t) {
        return t;
      }), d = [].concat(e["*"] || [], e[l] || []), c = 0, p = h.length; c < p; c++) {
        !function (t, e) {
          var i = t.nodeName.toLowerCase();if (-1 !== f.inArray(i, e)) return -1 === f.inArray(i, u) || Boolean(t.nodeValue.match(g) || t.nodeValue.match(m));for (var o = f(e).filter(function (t, e) {
            return e instanceof RegExp;
          }), n = 0, s = o.length; n < s; n++) {
            if (i.match(o[n])) return 1;
          }
        }(h[c], d) && r.removeAttribute(h[c].nodeName);
      }
    }return i.body.innerHTML;
  }var l = function l(t, e) {
    this.type = null, this.options = null, this.enabled = null, this.timeout = null, this.hoverState = null, this.$element = null, this.inState = null, this.init("tooltip", t, e);
  };l.VERSION = "3.4.1", l.TRANSITION_DURATION = 150, l.DEFAULTS = { animation: !0, placement: "top", selector: !1, template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>', trigger: "hover focus", title: "", delay: 0, html: !1, container: !1, viewport: { selector: "body", padding: 0 }, sanitize: !0, sanitizeFn: null, whiteList: t }, l.prototype.init = function (t, e, i) {
    if (this.enabled = !0, this.type = t, this.$element = f(e), this.options = this.getOptions(i), this.$viewport = this.options.viewport && f(document).find(f.isFunction(this.options.viewport) ? this.options.viewport.call(this, this.$element) : this.options.viewport.selector || this.options.viewport), this.inState = { click: !1, hover: !1, focus: !1 }, this.$element[0] instanceof document.constructor && !this.options.selector) throw new Error("`selector` option must be specified when initializing " + this.type + " on the window.document object!");for (var o = this.options.trigger.split(" "), n = o.length; n--;) {
      var s,
          a = o[n];"click" == a ? this.$element.on("click." + this.type, this.options.selector, f.proxy(this.toggle, this)) : "manual" != a && (s = "hover" == a ? "mouseleave" : "focusout", this.$element.on(("hover" == a ? "mouseenter" : "focusin") + "." + this.type, this.options.selector, f.proxy(this.enter, this)), this.$element.on(s + "." + this.type, this.options.selector, f.proxy(this.leave, this)));
    }this.options.selector ? this._options = f.extend({}, this.options, { trigger: "manual", selector: "" }) : this.fixTitle();
  }, l.prototype.getDefaults = function () {
    return l.DEFAULTS;
  }, l.prototype.getOptions = function (t) {
    var e,
        i = this.$element.data();for (e in i) {
      i.hasOwnProperty(e) && -1 !== f.inArray(e, o) && delete i[e];
    }return (t = f.extend({}, this.getDefaults(), i, t)).delay && "number" == typeof t.delay && (t.delay = { show: t.delay, hide: t.delay }), t.sanitize && (t.template = n(t.template, t.whiteList, t.sanitizeFn)), t;
  }, l.prototype.getDelegateOptions = function () {
    var i = {},
        o = this.getDefaults();return this._options && f.each(this._options, function (t, e) {
      o[t] != e && (i[t] = e);
    }), i;
  }, l.prototype.enter = function (t) {
    var e = t instanceof this.constructor ? t : f(t.currentTarget).data("bs." + this.type);if (e || (e = new this.constructor(t.currentTarget, this.getDelegateOptions()), f(t.currentTarget).data("bs." + this.type, e)), t instanceof f.Event && (e.inState["focusin" == t.type ? "focus" : "hover"] = !0), e.tip().hasClass("in") || "in" == e.hoverState) e.hoverState = "in";else {
      if (clearTimeout(e.timeout), e.hoverState = "in", !e.options.delay || !e.options.delay.show) return e.show();e.timeout = setTimeout(function () {
        "in" == e.hoverState && e.show();
      }, e.options.delay.show);
    }
  }, l.prototype.isInStateTrue = function () {
    for (var t in this.inState) {
      if (this.inState[t]) return !0;
    }return !1;
  }, l.prototype.leave = function (t) {
    var e = t instanceof this.constructor ? t : f(t.currentTarget).data("bs." + this.type);if (e || (e = new this.constructor(t.currentTarget, this.getDelegateOptions()), f(t.currentTarget).data("bs." + this.type, e)), t instanceof f.Event && (e.inState["focusout" == t.type ? "focus" : "hover"] = !1), !e.isInStateTrue()) {
      if (clearTimeout(e.timeout), e.hoverState = "out", !e.options.delay || !e.options.delay.hide) return e.hide();e.timeout = setTimeout(function () {
        "out" == e.hoverState && e.hide();
      }, e.options.delay.hide);
    }
  }, l.prototype.show = function () {
    var e,
        t,
        i,
        o,
        n,
        s,
        a,
        r = f.Event("show.bs." + this.type);this.hasContent() && this.enabled && (this.$element.trigger(r), n = f.contains(this.$element[0].ownerDocument.documentElement, this.$element[0]), !r.isDefaultPrevented()) && n && (r = (e = this).tip(), n = this.getUID(this.type), this.setContent(), r.attr("id", n), this.$element.attr("aria-describedby", n), this.options.animation && r.addClass("fade"), n = "function" == typeof this.options.placement ? this.options.placement.call(this, r[0], this.$element[0]) : this.options.placement, (a = (t = /\s?auto?\s?/i).test(n)) && (n = n.replace(t, "") || "top"), r.detach().css({ top: 0, left: 0, display: "block" }).addClass(n).data("bs." + this.type, this), this.options.container ? r.appendTo(f(document).find(this.options.container)) : r.insertAfter(this.$element), this.$element.trigger("inserted.bs." + this.type), t = this.getPosition(), i = r[0].offsetWidth, o = r[0].offsetHeight, a && (a = n, s = this.getPosition(this.$viewport), n = "bottom" == n && t.bottom + o > s.bottom ? "top" : "top" == n && t.top - o < s.top ? "bottom" : "right" == n && t.right + i > s.width ? "left" : "left" == n && t.left - i < s.left ? "right" : n, r.removeClass(a).addClass(n)), s = this.getCalculatedOffset(n, t, i, o), this.applyPlacement(s, n), a = function a() {
      var t = e.hoverState;e.$element.trigger("shown.bs." + e.type), e.hoverState = null, "out" == t && e.leave(e);
    }, f.support.transition && this.$tip.hasClass("fade") ? r.one("bsTransitionEnd", a).emulateTransitionEnd(l.TRANSITION_DURATION) : a());
  }, l.prototype.applyPlacement = function (t, e) {
    var i = this.tip(),
        o = i[0].offsetWidth,
        n = i[0].offsetHeight,
        s = parseInt(i.css("margin-top"), 10),
        a = parseInt(i.css("margin-left"), 10),
        s = (isNaN(s) && (s = 0), isNaN(a) && (a = 0), t.top += s, t.left += a, f.offset.setOffset(i[0], f.extend({ using: function using(t) {
        i.css({ top: Math.round(t.top), left: Math.round(t.left) });
      } }, t), 0), i.addClass("in"), i[0].offsetWidth),
        a = i[0].offsetHeight,
        r = ("top" == e && a != n && (t.top = t.top + n - a), this.getViewportAdjustedDelta(e, t, s, a)),
        e = (r.left ? t.left += r.left : t.top += r.top, /top|bottom/.test(e)),
        o = e ? 2 * r.left - o + s : 2 * r.top - n + a,
        s = e ? "offsetWidth" : "offsetHeight";i.offset(t), this.replaceArrow(o, i[0][s], e);
  }, l.prototype.replaceArrow = function (t, e, i) {
    this.arrow().css(i ? "left" : "top", 50 * (1 - t / e) + "%").css(i ? "top" : "left", "");
  }, l.prototype.setContent = function () {
    var t = this.tip(),
        e = this.getTitle();this.options.html ? (this.options.sanitize && (e = n(e, this.options.whiteList, this.options.sanitizeFn)), t.find(".tooltip-inner").html(e)) : t.find(".tooltip-inner").text(e), t.removeClass("fade in top bottom left right");
  }, l.prototype.hide = function (t) {
    var e = this,
        i = f(this.$tip),
        o = f.Event("hide.bs." + this.type);function n() {
      "in" != e.hoverState && i.detach(), e.$element && e.$element.removeAttr("aria-describedby").trigger("hidden.bs." + e.type), t && t();
    }if (this.$element.trigger(o), !o.isDefaultPrevented()) return i.removeClass("in"), f.support.transition && i.hasClass("fade") ? i.one("bsTransitionEnd", n).emulateTransitionEnd(l.TRANSITION_DURATION) : n(), this.hoverState = null, this;
  }, l.prototype.fixTitle = function () {
    var t = this.$element;!t.attr("title") && "string" == typeof t.attr("data-original-title") || t.attr("data-original-title", t.attr("title") || "").attr("title", "");
  }, l.prototype.hasContent = function () {
    return this.getTitle();
  }, l.prototype.getPosition = function (t) {
    var e = (t = t || this.$element)[0],
        i = "BODY" == e.tagName,
        o = e.getBoundingClientRect(),
        e = (null == o.width && (o = f.extend({}, o, { width: o.right - o.left, height: o.bottom - o.top })), window.SVGElement && e instanceof window.SVGElement),
        e = i ? { top: 0, left: 0 } : e ? null : t.offset(),
        t = { scroll: i ? document.documentElement.scrollTop || document.body.scrollTop : t.scrollTop() },
        i = i ? { width: f(window).width(), height: f(window).height() } : null;return f.extend({}, o, t, i, e);
  }, l.prototype.getCalculatedOffset = function (t, e, i, o) {
    return "bottom" == t ? { top: e.top + e.height, left: e.left + e.width / 2 - i / 2 } : "top" == t ? { top: e.top - o, left: e.left + e.width / 2 - i / 2 } : "left" == t ? { top: e.top + e.height / 2 - o / 2, left: e.left - i } : { top: e.top + e.height / 2 - o / 2, left: e.left + e.width };
  }, l.prototype.getViewportAdjustedDelta = function (t, e, i, o) {
    var n,
        s,
        a = { top: 0, left: 0 };return this.$viewport && (n = this.options.viewport && this.options.viewport.padding || 0, s = this.getPosition(this.$viewport), /right|left/.test(t) ? (t = e.top - n - s.scroll, o = e.top + n - s.scroll + o, t < s.top ? a.top = s.top - t : o > s.top + s.height && (a.top = s.top + s.height - o)) : (t = e.left - n, o = e.left + n + i, t < s.left ? a.left = s.left - t : o > s.right && (a.left = s.left + s.width - o))), a;
  }, l.prototype.getTitle = function () {
    var t = this.$element,
        e = this.options;return t.attr("data-original-title") || ("function" == typeof e.title ? e.title.call(t[0]) : e.title);
  }, l.prototype.getUID = function (t) {
    for (; t += ~~(1e6 * Math.random()), document.getElementById(t);) {}return t;
  }, l.prototype.tip = function () {
    if (this.$tip || (this.$tip = f(this.options.template), 1 == this.$tip.length)) return this.$tip;throw new Error(this.type + " `template` option must consist of exactly 1 top-level element!");
  }, l.prototype.arrow = function () {
    return this.$arrow = this.$arrow || this.tip().find(".tooltip-arrow");
  }, l.prototype.enable = function () {
    this.enabled = !0;
  }, l.prototype.disable = function () {
    this.enabled = !1;
  }, l.prototype.toggleEnabled = function () {
    this.enabled = !this.enabled;
  }, l.prototype.toggle = function (t) {
    var e = this;t && !(e = f(t.currentTarget).data("bs." + this.type)) && (e = new this.constructor(t.currentTarget, this.getDelegateOptions()), f(t.currentTarget).data("bs." + this.type, e)), t ? (e.inState.click = !e.inState.click, e.isInStateTrue() ? e.enter(e) : e.leave(e)) : e.tip().hasClass("in") ? e.leave(e) : e.enter(e);
  }, l.prototype.destroy = function () {
    var t = this;clearTimeout(this.timeout), this.hide(function () {
      t.$element.off("." + t.type).removeData("bs." + t.type), t.$tip && t.$tip.detach(), t.$tip = null, t.$arrow = null, t.$viewport = null, t.$element = null;
    });
  }, l.prototype.sanitizeHtml = function (t) {
    return n(t, this.options.whiteList, this.options.sanitizeFn);
  };var e = f.fn.tooltip;f.fn.tooltip = function (o) {
    return this.each(function () {
      var t = f(this),
          e = t.data("bs.tooltip"),
          i = "object" == (typeof o === "undefined" ? "undefined" : _typeof(o)) && o;!e && /destroy|hide/.test(o) || (e || t.data("bs.tooltip", e = new l(this, i)), "string" == typeof o && e[o]());
    });
  }, f.fn.tooltip.Constructor = l, f.fn.tooltip.noConflict = function () {
    return f.fn.tooltip = e, this;
  };
}(jQuery), function (n) {
  "use strict";
  var s = function s(t, e) {
    this.init("popover", t, e);
  };if (!n.fn.tooltip) throw new Error("Popover requires tooltip.js");s.VERSION = "3.4.1", s.DEFAULTS = n.extend({}, n.fn.tooltip.Constructor.DEFAULTS, { placement: "right", trigger: "click", content: "", template: '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>' }), ((s.prototype = n.extend({}, n.fn.tooltip.Constructor.prototype)).constructor = s).prototype.getDefaults = function () {
    return s.DEFAULTS;
  }, s.prototype.setContent = function () {
    var t,
        e = this.tip(),
        i = this.getTitle(),
        o = this.getContent();this.options.html ? (t = typeof o === "undefined" ? "undefined" : _typeof(o), this.options.sanitize && (i = this.sanitizeHtml(i), "string" == t) && (o = this.sanitizeHtml(o)), e.find(".popover-title").html(i), e.find(".popover-content").children().detach().end()["string" == t ? "html" : "append"](o)) : (e.find(".popover-title").text(i), e.find(".popover-content").children().detach().end().text(o)), e.removeClass("fade top bottom left right in"), e.find(".popover-title").html() || e.find(".popover-title").hide();
  }, s.prototype.hasContent = function () {
    return this.getTitle() || this.getContent();
  }, s.prototype.getContent = function () {
    var t = this.$element,
        e = this.options;return t.attr("data-content") || ("function" == typeof e.content ? e.content.call(t[0]) : e.content);
  }, s.prototype.arrow = function () {
    return this.$arrow = this.$arrow || this.tip().find(".arrow");
  };var t = n.fn.popover;n.fn.popover = function (o) {
    return this.each(function () {
      var t = n(this),
          e = t.data("bs.popover"),
          i = "object" == (typeof o === "undefined" ? "undefined" : _typeof(o)) && o;!e && /destroy|hide/.test(o) || (e || t.data("bs.popover", e = new s(this, i)), "string" == typeof o && e[o]());
    });
  }, n.fn.popover.Constructor = s, n.fn.popover.noConflict = function () {
    return n.fn.popover = t, this;
  };
}(jQuery), function (n) {
  "use strict";
  function o(t, e) {
    this.$body = n(document.body), this.$scrollElement = n(t).is(document.body) ? n(window) : n(t), this.options = n.extend({}, o.DEFAULTS, e), this.selector = (this.options.target || "") + " .nav li > a", this.offsets = [], this.targets = [], this.activeTarget = null, this.scrollHeight = 0, this.$scrollElement.on("scroll.bs.scrollspy", n.proxy(this.process, this)), this.refresh(), this.process();
  }function e(i) {
    return this.each(function () {
      var t = n(this),
          e = t.data("bs.scrollspy");e || t.data("bs.scrollspy", e = new o(this, "object" == (typeof i === "undefined" ? "undefined" : _typeof(i)) && i)), "string" == typeof i && e[i]();
    });
  }o.VERSION = "3.4.1", o.DEFAULTS = { offset: 10 }, o.prototype.getScrollHeight = function () {
    return this.$scrollElement[0].scrollHeight || Math.max(this.$body[0].scrollHeight, document.documentElement.scrollHeight);
  }, o.prototype.refresh = function () {
    var t = this,
        i = "offset",
        o = 0;this.offsets = [], this.targets = [], this.scrollHeight = this.getScrollHeight(), n.isWindow(this.$scrollElement[0]) || (i = "position", o = this.$scrollElement.scrollTop()), this.$body.find(this.selector).map(function () {
      var t = n(this),
          t = t.data("target") || t.attr("href"),
          e = /^#./.test(t) && n(t);return e && e.length && e.is(":visible") ? [[e[i]().top + o, t]] : null;
    }).sort(function (t, e) {
      return t[0] - e[0];
    }).each(function () {
      t.offsets.push(this[0]), t.targets.push(this[1]);
    });
  }, o.prototype.process = function () {
    var t,
        e = this.$scrollElement.scrollTop() + this.options.offset,
        i = this.getScrollHeight(),
        o = this.options.offset + i - this.$scrollElement.height(),
        n = this.offsets,
        s = this.targets,
        a = this.activeTarget;if (this.scrollHeight != i && this.refresh(), o <= e) return a != (t = s[s.length - 1]) && this.activate(t);if (a && e < n[0]) return this.activeTarget = null, this.clear();for (t = n.length; t--;) {
      a != s[t] && e >= n[t] && (n[t + 1] === undefined || e < n[t + 1]) && this.activate(s[t]);
    }
  }, o.prototype.activate = function (t) {
    this.activeTarget = t, this.clear();t = this.selector + '[data-target="' + t + '"],' + this.selector + '[href="' + t + '"]', t = n(t).parents("li").addClass("active");(t = t.parent(".dropdown-menu").length ? t.closest("li.dropdown").addClass("active") : t).trigger("activate.bs.scrollspy");
  }, o.prototype.clear = function () {
    n(this.selector).parentsUntil(this.options.target, ".active").removeClass("active");
  };var t = n.fn.scrollspy;n.fn.scrollspy = e, n.fn.scrollspy.Constructor = o, n.fn.scrollspy.noConflict = function () {
    return n.fn.scrollspy = t, this;
  }, n(window).on("load.bs.scrollspy.data-api", function () {
    n('[data-spy="scroll"]').each(function () {
      var t = n(this);e.call(t, t.data());
    });
  });
}(jQuery), function (a) {
  "use strict";
  var r = function r(t) {
    this.element = a(t);
  };function e(i) {
    return this.each(function () {
      var t = a(this),
          e = t.data("bs.tab");e || t.data("bs.tab", e = new r(this)), "string" == typeof i && e[i]();
    });
  }r.VERSION = "3.4.1", r.TRANSITION_DURATION = 150, r.prototype.show = function () {
    var t,
        e,
        i,
        o = this.element,
        n = o.closest("ul:not(.dropdown-menu)"),
        s = o.data("target");s || (s = o.attr("href"), s = a.bs.helpers.extractSelector(s)), o.parent("li").hasClass("active") || (t = n.find(".active:last a"), e = a.Event("hide.bs.tab", { relatedTarget: o[0] }), i = a.Event("show.bs.tab", { relatedTarget: t[0] }), t.trigger(e), o.trigger(i), i.isDefaultPrevented()) || e.isDefaultPrevented() || (i = a(document).find(s), this.activate(o.closest("li"), n), this.activate(i, i.parent(), function () {
      t.trigger({ type: "hidden.bs.tab", relatedTarget: o[0] }), o.trigger({ type: "shown.bs.tab", relatedTarget: t[0] });
    }));
  }, r.prototype.activate = function (t, e, i) {
    var o = e.find("> .active"),
        n = i && a.support.transition && (o.length && o.hasClass("fade") || !!e.find("> .fade").length);function s() {
      o.removeClass("active").find("> .dropdown-menu > .active").removeClass("active").end().find('[data-toggle="tab"]').attr("aria-expanded", !1), t.addClass("active").find('[data-toggle="tab"]').attr("aria-expanded", !0), n ? (t[0].offsetWidth, t.addClass("in")) : t.removeClass("fade"), t.parent(".dropdown-menu").length && t.closest("li.dropdown").addClass("active").end().find('[data-toggle="tab"]').attr("aria-expanded", !0), i && i();
    }o.length && n ? o.one("bsTransitionEnd", s).emulateTransitionEnd(r.TRANSITION_DURATION) : s(), o.removeClass("in");
  };var t = a.fn.tab,
      i = (a.fn.tab = e, a.fn.tab.Constructor = r, a.fn.tab.noConflict = function () {
    return a.fn.tab = t, this;
  }, function (t) {
    t.preventDefault(), e.call(a(this), "show");
  });a(document).on("click.bs.tab.data-api", '[data-toggle="tab"]', i).on("click.bs.tab.data-api", '[data-toggle="pill"]', i);
}(jQuery), function (a) {
  "use strict";
  var r = function r(t, e) {
    this.options = a.extend({}, r.DEFAULTS, e);e = this.options.target === r.DEFAULTS.target ? a(this.options.target) : a(document).find(this.options.target);this.$target = e.on("scroll.bs.affix.data-api", a.proxy(this.checkPosition, this)).on("click.bs.affix.data-api", a.proxy(this.checkPositionWithEventLoop, this)), this.$element = a(t), this.affixed = null, this.unpin = null, this.pinnedOffset = null, this.checkPosition();
  };function i(i) {
    return this.each(function () {
      var t = a(this),
          e = t.data("bs.affix");e || t.data("bs.affix", e = new r(this, "object" == (typeof i === "undefined" ? "undefined" : _typeof(i)) && i)), "string" == typeof i && e[i]();
    });
  }r.VERSION = "3.4.1", r.RESET = "affix affix-top affix-bottom", r.DEFAULTS = { offset: 0, target: window }, r.prototype.getState = function (t, e, i, o) {
    var n,
        s = this.$target.scrollTop(),
        a = this.$element.offset(),
        r = this.$target.height();return null != i && "top" == this.affixed ? s < i && "top" : "bottom" == this.affixed ? null != i ? !(s + this.unpin <= a.top) && "bottom" : !(s + r <= t - o) && "bottom" : (a = (n = null == this.affixed) ? s : a.top, null != i && s <= i ? "top" : null != o && t - o <= a + (n ? r : e) && "bottom");
  }, r.prototype.getPinnedOffset = function () {
    if (this.pinnedOffset) return this.pinnedOffset;this.$element.removeClass(r.RESET).addClass("affix");var t = this.$target.scrollTop(),
        e = this.$element.offset();return this.pinnedOffset = e.top - t;
  }, r.prototype.checkPositionWithEventLoop = function () {
    setTimeout(a.proxy(this.checkPosition, this), 1);
  }, r.prototype.checkPosition = function () {
    if (this.$element.is(":visible")) {
      var t = this.$element.height(),
          e = this.options.offset,
          i = e.top,
          o = e.bottom,
          n = Math.max(a(document).height(), a(document.body).height()),
          e = ("object" != (typeof e === "undefined" ? "undefined" : _typeof(e)) && (o = i = e), "function" == typeof i && (i = e.top(this.$element)), "function" == typeof o && (o = e.bottom(this.$element)), this.getState(n, t, i, o));if (this.affixed != e) {
        null != this.unpin && this.$element.css("top", "");var i = "affix" + (e ? "-" + e : ""),
            s = a.Event(i + ".bs.affix");if (this.$element.trigger(s), s.isDefaultPrevented()) return;this.affixed = e, this.unpin = "bottom" == e ? this.getPinnedOffset() : null, this.$element.removeClass(r.RESET).addClass(i).trigger(i.replace("affix", "affixed") + ".bs.affix");
      }"bottom" == e && this.$element.offset({ top: n - t - o });
    }
  };var t = a.fn.affix;a.fn.affix = i, a.fn.affix.Constructor = r, a.fn.affix.noConflict = function () {
    return a.fn.affix = t, this;
  }, a(window).on("load", function () {
    a('[data-spy="affix"]').each(function () {
      var t = a(this),
          e = t.data();e.offset = e.offset || {}, null != e.offsetBottom && (e.offset.bottom = e.offsetBottom), null != e.offsetTop && (e.offset.top = e.offsetTop), i.call(t, e);
    });
  });
}(jQuery), function (e) {
  "use strict";
  e.bs = e.bs || {}, e.bs.helpers = e.bs.helpers || {};var i = e.bs.helpers.extractSelector;e.bs.helpers.noConflict = function () {
    var t = { extractSelector: e.bs.helpers.extractSelector };return e.bs.helpers.extractSelector = i, t;
  }, e.bs.helpers.extractSelector = function (t) {
    var e;return t && (-1 === (e = t.lastIndexOf("#")) || (e = t.slice(e), /\s/.test(e)) ? t : e);
  };
}(jQuery);