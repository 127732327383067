var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

(function () {
    'use strict';

    function geckoExportService($state, $stateParams, asyncOptions) {
        var _this = {};

        _this.generateModule = function (type) {
            return {
                id: GeckoUI.generateUuid(),
                type: type || null,
                config: {
                    form_id: null,
                    event_id: null,
                    campaign_id: null,
                    filter_id: null,
                    outcome_id: null,
                    created_within: null,
                    date_from: null,
                    date_to: null,
                    label_id: null,
                    user_id: null,
                    assigned_id: null,
                    status: [],
                    closed: null,
                    channel_id: null
                },
                columns: []
            };
        };

        _this.generateColumn = function (key, label) {
            return {
                id: GeckoUI.generateUuid(),
                key: key || null,
                label: label || null,
                format: 'default'
            };
        };

        _this.generateStructure = function (type) {
            return {
                //id: null,
                account_id: Gecko.account.id,
                type: type || null,
                root_module: _this.generateModule(type),
                other_modules: []
            };
        };

        _this.getRootModule = function (_export) {
            if (_export && _export.structure_data && _export.structure_data['root_module']) {
                return _export.structure_data['root_module'];
            }
            return {};
        };

        _this.getOtherModules = function (_export) {
            if (_export && _export.structure_data && _export.structure_data['other_modules']) {
                return _export.structure_data['other_modules'];
            }
            return [];
        };

        _this.getModuleConfig = function (_module) {
            return (_module || {}).config || {};
        };

        _this.prepareFormatData = function (field) {
            // If the field has an option_id or a parent_id attached offer 2 formats
            if ((field.option_id || field.parent_id) && field.type !== Gecko.Field.TYPE_ADDRESS) {
                return [{
                    label: 'Export Value',
                    value: 'value'
                }, {
                    label: 'Display Value',
                    value: 'label'
                }];
            }

            // Otherwise the format is based on the field type
            switch (field.type) {
                case 'name':
                    return [{
                        label: 'First Last',
                        value: 'first_last'
                    }, {
                        label: 'Last First',
                        value: 'last_first'
                    }, {
                        label: 'First',
                        value: 'first_name'
                    }, {
                        label: 'Last',
                        value: 'last_name'
                    }];
                case 'address':
                    return [{
                        label: 'Full Address',
                        value: 'full'
                    }, {
                        label: 'Street',
                        value: 'street_address'
                    }, {
                        label: 'Line 2',
                        value: 'address_line_2'
                    }, {
                        label: 'City',
                        value: 'city'
                    }, {
                        label: 'State',
                        value: 'state'
                    }, {
                        label: 'Country',
                        value: 'country'
                    }, {
                        label: 'Post Code',
                        value: 'postal_code'
                    }];
                case 'tel':
                    return [
                    // E.g 07707, +447707
                    {
                        label: 'Number',
                        value: 'number'
                    },
                    // E.g GB, US...
                    {
                        label: 'Country Code',
                        value: 'country_code'
                    },
                    // E.g 44, 1...
                    {
                        label: 'Dial Code',
                        value: 'dial_code'
                    }];

                case 'date':
                case 'contact_created':
                case 'last_email':
                case 'last_sms':
                case 'last_called':
                case 'last_response':
                case 'last_call_response':
                case 'last_form_response':
                    return [{
                        label: 'yyyy-mm-dd hh:mm:ss',
                        value: 'Y-m-d G:i:s'
                    }, {
                        label: 'dd-mm-yyyy hh:mm',
                        value: 'd-m-Y G:i'
                    }, {
                        label: 'dd-mm-yy hh:mm',
                        value: 'd-m-y G:i'
                    }, {
                        label: 'dd/mm/yyyy hh:mm',
                        value: 'd/m/Y G:i'
                    }, {
                        label: 'dd/mm/yy hh:mm',
                        value: 'd/m/y G:i'
                    }, {
                        label: 'mm-dd-yyyy hh:mm',
                        value: 'm-d-Y G:i'
                    }, {
                        label: 'mm-dd-yy hh:mm',
                        value: 'm-d-y G:i'
                    }, {
                        label: 'mm/dd/yyyy hh:mm',
                        value: 'm/d/Y G:i'
                    }, {
                        label: 'mm/dd/yy hh:mm',
                        value: 'm/d/y G:i'
                    }, {
                        label: 'yy-mm-dd hh:mm:ss',
                        value: 'y-m-d G:i:s'
                    }, {
                        label: 'yyyy-mm-dd',
                        value: 'Y-m-d'
                    }, {
                        label: 'dd-mm-yyyy',
                        value: 'd-m-Y'
                    }, {
                        label: 'dd-mm-yy',
                        value: 'd-m-y'
                    }, {
                        label: 'dd/mm/yyyy',
                        value: 'd/m/Y'
                    }, {
                        label: 'dd/mm/yy',
                        value: 'd/m/y'
                    }, {
                        label: 'mm-dd-yyyy',
                        value: 'm-d-Y'
                    }, {
                        label: 'mm-dd-yy',
                        value: 'm-d-y'
                    }, {
                        label: 'mm/dd/yyyy',
                        value: 'm/d/Y'
                    }, {
                        label: 'mm/dd/yy',
                        value: 'm/d/y'
                    }, {
                        label: 'yy-mm-dd',
                        value: 'y-m-d'
                    }, {
                        label: 'hh:mm:ss',
                        value: 'G:i:s'
                    }, {
                        label: 'hh:mm',
                        value: 'G:i'
                    }, {
                        label: 'yyyy',
                        value: 'Y'
                    }, {
                        label: 'mm',
                        value: 'm'
                    }, {
                        label: 'dd',
                        value: 'd'
                    }];
                case 'sender':
                    return [{
                        label: 'Sender Name',
                        value: 'name'
                    }, {
                        label: 'Sender Telephone',
                        value: 'number'
                    }];
                case 'parsed_tel':
                    return [{
                        label: 'Original',
                        value: 'original'
                    }, {
                        label: 'Cleaned',
                        value: 'cleaned'
                    }, {
                        label: 'National',
                        value: 'national'
                    }, {
                        label: 'International',
                        value: 'international'
                    }, {
                        label: 'Country Code',
                        value: 'country_code'
                    }];
                // case 'consent':
                //     //TODO
                //     break;
                default:
                    return null;
            }
        };

        _this.prepareColumnData = function (type, item, data) {
            if ([Gecko.Export.TYPE_CONTACT, Gecko.Export.TYPE_RESPONSE, Gecko.Export.TYPE_ORGANISATION].indexOf(type) !== -1) {
                if (item.key) {
                    var field = (data || []).find(function (f) {
                        return f.id === Number(item.key) || f.id === item.key;
                    });
                    if (field) {
                        // Field label
                        item.field_label = field.label;
                        if (field.deleted_at !== undefined && field.deleted_at) item.field_label = item.field_label + ' (Archived)';
                        // Field type
                        item.field_type = field.type;
                        // Field formats
                        item.field_formats = _this.prepareFormatData(field);
                        // Set default format if one is not already set
                        if (item.field_formats && (!item.format || item.format === 'default')) {
                            item.format = item.field_formats[0].value;
                        }
                    }
                }
                return item;
            } else {
                if (item.key) {
                    var field = (data || []).find(function (f) {
                        return f.id === Number(item.key) || f.id === item.key;
                    });
                    if (field) {
                        // Field label
                        item.field_label = field.label;
                        if (field.deleted_at !== undefined && field.deleted_at) item.field_label = item.field_label + ' (Archived)';
                        // Field type
                        item.field_type = item.type ? item.type : field.type; // item.type was put here for a reason but i have no idea why, this is all fucked up since it was "rewritten"
                        item.type = item.type ? item.type : field.type; // FFS type needs to be set as well becuase some fucker has mismatched what we are actually meant to be using
                        // Field formats
                        item.field_formats = _this.prepareFormatData(item);
                        // Set default format if one is not already set
                        if (item.field_formats && (!item.format || item.format === 'default')) {
                            item.format = item.field_formats[0].value;
                        }
                    }
                }
                return item;
            }
        };

        _this.prepareAllColumnData = function (type, items, data) {
            return (items || []).map(function (i) {
                return _this.prepareColumnData(type, i, data);
            });
        };

        _this.sanatizeModule = function (_module) {
            var obj = angular.copy(_module);
            // Sanatize column objects
            (obj.columns || []).forEach(function (c) {
                for (var prop in c) {
                    if (prop.indexOf('field_') !== -1) {
                        delete c[prop];
                    }
                }
                // Label's cannot be null, set to empty string
                if (c.hasOwnProperty('label') && c.label === null) {
                    c.label = '';
                }
            });
            return obj;
        };

        _this.cleanAllUuids = function (data) {
            // Root module
            if (data.root_module) {
                data.root_module.id = GeckoUI.generateUuid();
                data.root_module.columns = (data.root_module.columns || []).map(function (c) {
                    c.id = GeckoUI.generateUuid();
                    return c;
                });
            }

            // Other modules
            if ((data.other_modules || []).length) {
                data.other_modules = data.other_modules.map(function (m) {
                    m.id = GeckoUI.generateUuid();
                    m.columns = (m.columns || []).map(function (c) {
                        c.id = GeckoUI.generateUuid();
                        return c;
                    });
                    return m;
                });
            }

            return data;
        };

        _this.exportIsLocked = function () {
            var exp = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            return !!exp.id && $stateParams.export_id !== 'new';
        };

        _this.exportIsNew = function () {
            var exp = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            return $stateParams.export_id === 'new' && !exp.id;
        };

        _this.exportStructureIsLocked = function () {
            var exp = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            // Don't lock export structure when recurring
            if (!!exp.id) {
                return !_this.exportIsRecurring(exp);
            }
            return $stateParams.export_id !== 'new';
        };

        _this.exportIsRecurring = function () {
            var exp = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            return !!(exp.id && exp.related_schedule);
        };

        _this.getDataForModule = function (_module) {
            // Query data needed for module type
            var getModuleDataFn = {
                attendance: function attendance() {
                    if (_module.config.event_id) {
                        return new Gecko.Event().rfields({ event: ['related_events'] }).perPage('all').orderBy('order', 'asc').get(_module.config.event_id).then(function (data) {
                            var findEventTitle = function findEventTitle(event) {
                                if (event.type !== 30) {
                                    return event.title;
                                }

                                var event_title = '';

                                // Find the parent event's (the session's) title
                                var parentEvent = data.related_events.find(function (e) {
                                    return e.id === event.parent_id;
                                });
                                if (parentEvent) {
                                    event_title += parentEvent.title + ': ';
                                }

                                // If the session time starts and ends on the same date then
                                // only include the end time, not the full end date
                                if (event.start_datetime.substring(0, 10) === event.end_datetime.substring(0, 10)) {
                                    event_title += event.start_datetime + ' - ' + event.end_datetime.substring(11);
                                } else {
                                    event_title += event.start_datetime + ' - ' + event.end_datetime;
                                }

                                return event_title;
                            };
                            var res = data.related_events.filter(function (event) {
                                return event.type !== 20;
                            }).map(function (event) {
                                return {
                                    id: event.id,
                                    label: findEventTitle(event),
                                    optgroupField: event.type
                                };
                            }).filter(function (n) {
                                return n;
                            }).concat({
                                id: 'guest_count',
                                label: 'Guest Count'
                            }, {
                                id: 'contact_id',
                                label: 'Contact ID'
                            }, {
                                id: 'attendee_id',
                                label: 'Attendee ID'
                            }, {
                                id: 'date_added',
                                label: 'Date Added',
                                type: 'date'
                            });
                            return res;
                        });
                    }

                    return Promise.resolve([]);
                },
                broadcast: function broadcast() {
                    return Promise.resolve([{
                        id: 'subscriber_id',
                        label: 'Subscriber ID'
                    }, {
                        id: 'contact_id',
                        label: 'Contact ID'
                    }, {
                        id: 'status',
                        label: 'Status'
                    }, {
                        id: 'outcome',
                        label: 'Outcome'
                    }]);
                },
                campaign: function campaign() {
                    return Promise.resolve([{
                        id: 'subscriber_id',
                        label: 'Subscriber ID'
                    }, {
                        id: 'contact_id',
                        label: 'Contact ID'
                    }, {
                        id: 'status',
                        label: 'Status'
                    }, {
                        id: 'outcome',
                        label: 'Outcome'
                    }]);
                },
                call: function call() {
                    return Promise.resolve([{ id: 'type', label: 'Type' }, { id: 'user_id', label: 'User' }, { id: 'campaign_id', label: 'Campaign' }, { id: 'outcome_id', label: 'Outcome' }, { id: 'sender_id', label: 'Sender', type: 'sender' }, { id: 'number_id', label: 'Number', type: 'parsed_tel' }, { id: 'status', label: 'Status' }, { id: 'action', label: 'Action' }, { id: 'prepared_at', label: 'Prepared At', type: 'date' }, { id: 'started_at', label: 'Started At', type: 'date' }, { id: 'ended_at', label: 'Ended At', type: 'date' }, { id: 'completed_at', label: 'Completed At', type: 'date' }, { id: 'cancelled_at', label: 'Cancelled At', type: 'date' }, { id: 'preparation_time', label: 'Preparation Time' }, { id: 'call_time', label: 'Call Time' }, { id: 'completion_time', label: 'Completion Time' }, { id: 'recording_url', label: 'Recording URL' }, { id: 'internal_notes', label: 'Notes' }]);
                },
                consent: function consent() {
                    return new Gecko.Consent().rfields({ consent: ['id', 'title', 'description'] }).perPage('all').orderBy('title', 'asc').get();
                },
                contact: function contact() {
                    return new Gecko.Field().rfields({ field: ['id', 'label', 'type', 'option_id'] }).where('field_type', Gecko.Field.FIELD_TYPE_CONTACT).perPage('all').orderBy('label', 'asc').get();
                },
                event: function event() {
                    return Promise.resolve([{ id: 'event_id', label: 'Event ID' }, { id: 'crm_id', label: 'CRM ID' }, { id: 'title', label: 'Title' }, { id: 'parent_id', label: 'Parent Event ID' }, { id: 'parent_title', label: 'Parent Event Title' }, { id: 'status', label: 'Status' }, { id: 'country', label: 'Country' }, { id: 'location', label: 'Location' }, { id: 'start_date', label: 'Start Date' }, { id: 'end_date', label: 'End Date' }, { id: 'event_time', label: 'Event Time' }, { id: 'auto_waitlist', label: 'Auto Waitlist' }, { id: 'maximum', label: 'Maximum' }, { id: 'cost', label: 'Cost' }, { id: 'delivery_method', label: 'Delivery Method' }, { id: 'webinar_description', label: 'Webinar Description' }, { id: 'webinar_url', label: 'Webinar Url' }, { id: 'registered', label: 'Registered' }, { id: 'invited', label: 'Invited' }, { id: 'attended', label: 'Attended' }, { id: 'engaged', label: 'Engaged' }, { id: 'waitlisted', label: 'Waitlisted' }, { id: 'removed', label: 'Removed' }, { id: 'cancelled', label: 'Cancelled' }, { id: 'total_attendees', label: 'Total Attendees' }]);
                },
                message: function message() {
                    return Promise.resolve([{ id: 'message_id', label: 'Message ID' }, { id: 'sender', label: 'Sender' }, { id: 'type', label: 'Type' }, { id: 'subject', label: 'Subject' }, { id: 'status', label: 'Status' }, { id: 'sent_at', label: 'Sent At', type: 'date' }, { id: 'template_id', label: 'Template ID' }, { id: 'template_name', label: 'Template Name' }]);
                },
                organisation: function organisation() {
                    return new Gecko.Field().rfields({ field: ['id', 'label', 'type'] }).where('field_type', 'organisation').perPage('all').get();
                },
                response: function response() {
                    var query = {};
                    // Get Forms
                    query.forms = new Gecko.Form().rfields({ form: ['id', 'name', 'internal_name', 'module'] }).perPage('all');

                    // Fields (only if form_id is set)
                    if (_module.config.form_id) {
                        query.fields = new Gecko.Field().rfields({ field: ['id', 'label', 'type', 'option_id', 'parent_id', 'deleted_at'] }).where('form_id', _module.config.form_id).where('trashed', 1).perPage('all').orderBy('order', 'asc');
                    }
                    return new Gecko.multi(query);
                },
                response_meta: function response_meta() {
                    return Promise.resolve([{
                        id: 'response_id',
                        label: 'Response ID'
                    }, {
                        id: 'call_id',
                        label: 'Call ID'
                    }, {
                        id: 'conversation_id',
                        label: 'Conversation ID'
                    }, {
                        id: 'device',
                        label: 'Device'
                    }, {
                        id: 'captured_by',
                        label: 'Captured By'
                    }, {
                        id: 'assigned_user',
                        label: 'Assigned User'
                    }, {
                        id: 'event_captured_at',
                        label: 'Event Captured At'
                    }, {
                        id: 'event_crm',
                        label: 'Event CRM Value'
                    }, {
                        id: 'utm_campaign',
                        label: 'UTM Campaign'
                    }, {
                        id: 'utm_medium',
                        label: 'UTM Medium'
                    }, {
                        id: 'utm_source',
                        label: 'UTM Source'
                    }, {
                        id: 'utm_content',
                        label: 'UTM Content'
                    }, {
                        id: 'utm_term',
                        label: 'UTM Term'
                    }, {
                        id: 'notes',
                        label: 'Notes'
                    }, {
                        id: 'captured_at',
                        label: 'Captured At',
                        type: 'date'
                    }]);
                },
                synchronisation: function synchronisation() {
                    return Promise.resolve([{
                        id: 'third_party_id',
                        label: 'Third Party ID'
                    }, {
                        id: 'sync_status',
                        label: 'Sync Status'
                    }, {
                        id: 'last_sync',
                        label: 'Last Synced'
                    }]);
                },
                response_geoip: function response_geoip() {
                    return Promise.resolve([{
                        id: 'ip_address',
                        label: 'IP Address'
                    }, {
                        id: 'country_code',
                        label: 'Country Code'
                    }, {
                        id: 'country',
                        label: 'Country'
                    }, {
                        id: 'region_code',
                        label: 'Region Code'
                    }, {
                        id: 'region',
                        label: 'Region'
                    }, {
                        id: 'city',
                        label: 'City'
                    }, {
                        id: 'latitude',
                        label: 'Latitude'
                    }, {
                        id: 'longitude',
                        label: 'Longitude'
                    }, {
                        id: 'user_type',
                        label: 'User Type'
                    }, {
                        id: 'organization',
                        label: 'Organistation'
                    }, {
                        id: 'isp',
                        label: 'Internet Service Provider'
                    }]);
                },
                conversation: function conversation() {
                    return Promise.resolve([{ id: 'conversation_id', label: 'Conversation ID' }, { id: 'transcript_url', label: 'Transcript URL' }, { id: 'channel_id', label: 'Channel ID' }, { id: 'channel_name', label: 'Channel Name' }, { id: 'original_channel_id', label: 'Original Channel ID' }, { id: 'original_channel_name', label: 'Original Channel Name' }, { id: 'closed_by_id', label: 'Closed By ID' }, { id: 'opened_at', label: 'Opened At', type: 'date' }, { id: 'updated_at', label: 'Updated At', type: 'date' }, { id: 'closed_at', label: 'Closed At', type: 'date' }, { id: 'deleted_at', label: 'Deleted At', type: 'date' }, { id: 'opened_by_id', label: 'Opened By ID' }, { id: 'external_participant_id', label: 'External Participant ID' }, { id: 'external_participant_name', label: 'External Participant Name' }, { id: 'last_bot_id', label: 'Last Bot ID' }, { id: 'last_bot_name', label: 'Last Bot Name' }, { id: 'unread_messages', label: 'Has Unread Messages' }, { id: 'insight_sentiment', label: 'Insight Sentiment' }, { id: 'insight_summary', label: 'Insight Summary' }, { id: 'rating_rating', label: 'Rating' }, { id: 'rating_comment', label: 'Rating Comment' }]
                    // The API supports these, product reckon these are "too internal processy" to be worth including
                    // { id: 'is_outbound_unreplied',     label: 'Is Outbound Unreplied' },
                    // { id: 'is_undelivered',            label: 'Is Undelivered' },
                    );
                }
            };
            return getModuleDataFn[_module.type]();
        };

        _this.prepareColumnsForModule = function (module_type, columns, fields) {
            switch (module_type) {
                case 'contact':
                    return geckoExportService.prepareAllColumnData(Gecko.Export.TYPE_CONTACT, columns, fields.toArray());
                case 'response':
                    return geckoExportService.prepareAllColumnData(Gecko.Export.TYPE_RESPONSE, columns, fields.toArray());
                default:
                    return [];
            }
        };

        _this.setupSchedule = function (schedule, filterData) {
            switch (schedule.cron_details.type) {
                case 'day':
                    schedule.cron_details.day = '*';
                    schedule.cron_details.dayofweek = '*';
                    break;

                case 'week':
                    schedule.cron_details.day = '*';
                    break;

                case 'month':
                    schedule.cron_details.dayofweek = '*';
                    break;

                case 'hour':
                    schedule.cron_details.day = '*';
                    schedule.cron_details.dayofweek = '*';
                    schedule.cron_details.hour = '*';
                    break;
            }

            schedule.cron = schedule.cron_details.minute + ' ' + schedule.cron_details.hour + ' ' + schedule.cron_details.day + ' ' + schedule.cron_details.month + ' ' + schedule.cron_details.dayofweek;

            schedule = schedule;
            schedule.type = "export";

            schedule.configuration = { filter: filterData };

            return schedule;
        };

        _this.sanatizeExport = function (data) {
            var _export = new Gecko.Export().fill(data || {});

            // Convert JSON; empty [] should = empty {}
            if (Array.isArray(_export.structure_data) && !_export.structure_data.length) _export.structure_data = {};
            if (Array.isArray(_export.filter_data) && !_export.filter_data.length) _export.filter_data = {};
            if (Array.isArray(_export.ftp_config) && !_export.ftp_config.length) _export.ftp_config = {};
            if (Array.isArray(_export.recipients) && !_export.recipients.length) _export.recipients = {};

            // required as on template save, values 1 and above are turned to ints which Gecko.Field.TYPE_SCHEDULE cant interpret
            var cronDetails = (_export.structure_data.related_schedule || {}).cron_details;
            if (cronDetails) {
                Object.entries(cronDetails).forEach(function (_ref) {
                    var _ref2 = _slicedToArray(_ref, 2),
                        key = _ref2[0],
                        value = _ref2[1];

                    cronDetails[key] = value.toString();
                });
                _export.related_schedule = new Gecko.Schedule().fill({ cron_details: cronDetails });
            } else {
                _export.related_schedule = null;
            }

            // Clean all UUIDs
            _export.structure_data = _this.cleanAllUuids(_export.structure_data);

            return _export;
        };

        _this.deleteTemplate = function (id) {
            GeckoUI.dialog.confirm('Are you sure you want to delete this template?', function (value) {
                if (value) {
                    new Gecko.ExportTemplate().fill({ id: id }).remove().then(function () {
                        GeckoUI.messenger.success('Template has been deleted');
                        $stateParams.export_template_id = null;
                        return $state.go($state.$current.name, $stateParams, { reload: true });
                    }).catch(function (err) {
                        GeckoUI.messenger.error(err.errors);
                        $stateParams.export_template_id = null;
                        return $state.go($state.$current.name, $stateParams, { reload: true });
                    });
                }
            });
        };

        _this.saveTemplate = function (id, exportData, conditions, logicOperator, schedule, toggles) {
            id = id || null;
            var name = exportData.name;
            var _export = angular.copy(exportData);

            // updates the schedule details to the export template
            if (schedule) {
                _export.structure_data.related_schedule = {}; // required to updates cron_details
                if (toggles.enabledRecurring) _export.structure_data.related_schedule.cron_details = schedule.cron_details;
            }

            // Unset ftp_config if toggle is unset
            if (!toggles.enabledFtp) _export.ftp_config = {};

            // updates the recipient details to the export template
            _export.recipients = {};
            if (toggles.enabledRecipient) _export.recipients = Object.assign({}, exportData.recipients);

            // updates custom search conditions to template
            _export.filter_data = {};
            _export.filter_data.conditions = conditions;
            _export.filter_data.requirement = logicOperator;

            return new Gecko.ExportTemplate().fill({ id: id, name: name, structure_data: _export }).save();
        };

        return _this;
    }

    angular.module('GeckoEngage').factory('geckoExportService', geckoExportService);
})();