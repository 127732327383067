(function () {
    'use strict';

    var fieldTypes = [{
        id: Gecko.Field.TYPE_NAME,
        title: 'Name'
    }, {
        id: Gecko.Field.TYPE_EMAIL,
        title: 'Email'
    }, {
        id: Gecko.Field.TYPE_TEL,
        title: 'Telephone'
    }, {
        id: Gecko.Field.TYPE_TEXT,
        title: 'Text - Single Line'
    }, {
        id: Gecko.Field.TYPE_TEXTAREA,
        title: 'Text - Multiple Lines'
    }, {
        id: Gecko.Field.TYPE_SELECT,
        title: 'Dropdown - Single Choice'
    }, {
        id: Gecko.Field.TYPE_MULTI,
        title: 'Dropdown - Multiple Choice'
    }, {
        id: Gecko.Field.TYPE_RADIO,
        title: 'Radio - Single Choice'
    }, {
        id: Gecko.Field.TYPE_CHECKBOX,
        title: 'Checkbox - Multiple Choice'
    }, {
        id: Gecko.Field.TYPE_DATE,
        title: 'Date'
    }, {
        id: Gecko.Field.TYPE_NUMBER,
        title: 'Number'
    }, {
        id: Gecko.Field.TYPE_ADDRESS,
        title: 'Address'
    }, {
        id: Gecko.Field.TYPE_HIDDEN,
        title: 'Hidden'
    }, {
        id: Gecko.Field.TYPE_EVENT,
        title: 'Event',
        help_text: 'Web Only',
        hideWhen: function hideWhen() {
            return !Gecko.has(Gecko.Package.FEATURE_EVENT_PRO);
        },
        script_builder_hidden: true
    }, {
        id: Gecko.Field.TYPE_ORGANISATION,
        title: 'Organisation',
        hideWhen: function hideWhen() {
            return !Gecko.has(Gecko.Package.FEATURE_ORGANISATION);
        },
        script_builder_hidden: true
    }, {
        id: Gecko.Field.TYPE_SECTION,
        title: 'Form Section'
    }, {
        id: Gecko.Field.TYPE_SCRIPT,
        title: 'Text Block',
        //form_builder_hidden: true,
        help_text: 'Web Only'
    }, {
        id: Gecko.Field.TYPE_MEDIA,
        title: 'Media',
        help_text: 'Mobile Only',
        script_builder_hidden: true
    }, {
        id: Gecko.Field.TYPE_FILE,
        title: 'File',
        help_text: 'Web Only'
    }, {
        id: Gecko.Field.TYPE_REPEATABLE,
        title: 'Repeatable',
        help_text: 'Web Only'
    }, {
        id: Gecko.Field.TYPE_MATRIX,
        title: 'Matrix',
        help_text: 'Web Only'
        // }, {
        //     id: Gecko.Field.TYPE_URL,
        //     title: 'Web URL'
    }, {
        id: Gecko.Field.TYPE_CONSENT,
        title: 'Consent',
        hideWhen: function hideWhen() {
            return !Gecko.able(Gecko.ABILITY_CONSENTS_FIELD);
        }
    }];

    var defaultValues = [{
        value: 'Choice 1',
        selected: false
    }, {
        value: 'Choice 2',
        selected: false
    }, {
        value: 'Choice 3',
        selected: false
    }];

    function $formBuilderService(geckoStore, $state, $stateParams, geckoTranslateService, portalRequestService, webShareUrl) {
        var _this = {

            fieldTypes: fieldTypes,

            defaultValues: defaultValues,

            data: {},

            setData: function setData(data) {
                var _data = angular.copy(data);

                for (var prop in _data) {
                    _this.data[prop] = _data[prop].toArray();
                }
            },

            setModule: function setModule(module) {
                _this.module = module || 'form';
            },

            getModuleTitle: function getModuleTitle(module) {
                return module == Gecko.Form.MODULE_CALL ? 'Script' : 'Form';
            },

            getModuleRouteBase: function getModuleRouteBase(module, index) {
                if (index) return module === Gecko.Form.MODULE_CALL ? 'scripts' : 'forms';
                return module === Gecko.Form.MODULE_CALL ? 'script' : 'form';
            },

            module: 'form',

            activePage: 0,

            newFieldRef: null,

            createField: function createField(target) {
                var _field,
                    values = [],
                    subFields = [],
                    settings = {};

                if (typeof target == 'string') {

                    if ([Gecko.Field.TYPE_RADIO, Gecko.Field.TYPE_CHECKBOX, Gecko.Field.TYPE_SELECT, Gecko.Field.TYPE_MULTI].indexOf(target) != -1) {
                        values = angular.copy(_this.defaultValues);
                    } else if (target == Gecko.Field.TYPE_MATRIX) {
                        //Rows
                        values.push({
                            id: 0,
                            label: 'Question 1'
                        });
                        values.push({
                            id: 1,
                            label: 'Question 2'
                        });
                        values.push({
                            id: 2,
                            label: 'Question 3'
                        });
                        //Columns
                        subFields.push({
                            type: 'radio',
                            label: '1'
                        });
                        subFields.push({
                            type: 'radio',
                            label: '2'
                        });
                        subFields.push({
                            type: 'radio',
                            label: '3'
                        });
                    } else if (target == Gecko.Field.TYPE_REPEATABLE) {
                        values = {};
                        values.display_mode = 'compact';
                        subFields.push({
                            type: 'text',
                            label: 'Institution'
                        });
                        subFields.push({
                            type: 'text',
                            label: 'Year(s)'
                        });
                        subFields.push({
                            type: 'text',
                            label: 'Qualification(s)'
                        });
                    } else if (target == Gecko.Field.TYPE_MEDIA) {
                        values = {
                            type: 'video',
                            max_duration: 30
                        };
                    } else if (target == Gecko.Field.TYPE_FILE) {
                        values = {
                            multi_file_upload: false,
                            multi_file_upload_limit: 1,
                            secure_attachments: 1
                        };
                    } else if (target == Gecko.Field.TYPE_EVENT) {
                        values = {
                            use_old_event_field: 0,
                            event_field_type: 'category',
                            event_category_id: null,
                            event_id: null,
                            default_status: Gecko.Attendance.REGISTERED
                        };
                    } else if (target == Gecko.Field.TYPE_CONSENT && _this.data.consents && _this.data.consents.length) {
                        // Add some default consent reasons
                        values.push(_this.data.consents[0].id);
                        if (_this.data.consents[1]) values.push(_this.data.consents[1].id);
                        if (_this.data.consents[2]) values.push(_this.data.consents[2].id);
                    }

                    _field = new Gecko.Field().create({
                        label: GeckoUI.gobk(_this.fieldTypes, 'id', target).title,
                        type: target,
                        values: values,
                        sub_fields: subFields,
                        settings: settings
                    });

                    _field.option_id = null;
                    _field.parent_id = null;
                } else {
                    //Clone contact field
                    _field = new Gecko.Field().create(angular.copy(target));
                    //Set contact field id
                    _field.contact_field_id = target.id;
                    _field.id = null;
                    _field.matchable = null;
                    _field.ai_available = null;

                    if ([Gecko.Field.TYPE_RADIO, Gecko.Field.TYPE_CHECKBOX, Gecko.Field.TYPE_SELECT, Gecko.Field.TYPE_MULTI].indexOf(_field.type) != -1 && !_field.values) {
                        values = angular.copy(_this.defaultValues);
                    } else if (_field.type === Gecko.Field.TYPE_PREFERRED_LANGUAGE) {
                        values = _field.values;
                    }

                    _field.values = values;
                }

                if (target === Gecko.Field.TYPE_SCRIPT) {
                    _field.help_text = "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.";
                }

                _field.is_form_builder = true;

                //Set as form field
                _field.field_type = Gecko.Field.FIELD_TYPE_FORM;

                //Set order
                _field.order = 0;

                //set placeholder text
                _field.placeholder = GeckoUI.getDefaultPlaceholder(_field.type);

                return _field;
            },

            getFieldsArray: function getFieldsArray(pages) {
                var _array = [];

                for (var i = 0; i < pages.length; i++) {
                    if (pages[i].fields && pages[i].fields.length) {
                        _array = _array.concat(pages[i].fields);
                    }
                }

                return _array;
            },

            isDependency: function isDependency(id, conditions) {
                return conditions.find(function (condition) {
                    return condition.dependency_id === id;
                }) !== undefined;
            },

            hasDependency: function hasDependency(id, conditions) {
                return conditions.find(function (condition) {
                    return condition.field_id === id;
                }) !== undefined;
            },

            cleanConditions: function cleanConditions(conditions, field) {
                var brokenConditions = conditions.filter(function (condition) {
                    return !condition.dependency_id || condition.dependency_id === field.id || condition.field_id === field.id;
                });
                brokenConditions.forEach(function (condition) {
                    var findThisCondition = GeckoUI.findById(condition.id);
                    var index = GeckoUI.indexOf(findThisCondition)(conditions);
                    conditions.splice(index, 1);
                });
            },

            completeDependancy: function completeDependancy(id, conditions) {
                var relevantConditions = conditions.filter(function (condition) {
                    return condition.field_id === id;
                });
                for (var index = 0; index < relevantConditions.length; index++) {
                    if (relevantConditions[index].type === undefined || relevantConditions[index].dependency_id === undefined) {
                        return false;
                    }
                }
                return true;
            },

            finalFieldCheck: function finalFieldCheck(form) {
                var fields = _this.getFieldsArray(form.pages);

                fields.forEach(function (field) {

                    // Parent/Child Field
                    if ([Gecko.Field.TYPE_SELECT, Gecko.Field.TYPE_MULTI].indexOf(field.type) !== -1) {
                        // Fields cant have parent_id and option_id
                        if (field.parent_id && field.option_id) field.option_id = null;
                    }

                    // Event
                    if (field.type === Gecko.Field.TYPE_EVENT && field.values) {
                        if (field.values.use_old_event_field) {
                            field.values.event_field_type = 'category';
                            field.values.event_id = null;
                        } else {
                            if (field.values.event_field_type === 'category') {
                                field.values.event_id = null;
                            } else if (field.values.event_field_type === 'single_event') {
                                field.values.event_category_id = null;
                            }
                        }
                    }
                });
            },

            buildIntegrationFields: function buildIntegrationFields(integrations, fields) {
                var configured_integrations = [];
                var configured_integration_fields = {};

                angular.forEach(integrations, function (integration) {
                    if (configured_integrations.indexOf(integration.type) === -1) {
                        configured_integrations.push(integration.type);
                    }
                    configured_integration_fields[integration.id] = {};
                    configured_integration_fields[integration.id].type = integration.type;

                    //Add field refs
                    angular.forEach(fields, function (field) {
                        configured_integration_fields[integration.id][field.id] = {};
                        //Handle formatting
                        if (field.type == Gecko.Field.TYPE_NAME) {
                            configured_integration_fields[integration.id][field.id] = {};
                            configured_integration_fields[integration.id][field.id]['first_name'] = { integration_id: integration.id, format: 'first_name' };
                            configured_integration_fields[integration.id][field.id]['last_name'] = { integration_id: integration.id, format: 'last_name' };
                        } else if (field.type == Gecko.Field.TYPE_ADDRESS) {
                            configured_integration_fields[integration.id][field.id]['street_address'] = { integration_id: integration.id, format: 'street_address' };
                            configured_integration_fields[integration.id][field.id]['address_line_2'] = { integration_id: integration.id, format: 'address_line_2' };
                            configured_integration_fields[integration.id][field.id]['city'] = { integration_id: integration.id, format: 'city' };
                            configured_integration_fields[integration.id][field.id]['state'] = { integration_id: integration.id, format: 'state' };
                            configured_integration_fields[integration.id][field.id]['country'] = { integration_id: integration.id, format: 'country' };
                            configured_integration_fields[integration.id][field.id]['postal_code'] = { integration_id: integration.id, format: 'postal_code' };
                        } else {
                            configured_integration_fields[integration.id][field.id].integration_id = integration.id;
                        }
                    });
                });

                // Process exisiting values
                angular.forEach(fields, function (field) {
                    if (field.integration_fields) {
                        angular.forEach(field.integration_fields, function (int_field) {

                            if (!configured_integration_fields[int_field.integration_id]) {
                                configured_integration_fields[int_field.integration_id] = {};
                            }

                            if (!configured_integration_fields[int_field.integration_id][field.id]) {
                                configured_integration_fields[int_field.integration_id][field.id] = {};
                            }

                            if (field.type == Gecko.Field.TYPE_NAME || field.type == Gecko.Field.TYPE_ADDRESS) {
                                // Get format from pivot (if exisits)
                                var pivFormat = int_field.pivot ? int_field.pivot.format : null;
                                var format = pivFormat || int_field.format;

                                configured_integration_fields[int_field.integration_id][field.id][format] = int_field;
                                configured_integration_fields[int_field.integration_id][field.id][format]['format'] = format;
                            } else {
                                configured_integration_fields[int_field.integration_id][field.id] = int_field;
                                if (field.type === Gecko.Field.TYPE_ORGANISATION) {
                                    configured_integration_fields[int_field.integration_id][field.id]['organisation_field_id'] = int_field.pivot ? int_field.pivot.organisation_field_id : null;
                                }
                            }
                        });
                    }
                });
                return configured_integration_fields;
            },

            includeIntegrationFields: function includeIntegrationFields(form) {
                var fields = _this.getFieldsArray(form.pages);

                // Reset field mapping
                angular.forEach(fields, function (field) {
                    field.integration_fields = [];
                });

                // Loop the map
                for (var int in _this.integrationFieldsMap) {

                    // Loop the integration
                    for (var field in _this.integrationFieldsMap[int]) {

                        var fieldObj = GeckoUI.gobk(fields, 'id', field);

                        // Add Standard ref
                        if (_this.integrationFieldsMap[int][field].id && fieldObj) {
                            fieldObj.integration_fields.push({
                                integration_field_id: _this.integrationFieldsMap[int][field].id,
                                organisation_field_id: _this.integrationFieldsMap[int][field].organisation_field_id || null,
                                format: null
                            });
                        }
                        // Add Name ref
                        if ((_this.integrationFieldsMap[int][field].first_name || _this.integrationFieldsMap[int][field].last_name) && fieldObj) {
                            ['first_name', 'last_name'].forEach(function (key) {
                                if (_this.integrationFieldsMap[int][field][key] && _this.integrationFieldsMap[int][field][key].id) {
                                    fieldObj.integration_fields.push({
                                        integration_field_id: _this.integrationFieldsMap[int][field][key].id,
                                        format: _this.integrationFieldsMap[int][field][key].format
                                    });
                                }
                            });
                        }
                        // Add Address ref
                        if ((_this.integrationFieldsMap[int][field].street_address || _this.integrationFieldsMap[int][field].address_line_2 || _this.integrationFieldsMap[int][field].city || _this.integrationFieldsMap[int][field].state || _this.integrationFieldsMap[int][field].country || _this.integrationFieldsMap[int][field].postal_code) && fieldObj) {
                            ['street_address', 'address_line_2', 'city', 'state', 'country', 'postal_code'].forEach(function (key) {
                                if (_this.integrationFieldsMap[int][field][key] && _this.integrationFieldsMap[int][field][key].id) {
                                    fieldObj.integration_fields.push({
                                        integration_field_id: _this.integrationFieldsMap[int][field][key].id,
                                        format: _this.integrationFieldsMap[int][field][key].format
                                    });
                                }
                            });
                        }
                    }
                }
            },

            getPrepopulatePairs: function getPrepopulatePairs() {
                return geckoStore.get('gecko_form_prepopulate_' + $stateParams.form_id);
            },

            setPrepopulatePairs: function setPrepopulatePairs(pairs) {
                return geckoStore.set('gecko_form_prepopulate_' + $stateParams.form_id, pairs);
            },

            buildPrepopulateQuery: function buildPrepopulateQuery(values) {
                var _values = angular.copy(_this.getPrepopulatePairs());

                if (!_values) return '';

                var _query = {};

                angular.forEach(_values, function (pair) {
                    //Flatten array values with |
                    if (pair.key && pair.value) _query[pair.key] = angular.isArray(pair.value) ? pair.value.join('|') : pair.value;
                });
                // Serialize, but keep template tags (for copying into templates)
                return GeckoUI.serialize(_query).replace(/(%7B)/g, '{').replace(/\|/g, '%7C').replace(/(%7D)/g, '}');
            },

            isStage: function isStage() {
                return window.location.host.indexOf('stage') !== -1;
            },

            /**
             * @param {string} uuid UUID of the form
             * @param {string} style Style of public form i.e modern
             * @param {number} event_id Event ID that the response was captured at
             * @param {boolean} safe Should the url be uri encoded
             * @param {number} captured_by_id User ID that the response was captured by
             *
             * @return {string} public form url
             */
            buildShareURL: function buildShareURL(uuid, style, event_id, safe) {
                var captured_by_id = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;

                var formUrl = webShareUrl() + '/public/#/' + style + '/' + uuid;
                var prepopulateQuery = _this.buildPrepopulateQuery();

                var url = _this.buildShareUrlQueryString(formUrl, [{
                    value: prepopulateQuery
                }, {
                    key: 'event_id',
                    value: event_id
                }, {
                    key: 'user_id',
                    value: captured_by_id
                }]);

                if (safe) return encodeURIComponent(url);

                return url;
            },

            /**
             * @param {string} uuid UUID of the form
             * @param {number} event_id Event ID that the response was captured at
             * @param {number} captured_by_id User ID that the response was captured by
             *
             * @return {string} public form url
             */
            buildIframeShareURL: function buildIframeShareURL(uuid, event_id, captured_by_id) {
                var appUrl = webShareUrl() + '/gecko-embed/form.js';
                var prepopulateQuery = _this.buildPrepopulateQuery();

                var url = _this.buildShareUrlQueryString(appUrl, [{
                    key: 'uuid',
                    value: uuid
                }, {
                    value: prepopulateQuery
                }, {
                    key: 'event_id',
                    value: event_id
                }, {
                    key: 'user_id',
                    value: captured_by_id
                }]);

                return url;
            },

            /**
             * Make a query string from an array of optional key/value pairs.
             *
             * @param {string} url HTTP url to append query strings to
             * @param {Array<Object>} queryObjects key/value pairs to create a query string value from
             *
             * @return {string} Url with optional query params
             */
            buildShareUrlQueryString: function buildShareUrlQueryString(url, queryObjects) {
                var queryParams = queryObjects.reduce(function (paramBuilder, queryParam) {
                    if (!queryParam.value) return paramBuilder;

                    if (!queryParam.key) {
                        return paramBuilder.concat([queryParam.value]);
                    }

                    return paramBuilder.concat([queryParam.key + '=' + queryParam.value]);
                }, []);

                return url + (queryParams.length ? '?' + queryParams.join('&') : '');
            },

            buildShareUrlWrapper: function buildShareUrlWrapper(url, iframe) {
                if (iframe) {
                    return '<iframe src="' + url + '" width="100%" height="300" frameborder="0" seamless title="GeckoForm Form Embed"></iframe>';
                }

                return '<script src="' + url + '" id="gecko-form-embed-script"></script>';
            },

            getShortUrl: function getShortUrl(url) {
                if (!url) return Promise.reject('No url provided');
                return portalRequestService.get('/short-urls?url=' + url).then(function (res) {
                    return res.data;
                });
            },

            goTo: function goTo(form, module) {
                var module = form && form.module ? form.module : module,
                    route = module === Gecko.Form.MODULE_CALL ? 'script.designer' : 'form.designer',
                    id = form && form.id ? form.id : 'new';

                return $state.go(route, { form_id: id }, { reload: true });
            },

            // Trigger Intercom Event
            triggerIntercomEvent: function triggerIntercomEvent(form, message, error) {
                try {
                    var moduleTitle = _this.getModuleTitle(form.module);
                    var intercomData = {};
                    // Form id
                    intercomData[moduleTitle + ' ID'] = $stateParams.form_id == 'new' ? 'New' : form.id;
                    // Form name
                    if (form.internal_name || form.name) intercomData[moduleTitle + ' Name'] = form.internal_name || form.name;
                    // Form error
                    if (error) intercomData[moduleTitle + ' Error'] = error;
                    GeckoUI.triggerIntercomEvent(message, intercomData);
                } catch (e) {} // eslint-disable-line no-empty
            },

            // Fix Pages and Fields Menus
            handleMenusWindowScroll: function handleMenusWindowScroll(iElement, menuContainer) {
                // This function handles the fixed positioning of the fields-menu, normal fixed positioning won't work as this is nested inside another fixed position component
                var bottomPadding = 40; // The padding from the bottom of the gecko-form-designer and the bottom of the page
                var scrollPoint = 175; // The point at which this element should be fixed

                var top = iElement[0].getBoundingClientRect().top; // Current top of the fields-menu
                var menu = iElement.find(menuContainer)[0]; // The inner menu component(this is what will actually be fixed)

                if (top < scrollPoint) {
                    // Get the full page's scroll height and current scroll position
                    var pageScrollTop = $(window).height() + $('html')[0].scrollTop;
                    var pageScrollHeight = $('html')[0].scrollHeight;

                    // Make the element fixed and scrollable
                    menu.style.width = menu.getBoundingClientRect().width + 'px';
                    menu.style.position = 'fixed';
                    menu.style.top = scrollPoint + 'px';
                    menu.style.overflow = 'scroll';

                    if (pageScrollTop > pageScrollHeight - bottomPadding) {
                        // If we are scrolling past the bottom of the gecko-form-designer then set the elements height to match
                        var diff = bottomPadding * 2 - Math.abs(pageScrollTop - pageScrollHeight - bottomPadding);
                        menu.style.height = document.documentElement.clientHeight - menu.getBoundingClientRect().top - diff + 'px';
                    } else {
                        // Otherwise set the height to the size of gecko-form-designer
                        menu.style.height = document.documentElement.clientHeight - menu.getBoundingClientRect().top + 'px';
                    }
                } else {
                    // Clean up the styles
                    menu.style.width = null;
                    menu.style.position = null;
                    menu.style.top = null;
                    menu.style.height = null;
                    menu.style.overflow = null;
                }
            },

            fieldOptionUtils: {
                getTopParentField: function getTopParentField(fields, fieldId) {
                    var field = fields.find(function (f) {
                        return f.id === fieldId;
                    });
                    // Keep looking when parent_id is set
                    if (field && field.parent_id) {
                        return _this.fieldOptionUtils.getTopParentField(fields, field.parent_id);
                    }
                    return field;
                },
                getFieldOptionLevel: function getFieldOptionLevel(fields, fieldId, currentCount) {
                    var field = (fields || []).find(function (f) {
                        return f.id === fieldId;
                    });
                    if (field.parent_id) {
                        return _this.fieldOptionUtils.getFieldOptionLevel(fields, field.parent_id, currentCount + 1);
                    }
                    return currentCount;
                },
                getOptionsByLevel: function getOptionsByLevel(newOptions, level, options, optgroup, currentLevel) {
                    if (options && options.length) {
                        options.forEach(function (opt) {
                            // Add option list
                            if (level === currentLevel) {
                                opt.optgroup = optgroup;
                                newOptions.push(opt);
                                return;
                            }
                            // Check for further options
                            if (opt.options && opt.options.length) {
                                _this.fieldOptionUtils.getOptionsByLevel(newOptions, level, opt.options, opt.value, currentLevel + 1);
                            }
                        });
                    }
                }

            },

            getDependencyOptions: function getDependencyOptions(fields, dependency_id, level) {
                var _field = (fields || []).find(function (f) {
                    return f.id === dependency_id;
                });

                if (!_field) return [];

                if (_field.parent_id) {
                    var topParent = _this.fieldOptionUtils.getTopParentField(fields, _field.id);
                    var level = _this.fieldOptionUtils.getFieldOptionLevel(fields, _field.id, 0);
                    var newOptions = [];
                    // Extract options
                    _this.fieldOptionUtils.getOptionsByLevel(newOptions, level, topParent.options || [], null, 0);
                    return newOptions;
                }

                if (_field.option || _field.options || (_field.values || []).length) {
                    if (_field.option && (_field.option || []).values) return _field.option.values;
                    if ((_field.options || []).length) return _field.options;
                    if ((_field.values || []).length) return _field.values;
                    return [];
                }
                return false;
            },

            successUrlConditionIsValid: function successUrlConditionIsValid(condition) {
                return condition.field_id && (condition.value || []).length && condition.success_url;
            },

            prepareOptions: function prepareOptions(field, options) {
                if (field.type === Gecko.Field.TYPE_PREFERRED_LANGUAGE) {
                    return geckoTranslateService.prepareCustomLanguageOptions(options);
                }
                return options.map(function (o) {
                    return {
                        value: o.value,
                        label: o.hidden ? o.value + ' (Hidden)' : o.value
                    };
                });
            },

            getWorkflowsAssociatedWithField: function getWorkflowsAssociatedWithField(workflows, fieldId) {
                return (workflows || []).filter(function (workflow) {

                    var hasAssociatedConditions = workflow.conditions.some(function (condition) {
                        return condition.type === 'field' && condition.id === fieldId;
                    });

                    var hasAssociatedActions = workflow.actions.some(function (action) {
                        var hasSetResponseValueAction = action.type === 'set_response_value' && action.field_id === fieldId;
                        var hasToFieldAction = Array.isArray(action.to) && (action.to || []).some(function (to) {
                            return to === 'field:' + fieldId;
                        });
                        return hasSetResponseValueAction || hasToFieldAction;
                    });

                    return hasAssociatedConditions || hasAssociatedActions;
                });
            },

            getFieldsAssociatedWithFieldThroughConditions: function getFieldsAssociatedWithFieldThroughConditions(conditions, fields, fieldId) {
                var filterForAssocatedConditions = function filterForAssocatedConditions(condition) {
                    return [condition.field_id, condition.dependency_id].includes(fieldId);
                };
                var determineAssociatedFieldIds = function determineAssociatedFieldIds(condition) {
                    return condition.field_id === fieldId ? condition.dependency_id : condition.field_id;
                };
                var removeDuplicateFieldIds = function removeDuplicateFieldIds(currentFieldId, index, self) {
                    return self.indexOf(currentFieldId) === index;
                };
                var getAssociatedFieldObjs = function getAssociatedFieldObjs(currentFieldId) {
                    return fields.find(function (field) {
                        return field.id === currentFieldId;
                    });
                };
                return (conditions || []).filter(filterForAssocatedConditions).map(determineAssociatedFieldIds).filter(removeDuplicateFieldIds).map(getAssociatedFieldObjs);
            },

            renderRemoveFieldAlert: function renderRemoveFieldAlert(message, items) {
                return '\n                    <div class="field-remove-dialog-alert alert alert-danger">\n                        <div>\n                            <i class="fa fa-exclamation-triangle"></i>\n                        </div>\n                        <div>\n                            <p>' + message + '</p>\n                            <ul>' + items + '</ul>\n                        </div>\n                    </div>\n                ';
            },

            confirmRemoveField: function confirmRemoveField(associatedWorkflows, associatedConditions, cb) {
                var dialogText = '<p>Are you sure you want to remove this field?</p>';

                if (associatedWorkflows.length) {
                    dialogText += _this.renderRemoveFieldAlert('Removing this field will break conditions/actions associated with the following workflows:', associatedWorkflows.map(function (workflow) {
                        return '<li>' + workflow.name + '</li>';
                    }).join(''));
                }
                if (associatedConditions.length) {
                    dialogText += _this.renderRemoveFieldAlert('Removing this field will break conditions associated with the following fields:', associatedConditions.map(function (field) {
                        return '<li>' + field.label + '</li>';
                    }).join(''));
                }

                return GeckoUI.dialog.confirm(dialogText, cb);
            }

        };

        return _this;
    }

    angular.module('GeckoEngage').factory('$formBuilderService', $formBuilderService);
})();