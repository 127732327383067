(function () {
    'use strict';

    function eventSessionModalLocation() {

        return {
            restrict: 'E',
            scope: {
                event: '=',
                session: '=',
                locations: '='
            },
            template: '<gecko-fields fields="ctrl.fields" values="ctrl.session"></gecko-fields>',
            controllerAs: 'ctrl',
            bindToController: true,
            controller: eventSessionModalLocationCtrl
        };
    }

    function eventSessionModalLocationCtrl($scope, $geckoModal) {
        var ctrl = this;

        $scope.$watch('ctrl', function () {

            ctrl.session.self_checkin = ctrl.session.checkin_start === undefined || ctrl.session.checkin_start === null ? 0 : 1;
            ctrl.fields = [];

            var delivery_method = void 0;
            if ([Gecko.Event.DELIVERY_METHOD_VENUE, Gecko.Event.DELIVERY_METHOD_ONLINE].includes(ctrl.event.delivery_method)) {
                delivery_method = ctrl.event.delivery_method;
            } else if (ctrl.event.delivery_method === Gecko.Event.DELIVERY_METHOD_HYBRID) {
                delivery_method = ctrl.session.delivery_method;
            }

            var buildHybridFields = function buildHybridFields() {
                return [{
                    id: 'delivery_method',
                    label: 'Is your session taking place in a venue or is it a virtual event?',
                    colClass: 'col-xs-12 text-left',
                    type: Gecko.Field.TYPE_PANEL_OPTIONS,
                    onChange: function onChange() {
                        if (ctrl.session.delivery_method === Gecko.Event.DELIVERY_METHOD_ONLINE) {
                            buildOnlineFields();
                        } else if (ctrl.session.delivery_method === Gecko.Event.DELIVERY_METHOD_VENUE) {
                            buildVenueFields();
                        }
                        // Force self checkin when online
                        if (ctrl.session.delivery_method === Gecko.Event.DELIVERY_METHOD_ONLINE) {
                            ctrl.session.self_checkin = 1;
                            if (!ctrl.session.checkin_start) ctrl.event.checkin_start = 0;
                        }
                    },
                    options: [{
                        value: Gecko.Event.DELIVERY_METHOD_VENUE,
                        icon: 'fa-map-marker-alt',
                        title: 'Venue'
                    }, {
                        value: Gecko.Event.DELIVERY_METHOD_ONLINE,
                        icon: 'fa-wifi',
                        title: 'Virtual'
                    }]
                }];
            };

            var buildOnlineFields = function buildOnlineFields() {
                var fields = [{
                    type: Gecko.Field.TYPE_TITLE,
                    label: 'Webinar Information',
                    colClass: 'col-xs-12'
                }, {
                    id: 'webinar_url',
                    label: 'Webinar Link',
                    description: 'Add a webinar link so attendees know how to join you. You can use your preferred web conference solution.',
                    colClass: 'col-xs-12'
                }, {
                    type: Gecko.Field.TYPE_RICHTEXT,
                    config: { toolbar: 'simple' },
                    colClass: 'col-xs-12',
                    label: 'Additional Details',
                    id: 'webinar_description',
                    description: 'Add anything additional attendees will need to know, such as dial in details.'
                }, {
                    label: 'Self Check-in',
                    type: Gecko.Field.TYPE_TITLE,
                    colClass: 'col-xs-12'
                }, {
                    id: 'checkin_start',
                    label: 'Minutes before the event start time',
                    description: 'How many minutes before the start time can attendees access joining details?',
                    colClass: 'col-xs-12',
                    type: Gecko.Field.TYPE_NUMBER
                }];

                if (ctrl.event.delivery_method === Gecko.Event.DELIVERY_METHOD_HYBRID) {
                    fields = buildHybridFields().concat(fields);
                }

                ctrl.fields = fields;
            };

            var buildVenueFields = function buildVenueFields() {
                var fields = [];

                if (Gecko.able(Gecko.ABILITY_LOCATIONS_VIEW)) {

                    ctrl.openSessionLocationModal = function () {
                        return $geckoModal.addLocationModal(new Gecko.Location()).result.then(function (location) {
                            // Update options
                            ctrl.locations.push(location);
                            // Update location variable on event
                            ctrl.session.location_id = location.id;
                        }).catch(console.error);
                    };

                    var addSessionLocationModalFn = function addSessionLocationModalFn() {
                        setTimeout(function () {
                            angular.element('#openSessionLocationModal').on('click', function () {
                                ctrl.openSessionLocationModal();
                            });
                        }, 300);
                    };

                    addSessionLocationModalFn();

                    // Show deprecated location / disable the input
                    if (ctrl.session.location_title_legacy && !ctrl.session.location_id) {
                        fields.push({
                            id: 'location_title_legacy',
                            label: 'Location Name (Deprecated)',
                            description: 'This feature has been depreacted. Please choose a location from the dropdown below.',
                            colClass: 'col-xs-12',
                            disabledWhen: function disabledWhen() {
                                return true;
                            }
                        });
                    }

                    fields.push({
                        id: 'location_id',
                        label: 'Venue Address',
                        description: 'Select a venue so attendees know where to go. If you are unable to find a venue, add a new <a id="openSessionLocationModal">location</a>.',
                        colClass: 'col-xs-12',
                        type: Gecko.Field.TYPE_SELECT,
                        optionsKey: 'id',
                        optionsLabelKey: 'title',
                        options: ctrl.locations
                    });
                } else {
                    fields.push({
                        id: 'location_title_legacy',
                        label: 'Location',
                        colClass: 'col-xs-12'
                    });
                }

                fields = fields.concat([{
                    label: 'Self Check-in',
                    type: Gecko.Field.TYPE_TITLE,
                    colClass: 'col-xs-12'
                }, {
                    id: 'self_checkin',
                    label: 'Self Check-in',
                    description: 'Allow attendees to check themselves into your venue through the RSVP page, and for them to show as attended.',
                    colClass: 'col-md-6 col-xs-12',
                    type: Gecko.Field.TYPE_TOGGLE,
                    trueValue: 1,
                    falseValue: 0,
                    obj: ctrl.session,
                    action: function action() {
                        return ctrl.session.checkin_start = ctrl.session.self_checkin === 0 ? null : 0;
                    }
                }, {
                    id: 'checkin_start',
                    label: 'Minutes before the event start time',
                    description: 'How many minutes before the event start time can attendees check-in?',
                    colClass: 'col-md-6 col-xs-12',
                    type: Gecko.Field.TYPE_NUMBER,
                    hideWhen: function hideWhen() {
                        return !ctrl.session.self_checkin;
                    }
                }]);

                if (ctrl.event.delivery_method === Gecko.Event.DELIVERY_METHOD_HYBRID) {
                    fields = buildHybridFields().concat(fields);
                }

                ctrl.fields = fields;
            };

            // VENUE
            if (delivery_method === Gecko.Event.DELIVERY_METHOD_VENUE) buildVenueFields();

            // ONLINE
            if (delivery_method === Gecko.Event.DELIVERY_METHOD_ONLINE) buildOnlineFields();
        });
    }

    angular.module('GeckoEngage').directive('eventSessionModalLocation', eventSessionModalLocation).controller('eventSessionModalLocationCtrl', eventSessionModalLocationCtrl);
})();