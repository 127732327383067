(function () {
    'use strict';

    function geckoCallTransferCard($geckoCall, $geckoPresence, $geckoTransfer, $geckoSounds, $geckoWatch, asyncOptions) {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-call/views/gecko-call-transfer-card.html',

            require: '^geckoCallPane',

            link: function link(scope, iElement, iAttrs, ctrl) {

                // Toggle call transfer
                ctrl.toggleTransfer = function () {
                    // Open/close transfer card
                    ctrl.transerOpen = !ctrl.transerOpen;

                    // Reset selecteduser
                    if (!ctrl.transerOpen) ctrl.transferUser = null;
                };

                // Some defaults
                ctrl.transerOpen = null;
                ctrl.transferUser = null;
                ctrl.transferNumber = null;

                // $geckoSocket.registerEvent('user:updated', function(){
                //     Var field = ctrl.transferCardFields.find(function(field){ return field.id === 'transferUser'; });
                //     $geckoPresence.getOnlineUsers().get().then(function(data){
                //         Field.options = data.toArray().filter(function(user){ if (user.group) { return user.group.type !== Gecko.Group.SUPER; } else { return true; } });
                //         Scope.$digest();
                //     });
                // }, false);

                // Transfer fields
                ctrl.transferCardFields = [{
                    //     ColClass: 'none',
                    //     Label: 'Transfer to Number',
                    //     Id: 'transferNumber',
                    //     Type: Gecko.Field.TYPE_TEL
                    // }, {
                    label: 'Transfer to User',
                    type: Gecko.Field.TYPE_SELECT,
                    id: 'transferUser',
                    colClass: 'none',
                    disabledWhen: function disabledWhen() {
                        return $geckoTransfer.inProgress;
                    },
                    // Remove super user as this break
                    getOptions: asyncOptions.create($geckoPresence.getOnlineUsers().orderBy('full_name'), function () {
                        return this.options = this.options.filter(function (user) {
                            if (Gecko.user && Gecko.user.id == user.id) {
                                return false;
                            } else if (user.group) {
                                return user.group.type !== Gecko.Group.SUPER;
                            } else {
                                return true;
                            }
                        });
                    }),
                    optionsLabelKey: 'full_name'
                }];

                // Transfer buttons
                ctrl.transferCardBtns = [{
                    safeMode: true,
                    position: 'secondary',
                    preset: 'close',
                    action: ctrl.toggleTransfer,
                    hideWhen: function hideWhen() {
                        return $geckoTransfer.inProgress;
                    }
                }, {
                    safeMode: true,
                    title: 'Cancel Transfer',
                    btnClass: 'btn-danger',
                    action: $geckoTransfer.cancel,
                    hideWhen: function hideWhen() {
                        return !$geckoTransfer.inProgress;
                    }
                }, {
                    safeMode: true,
                    title: 'Transfer Call',
                    btnClass: 'btn-primary',
                    hideWhen: function hideWhen() {
                        return $geckoTransfer.inProgress;
                    },
                    action: function action() {

                        // Reset error message
                        ctrl.transferErrorMessage = false;

                        // Check a number and user has been selected
                        if (!ctrl.transferNumber && !ctrl.transferUser) {
                            return ctrl.transferErrorMessage = 'Choose a number or user to transfer call to.';
                        }

                        // Show loading indicator
                        $geckoCall.loadingIndicator(true);

                        // Play sound
                        $geckoSounds.toggle('ringing', true);

                        // Transfer to user
                        if (ctrl.transferUser) {

                            $geckoCall.transfer('user', ctrl.transferUser).then(function (call) {
                                $geckoCall.restore(call);
                                // Hide transfer card
                                // Ctrl.toggleTransfer();
                                $geckoTransfer.inProgress = true;
                                // Hide loading indicator
                                $geckoCall.loadingIndicator(false);
                            }).catch(function (err) {
                                GeckoUI.messenger.error(err.errors);
                                // Hide loading indicator
                                $geckoCall.loadingIndicator(false);
                            });
                        }

                        // Transfer to number
                        // If (ctrl.transferNumber) {
                        //     //Create (or get number)
                        //     Gecko.Number.check(ctrl.transferNumber).then(function(number){
                        //         //Transfer number
                        //         $geckoCall.transfer('number', number.id).then(function(call){
                        //             //Hide transfer card
                        //             Ctrl.transerOpen = false;
                        //             // Hide loading indicator
                        //             $geckoCall.loadingIndicator(false);
                        //         });
                        //     }).catch(function(err){
                        //         GeckoUI.messenger.error(err.errors);
                        //         // Hide loading indicator
                        //         $geckoCall.loadingIndicator(false);
                        //     });
                        // }
                    }
                }];

                // Only get possible user transfers if open
                $geckoWatch(ctrl, 'transerOpen', function (value) {
                    // Reset everything if false
                    if (value) {
                        $geckoPresence.getOnlineUsers().then(function (users) {
                            GeckoUI.gobk(ctrl.transferCardFields, 'id', 'transferUser').options = users.toArray();
                            scope.$digest();
                        });
                    }
                });
                // Reset user if transfer number is typed
                $geckoWatch(ctrl, 'transferNumber', function (value) {
                    if (value) ctrl.transferUser = null;
                });

                // Reset number if transfer user is picked
                $geckoWatch(ctrl, 'transferUser', function (value) {
                    if (value) ctrl.transferNumber = null;
                });

                // Close transfer when accepted or rejected
                $geckoWatch($geckoTransfer, 'inProgress', function (value) {
                    if (ctrl.transerOpen && !value) ctrl.transerOpen = false;
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCallTransferCard', geckoCallTransferCard);
})();