var modules = ['controllers', 'directives', 'services', 'localytics.directives', 'colorpicker.module', 'ui.router.compat', 'ui.sortable', 'selectize', 'ui.tree', 'angularFileUpload', 'chart.js', 'ui.bootstrap.tpls', 'ui.bootstrap.buttons', 'ui.bootstrap.modal', 'ui.bootstrap.dropdown', 'ui.bootstrap.datepicker', 'ui.bootstrap.popover', 'internationalPhoneNumber', 'ngAudio', 'ui.bootstrap.tooltip', 'mgcrea.bootstrap.affix', 'ui.select', 'ui.tinymce', 'GeckoEngageTemplates', 'GeckoEngage.data', 'GeckoEngage.routes'];

angular.module('GeckoEngage.data', []);
angular.module('GeckoEngage.routes', ['GeckoEngage.data', 'ui.router.compat']);
angular.module('GeckoEngage', modules);
angular.module('controllers', []);
angular.module('directives', []);
angular.module('services', []);

angular.module("GeckoEngage").config(function ($urlRouterProvider, $locationProvider, $uiViewScrollProvider) {

    tinyMCE.baseURL = './js/3rdparty/tinymce';

    $uiViewScrollProvider.useAnchorScroll();

    $locationProvider.hashPrefix('');

    // For any unmatched url, send to /dashboard if loggedin
    $urlRouterProvider.otherwise(function ($injector, $location) {
        if ($location.url() === "" || $location.url() === "/") {
            if (Gecko.user) {
                if (Gecko.able(Gecko.ABILITY_AGENT)) {
                    return "/campaigns/call";
                } else if (Gecko.able(Gecko.ABILITY_CHAT_AGENT) || Gecko.able(Gecko.ABILITY_CHAT_SUPERVISOR)) {
                    return "/conversations";
                }
                return "/dashboard";
            }
            return "/login";
        }
        return "/404?url=" + encodeURIComponent($location.url());
    });
});

angular.module("GeckoEngage").run(function ($rootScope, $state, $stateParams, $unsaved, $geckoCall, $geckoVoip, $geckoQueue, $geckoPresence, $geckoTransfer, $geckoCallSidebar, LoadingIndicator, $injector, geckoSideMenuService, $location, geckoStore, geckoDataService, redirectToReactApp, savedSearchService) {
    $rootScope.Gecko = Gecko;
    $rootScope.GeckoUI = GeckoUI;
    $rootScope.$geckoCall = $geckoCall;
    $rootScope.$stateParams = $stateParams;
    $rootScope.$state = $state;

    // Remove Gecko-Auth code (if set)
    $location.search('code', null);

    // Init GeckoCall
    $geckoCall.init();
    // Init GeckoPresence service
    $geckoPresence.init();
    // Init GeckoQueue service
    $geckoQueue.init();
    // Init GeckoTransfer service
    $geckoTransfer.init();
    // Init Voip functionality
    var voipInit = false;
    var voipInitFn = function voipInitFn() {
        // Only run this once!
        if (voipInit) return;
        // Only load VOIP assets if user has VOIP enabled
        if (Gecko.User.voipOutboundEnabled() || Gecko.User.voipInboundEnabled()) {
            GeckoUI.loadExternalScript('/js/3rdparty/twilio/twilio.min.84fb77c0.js', function () {
                // Init GeckoVoip
                $geckoVoip.init();
            });
        }
        // Flag VOIP has inited
        voipInit = true;
    };
    // Lazy load voip script
    Gecko.on('available', voipInitFn);

    // Events
    Gecko.on('available', function () {
        // Boot Intercom
        window.bootIntercom().then(function (refreshFn) {
            $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                if (Gecko.getCurrentUser() !== null) {
                    refreshFn();
                }
            });
        }).catch(console.error);

        // Boot FullStory
        bootFullstory();

        // Boot Pendo / Feedback (Receptive)
        bootPendo();

        // Boot GeckoChat
        //bootGeckoChat();

        // Set Gecko (JS)
        $rootScope.Gecko = Gecko;

        // Get Users GeoIp
        if (!geckoStore.get('gecko_geo')) {
            new Gecko.Geoips().get().then(function (geo) {
                geckoStore.set('gecko_geo', geo.geoip);
                Gecko.geo = geo.geoip;
            });
        } else {
            Gecko.geo = geckoStore.get('gecko_geo');
        }

        // Check if RefreshToken has expired EVERY 5 minutes
        var authCheckInterval = setInterval(function () {
            try {
                // Will throw error when RefreshToken has expired
                TokenManager.fromLocalStorage().needsRefresh();
            } catch (err) {
                clearInterval(authCheckInterval);
                return Gecko.fire('unavailable');
            }
        }, 300000);

        $rootScope.$digest();
    });

    Gecko.on('permissions_changed', function () {
        console.log('permissions_changed');
        $rootScope.Gecko = Gecko;
        $rootScope.$digest();
    });

    Gecko.on('unavailable', function (logout_details) {
        window.Intercom('shutdown');
    });

    $rootScope.$on('$viewContentLoaded', function () {
        // Force the window to scroll to the top
        if ($state.$current.name !== 'dashboardview' && !$state.$current.ignorePageScroll) {
            window.scrollTo(0, 0);
        } else {
            setTimeout(function () {
                return window.scrollTo(0, scrollPos || 0);
            });
        }
    });

    var scrollPos;

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

        try {
            // Check if transition should redirect to 'new' React app
            var redirectKey = toState.redirectKey;
            if (typeof redirectKey === 'string') {

                if (redirectToReactApp.check(redirectKey)) {
                    // Stop transition
                    event.preventDefault();

                    return Promise.resolve().then(function () {
                        // Redirect to React app
                        window.location.href = redirectToReactApp.url(redirectKey, toParams);
                    });
                }
            }
        } catch (err) {
            console.log('Redirect Error: ', err);
        }

        scrollPos = window.scrollY;

        // Check the users abilities
        if (toState.requires) {

            if (!angular.isArray(toState.requires)) toState.requires = [toState.requires];

            for (var i = 0; i < toState.requires.length; i++) {

                var abilityName = toState.requires[i];

                // If the ability is a function run it through the injector.
                if (typeof abilityName === 'function') {
                    abilityName = $injector.invoke(abilityName, toState, { $stateParams: toParams });
                }

                if (!Gecko.able(abilityName)) {

                    if (!fromState.name || toState.name == fromState.name) {
                        fallbackURL('state', $state);
                    }

                    event.preventDefault();
                    return GeckoUI.messenger.error(Gecko.unableReason(abilityName));
                }
            }
        }
        // Check if user has permission to view module
        else if (toState.permissionCheck) {
                if (!toState.permissionCheck(toParams)) {

                    // Handle redirect
                    if (!fromState.name || toState.name == fromState.name) {
                        fallbackURL('state', $state);
                    }

                    event.preventDefault();
                    return GeckoUI.messenger.error('Sorry, you do not have permission to access this module.');
                }
            }

        // Check for unsaved models
        if ($unsaved && $unsaved.check !== undefined && $unsaved.check()) {
            GeckoUI.dialog.confirm("You have unsaved changes, do you want to discard them?", function (value) {
                if (value) {
                    $unsaved.clear();
                    LoadingIndicator.show();
                    return $state.go(toState.name, toParams);
                }
            });
            event.preventDefault();
        } else {
            // Show loading indicator
            LoadingIndicator.show();
            // Hide pre-Angular loading indicator (ignore after app has loaded)
            if (!window.preAngularLoadingIndicatorRemoved) window.removePreAngularLoadingIndicator();
        }

        // Depreciate old routes
        if (toState.name == 'viewform' || toState.name == 'viewformwithslash') {
            window.location.href = '/public/#/form/' + toParams.uuid;
        } else if (toState.name == 'viewiframe' || toState.name == 'viewiframewithslash') {
            window.location.href = '/public/#/iframe/' + toParams.uuid;
        }

        // Cancel prepared but unstarted calls
        if ($geckoCall.call && $geckoCall.call.status == Gecko.Call.PREPARING) {
            if (toState.name.indexOf('contact.') == -1) {
                console.log('Cancelling Prepared Call'); // eslint-disable-line no-console
                $geckoCall.call.cancel();
                $geckoCall.reset();
                $geckoCall.close();
            }
        }

        // Close pusher connection
        Gecko.pusher.unwatch();

        if (Gecko.able(Gecko.ABILITY_FILTERS_VIEW)) {
            // Load saved searches
            savedSearchService.saturate();
        }
    });

    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        // Set page title
        if (toState.page_title) {
            document.title = toState.page_title + ' | GeckoEngage';
        }

        // Update the users page history
        var maximumHistory = 10;
        if (Gecko.user) {
            var pagesHistory = geckoStore.get('pages-history') || [];
            if (pagesHistory.length >= maximumHistory) {
                pagesHistory.pop();
            }
            pagesHistory.unshift({
                toState: toState.name,
                toParams: toParams,
                timestamp: moment().format('X')
            });
            geckoStore.set('pages-history', pagesHistory);
        }

        // Hide/Cancel loading indicator
        LoadingIndicator.hide();

        // Get field options list (add to fields if needed)
        geckoDataService.fetchAllOptions().catch(function (err) {
            console.error('Error: ALL options fetch has FAILED (geckoDataService)', err);
        });
    });

    // Handle Resolve Errors
    $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
        event.preventDefault();

        // Hide/Cancel loading indicator
        LoadingIndicator.hide();

        var message = 'Sorry, the resource requested was unavailable.';
        if (error.errors && error.errors[0]) {
            message = error.errors[0];
        }
        if (error.message && error.message != '') {
            message = error.message;
        }
        GeckoUI.messenger.error(message);

        // Handle redirect
        if (fromState.name) {
            return $state.go(fromState.name, $stateParams);
        } else {
            return $state.go('404', { url: encodeURIComponent($location.url()) });
        }
    });

    // Set main container classes
    $rootScope.loadingClasses = function () {
        return {
            'loading-indicator-main': !Gecko.User.isGroup(Gecko.Group.AGENT),
            'loading-indicator-full-width': Gecko.User.isGroup(Gecko.Group.AGENT),
            'menu-expanded': !Gecko.User.isGroup(Gecko.Group.AGENT) && geckoSideMenuService.expanded,
            'menu-collapsed': !Gecko.User.isGroup(Gecko.Group.AGENT) && geckoSideMenuService.collapsed
        };
    };

    $rootScope.wrapperClasses = function () {
        var classes = {};

        // Add state name
        if ($state.$current.name) classes['qa-page-' + $state.$current.name] = true;

        return classes;
    };

    // Set main container classes
    $rootScope.containerClasses = function () {
        return {
            'menu-expanded': !Gecko.User.isGroup(Gecko.Group.AGENT) && geckoSideMenuService.expanded,
            'menu-collapsed': !Gecko.User.isGroup(Gecko.Group.AGENT) && geckoSideMenuService.collapsed,
            'in-call-open': !$geckoCallSidebar.applyContainerPadding(),
            'in-call-closed': $geckoCallSidebar.applyContainerPadding(),
            'in-call': $geckoCall.onCall && !$geckoCall.isOpen,
            'call-expanded': $geckoCallSidebar.isOpen()
        };
    };
});

// Init
Gecko.on('available', function () {
    console.log('available'); // eslint-disable-line no-console
    if (!window.GeckoBootstrapped) {
        try {
            angular.element(document).ready(function () {
                angular.bootstrap(document, ['GeckoEngage']);
                window.GeckoBootstrapped = true;
            });
        } catch (e) {
            console.log(e); // eslint-disable-line no-console
        }
    }
});

Gecko.on('restore_token', function (restoreCb) {
    console.log('restore_token');
    // Is there a claimcode in the url OR existing tokens?
    var restore = Gecko.useNewAuth() ? true : false;
    // restoreCb = Gecko.restore
    restoreCb(restore);
});

var unavailableRedirect = function unavailableRedirect() {
    window.location.href = function () {
        var href = window.location.href;
        var isOldProd = href.includes('app.geckoform.com');
        var isOldStage = href.includes('app-stage.geckoform.com');

        // ONLY do this for 'old' Engage
        if (isOldProd) return href.replace('app.geckoform.com', 'app.geckoengage.com');
        if (isOldStage) return href.replace('app-stage.geckoform.com', 'app-stage.geckointernal.com');

        return AuthRequest.getAuthRedirectUrl();
    }();
};

Gecko.on('unavailable', function () {
    console.log('unavailable');

    // HAS Tokens or Auth Code
    if (Gecko.useNewAuth()) {
        return TokenManager.fromLocalStorage().tokenRevokeRequest().finally(function () {
            // Clear tokens from localstorage
            TokenManager.destroy();

            // Redirect to Auth (or New Engage)
            unavailableRedirect();
        });
    }

    // NO Tokens or Auth Code
    // Cache page url
    window.localStorage.setItem('GeckoAuthReferrer', window.location.href);
    // Redirect to Auth (or New Engage)
    unavailableRedirect();
});

// GeckoAuth - Local Fallback
var isLocalAuth = localStorage.getItem('isLocalAuth') || window.location.href.includes('localhost:');

if (Gecko.getAuthCode() === '00000000-0000-0000-0000-000000000000' || isLocalAuth) {
    // Set isLocalAuth flag in localStorage
    localStorage.setItem('isLocalAuth', 'true');

    // Overwrite claimCode method to point at GeckoAPI instead
    TokenManager.claimCode = function (code) {
        return new Promise(function (resolve, reject) {
            fetch('http://localhost:8000/local-auth/claim-token?code=' + code, {
                method: 'GET',
                headers: {}
            }).then(function (response) {
                return response.json().then(function (data) {
                    var tm = TokenManager.fromData(data);
                    tm.persistToLocalStorage();
                    resolve(tm);
                });
            }).catch(reject);
        });
    };

    TokenManager.prototype.tokenRevokeRequest = function () {
        return Promise.resolve();
    };

    // Remove isLocalAuth flag set in localStorage
    var getLocalAuthUrl = function getLocalAuthUrl() {
        localStorage.removeItem('isLocalAuth');
        return 'http://localhost:8000/local-auth';
    };

    // Redirect back to local auth page
    AuthRequest.getAuthUrl = getLocalAuthUrl;
    AuthRequest.getAuthRedirectUrl = getLocalAuthUrl;
} else if (Gecko.getAuthCode()) {
    localStorage.removeItem('isLocalAuth');
}

window.appConfig;

var getAppConfig = function getAppConfig() {
    return new Promise(function (resolve) {
        return fetch('/admin/app-config/index.json?v=' + Date.now(), {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }).then(function (res) {
            return res.json();
        }).then(function (res) {
            window.appConfig = res;
        }).catch(function () {
            window.appConfig = {};
        }).finally(resolve);
    });
};

var bootApp = function bootApp() {
    // If code is in query string, setup Auth
    if (Gecko.getAuthCode()) {
        TokenManager.claimCode(Gecko.getAuthCode()).then(function (data) {
            // ALWAYS clear app data cache on login
            Gecko.Token.appData.clearCache();

            Gecko.fire('ready');
            window.localStorage.setItem('GeckoAuthUser', 'yes');

            // Redirect to page previously accessed
            if (localStorage.getItem('GeckoAuthReferrer')) {
                window.location.href = localStorage.getItem('GeckoAuthReferrer');
                localStorage.removeItem('GeckoAuthReferrer');
            }
        }).catch(function (err) {
            // Most likely a SetNotFound exception for an invalid code
            // Redirect back to GeckoAuth
            window.location.href = AuthRequest.getAuthUrl();
        });
    } else {
        // Restore token (or Redirect to Gecko-Auth)
        Gecko.fire('ready');
    }
};

getAppConfig().finally(function () {
    bootApp();
});

function fallbackURL(type, $state) {
    if (type == 'state' && $state) {
        if (Gecko.able(Gecko.ABILITY_AGENT)) {
            return $state.go('campaigns', { module: Gecko.Campaign.MODULE_CALL });
        } else if (Gecko.able(Gecko.ABILITY_CHAT_AGENT)) {
            return $state.go('conversations');
        } else {
            return $state.go('dashboard');
        }
    }
    return false;
}

function bootFullstory() {
    try {
        FS.identify(Gecko.getCurrentAccount().routing_id + '_' + Gecko.getCurrentUser().id, {
            displayName: Gecko.getCurrentUser().full_name,
            email: Gecko.getCurrentUser().email
        });
        FS.setUserVars({
            accountId_int: Gecko.getCurrentAccount().routing_id,
            userId_int: Gecko.getCurrentUser().id,
            companyName_str: Gecko.getCurrentAccount().name
        });
    } catch (e) {
        // Pass
    }
}

// function bootGeckoChat() {
//     try {
//         // This name bit is not ideal, but will work for just now;
//         var name = Gecko.getCurrentUser().full_name;
//         var first_name = name.split(' ')[0];
//         var last_name = name.split(' ')[name.split(' ').length - 1];
//         window.GeckoChatSettings.user_info = {
//             first_name  : first_name,
//             last_name   : last_name,
//             email       : Gecko.getCurrentUser().email,
//             client_name : Gecko.getCurrentAccount().name,
//             client_id   : Gecko.getCurrentAccount().routing_id,
//             user_id     : Gecko.getCurrentUser().id,
//             contact_id  : Gecko.getCurrentAccount().routing_id + '_' + Gecko.getCurrentUser().id,
//         };
//         window.GeckoChat.boot();
//     } catch(e) {
//         console.error(e);
//     }
// }

function bootPendo() {
    try {
        var account = Gecko.getCurrentAccount();
        var user = Gecko.getCurrentUser();
        var isLocal = window.location.href.includes('localhost:');
        var isStage = window.location.href.includes('app-stage.') && window.location.href.includes('.com');

        if (isLocal || isStage) return;

        pendo.initialize({
            visitor: {
                id: account.routing_id + '_' + user.id,
                email: user.email,
                // created_at : user.created_at,
                name: user.full_name,
                full_name: user.full_name,
                telephone: user.telephone
            },
            account: {
                id: account.routing_id,
                name: account.name
                // created_at : account.created_at,
            }
        });
    } catch (e) {}
}

// Stop cross account data leaks when using the account switcher across multiple tabs/windows
Gecko.on('available', function () {
    try {
        var isSuperUser = Gecko.user.related_group.type === Gecko.Group.SUPER;
        if (!isSuperUser) return;

        var fn = function fn(storageEvent) {
            var hasSwitched = storageEvent.key === 'gecko_account_switched' && storageEvent.newValue !== Gecko.account.routing_id;

            if (hasSwitched) {
                // Clear app data cache
                Gecko.Token.appData.clearCache();
                window.removeEventListener('storage', fn);
                window.location.reload(true);
            }
        };

        window.addEventListener('storage', fn);
    } catch (err) {
        console.log('Switching Error: ', err);
        Gecko.fire('unavailable');
    }
});

// Remove pre-Angular loading indicator
window.removePreAngularLoadingIndicator = function () {
    setTimeout(function () {
        return $('#initial-loading-cover').remove();
    }, 300);
    window.preAngularLoadingIndicatorRemoved = true;
};