(function () {
    'use strict';

    function geckoModal($compile) {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-modal/gecko-modal.html',
            transclude: true,
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {
                title: '@',
                alertMessage: '@',
                description: '@',
                footerMessage: '@',
                footerBtns: '=',
                fields: '=',
                values: '=',
                tabs: '=',
                activeTab: '=?',
                listItems: '=?',
                showListProgress: '=?',
                maxFieldHeight: '=?'
            },
            controller: function controller($scope, $modal, $modalStack) {
                var ctrl = this;

                $scope.$watch('ctrl', function () {

                    // Stop modals closing when highlighting text inputs
                    if ($modalStack.getTop().value.backdrop !== 'static') {
                        $modalStack.getTop().value.backdrop = 'static';
                        $('.modal').on('mousedown', function (e) {
                            if (e.which === 1) {
                                //left click
                                $modalStack.getTop().key.dismiss();
                            }
                        });
                        $('.modal-content').on('mousedown', function (e) {
                            e.stopPropagation();
                        });
                    }

                    // Loop through all the footerBtns
                    angular.forEach(ctrl.footerBtns, function (btn, key) {
                        if (btn.btnDropdownAction) {
                            // Generate a function that passes through to the parent
                            // If you do this directly it just doesn't work
                            btn.dropdownAction = function (action) {
                                btn.btnDropdownAction(action);
                            };
                        }
                    });

                    // Modal tabs setup
                    if (ctrl.tabs && ctrl.tabs.length) {
                        $modal.activeTab = 0;
                        $scope.$modal = $modal;

                        // Active tab check method
                        $scope.$modal.isActiveTab = function (index) {
                            if ($modal.activeTab == undefined) return null;
                            return $modal.activeTab == index;
                        };

                        // Reset (remove) tab index when modal is destroyed
                        $scope.$on('$destroy', function () {
                            delete $modal.activeTab;
                            delete $modal.isActiveTab;
                        });
                    }

                    // adds height and scroll to fields 
                    if (ctrl.maxFieldHeight) {
                        ctrl.fieldStyle = {
                            maxHeight: ctrl.maxFieldHeight,
                            overflowY: 'scroll',
                            scrollBehavior: 'smooth'
                        };
                    }

                    // Close modal
                    ctrl.dismiss = function () {
                        $scope.$parent.$dismiss();
                    };
                });
            },
            link: function link(scope, element, attrs, ctrl, transclude) {
                transclude(scope.$parent, function (clone, scope) {

                    var bodyContent = clone.filter(':not(footer-content)');
                    element.find('.transclude-gecko-modal').append(bodyContent);

                    //element.find('.transclude-gecko-modal').append(clone);

                    // Get the footer content, Clone the children to avoid issues with angular compilation
                    var footerContent = clone.filter('footer-content').children().clone();
                    if (footerContent.length) {

                        var footerTransclude = element.find('.transclude-footer').append(footerContent);
                        $compile(footerTransclude)(scope);
                    }
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoModal', geckoModal);
})();