(function () {

    'use strict';

    function LegacyEventsRoute($stateProvider) {
        $stateProvider.state('legacy-event', {
            url: '/legacy-events/{event_id}?{sync_status}',
            templateUrl: "/modules/event_legacy/views/edit.3798e394.html",
            controller: 'LegacyEventCtrl',
            controllerAs: 'ctrl',
            page_title: 'Event',
            resolve: {
                event: function event($stateParams) {
                    if ($stateParams.event_id != 'new') {
                        return new Gecko.Event().include('workflows:all').includeCount('attendances').get($stateParams.event_id);
                    } else {
                        return new Gecko.Event();
                    }
                },
                integrations: function integrations() {
                    return new Gecko.Integration().where('sync_responses', 1).get();
                }
            }
        }).state('legacy-event.overview', {
            url: '/overview?{status}&{page}&{session}&{search}&{order}&{sort}&{act_page}',
            templateUrl: '/modules/event_legacy/views/overview.54ff21c9.html',
            controller: 'LegacyEventOverviewCtrl',
            controllerAs: 'ctrl',
            page_title: 'Event Overview',
            requires: [Gecko.ABILITY_EVENTS_VIEW],
            resolve: {
                attendancesQuery: function attendancesQuery($stateParams) {
                    return function () {
                        var attendances = new Gecko.Attendance().include('contact');
                        var order = $stateParams.order !== undefined ? $stateParams.order : 'created_at',
                            sort = $stateParams.sort !== undefined ? $stateParams.sort : 'ASC';

                        if ($stateParams.status) attendances.where('status', $stateParams.status);

                        if ($stateParams.session) {
                            attendances.where('event_id', $stateParams.session);
                        } else {
                            attendances.where('event_id', $stateParams.event_id);
                        }

                        if ($stateParams.sync_status) {
                            attendances.where('sync_status', $stateParams.sync_status);
                        }

                        if ($stateParams.search) attendances.search($stateParams.search);

                        return attendances.orderBy(order, sort).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page).rfields({ attendance: ['sync_status', 'created_at', 'status', 'contact_id', 'rsvp'], contact: ['full_name', 'qr_url', 'email'] });
                    }.bind(this);
                },
                attendances: function attendances(attendancesQuery) {
                    return attendancesQuery().get();
                },
                statConfig: function statConfig($stateParams) {
                    return {
                        type: { model: 'attendance', count: '*', group_by: 'status' },
                        filter: { event_id: $stateParams.event_id }
                    };
                },
                stat: function stat($stateParams, statConfig) {
                    return new Gecko.Stat().generate(statConfig.type, statConfig.filter);
                },
                data: function data($stateParams) {
                    var _query = {};

                    _query.sessions = new Gecko.Event().where('parent_id', $stateParams.event_id).perPage('all');

                    return Gecko.multi(_query);
                },
                history: function history($stateParams) {
                    var pagination_act = {
                        per_page: Gecko.DEFAULT_PER_PAGE,
                        page: $stateParams.act_page || 1
                    };

                    return new Gecko.MixedObjects().call('/events/' + $stateParams.event_id + '/history', 'GET', pagination_act, true);
                }
            }
        }).state('legacy-event.settings', {
            url: '/edit',
            templateUrl: '/modules/event_legacy/views/settings.e94296de.html',
            controller: 'LegacyEventSettingsCtrl',
            controllerAs: 'ctrl',
            requires: [function ($stateParams) {
                return $stateParams.event_id === 'new' ? Gecko.ABILITY_EVENTS_CREATE : Gecko.ABILITY_EVENTS_UPDATE;
            }],
            page_title: 'Event Edit'
        }).state('legacy-event.sessions', {
            url: '/sessions?{page}&{filter}&{search}&{order}&{sort}',
            templateUrl: '/modules/event_legacy/views/sessions.328a8160.html',
            controller: 'LegacyEventSessionsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Event Sessions',
            requires: [Gecko.ABILITY_EVENTS_SESSIONS_TAB],
            resolve: {
                sessions: function sessions($stateParams) {
                    var events = new Gecko.Event().includeCount('attendances').where('parent_id', $stateParams.event_id).page($stateParams.page).perPage(GeckoUI.getPageLimit(this.self.name));
                    if ($stateParams.order) events.orderBy($stateParams.order, $stateParams.sort ? $stateParams.sort : 'asc');
                    if ($stateParams.search) events.search($stateParams.search);
                    return events.get();
                }
            }
        }).state('legacy-event.session', {
            url: '/session/{session_id}?{status}&{page}&{search}&{order}&{sort}',
            templateUrl: '/modules/event_legacy/views/overview.54ff21c9.html',
            controller: 'LegacyEventOverviewCtrl',
            controllerAs: 'ctrl',
            page_title: 'Event Session',
            resolve: {
                attendancesQuery: function attendancesQuery($stateParams) {
                    return function () {
                        var attendances = new Gecko.Attendance().include('contact').where('event_id', $stateParams.session_id),
                            order = $stateParams.order !== undefined ? $stateParams.order : 'created_at',
                            sort = $stateParams.sort !== undefined ? $stateParams.sort : 'ASC';

                        if ($stateParams.status) attendances.where('status', $stateParams.status);
                        if ($stateParams.search) attendances.search($stateParams.search);
                        return attendances.orderBy(order, sort).perPage(GeckoUI.getPageLimit(this.self.name)).page($stateParams.page);
                    }.bind(this);
                },
                event: function event($stateParams) {
                    return new Gecko.Event().include('attendances:all', 'workflows:all').includeCount('attendances').get($stateParams.session_id);
                },
                attendances: function attendances(attendancesQuery) {
                    return attendancesQuery().get();
                },
                statConfig: function statConfig($stateParams) {
                    return {
                        type: { model: 'attendance', count: '*', group_by: 'status' },
                        filter: { event_id: $stateParams.session_id }
                    };
                },
                stat: function stat($stateParams, statConfig) {
                    return new Gecko.Stat().generate(statConfig.type, statConfig.filter);
                },
                data: function data($stateParams) {

                    var _query = {};

                    _query.integrations = new Gecko.Integration().where('sync_responses', 1);

                    _query.sessions = new Gecko.Event().where('parent_id', $stateParams.event_id).perPage('all');

                    return Gecko.multi(_query);
                },
                history: function history($stateParams) {
                    var pagination_act = {
                        per_page: Gecko.DEFAULT_PER_PAGE,
                        page: $stateParams.page || 1
                    };

                    return new Gecko.MixedObjects().call('/events/' + $stateParams.event_id + '/history', 'GET', pagination_act, true);
                }
            }
        }).state('legacy-event.workflows', {
            url: '/workflows',
            templateUrl: '/modules/event_legacy/views/workflows.e3f6bfd9.html',
            controller: 'LegacyEventWorkflowsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Event Workflows',
            requires: [Gecko.ABILITY_EVENTS_WORKFLOWS_TAB],
            resolve: {
                multiData: function multiData() {
                    var _query = {};

                    _query.labels = new Gecko.Label().rfields({ 'label': ['name', 'color'] }).orderBy('name').perPage('all');
                    _query.fields = new Gecko.Field().rfields({ 'field': ['label', 'type', 'is_calculated', 'data_type', 'values', 'option_id'], option: ['values'] }).include('option').where('field_type', 'contact').orderBy('label').perPage('all');
                    _query.campaigns = new Gecko.Campaign().rfields({ 'campaign': ['title', 'module'] }).orderBy('title').perPage('all');
                    _query.senders = new Gecko.Sender().rfields({ 'sender': ['type', 'name', 'email', 'tel', 'outgoing_sms', 'verified'] }).orderBy('name').perPage('all');
                    _query.templates = new Gecko.Template().rfields({ 'template': ['name', 'type'] }).where('type', [Gecko.Template.TYPE_EMAIL, Gecko.Template.TYPE_SMS]).orderBy('name').perPage('all');
                    _query.users = new Gecko.User().rfields({ 'user': ['full_name', 'email', 'telephone'] }).orderBy('full_name').perPage('all');
                    _query.consents = new Gecko.Consent().rfields({ 'consent': ['title'] }).orderBy('title').perPage('all');

                    return Gecko.multi(_query);
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(LegacyEventsRoute);
})();