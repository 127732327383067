(function () {
    'use strict';

    function dashboardRefreshAlert() {
        return {
            restrict: 'E',
            template: '\n            \t<gecko-card>\n\t            \t<gecko-card-body padded="true">\n\t            \t\t<div class="alert alert-warning" gecko-bind-html="ctrl.alert"></div>\n\t            \t</gecko-card-body>\n            \t</gecko-card>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {
                refreshedAt: '='
            },
            controller: function controller($scope) {
                var ctrl = this;

                $scope.$watch('ctrl', function () {
                    var refreshedAtFormatted = moment(ctrl.refreshedAt, 'X').format('h:mmA [on] Do MMM YYYY');
                    var lastRefreshedText = 'Last refreshed at <strong>' + refreshedAtFormatted + '</strong>.';
                    // Last refreshed was MORE than 5 minutes ago
                    var refreshTimeout = 5 * 60;
                    var needsRefresh = ctrl.refreshedAt <= moment().format('X') - refreshTimeout;
                    var refreshingText = needsRefresh ? 'Refresh the page in a few minutes to see the latest data.' : '';
                    ctrl.alert = [lastRefreshedText, refreshingText].join(' ').trim();
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('dashboardRefreshAlert', dashboardRefreshAlert);
})();