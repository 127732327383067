(function () {
    'use strict';

    function SettingsContactFieldsCtrl($state, $filter, fields, $geckoModal, munge, ContactFieldOptions) {
        var ctrl = this;
        ctrl.fields = fields.toInstances();
        ctrl.fieldOptions = angular.copy(ctrl.fields);

        // Only show editable and social fields
        ctrl.fields = angular.copy($filter('filter')(ctrl.fields, function (field) {
            return !field.is_uneditable || field.is_social;
        }));

        ctrl.sortOptions = {
            contact_sort_field_id: Gecko.account.contact_sort_field_id,
            contact_sort_direction: Gecko.account.contact_sort_direction
        };

        var fieldTypeLabels = munge(ContactFieldOptions).toObjectByKey('type').done();

        // Table structure
        ctrl.cols = [{
            title: 'Label',
            key: 'label',
            colClass: 'col-md-3'
        }, {
            title: 'Type',
            key: 'type',
            colClass: 'col-md-3',
            render: function render(value) {
                var type = fieldTypeLabels[value];
                if (type) return type.label;
                return $filter('titlecase')(value.replace('_', ' '));
            }
        }, {
            title: 'Template tag',
            key: 'tag',
            colClass: 'col-md-3'
        }, {
            title: 'Option template',
            key: 'option.name',
            colClass: 'col-md-3',
            action: function action(contact_field, col, index, rowAction) {
                if (!contact_field.option_id) return rowAction();
                return $state.go('settingsoption', { option_id: contact_field.option_id });
            }
        }, {
            title: 'Matchable',
            key: 'matchable',
            render: function render(value) {
                return value ? "Yes" : "-";
            }
        }, {
            key: 'system',
            icon_styles: [{
                id: 1,
                icon: 'fa-lock'
            }]
        }];

        // Table row action
        ctrl.rowAction = {
            action: function action(contact_field) {
                // If (!contact_field.system)
                return $state.go('settingscontactfield', { 'contactfield_id': contact_field.id });
            }
        };

        // Table row redorder callback
        ctrl.reorderOptions = {
            stop: function stop() {
                // Refresh order values
                GeckoUI.refreshOrder(ctrl.fields);
                // Map order values
                var _fields = GeckoUI.mapObjectArray(angular.copy(ctrl.fields), { id: 'id', order: 'order' });

                // API update order
                new Gecko.Field().post('', {
                    'fields': _fields
                }).then(function () {
                    GeckoUI.messenger.success('Contact field order updated.');
                }).catch(function (err) {
                    GeckoUI.messenger.error(err.errors);
                });
            }
        };

        // Cog drop down
        ctrl.optionsBtn = {
            iconRight: 'fa-cogs',
            btnTooltip: 'Options'
        };
        ctrl.optionsBtn.items = [{
            title: 'Contact Field Options',
            action: function action() {
                $geckoModal.listViewFieldModal();
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Contact Fields',
            active: true
        }];
    }

    var social_field_types = [{
        icon: 'fa fa-facebook-official',
        type: 'facebook_username',
        label: 'Facebook Username'
    }, {
        icon: 'fa fa-facebook-official',
        type: 'facebook_followers',
        label: 'Facebook Followers'
    }, {
        icon: 'fa fa-facebook-official',
        type: 'facebook_following',
        label: 'Facebook Following'
    }, {
        icon: 'fa fa-twitter',
        type: 'twitter_username',
        label: 'Twitter Username'
    }, {
        icon: 'fa fa-twitter',
        type: 'twitter_followers',
        label: 'Twitter Followers'
    }, {
        icon: 'fa fa-twitter',
        type: 'twitter_following',
        label: 'Twitter Following'
    }, {
        icon: 'fa fa-pinterest',
        type: 'pinterest_username',
        label: 'Pinterest Username'
    }, {
        icon: 'fa fa-pinterest',
        type: 'pinterest_followers',
        label: 'Pinterest Followers'
    }, {
        icon: 'fa fa-pinterest',
        type: 'pinterest_following',
        label: 'Pinterest Following'
    }, {
        icon: 'fa fa-youtube',
        type: 'youtube_username',
        label: 'Youtube Username'
    }, {
        icon: 'fa fa-youtube',
        type: 'youtube_followers',
        label: 'Youtube Followers'
    }, {
        icon: 'fa fa-youtube',
        type: 'youtube_following',
        label: 'Youtube Following'
    }, {
        icon: 'fa fa-instagram',
        type: 'instagram_username',
        label: 'Instagram Username'
    }, {
        icon: 'fa fa-instagram',
        type: 'instagram_followers',
        label: 'Instagram Followers'
    }, {
        icon: 'fa fa-instagram',
        type: 'instagram_following',
        label: 'Instagram Following'
    }];

    function SettingsContactFieldCtrl($scope, $state, $stateParams, asyncOptions, unsavedAlert, field, option, ContactFieldOptions, geckoTranslateService) {
        var ctrl = this;
        ctrl.field = field;

        ctrl.isTagInvalid = function () {
            return (ctrl.field.tag || '').includes(' ');
        };

        // Set social type
        if (field.is_social) {
            ctrl.field.type_social = ctrl.field.type;
            ctrl.field.type = 'social';
        }

        // Prepare language options
        if (ctrl.field.type === Gecko.Field.TYPE_PREFERRED_LANGUAGE) {
            ctrl.field.values = geckoTranslateService.prepareCustomLanguageDisplayValues(ctrl.field.values);
        }

        $scope.$watch('ctrl.field.type', function (newVal, oldVal) {
            if (newVal != oldVal) {
                // Console.log(ctrl.field.type);
                if (ctrl.field.type == 'social') {
                    ctrl.field.is_social = true;
                } else {
                    ctrl.field.is_social = false;
                }
            }
        }, true);

        // Dont allow users to create new langauage fields
        if (ctrl.field.type !== Gecko.Field.TYPE_PREFERRED_LANGUAGE) {
            var types = angular.copy(ContactFieldOptions).filter(function (o) {
                return o.type !== Gecko.Field.TYPE_PREFERRED_LANGUAGE;
            });
        } else {
            var types = angular.copy(ContactFieldOptions);
        }

        // We only want feature social added if they have permissions
        if (ctrl.field.is_social) {
            types.push({
                icon: 'fa fa-link',
                type: 'social',
                label: 'Social'
            });
        }

        ctrl.fields = [{
            label: 'Label',
            key: 'label',
            required: true,
            description: 'Choose a name for your new field.',
            colClass: 'col-xs-12'
        }, {
            label: 'Type',
            key: 'type',
            noBlank: true,
            required: true,
            options: types,
            optionsKey: 'type',
            optionsLabelKey: 'label',
            type: Gecko.Field.TYPE_SELECT,
            description: 'Select which type of field you’d like to add. Click <a href="https://academy.geckoengage.com/en/articles/6830688-field-management#h_613f0f1129" title="Field Types">here</a> for for help with field types.',
            colClass: 'col-xs-12',
            disabledWhen: function disabledWhen() {
                return ctrl.field.id || ctrl.field.system === 1;
            }
        }, {
            label: 'Social Type',
            key: 'type_social',
            noBlank: true,
            required: true,
            options: social_field_types,
            optionsKey: 'type',
            optionsLabelKey: 'label',
            description: 'Choose a specific type of social media field.',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_SELECT,
            disabledWhen: function disabledWhen() {
                return true;
            },
            hideWhen: function hideWhen() {
                return !ctrl.field.is_social;
            }
        },
        // {
        //     Label       : 'Help text',
        //     Key         : 'help_text',
        //     ColClass    : 'col-xs-12',
        //     Description : 'Try to be specific and concise.',
        // },
        {
            label: 'Template tag',
            key: 'tag',
            description: '(Optional) set the template tag that can be used in email/SMS templates e.g. <code>full_name</code> or <code>email_address</code>.',
            colClass: 'col-xs-12'
        }, {
            label: 'Option Template',
            key: 'option_id',
            required: true,
            options: asyncOptions.pick(option, 'option'),
            getOptions: asyncOptions.create(new Gecko.Option().orderBy('name').rfields({ option: ['name'] })),
            colClass: 'col-xs-12',
            description: 'Select which options you want to use for this field - <a ui-sref="settingsoptions">add or view existing templates here</a>.',
            type: Gecko.Field.TYPE_SELECT,
            hideWhen: function hideWhen() {
                return !ctrl.multipleChoiceTypes[ctrl.field.type];
            }
        }, {
            label: 'Country List Overwrite',
            key: 'option_id',
            options: asyncOptions.pick(option, 'option'),
            getOptions: asyncOptions.create(new Gecko.Option().orderBy('name').rfields({ option: ['name'] })),
            colClass: 'col-xs-12',
            description: 'Select which options you want to use for the country dropdown field.',
            type: Gecko.Field.TYPE_SELECT,
            hideWhen: function hideWhen() {
                return ctrl.field.type !== Gecko.Field.TYPE_ADDRESS;
            }
        }, {
            label: 'Available Languages',
            key: 'values',
            options: geckoTranslateService.languages,
            colClass: 'col-xs-12',
            description: 'Select which languages you want to display for the field.',
            type: Gecko.Field.TYPE_MULTI,
            hideWhen: function hideWhen() {
                return ctrl.field.type !== Gecko.Field.TYPE_PREFERRED_LANGUAGE;
            }
        }, {
            label: 'Required',
            colClass: 'col-md-6',
            description: 'Making this field required forces users to complete it.',
            key: 'required',
            type: Gecko.Field.TYPE_TOGGLE
        }, {
            label: 'Matchable',
            key: 'matchable',
            colClass: 'col-md-6',
            description: 'Merge this field for existing contacts - this helps stop duplicate data',
            type: Gecko.Field.TYPE_TOGGLE,
            hideWhen: function hideWhen() {
                return !ctrl.matchableFieldTypes[ctrl.field.type] || !Gecko.User.isGroup(Gecko.Group.SUPER);
            }
        }, {
            label: 'Show on contact portal page',
            key: 'portal_show',
            colClass: 'col-md-6',
            description: 'Should this field be visible to contacts when viewing their portal page.',
            type: Gecko.Field.TYPE_TOGGLE,
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_PORTAL_CONTACT);
            }
        }, {
            label: 'Can be used by AI',
            key: 'ai_available',
            colClass: 'col-md-6',
            description: 'Should values in this field be available to AI for use in conversations.',
            type: Gecko.Field.TYPE_TOGGLE,
            hideWhen: function hideWhen() {
                !Gecko.able(Gecko.ABILITY_AI_CONTEXT);
            }
        }];

        // Map for Checking Matchable field types, values with keys in the map are valid for matchable.
        ctrl.matchableFieldTypes = {};

        ctrl.matchableFieldTypes[Gecko.Field.TYPE_ADDRESS] = true;
        ctrl.matchableFieldTypes[Gecko.Field.TYPE_EMAIL] = true;
        ctrl.matchableFieldTypes[Gecko.Field.TYPE_NUMBER] = true;
        ctrl.matchableFieldTypes[Gecko.Field.TYPE_NAME] = true;
        ctrl.matchableFieldTypes[Gecko.Field.TYPE_TEL] = true;
        ctrl.matchableFieldTypes[Gecko.Field.TYPE_TEXT] = true;

        // Map for Checking Option field types, values with keys in the map are valid for dropdowns/ checkboxes/etc.
        ctrl.multipleChoiceTypes = {};

        ctrl.multipleChoiceTypes[Gecko.Field.TYPE_SELECT] = true;
        ctrl.multipleChoiceTypes[Gecko.Field.TYPE_MULTI] = true;
        ctrl.multipleChoiceTypes[Gecko.Field.TYPE_CHECKBOX] = true;
        ctrl.multipleChoiceTypes[Gecko.Field.TYPE_RADIO] = true;

        ctrl.saveContactField = function () {
            var _field = angular.copy(ctrl.field);
            // Set social type
            if (_field.is_social && !_field.type_social) return GeckoUI.messenger.error('Social type is required');
            if (_field.is_social && _field.type_social) _field.type = _field.type_social;

            // Language field values
            if (_field.type === Gecko.Field.TYPE_PREFERRED_LANGUAGE) {
                _field.values = geckoTranslateService.prepareCustomLanguageSaveValues(_field.values);
            }

            _field.save().then(function (field) {
                GeckoUI.messenger.success('Contact Field has been saved');
                unsavedAlert.clear();
                if ($stateParams.contactfield_id == 'new') {
                    $state.go('settingscontactfield', { contactfield_id: field.id });
                }
                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Contact Field Save Success', {
                    'field id': field.id,
                    'field label': field.label
                });
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);
                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Contact Field Save Error', {
                    'field id': ctrl.field.id || 'New',
                    'field title': ctrl.field.label,
                    'error': GeckoUI.renderError(err.errors)
                });
            });
        };

        // Button setup
        ctrl.footerBtns = [{
            preset: 'save',
            action: ctrl.saveContactField
        }, {
            position: 'secondary',
            preset: 'remove',
            hideWhen: function hideWhen() {
                return $stateParams.contactfield_id == 'new' || ctrl.field.system;
            },
            action: function action() {
                GeckoUI.dialog.confirm('Are you sure you want to delete this contact field?', function (value) {
                    if (value) {
                        unsavedAlert.clear();
                        ctrl.field.remove().then(function () {
                            GeckoUI.messenger.success('Field has been deleted');
                            $state.go('settingscontactfields');
                        }).catch(function (err) {
                            GeckoUI.messenger.error(err.errors);
                        });
                    }
                });
            }
        }];

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Settings',
            click: function click() {
                $state.go('settings');
            }
        }, {
            label: 'Contact Fields',
            click: function click() {
                $state.go('settingscontactfields');
            }
        }, {
            label: ctrl.field.id ? ctrl.field.label : 'Add Contact Field',
            active: true
        }];

        // Listen for CMD + S event
        $scope.$on('quickSave:' + $state.$current.name, ctrl.saveContactField);
    }

    angular.module('GeckoEngage').controller('SettingsContactFieldsCtrl', SettingsContactFieldsCtrl).controller('SettingsContactFieldCtrl', SettingsContactFieldCtrl);
})();