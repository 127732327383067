(function () {
    'use strict';

    function geckoCardSchedule() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-card-schedule/gecko-card-schedule.html',
            scope: {
                field: '=',
                values: '=values'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            transclude: true,
            controller: function controller($scope) {
                var ctrl = this;

                $scope.$watch('ctrl', function () {
                    ctrl.values = ctrl.values ? ctrl.values : {
                        minute: '0',
                        hour: '0',
                        day: '*',
                        month: '*',
                        dayofweek: '*',
                        type: 'day'
                    };

                    ctrl.hours = [{
                        id: '0',
                        name: 'Midnight'
                    }, {
                        id: '1',
                        name: '1am'
                    }, {
                        id: '2',
                        name: '2am'
                    }, {
                        id: '3',
                        name: '3am'
                    }, {
                        id: '4',
                        name: '4am'
                    }, {
                        id: '5',
                        name: '5am'
                    }, {
                        id: '6',
                        name: '6am'
                    }, {
                        id: '7',
                        name: '7am'
                    }, {
                        id: '8',
                        name: '8am'
                    }, {
                        id: '9',
                        name: '9am'
                    }, {
                        id: '10',
                        name: '10am'
                    }, {
                        id: '11',
                        name: '11am'
                    }, {
                        id: '12',
                        name: 'Noon'
                    }, {
                        id: '13',
                        name: '1pm'
                    }, {
                        id: '14',
                        name: '2pm'
                    }, {
                        id: '15',
                        name: '3pm'
                    }, {
                        id: '16',
                        name: '4pm'
                    }, {
                        id: '17',
                        name: '5pm'
                    }, {
                        id: '18',
                        name: '6pm'
                    }, {
                        id: '19',
                        name: '7pm'
                    }, {
                        id: '20',
                        name: '8pm'
                    }, {
                        id: '21',
                        name: '9pm'
                    }, {
                        id: '22',
                        name: '10pm'
                    }, {
                        id: '23',
                        name: '11pm'
                    }];

                    ctrl.days = [{
                        id: '1',
                        name: '1'
                    }, {
                        id: '2',
                        name: '2'
                    }, {
                        id: '3',
                        name: '3'
                    }, {
                        id: '4',
                        name: '4'
                    }, {
                        id: '5',
                        name: '5'
                    }, {
                        id: '6',
                        name: '6'
                    }, {
                        id: '7',
                        name: '7'
                    }, {
                        id: '8',
                        name: '8'
                    }, {
                        id: '9',
                        name: '9'
                    }, {
                        id: '10',
                        name: '10'
                    }, {
                        id: '11',
                        name: '11'
                    }, {
                        id: '12',
                        name: '12'
                    }, {
                        id: '13',
                        name: '13'
                    }, {
                        id: '14',
                        name: '14'
                    }, {
                        id: '15',
                        name: '15'
                    }, {
                        id: '16',
                        name: '16'
                    }, {
                        id: '17',
                        name: '17'
                    }, {
                        id: '18',
                        name: '18'
                    }, {
                        id: '19',
                        name: '19'
                    }, {
                        id: '20',
                        name: '20'
                    }, {
                        id: '21',
                        name: '21'
                    }, {
                        id: '22',
                        name: '22'
                    }, {
                        id: '23',
                        name: '23'
                    }, {
                        id: '24',
                        name: '24'
                    }, {
                        id: '25',
                        name: '25'
                    }, {
                        id: '26',
                        name: '26'
                    }, {
                        id: '27',
                        name: '27'
                    }, {
                        id: '28',
                        name: '28'
                    }];

                    ctrl.daysofweek = [{
                        id: '1',
                        name: 'Monday'
                    }, {
                        id: '2',
                        name: 'Tuesday'
                    }, {
                        id: '3',
                        name: 'Wednesday'
                    }, {
                        id: '4',
                        name: 'Thursday'
                    }, {
                        id: '5',
                        name: 'Friday'
                    }, {
                        id: '6',
                        name: 'Saturday'
                    }, {
                        id: '0',
                        name: 'Sunday'
                    }];

                    ctrl.fields = [{
                        id: 'type',
                        label: 'Frequency',
                        type: Gecko.Field.TYPE_SELECT,
                        obj: ctrl.values,
                        colClass: ' ',
                        noBlank: true,
                        options: [{
                            id: 'hour',
                            name: 'Hourly'
                        }, {
                            id: 'day',
                            name: 'Daily'
                        }, {
                            id: 'week',
                            name: 'Weekly'
                        }, {
                            id: 'month',
                            name: 'Monthly'
                        }],
                        disabledWhen: function disabledWhen() {
                            if (ctrl.field && ctrl.field.disabledWhen) {
                                return ctrl.field.disabledWhen();
                            }

                            return false;
                        }
                    }, {
                        id: 'dayofweek',
                        label: 'Day of Week',
                        type: Gecko.Field.TYPE_SELECT,
                        obj: ctrl.values,
                        options: ctrl.daysofweek,
                        colClass: ' ',
                        hideWhen: function hideWhen() {
                            return ctrl.values.type != 'week';
                        },
                        disabledWhen: function disabledWhen() {
                            if (ctrl.field && ctrl.field.disabledWhen) {
                                return ctrl.field.disabledWhen();
                            }

                            return false;
                        }
                    }, {
                        id: 'day',
                        label: 'Day of Month',
                        type: Gecko.Field.TYPE_SELECT,
                        obj: ctrl.values,
                        options: ctrl.days,
                        colClass: ' ',
                        hideWhen: function hideWhen() {
                            return ctrl.values.type != 'month';
                        },
                        disabledWhen: function disabledWhen() {
                            if (ctrl.field && ctrl.field.disabledWhen) {
                                return ctrl.field.disabledWhen();
                            }

                            return false;
                        }
                    }, {
                        id: 'hour',
                        label: 'Time of Day',
                        type: Gecko.Field.TYPE_SELECT,
                        obj: ctrl.values,
                        noBlank: true,
                        options: ctrl.hours,
                        colClass: ' ',
                        hideWhen: function hideWhen() {
                            return ctrl.values.type == 'hour';
                        },
                        disabledWhen: function disabledWhen() {
                            if (ctrl.field && ctrl.field.disabledWhen) {
                                return ctrl.field.disabledWhen();
                            }

                            return false;
                        }
                    }];
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCardSchedule', geckoCardSchedule);
})();