(function () {
    'use strict';

    function geckoFormFieldSettings() {

        return {
            restrict: 'E',
            scope: {
                form: '=',
                field: '=',
                selectedData: '='
            },
            template: '<gecko-fields fields="ctrl.fields" values="ctrl.field"></gecko-fields>',
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($scope, $formBuilderService, asyncOptions, geckoFileUploadService) {
                var ctrl = this;

                $scope.$watch('ctrl', function () {
                    var valuesShouldBeAnArray = [Gecko.Field.TYPE_RADIO, Gecko.Field.TYPE_CHECKBOX, Gecko.Field.TYPE_SELECT, Gecko.Field.TYPE_MULTI, Gecko.Field.TYPE_MATRIX, Gecko.Field.TYPE_CONSENT].indexOf(ctrl.field.type) != -1;
                    var valuesIsEmptyArray = ctrl.field.values && Array.isArray(ctrl.field.values) && ctrl.field.values.length === 0;
                    var settingsIsEmptyArray = ctrl.field.settings && Array.isArray(ctrl.field.settings) && ctrl.field.settings.length === 0;

                    if (!ctrl.field.values || !valuesShouldBeAnArray && valuesIsEmptyArray) ctrl.field.values = {};
                    if (!ctrl.field.settings || settingsIsEmptyArray) ctrl.field.settings = {};

                    // Settings fields
                    ctrl.fields = [{
                        id: 'label',
                        label: 'Label',
                        placeholder: 'Enter a label',
                        type: Gecko.Field.TYPE_TEXT,
                        colClass: 'col-xs-12',
                        hideWhen: function hideWhen() {
                            return [Gecko.Field.TYPE_SCRIPT].indexOf(ctrl.field.type) != -1;
                        }
                    },
                    // HIDDEN ONLY
                    {
                        id: 'value',
                        label: 'Value',
                        type: Gecko.Field.TYPE_TEXT,
                        colClass: 'col-xs-12',
                        hideWhen: function hideWhen() {
                            return ctrl.field.type != Gecko.Field.TYPE_HIDDEN;
                        }
                    }, {
                        id: 'help_text',
                        label: 'Help Text',
                        type: Gecko.Field.TYPE_TEXT,
                        colClass: 'col-xs-12',
                        placeholder: 'Enter help text (optional)',
                        hideWhen: function hideWhen() {
                            return [Gecko.Field.TYPE_HIDDEN, Gecko.Field.TYPE_PAGEBREAK, Gecko.Field.TYPE_SCRIPT, Gecko.Field.TYPE_CONSENT].indexOf(ctrl.field.type) != -1;
                        }
                    }, {
                        id: 'placeholder',
                        label: 'Placeholder Text',
                        type: Gecko.Field.TYPE_TEXT,
                        colClass: 'col-xs-12',
                        placeholder: 'Enter placeholder text (optional)',
                        description: ctrl.field.type === Gecko.Field.TYPE_ADDRESS ? 'Add a pipe | to add placeholders to different sections of the address field. \u2018High Street | New Town\u2019 would add the respective text to the first and second fields. Likewise, \u2018|||EH1\u2019 would add the text EH1 to the fourth section of your address field.' : ctrl.field.type === Gecko.Field.TYPE_NAME ? 'Add a pipe | to add placeholders to different sections of the name field. ‘First Name | Last Name’ would add the respective text to the first and second fields.' : '',
                        hideWhen: function hideWhen() {
                            return [Gecko.Field.TYPE_NAME, Gecko.Field.TYPE_ADDRESS, Gecko.Field.TYPE_NUMBER, Gecko.Field.TYPE_EMAIL, Gecko.Field.TYPE_URL, Gecko.Field.TYPE_TEL, Gecko.Field.TYPE_TEXT, Gecko.Field.TYPE_TEXTAREA, Gecko.Field.TYPE_ORGANISATION, Gecko.Field.TYPE_SELECT, Gecko.Field.TYPE_MULTI].indexOf(ctrl.field.type) == -1;
                        }
                    }, {
                        id: 'tag',
                        label: 'Template Tag',
                        type: Gecko.Field.TYPE_TEXT,
                        colClass: 'col-xs-12',
                        placeholder: 'Enter a template tag, e.g. full_name',
                        help_text: 'For use in email templates e.g. full_name or email_address',
                        hideWhen: function hideWhen() {
                            return [Gecko.Field.TYPE_SCRIPT, Gecko.Field.TYPE_SECTION, Gecko.Field.TYPE_REPEATABLE, Gecko.Field.TYPE_PAGEBREAK, Gecko.Field.TYPE_MATRIX, Gecko.Field.TYPE_MEDIA].indexOf(ctrl.field.type) !== -1;
                        }
                    }, {
                        id: 'contact_field_id',
                        label: 'Contact Field',
                        type: Gecko.Field.TYPE_SELECT,
                        colClass: 'col-xs-12',
                        options: ($formBuilderService.data.contact_fields || []).filter(function (f) {
                            // Ignore filtering for these types
                            if ([Gecko.Field.TYPE_HIDDEN].indexOf(ctrl.field.type) !== -1) {
                                return true;
                            }
                            // Only show contact fields of same type (unless already WRONGLY mapped)
                            return f.type === ctrl.field.type || f.id === ctrl.field.contact_field_id;
                        }),
                        optionsKey: 'id',
                        optionsLabelKey: 'label',
                        hideWhen: function hideWhen() {
                            return [Gecko.Field.TYPE_SECTION, Gecko.Field.TYPE_SCRIPT, Gecko.Field.TYPE_PAGEBREAK, Gecko.Field.TYPE_MATRIX, Gecko.Field.TYPE_MEDIA, Gecko.Field.TYPE_ORGANISATION, Gecko.Field.TYPE_EVENT, Gecko.Field.TYPE_CONSENT].indexOf(ctrl.field.type) != -1;
                        }
                    },
                    // SCRIPT/CONSENT ONLY
                    {
                        id: 'help_text',
                        label: ctrl.field.type === Gecko.Field.TYPE_CONSENT ? 'Short Statement' : '',
                        config: {
                            toolbar: ctrl.field.type === Gecko.Field.TYPE_CONSENT ? 'simple' : false
                        },
                        type: Gecko.Field.TYPE_RICHTEXT,
                        colClass: 'col-xs-12',
                        help_text: 'This statement can be used to inform users as to why you are collecting their data.',
                        hideWhen: function hideWhen() {
                            return [Gecko.Field.TYPE_SCRIPT, Gecko.Field.TYPE_CONSENT].indexOf(ctrl.field.type) === -1;
                        }
                    }, {
                        id: 'help_text',
                        type: Gecko.Field.TYPE_TAG_BUILDER,
                        models: [Gecko.Template.TAG_FORMS, Gecko.Template.TAG_ASSIGNED_USER, Gecko.Template.TAG_USERS, Gecko.Template.TAG_EVENTS, Gecko.Template.TAG_CONTACT],
                        description: 'Select from each dropdown and then hit the <code>+</code> button to insert the tag in to the message.',
                        colClass: 'col-xs-12',
                        hideWhen: function hideWhen() {
                            return ctrl.field.type !== Gecko.Field.TYPE_SCRIPT;
                        }
                    },
                    // ORGANISATION ONLY
                    {
                        id: 'organisation_type_id',
                        label: 'Organisation Type',
                        type: Gecko.Field.TYPE_SELECT,
                        colClass: 'col-xs-12',
                        options: asyncOptions.pick(ctrl.selectedData, 'organisation_type_id'),
                        getOptions: asyncOptions.create(new Gecko.OrganisationType().rfields({ organisationtype: ['title'] }).orderBy('title', 'asc')),
                        optionsKey: 'id',
                        optionsLabelKey: 'title',
                        description: 'To create/edit an Organisation Type, click <a ui-sref="organisationtypes">here</a>',
                        hideWhen: function hideWhen() {
                            return !Gecko.has(Gecko.Package.FEATURE_ORGANISATION) || ctrl.field.type != Gecko.Field.TYPE_ORGANISATION;
                        }
                    },
                    // {
                    //     id       : 'organisation_create',
                    //     obj      : ctrl.field.settings,
                    //     label    : 'Create New Organisations',
                    //     type     : Gecko.Field.TYPE_TOGGLE,
                    //     colClass : 'col-xs-4',
                    //     hideWhen : function() {
                    //         return !Gecko.has(Gecko.Package.FEATURE_ORGANISATION) || ctrl.field.type != Gecko.Field.TYPE_ORGANISATION;
                    //     },
                    // },
                    // TEXT, TEXTAREA, EMAIL, URL ONLY
                    {
                        id: 'min_length',
                        label: 'Minimum Length',
                        type: Gecko.Field.TYPE_NUMBER,
                        colClass: 'col-sm-6 col-xs-12',
                        placeholder: 'e.g. 10',
                        minimum: 0,
                        help_text: 'The minimum length of an entry in characters.',
                        hideWhen: function hideWhen() {
                            return [Gecko.Field.TYPE_TEXT, Gecko.Field.TYPE_TEXTAREA, Gecko.Field.TYPE_EMAIL, Gecko.Field.TYPE_URL].indexOf(ctrl.field.type) === -1;
                        }
                    }, {
                        id: 'max_length',
                        label: 'Maximum Length',
                        type: Gecko.Field.TYPE_NUMBER,
                        colClass: 'col-sm-6 col-xs-12',
                        placeholder: 'e.g. 100',
                        help_text: 'The maximum length of an entry in characters.',
                        minimum: 0,
                        hideWhen: function hideWhen() {
                            return [Gecko.Field.TYPE_TEXT, Gecko.Field.TYPE_TEXTAREA, Gecko.Field.TYPE_EMAIL, Gecko.Field.TYPE_URL].indexOf(ctrl.field.type) === -1;
                        }
                    },

                    // DATE ONLY
                    {
                        id: 'min_length',
                        label: 'Earliest Date',
                        type: Gecko.Field.TYPE_DATE,
                        colClass: 'col-sm-6 col-xs-12',
                        help_text: 'The earlist date value allowed',
                        utc: true,
                        hideWhen: function hideWhen() {
                            return [Gecko.Field.TYPE_DATE].indexOf(ctrl.field.type) == -1;
                        }
                    }, {
                        id: 'max_length',
                        label: 'Latest Date',
                        type: Gecko.Field.TYPE_DATE,
                        colClass: 'col-sm-6 col-xs-12',
                        help_text: 'The latest date value allowed',
                        utc: true,
                        hideWhen: function hideWhen() {
                            return [Gecko.Field.TYPE_DATE].indexOf(ctrl.field.type) == -1;
                        }
                    },

                    // ADDRESS ONLY
                    {
                        id: 'option_id',
                        label: 'Country List Overwrite',
                        type: Gecko.Field.TYPE_SELECT,
                        colClass: 'col-xs-12',
                        options: $formBuilderService.data.options,
                        getOptions: asyncOptions.create(new Gecko.Option().orderBy('name', 'asc').rfields({ field: ['name'] })),
                        optionsKey: 'id',
                        optionsLabelKey: 'name',
                        hideWhen: function hideWhen() {
                            return ctrl.field.type != Gecko.Field.TYPE_ADDRESS;
                        }
                    }, {
                        id: 'auto_populate',
                        obj: ctrl.field.settings,
                        label: 'Auto Populate',
                        type: Gecko.Field.TYPE_TOGGLE,
                        colClass: 'col-xs-12',
                        description: 'Enabling this feature will allow Gecko to auto-populate the address field based on a users input e.g. entering EH1 into the field will display all addresses in the EH1 postcode. If a user clicks an address, it will automatically fill the address field.',
                        hideWhen: function hideWhen() {
                            return ctrl.field.type != Gecko.Field.TYPE_ADDRESS;
                        }
                    }, {
                        id: 'auto_populate_key',
                        obj: ctrl.field.settings,
                        label: 'Auto Populate Key',
                        type: Gecko.Field.TYPE_TEXT,
                        colClass: 'col-xs-12',
                        description: 'Please note to use this feature, you will need to obtain a key from our <a href="" id="Intercom">support team</a>.',
                        hideWhen: function hideWhen() {
                            return ctrl.field.type != Gecko.Field.TYPE_ADDRESS || ctrl.field.settings && !ctrl.field.settings.auto_populate;
                        }
                    },
                    // MEDIA ONLY
                    {
                        id: 'type',
                        label: 'Type',
                        obj: ctrl.field.values,
                        type: Gecko.Field.TYPE_SELECT,
                        colClass: 'col-sm-6 col-xs-12',
                        options: [{ value: 'video', label: 'Video' }, { value: 'audio', label: 'Audio' }, { value: 'image', label: 'Image' }],
                        optionsKey: 'value',
                        optionsLabelKey: 'label',
                        help_text: 'Selects whether to activate the camera or microphone on your mobile device.',
                        hideWhen: function hideWhen() {
                            return ctrl.field.type !== Gecko.Field.TYPE_MEDIA;
                        }
                    }, {
                        id: 'max_duration',
                        label: 'Maximum duration',
                        help_text: 'Enter a maximum number of seconds for recordings',
                        obj: ctrl.field.values,
                        type: Gecko.Field.TYPE_NUMBER,
                        minimum: 0,
                        colClass: 'col-sm-6 col-xs-12',
                        hideWhen: function hideWhen() {
                            return ctrl.field.type !== Gecko.Field.TYPE_MEDIA || ctrl.field.values.type === 'image';
                        }
                    }, {
                        id: 'verify_email',
                        obj: ctrl.field.settings,
                        label: 'Verify email',
                        type: Gecko.Field.TYPE_TOGGLE,
                        description: 'Requires users to enter their email address twice.',
                        colClass: 'col-sm-4 col-xs-12',
                        hideWhen: function hideWhen() {
                            return ctrl.field.type !== Gecko.Field.TYPE_EMAIL;
                        }
                    },
                    // CONSENT ONLY
                    {
                        id: 'hide_inline_privacy',
                        obj: ctrl.field.settings,
                        label: 'Show As Modal',
                        type: Gecko.Field.TYPE_TOGGLE,
                        colClass: 'col-sm-4 col-xs-12',
                        hideWhen: function hideWhen() {
                            return ctrl.field.type !== Gecko.Field.TYPE_CONSENT;
                        },
                        description: 'By default the privacy policy will be displayed alongside consent options. Enable this setting to show in a modal window instead.'
                    }, {
                        id: 'hide_all_privacy',
                        obj: ctrl.field.settings,
                        label: 'Disable Privacy Statement',
                        type: Gecko.Field.TYPE_TOGGLE,
                        colClass: 'col-sm-4 col-xs-12',
                        hideWhen: function hideWhen() {
                            return ctrl.field.type !== Gecko.Field.TYPE_CONSENT;
                        },
                        description: 'Enable this setting to hide all privacy statements from the consent field.'
                    }, {
                        id: 'required',
                        label: 'Required',
                        type: Gecko.Field.TYPE_TOGGLE,
                        colClass: 'col-sm-4 col-xs-12',
                        hideWhen: function hideWhen() {
                            return [Gecko.Field.TYPE_PAGEBREAK, Gecko.Field.TYPE_SCRIPT, Gecko.Field.TYPE_SECTION, Gecko.Field.TYPE_HIDDEN].indexOf(ctrl.field.type) != -1;
                        }
                    }, {
                        id: 'hidden',
                        label: 'Hidden',
                        type: Gecko.Field.TYPE_TOGGLE,
                        colClass: 'col-sm-4 col-xs-12',
                        hideWhen: function hideWhen() {
                            return [Gecko.Field.TYPE_PAGEBREAK, Gecko.Field.TYPE_SCRIPT, Gecko.Field.TYPE_SECTION, Gecko.Field.TYPE_HIDDEN, Gecko.Field.TYPE_REPEATABLE, Gecko.Field.TYPE_CONSENT].indexOf(ctrl.field.type) != -1;
                        }
                    }, {
                        id: 'ai_available',
                        label: 'Can be used by AI',
                        type: Gecko.Field.TYPE_TOGGLE,
                        colClass: 'col-sm-4 col-xs-12',
                        hideWhen: function hideWhen() {
                            return !Gecko.able(Gecko.ABILITY_AI_CONTEXT) || ctrl.field.contact_field_id !== null;
                        }
                    },
                    // FILE UPLOAD
                    {
                        id: 'multi_file_upload',
                        obj: ctrl.field.values,
                        label: 'Enable Multi File Upload',
                        type: Gecko.Field.TYPE_TOGGLE,
                        colClass: 'col-sm-4 col-xs-12',
                        hideWhen: function hideWhen() {
                            return [Gecko.Field.TYPE_FILE].indexOf(ctrl.field.type) === -1;
                        }
                    }, {
                        id: 'multi_file_upload_limit',
                        obj: ctrl.field.values,
                        label: "File Limit",
                        type: Gecko.Field.TYPE_NUMBER,
                        colClass: 'col-xs-12',
                        hideWhen: function hideWhen() {
                            return [Gecko.Field.TYPE_FILE].indexOf(ctrl.field.type) === -1 || !ctrl.field.values.multi_file_upload;
                        }
                    }, {
                        id: 'mimetype_group',
                        obj: ctrl.field.values,
                        label: 'Allowed File Types',
                        colClass: 'col-xs-12',
                        description: '<a href="https://geckoengage.notion.site/File-field-7251ceab7e7a41ea8c626ae28f09aa69" target="_blank">Specify</a> which file types can be uploaded using this field.',
                        type: Gecko.Field.TYPE_MULTI,
                        options: geckoFileUploadService.mimetypeGroupsOptions,
                        hideWhen: function hideWhen() {
                            return ![Gecko.Field.TYPE_FILE].includes(ctrl.field.type);
                        }
                    }, {
                        id: 'secure_attachments',
                        obj: ctrl.field.values,
                        label: "Secure Attachments",
                        type: Gecko.Field.TYPE_TOGGLE,
                        colClass: 'col-xs-12',
                        hideWhen: function hideWhen() {
                            return [Gecko.Field.TYPE_FILE].indexOf(ctrl.field.type) === -1;
                        },
                        description: '<a href="https://geckoengage.notion.site/File-field-7251ceab7e7a41ea8c626ae28f09aa69#78cbb42c567d4a0bbb82dd374bf595db" target="_blank">Secure attachments</a> to reduce the time it takes for exported links to expire.'
                    }];
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoFormFieldSettings', geckoFormFieldSettings);
})();