(function () {

    'use strict';

    function ConversationsRoute($stateProvider) {
        $stateProvider.state('conversations', {
            url: "/conversations",
            redirectKey: 'module_conversations',
            template: '<div>Redirect to <a href="/admin/conversations">/admin/conversations</a>.</div>'
        }).state('knowledge_bases', {
            url: "/settings/knowledge_bases",
            redirectKey: 'module_knowledge_bases',
            template: '<div>Redirect to <a href="/admin/settings/knowledge_bases">/admin/settings/knowledge_bases</a>.</div>'
        }).state('bots', {
            url: "/settings/chatbots",
            redirectKey: 'module_bots',
            template: '<div>Redirect to <a href="/admin/settings/chatbots">/admin/settings/chatbots</a>.</div>'
        }).state('channels', {
            url: "/settings/channels",
            redirectKey: 'module_channels',
            template: '<div>Redirect to <a href="/admin/settings/channels">/admin/settings/channels</a>.</div>'
        }).state('widgets', {
            url: "/settings/widgets",
            redirectKey: 'module_widgets',
            template: '<div>Redirect to <a href="/admin/settings/widgets">/admin/settings/widgets</a>.</div>'
        }).state('chat_reporting', {
            url: "/settings/chat_reporting",
            redirectKey: 'module_chat_reports_conversations',
            template: '<div>Redirect to <a href="/admin/chat_reporting/conversations">/admin/chat_reporting/conversations</a>.</div>'
        });
    }

    angular.module('GeckoEngage.routes').config(ConversationsRoute);
})();