(function () {
    'use strict';

    function geckoUserControl() {
        return {
            restrict: 'EA',
            templateUrl: '/components/gecko-user-control/gecko-user-control.html',
            scope: {},
            controllerAs: 'ctrl',
            controller: function controller($rootScope, $scope, $state, changeLogService, $q) {
                var ctrl = this;

                $scope.Gecko = Gecko;

                ctrl.GeckoAuthAccountSettings = function () {
                    window.open(window.AuthRequest.getAuthUrl() + 'account/', '_blank');
                };

                ctrl.mySettings = function () {
                    if (Gecko.user) $state.go('user.settings', { user_id: Gecko.user.id });
                };

                ctrl.logout = function () {
                    return Gecko.fire('unavailable');
                };

                var accessToken = window.TokenManager.decode(window.TokenManager.fromLocalStorage().AccessToken);

                // Menu
                ctrl.items = [{
                    icon: 'fa-cog',
                    title: 'User settings',
                    action: ctrl.mySettings,
                    hideWhen: function hideWhen() {
                        return !Gecko.able(Gecko.ABILITY_USERS_UPDATE);
                    }
                }, {
                    icon: 'fa-lock',
                    title: 'Security preferences',
                    action: ctrl.GeckoAuthAccountSettings,
                    hideWhen: function hideWhen() {
                        return !Gecko.useNewAuth();
                    }
                }, {
                    icon: 'fa-user',
                    title: 'My accounts',
                    action: function action() {
                        return window.location.replace(window.AuthRequest.getAuthUrl() + 'select-profile/');
                    },
                    hideWhen: function hideWhen() {
                        if (!accessToken || !accessToken.profiles || accessToken.profiles <= 1) {
                            return true;
                        }
                        return false;
                    }
                }, {
                    icon: 'fa-sign-out',
                    title: 'Logout',
                    action: ctrl.logout
                }];

                // Are there any new changelogs
                // I'm storing this in rootScope so i can use it in change-log-badge.js as well - SL
                $rootScope.new_changelog = false;

                getChangelogNotifications(changeLogService, $q).then(function (result) {
                    $rootScope.new_changelog = result;
                });
            }
        };
    }

    function getChangelogNotifications(changeLogService, $q) {
        // If use is logged out, return false
        if (!window.Gecko.user) {
            return $q(function (resolve, reject) {
                resolve(false);
            });
        }

        // Check when the user last looked at the changelog
        var last_viewed = window.Gecko.user.getConfig('last_viewed_changelog');

        if (typeof last_viewed != 'undefined') {
            return changeLogService.get({ perPage: 1 }).then(function (data) {
                var changelog = data.data[0];
                if (changelog && changelog.created_at > last_viewed) {
                    // There are new changes since the last time the user looked
                    return true;
                } else {
                    // Nothing has changed since the last time
                    return false;
                }
            });
        } else {
            // The user has "never" looked at the change log so lets let them know whats new.
            return $q(function (resolve, reject) {
                resolve(true);
            });
        }
    }

    angular.module('GeckoEngage').directive('geckoUserControl', geckoUserControl);
})();