(function () {
    'use strict';

    function ContactFieldsRoute($stateProvider) {
        $stateProvider.state('settingscontactfields', {
            url: "/settings/contactfields?{page}",
            templateUrl: "/modules/contact_fields/views/index.428df9b4.html",
            controller: 'SettingsContactFieldsCtrl',
            controllerAs: 'ctrl',
            page_title: 'Contact Fields',
            requires: [Gecko.ABILITY_ADV_SETTINGS],
            redirectKey: 'module_contact_fields',
            resolve: {
                fields: function fields() {
                    return new Gecko.Field().include('option').where('field_type', Gecko.Field.FIELD_TYPE_CONTACT).where('editable', 1).perPage('all').orderBy('order', 'asc').rfields({
                        field: ['label', 'type', 'tag', 'option', 'system', 'is_uneditable', 'is_social', 'option_id', 'order', 'matchable', 'portal_show', 'ai_available'],
                        option: ['name']
                    }).get();
                }
            }
        }).state('settingscontactfield', {
            url: "/settings/contactfields/view/{contactfield_id}",
            templateUrl: "/modules/contact_fields/views/edit.68b05320.html",
            controllerAs: 'ctrl',
            controller: 'SettingsContactFieldCtrl',
            page_title: 'Contact Field',
            requires: [Gecko.ABILITY_ADV_SETTINGS],
            redirectKey: 'module_contact_field',
            resolve: {
                data: function data($stateParams) {
                    var _query = {};
                    // Contact field
                    if ($stateParams.contactfield_id != 'new') _query.field = new Gecko.Field().path($stateParams.contactfield_id);
                    // Integrations
                    //_query.integrations = new Gecko.Integration().where('configurable', 1); .include('integration_fields')

                    return new Gecko.multi(_query);
                },
                field: function field(data) {
                    if (data.field) return data.field;
                    // New field instance
                    return new Gecko.Field();
                },
                // integrations: function(data){
                //     return data.integrations;
                // },
                option: function option($stateParams, asyncOptions, field) {
                    return asyncOptions.getSelectedValues({
                        option: { model: 'Option', id: field.option_id, fields: { option: ['name'] } }
                    });
                }
            }
        });
    }

    angular.module('GeckoEngage.routes').config(ContactFieldsRoute);
})();