(function () {
    'use strict';

    // https://google-developers.appspot.com/chart/interactive/docs/gallery/geochart#region-geocharts
    // https://gist.github.com/gerryster/4242467
    //https://developers.google.com/chart/interactive/docs/gallery/geochart

    // US - States e.g. California
    // CA - States e.g. British Columbia, Alerta, Ontario
    // GB - Nations e.g. England, Scotland
    // AU - 
    // CN - 
    // India - 

    //     // var data = google.visualization.arrayToDataTable([
    //     //     ['Region', 'Popularity'],
    //     //     ['Edinburgh', 5],
    //     //     ['South Queensferry', 1],
    //     //     ['Haddington', 3],
    //     //     ['Airdrie', 1],
    //     //     ['Basingstoke', 1],
    //     //     ['Aspatria', 10]
    //     //     //['Alberta', 123],
    //     //     //['Ontario', 543],
    //     //     // ['Scotland', 5],
    //     //     // ['England', 4],
    //     //     // ['Wales', 3],
    //     //     // ['Northern Ireland', 2],
    //     //     //['UK-EN', 300],
    //     //     //['US-IL', 200],
    //     //     // ['US-IN', 300],
    //     //     // ['US-IA', 20],
    //     //     // ['US-RI', 150]
    //     // ]);

    // var countryData = [
    //     ['Region', ''],
    //     ['US', 500],
    //     ['CN', 600],
    //     ['IN', 100],
    //     ['GB', 200],
    //     ['CA', 250]
    // ];

    // var markerData = {
    //     'GB': [
    //         ['Region', ''],
    //         ['Edinburgh', 5],
    //         ['South Queensferry', 1],
    //         ['Haddington', 3],
    //         ['Airdrie', 1],
    //         ['Basingstoke', 1],
    //         ['Aspatria', 10]
    //     ],
    //     'US': [
    //         ['Region', ''],
    //         ['Chicago', 5],
    //         ['New York', 2],
    //         ['Seattle', 3],
    //         ['Auburn', 3],
    //     ],
    //     'CA': [
    //         ['Region', ''],
    //         ['Whistler', 200],
    //         ['Calgary', 100],
    //         ['Halifax', 50],
    //         ['Oakville', 80],
    //         ['Dorval', 100]
    //     ]
    // };

    function geckoStatWidgetGeo() {
        return {
            restrict: 'EA',
            template: '\n                <div class="chart chart-geo" style="width:100%; height:auto;""></div>\n                <span class="chart-geo-unknown-total">\n                    Unknown Location: {{ctrl.unknownTotal}}\n                </span>\n                <!--<gecko-btn \n                    ng-if="ctrl.mode === \'markers\'"\n                    class="chart-geo-btn"\n                    btn-class="btn-default" \n                    icon-left="fa-times" \n                    action="ctrl.drawRegionsMap()">\n                </gecko-btn>-->\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {
                widget: '='
            },
            controller: function controller($scope, $element, $stateParams) {
                var ctrl = this;

                $scope.$watch('ctrl', function () {

                    var chart;
                    var chartContainer = $($element).find('.chart-geo')[0];
                    var chartContainerWidth = $(chartContainer).width() - 1;

                    ctrl.mode = 'regions';

                    // Handle 'unknown' totals
                    var unknown = ctrl.widget.data.find(function (stat) {
                        return stat[0] === '[Unknown]';
                    });
                    if (unknown) {
                        ctrl.unknownTotal = unknown[1];
                    } else {
                        ctrl.unknownTotal = 0;
                    }

                    // Country map init
                    ctrl.drawRegionsMap = function () {
                        var data = google.visualization.arrayToDataTable(ctrl.widget.data.length ? ctrl.widget.data : countryData);
                        var options = {
                            displayMode: 'regions',
                            keepAspectRatio: true,
                            width: chartContainerWidth
                            //legendTemplate: "<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<datasets.length; i++){%><li><span style=\"background-color:<%=datasets[i].fillColor%> !important\"></span><%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>"
                            //legend: {}
                        };

                        chart = new google.visualization.GeoChart(chartContainer);

                        //google.visualization.events.addListener(chart, 'select', ctrl.selectionHandler);

                        chart.draw(data, options);

                        ctrl.mode = 'regions';
                    };

                    // Markers map init
                    ctrl.drawMarkersMap = function (region, data) {
                        var data = google.visualization.arrayToDataTable(data.slice(0, 50));
                        var options = {
                            region: region,
                            displayMode: 'markers',
                            resolution: 'provinces',
                            magnifyingGlass: { enable: true },
                            keepAspectRatio: true,
                            width: chartContainerWidth
                        };

                        chart = new google.visualization.GeoChart(chartContainer);

                        chart.draw(data, options);

                        ctrl.mode = 'markers';
                    };

                    // Click a region
                    // ctrl.selectionHandler = () => {
                    //     var selection = ctrl.getSelection(chart.getSelection());

                    //     if (!selection) return;

                    //     // Load markers map (if data exists)
                    //     if (markerData[selection]) return ctrl.drawMarkersMap(selection, markerData[selection]);

                    //     return;
                    // };

                    // // Get region that was clicked on
                    // ctrl.getSelection = (selection) => {
                    //     if (selection[0] && selection[0].row !== undefined && countryData[selection[0].row + 1] && countryData[selection[0].row + 1][0]) {
                    //         return countryData[selection[0].row + 1][0];
                    //     }
                    //     return;
                    // };

                    // ctrl.selectionHandler = () => {
                    //     var selection = ctrl.getSelection(chart.getSelection());

                    //     if (!selection) return;

                    //     // Clear current map
                    //     //$(chartContainer).html('');
                    //     //chart.clearChart();

                    //     var stat = {
                    //         no_cache: 1,
                    //         model: 'geoip', 
                    //         count: '*', 
                    //         group_by: 'city_name',
                    //     };

                    //     var filter = {
                    //         country_code: selection
                    //     };

                    //     new Gecko.Stat().generate(stat, filter).then((stat) => {
                    //         var data = [
                    //             ['Region', '']
                    //         ];

                    //         if (stat.result.length) {
                    //             data = data.concat(stat.result.filter(agg => agg.title !== '[Unknown]').map(agg => [agg.title, agg.aggregate]));
                    //         }

                    //         // Load markers map (if data exists)
                    //         ctrl.drawMarkersMap(selection, data);

                    //         $scope.$digest();
                    //     });

                    //     // new Gecko.Stat().generate({
                    //     //     no_cache: 1,
                    //     //     model: 'geoip', 
                    //     //     count: '*', 
                    //     //     group_by: 'city_name',
                    //     // }, {
                    //     //     country_code: 'GB'
                    //     // }).then((stat) => {
                    //     //     console.log(stat)
                    //     // });

                    // };

                    // ctrl.getSelection = (selection) => {
                    //     if (selection[0] && selection[0].row !== undefined && ctrl.widget.data[selection[0].row + 1] && ctrl.widget.data[selection[0].row + 1][0]) {
                    //         return ctrl.widget.data[selection[0].row + 1][0];
                    //     }
                    //     return;
                    // };

                    // Load GEO charts script charts
                    if (!window.google || !window.google.visualization || !window.google.visualization.GeoChart) {
                        GeckoUI.loadExternalScript('https://www.gstatic.com/charts/loader.js', function () {
                            google.charts.load('current', {
                                'packages': ['geochart'],
                                'mapsApiKey': 'AIzaSyAS9QXHFdfVsUrKQ-PSKPqn7GIe2PKGV3o'
                            });
                            google.charts.setOnLoadCallback(ctrl.drawRegionsMap);
                        });
                    } else {
                        google.charts.setOnLoadCallback(ctrl.drawRegionsMap);
                    }
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoStatWidgetGeo', geckoStatWidgetGeo);
})();