(function () {
    'use strict';

    function WorkflowDelayModal($geckoModalProvider) {

        $geckoModalProvider.modalAdd('workflowDelay', function (_workflow, _type) {
            return {
                size: 'md',
                controllerAs: 'ctrl',
                templateUrl: '/components/gecko-workflow/_modals/delay.html',
                controller: 'workflowDelaysModalCtrl',
                resolve: {
                    workflow: function workflow() {
                        return _workflow;
                    },
                    type: function type() {
                        return _type;
                    }
                }
            };
        });
    }

    function workflowDelaysModalCtrl($modalInstance, workflow, type) {
        var ctrl = this;

        // Setup the variables
        ctrl.workflow = workflow;
        ctrl.type = type;

        // Need to set this to null or it will display 1969
        if (!ctrl.workflow.schedule) {
            ctrl.workflow.schedule = null;
        }

        if (ctrl.workflow.schedule) {
            ctrl.workflow.delayType = 'scheduled';
        } else if (ctrl.workflow.delay) {
            ctrl.workflow.delayType = 'delay';
        } else if (ctrl.workflow.before) {
            ctrl.workflow.delayType = 'before';
        } else if (ctrl.workflow.after) {
            ctrl.workflow.delayType = 'after';
        } else {
            ctrl.workflow.delayType = 'immediately';
        }

        // Workflow delay
        if (Gecko.able(Gecko.ABILITY_FORMS_DELAYED_MARKETING) && !['chat'].includes(ctrl.type)) {
            ctrl.showDelayOptions = [{
                title: 'Immediately',
                value: 'immediately'
            }, {
                title: 'After a delay',
                value: 'delay'
            }, {
                title: 'After a date',
                value: 'scheduled'
            }];
            // Relative delays (for events ONLY)
            if (ctrl.type === 'event') {
                ctrl.showDelayOptions = ctrl.showDelayOptions.concat([{
                    title: 'Before Event/Session starts',
                    value: 'before'
                }, {
                    title: 'After Event/Session ends',
                    value: 'after'
                }]);
            }
        } else {
            ctrl.showDelayOptions = [{
                title: 'Immediately',
                value: 'immediately'
            }];
        }

        // Set the show/hide of the end date right away
        ctrl.workflow.endDate = ctrl.workflow.stop_at ? 1 : 0;

        ctrl.fields = [{
            id: 'delayType',
            label: 'Delay',
            type: Gecko.Field.TYPE_SELECT,
            colClass: 'none',
            options: ctrl.showDelayOptions,
            orderBy: 'title',
            optionsKey: 'value',
            optionsLabelKey: 'title',
            blankLabel: ' '
        }, {
            id: 'delay',
            label: 'Delay Length',
            type: Gecko.Field.TYPE_DURATION,
            labeled: true,
            colClass: 'none',
            hideWhen: function hideWhen() {
                return ctrl.workflow.delayType != 'delay';
            }
        }, {
            id: 'schedule',
            label: 'Scheduled Date',
            type: Gecko.Field.TYPE_DATETIME,
            colClass: 'none',
            interval: 15,
            hideWhen: function hideWhen() {
                return ctrl.workflow.delayType != 'scheduled';
            }
        }, {
            id: 'before',
            label: 'Time before Event/Session Start',
            type: Gecko.Field.TYPE_DURATION,
            labeled: true,
            colClass: 'none',
            hideWhen: function hideWhen() {
                return ctrl.workflow.delayType !== 'before';
            }
        }, {
            id: 'after',
            label: 'Time after Event/Session End',
            type: Gecko.Field.TYPE_DURATION,
            labeled: true,
            colClass: 'none',
            hideWhen: function hideWhen() {
                return ctrl.workflow.delayType !== 'after';
            }
        }, {
            id: 'endDate',
            label: 'Set an end date',
            type: Gecko.Field.TYPE_TOGGLE,
            colClass: 'none',
            hideWhen: function hideWhen() {
                return ['before', 'after'].indexOf(ctrl.workflow.delayType) !== -1;
            }
        }, {
            id: 'stop_at',
            label: 'Stop at',
            type: Gecko.Field.TYPE_DATETIME,
            colClass: 'none',
            interval: 15,
            hideWhen: function hideWhen() {
                return !ctrl.workflow.endDate || ['before', 'after'].indexOf(ctrl.workflow.delayType) !== -1;
            }
        }, {
            id: 'evaluate_after_delay',
            label: 'Check conditions after the delay',
            type: Gecko.Field.TYPE_TOGGLE,
            colClass: 'none',
            hideWhen: function hideWhen() {
                return ctrl.workflow.delayType === 'immediately';
            }
        }];

        ctrl.footerBtns = [{
            id: 'delay_update',
            title: 'Update',
            icon: 'fa-check',
            btnClass: 'btn-primary',
            action: function action() {
                // Reset all the extra values to make sure nothing gets mis-set
                if (ctrl.workflow.delayType == 'immediately') {
                    ctrl.workflow.delay = 0;
                    ctrl.workflow.schedule = null;
                    ctrl.workflow.before = null;
                    ctrl.workflow.after = null;
                } else if (ctrl.workflow.delayType == 'scheduled') {
                    ctrl.workflow.delay = 0;
                    ctrl.workflow.schedule = Number(ctrl.workflow.schedule);
                    ctrl.workflow.before = null;
                    ctrl.workflow.after = null;
                } else if (['before', 'after'].indexOf(ctrl.workflow.delayType) !== -1) {
                    ctrl.workflow.delay = 0;
                    ctrl.workflow.schedule = null;
                    ctrl.workflow.endDate = null;
                    ctrl.workflow.stop_at = null;
                    if (ctrl.workflow.delayType === 'before') {
                        ctrl.workflow.before = Number(ctrl.workflow.before);
                        ctrl.workflow.after = null;
                    } else {
                        ctrl.workflow.before = null;
                        ctrl.workflow.after = Number(ctrl.workflow.after);
                    }

                    if (ctrl.workflow.before === 0) {
                        ctrl.workflow.before = null;
                    }

                    if (ctrl.workflow.after === 0) {
                        ctrl.workflow.after = null;
                    }
                } else {
                    ctrl.workflow.schedule = null;
                    ctrl.workflow.before = null;
                    ctrl.workflow.after = null;
                }

                ctrl.workflow.stop_at = ctrl.workflow.stop_at && ctrl.workflow.endDate ? Number(ctrl.workflow.stop_at) : null;

                // Close it out and return the value
                $modalInstance.close(ctrl.workflow);
            }
        }];
    }

    angular.module('GeckoEngage').config(WorkflowDelayModal).controller('workflowDelaysModalCtrl', workflowDelaysModalCtrl);
})();