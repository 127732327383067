var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

(function () {
    'use strict';

    // Copied from angular source.

    var nullFormCtrl = {
        $addControl: angular.noop,
        $$renameControl: nullFormRenameControl,
        $removeControl: angular.noop,
        $setValidity: angular.noop,
        $setDirty: angular.noop,
        $setPristine: angular.noop,
        $setSubmitted: angular.noop
    };

    function nullFormRenameControl(control, name) {
        control.$name = name;
    }

    function geckoField($templateRequest, $compile, geckoSanitize, geckoOptions, geckoFieldValueLink, fieldErrors, templateTagReferenceService, geckoTranslateService) {

        var templateBase = '/components/gecko-field/fields/';

        var fieldTemplates = {
            address: 'address.html',
            button: 'button.html',
            checkbox: 'checkbox.html',
            consent: 'consent.html',
            color: 'color.html',
            country: 'country.html',
            date: 'date.html',
            datetime: 'datetime.html',
            daterange: 'date_range.html',
            dateselector: 'date_selector.html',
            duration: 'duration.html',
            link: 'link.html',
            email: 'text.html',
            event: 'event.html',
            file: 'file.html',
            multi: 'multi.html',
            multi_async: 'multi_async.html',
            map: 'map.html',
            matrix: 'matrix.html',
            name: 'name.html',
            noedit: 'noedit.html',
            number: 'number.html',
            panel_options: 'panel_options.html',
            password: 'password.html',
            preferred_language: 'preferred_language.html',
            organisation: 'text.html',
            radio: 'radio.html',
            repeatable: 'repeatable.html',
            richtext: 'richtext.html',
            script: 'script.html',
            schedule: 'schedule.html',
            section: 'section.html',
            select: 'dropdown.html',
            select_async: 'dropdown_async.html',
            stream_details: 'stream_details.html',
            tag_builder: 'tag_builder.html',
            tagger: 'tagger.html',
            tagger_single: 'tagger_single.html',
            tel: 'tel.html',
            text: 'text.html',
            textarea: 'textarea.html',
            tiered_select: 'tiered_dropdown.html',
            time: 'time.html',
            title: 'title.html',
            toggle: 'toggle.html',
            url: 'text.html'
        };

        // Fields that use options.
        var optionsFields = {
            checkbox: true,
            multi: true,
            radio: true,
            select: true
        };

        return {
            restrict: 'E',

            scope: {
                field: '=',
                _values: '=values',
                onChange: '&', // `field` and `value` as locals
                displayMode: '='
            },

            controllerAs: 'ctrl',
            bindToController: true,

            require: ['geckoField', '?^form'],

            link: function link(scope, iElement, iAttrs, ctrls) {
                var ctrl = ctrls[0];
                var formCtrl = ctrls[1] || nullFormCtrl;
                if (!ctrl.field.placeholder) ctrl.field.placeholder = GeckoUI.getDefaultPlaceholder(ctrl.field.type);

                var onChangeData = function onChangeData(value) {
                    return { field: ctrl.field, value: value };
                };

                ctrl.$setDirty = function () {
                    try {
                        formCtrl.$setDirty();
                    } catch (e) {
                        console.log(e);
                    }
                };

                var setterFunc;
                // Use change funciton in setter function. If not defined then use simple setter function
                if (angular.isDefined(iAttrs.onChange) || ctrl.field && angular.isDefined(ctrl.field.onChange)) {

                    ctrl.valueChange = function () {
                        if (angular.isDefined(iAttrs.onChange)) {
                            ctrl.onChange({ field: ctrl.field, value: ctrl.value, element: iElement }); // Trigger change handler with field and value as injectable locals.
                        }
                        if (angular.isDefined(ctrl.field.onChange)) {
                            ctrl.field.onChange({ field: ctrl.field, value: ctrl.value, element: iElement });
                        }
                    };

                    setterFunc = function setterFunc(value) {

                        var valObj = this.overrideObj || this._values;

                        if (valObj[this.field.id] !== value) {
                            valObj[this.field.id] = value;
                            ctrl.valueChange();
                        }
                        geckoFieldValueLink.update(this.field.id, value);
                    };
                } else {
                    setterFunc = function setterFunc(value) {

                        (this.overrideObj || this._values)[this.field.id] = value;
                        geckoFieldValueLink.update(this.field.id, value);
                    };
                }

                // Getter setter for the value
                Object.defineProperty(ctrl, 'value', {
                    get: function get() {
                        return (ctrl.overrideObj || this._values)[this.field.id];
                    },
                    set: setterFunc
                });

                // If id is not specified on field we check for key and copy it over so that objects read a little better.
                if (ctrl.field && !ctrl.field.id && ctrl.field.key) {
                    ctrl.field.id = ctrl.field.key;
                }

                // Register for errors
                if (ctrl.field && ctrl.field.id) {
                    fieldErrors.register(ctrl.field.id, function (error) {
                        ctrl.error = error;
                        console.debug("ctrl.error", ctrl.error);
                        // Set error class on field container
                        if (error && error.length) {
                            ctrl.field.hasError = true;
                            // Scroll to field that has the error
                            document.body.scrollTop = iElement.offset().top;
                        } else {
                            ctrl.field.hasError = false;
                        }
                    });

                    scope.$on('$destroy', function () {
                        fieldErrors.unRegister(ctrl.field.id);
                    });
                }

                // If the field settings override the location to store the value.
                if (ctrl.field && ctrl.field.obj && !ctrl.field.noOverride) {
                    ctrl.overrideObj = ctrl.field.obj;
                }

                // Make sure Value is an array for checkbox type
                if ([Gecko.Field.TYPE_CHECKBOX, Gecko.Field.TYPE_MULTI].indexOf(ctrl.field.type) !== -1) {
                    // Don't want to set this through the setter (ctrl.value) since that would trigger form caching and this is not a user update
                    if (ctrl.value && !angular.isArray(ctrl.value)) {
                        (ctrl.overrideObj || ctrl._values)[ctrl.field.id] = [ctrl.value];
                    } else {
                        (ctrl.overrideObj || ctrl._values)[ctrl.field.id] = ctrl.value || [];
                    }
                    geckoFieldValueLink.update(ctrl.field.id, ctrl.value);
                }

                // Add countries array to ctrl for country field
                if (ctrl.field.type === Gecko.Field.TYPE_COUNTRY) {
                    ctrl.countries = countries;
                }

                // Fill in hidden field value.
                if (ctrl.field.type === Gecko.Field.TYPE_HIDDEN) {
                    // Don't want to set this through the setter (ctrl.value) since that would trigger form caching and this is not a user update
                    var setHiddenValue = function setHiddenValue(value) {
                        if (value && value === ctrl.value) return;
                        (ctrl.overrideObj || ctrl._values)[ctrl.field.id] = ctrl.field.value;
                        geckoFieldValueLink.update(ctrl.field.id, ctrl.value);
                    };

                    setHiddenValue();

                    // Update value when it changes (for FORM BUILDER only)
                    if (ctrl.displayMode) scope.$watch('ctrl.field.value', setHiddenValue);
                }

                // Config for fields that use options lists.
                if (optionsFields[ctrl.field.type]) {
                    geckoOptions.configureField(ctrl.field, scope);
                    geckoFieldValueLink.update(ctrl.field.id, ctrl.value);
                }

                // Consent field
                if (ctrl.field.type === Gecko.Field.TYPE_CONSENT) {
                    // Set consent reasons as field options
                    if (!ctrl.field.options || ctrl.field.options.length) {
                        new Gecko.Consent().perPage('all').get().then(function (reasons) {

                            try {
                                // Check for removed/missing consents options
                                var missing = (ctrl.value || []).filter(function (v) {
                                    return !(ctrl.field.values || []).find(function (o) {
                                        return v.consent === o;
                                    });
                                });
                                // Add missing consent options back to field
                                if (missing.length) ctrl.field.values = ctrl.field.values.concat(missing.map(function (v) {
                                    return v.consent;
                                }));
                            } catch (e) {
                                console.log(e);
                            }

                            ctrl.field.options = reasons.toArray().filter(function (r) {
                                return (ctrl.field.values || []).indexOf(r.id) !== -1;
                            });
                            // Set value
                            ctrl.buildConsentValue(ctrl.field.options, false);
                            scope.$digest();
                        });
                    }

                    // Build value for displaying
                    ctrl.buildConsentValue = function (reasons, save) {
                        if (save) {
                            ctrl.value = reasons.map(function (reason) {
                                return {
                                    consent: reason.id,
                                    granted: ctrl._value[reason.id] || null
                                };
                            });
                        } else {
                            ctrl._value = {};
                            reasons.forEach(function (r) {
                                var value = (ctrl.value || []).find(function (c) {
                                    return c.consent === r.id;
                                }) || {};
                                ctrl._value[r.id] = value.granted || null;
                            });
                        }
                    };
                }

                if (ctrl.field.type === Gecko.Field.TYPE_MATRIX) {
                    ctrl.value = {
                        data: {},
                        structure: {
                            fields: ctrl.field.values,
                            sub_fields: ctrl.field.sub_fields
                        }
                    };
                }

                // Add a class based on the field type to help with styling.
                if (ctrl.field.type) {
                    iElement.addClass('gecko-field-' + ctrl.field.type);
                }

                // Add a class for required fields.
                if (ctrl.field.required) {
                    iElement.addClass('field-required');
                }

                // Determine the field template to use
                // Calculated fields are always uneditable
                var templateUrl;
                if (ctrl.field.is_calculated && !ctrl.displayMode && ctrl.field.type !== Gecko.Field.TYPE_ORGANISATION) {
                    templateUrl = templateBase + fieldTemplates.noedit;
                }
                // When we have an options function check for an async template
                else if (typeof ctrl.field.getOptions === 'function' && fieldTemplates[ctrl.field.type + '_async']) {
                        templateUrl = templateBase + fieldTemplates[ctrl.field.type + '_async'];
                    }
                    // Get the type from the field.
                    else if (fieldTemplates[ctrl.field.type]) {
                            templateUrl = templateBase + fieldTemplates[ctrl.field.type];
                        }
                        // Default to text type field.
                        else {
                                templateUrl = templateBase + fieldTemplates.text;
                            }

                $templateRequest(templateUrl, true).then(function (template) {

                    // Set the template as the content of the element.
                    iElement.html(template);

                    iElement.append('<p class="help-block error" ng-if="ctrl.error">{{ctrl.error}}</p>');

                    // Optionally change label of empty option for dropdown
                    if (ctrl.field.type === Gecko.Field.TYPE_SELECT && ctrl.field.blankLabel) {
                        iElement.find('option[value=""]').text(ctrl.field.blankLabel);
                    }
                    // Optionally remove empty option for dropdown
                    if ([Gecko.Field.TYPE_SELECT, Gecko.Field.TYPE_PREFERRED_LANGUAGE].indexOf(ctrl.field.type) !== -1 && ctrl.field.noBlank) {
                        iElement.find('option[value=""]').remove();
                        if (ctrl.field.options && ctrl.field.options.length && (ctrl.value === undefined || ctrl.value === null)) {
                            var option = ctrl.field.options[0];
                            ctrl.value = option[ctrl.field.optionsKey] != undefined ? option[ctrl.field.optionsKey] : option;
                        }
                    }

                    // Add desciption text to the end of a field.
                    if (ctrl.field.description) {
                        iElement.append('<p class="description">' + geckoSanitize(ctrl.field.description, {
                            ADD_ATTR: ['target', 'ui-sref', 'ng-non-bindable']
                        }) + '</p>');
                    }
                    // Add readonly attr to textarea (if set)
                    if ([Gecko.Field.TYPE_TEXTAREA, Gecko.Field.TYPE_TEXT].indexOf(ctrl.field.type) !== -1 && ctrl.field.readonly) {
                        if ([Gecko.Field.TYPE_TEXTAREA].indexOf(ctrl.field.type) !== -1) {
                            iElement.find('textarea').attr('readonly', true);
                        }
                        if ([Gecko.Field.TYPE_TEXT].indexOf(ctrl.field.type) !== -1) {
                            iElement.find('input').attr('readonly', true);
                        }
                    }

                    // Compile it amd link it to the isolated scope.
                    $compile(iElement.contents())(scope);
                });

                // Handle date types

                switch (ctrl.field.data_type) {
                    case 'integer':
                        ctrl.value = parseInt(ctrl.value, 10);
                        break;
                }

                // Create a function to make filter objects for options search
                ctrl.optionsFilter = function (search) {
                    var filter = {};

                    if (ctrl.field.optionsKey) {
                        filter[ctrl.field.optionsLabelKey] = search;
                    } else {
                        filter = search;
                    }

                    return filter;
                };

                ctrl.getOptions = function (select) {
                    if (typeof ctrl.field.getOptions === 'function') ctrl.field.getOptions(select, scope);
                };

                ctrl.getTemplateUrl = function (type) {
                    return templateBase + 'select-templates/' + ctrl.field[type] + '.html';
                };

                ctrl.validateNumber = function () {
                    if (ctrl.field.minimum !== undefined && ctrl.value < ctrl.field.minimum) {
                        ctrl.value = ctrl.field.minimum;
                    }
                    if (ctrl.field.maximum !== undefined && ctrl.value < ctrl.field.maximum) {
                        ctrl.value = ctrl.field.maximum;
                    }
                };

                // Default tag models (Tag builder fields)
                if (ctrl.field.type === Gecko.Field.TYPE_TAG_BUILDER && !ctrl.field.models) {
                    ctrl.field.models = [Gecko.Template.TAG_FORMS, Gecko.Template.TAG_RESPONSES, Gecko.Template.TAG_USERS, Gecko.Template.TAG_EVENTS, Gecko.Template.TAG_CONTACT, Gecko.Template.TAG_SENDER];
                }

                if (ctrl.field.type === Gecko.Field.TYPE_MULTI) {
                    ctrl.multiOptions = {
                        valueField: ctrl.field.optionsKey,
                        labelField: ctrl.field.optionsLabelKey,
                        searchField: ctrl.field.optionsLabelKey,
                        sortField: ctrl.field.orderBy || ctrl.field.optionsLabelKey,
                        create: ctrl.field.config && ctrl.field.config.create ? ctrl.field.config.create : false,
                        maxItems: ctrl.field.maxItems || Infinity,
                        placeholder: ctrl.field.placeholder || '-'
                    };
                }

                // Add ref to templateTagReferenceService
                if (ctrl.field.type === Gecko.Field.TYPE_TEXTAREA && ctrl.field.templateTagBuilderRef) {
                    // Set textarea ref
                    templateTagReferenceService.textarea.ref[ctrl.field.templateTagBuilderRef] = 'field_' + ctrl.field.id;
                    // Remove textarea ref when its no longer on the dom
                    scope.$on('$destroy', function () {
                        delete templateTagReferenceService.textarea.ref[ctrl.field.templateTagBuilderRef];
                    });
                }

                if (ctrl.field.type === Gecko.Field.TYPE_RICHTEXT) {
                    var richtextPlugins = 'autoresize link image code table hr textcolor lists colorpicker charmap paste';
                    if (ctrl.field.format === 'fullpage') {
                        richtextPlugins += ' fullpage';
                    }

                    // Toolbar
                    if (ctrl.field.config && ctrl.field.config.toolbar === 'simple') {
                        var richtextToolbar = 'removeformat | formatselect | bold italic underline | link';
                    } else {
                        var richtextToolbar = 'code | removeformat | undo redo | formatselect | bold italic underline strikethrough forecolor forecolorpicker | fontselect | fontsizeselect | bullist numlist indent outdent | alignleft aligncenter alignright alignjustify | image link table hr charmap | pastetext';
                    }

                    // https://stackoverflow.com/questions/12247339/how-to-enable-font-family-and-color-options-in-tinymce-editor
                    ctrl.richtextOptions = {
                        format: 'raw',
                        plugins: richtextPlugins,
                        toolbar: richtextToolbar,
                        cleanup: false,
                        verify_html: false,
                        menubar: false,
                        valid_children: "+body[style]",
                        convert_urls: false,
                        relative_urls: false,
                        remove_script_host: false,
                        readonly: ctrl.field.disabledWhen ? ctrl.field.disabledWhen() : 0,
                        init_instance_callback: function init_instance_callback(editor) {
                            // https://stackoverflow.com/questions/28983735/how-can-i-prevent-tinymce-from-adding-cdata-to-script-tags-and-from-commenting/41015569
                            editor.serializer.addNodeFilter('script,style', function (nodes, name) {
                                var i = nodes.length,
                                    node,
                                    value,
                                    type;

                                function trim(value) {
                                    return value.replace(/(<!--\[CDATA\[|\]\]-->)/g, '\n').replace(/^[\r\n]*|[\r\n]*$/g, '').replace(/^\s*((<!--)?(\s*\/\/)?\s*<!\[CDATA\[|(<!--\s*)?\/\*\s*<!\[CDATA\[\s*\*\/|(\/\/)?\s*<!--|\/\*\s*<!--\s*\*\/)\s*[\r\n]*/gi, '').replace(/\s*(\/\*\s*\]\]>\s*\*\/(-->)?|\s*\/\/\s*\]\]>(-->)?|\/\/\s*(-->)?|\]\]>|\/\*\s*-->\s*\*\/|\s*-->\s*)\s*$/g, '');
                                }
                                while (i--) {
                                    node = nodes[i];
                                    value = node.firstChild ? node.firstChild.value : '';

                                    if (value.length > 0) {
                                        node.firstChild.value = trim(value);
                                    }
                                }
                            });

                            if (ctrl.field.templateTagBuilderRef) {
                                // Set editor ref
                                templateTagReferenceService.richtext.ref[ctrl.field.templateTagBuilderRef] = editor;
                                // Remove editor ref when its no longer on the dom
                                editor.on('remove', function () {
                                    delete templateTagReferenceService.richtext.ref[ctrl.field.templateTagBuilderRef];
                                });
                            }
                        }
                    };
                }

                // (Textarea) Character Counter
                if (ctrl.field.type === Gecko.Field.TYPE_TEXTAREA && ctrl.field.counter) {
                    scope.$watch('ctrl.value', function (val, oldVal) {
                        if (val !== oldVal) ctrl.currentCounterCount = String(ctrl.value).length;
                    }, true);
                    ctrl.currentCounterCount = String(ctrl.value).length;
                }

                if (ctrl.field.type === Gecko.Field.TYPE_TAGGER) {
                    ctrl.taggingLabel = ctrl.field.taggingLabel !== undefined ? ctrl.field.taggingLabel : '(add new)';
                    ctrl.closeOnSelect = ctrl.field.closeOnSelect || false;
                }

                // Tagger field: Add new item util fn
                if (ctrl.field.type === Gecko.Field.TYPE_TAGGER && typeof (ctrl.field || {}).newItem === 'function') {
                    var taggerOptions = ctrl.field.options || [];
                    var taggerOptionsKey = ctrl.field.optionsKey || 'id';
                    var taggerOptionsLabelKey = ctrl.field.optionsLabelKey || 'title';
                    var taggerToLowerCase = function taggerToLowerCase(string) {
                        return String(string).toLowerCase();
                    };

                    var newItemFilterFn = function newItemFilterFn(option, string) {
                        if (typeof option !== 'string') {
                            return taggerToLowerCase(option[taggerOptionsLabelKey]) === taggerToLowerCase(string);
                        }
                        return taggerToLowerCase(option) === taggerToLowerCase(string);
                    };
                    ctrl.newItem = function (string) {
                        if (!taggerOptions.find(function (o) {
                            return newItemFilterFn(o, string);
                        })) {
                            return ctrl.field.newItem(string);
                        }
                    };
                    // Add to options list when a new item is created
                    ctrl.onTaggerSelect = function ($item, $model, $select) {
                        if (!taggerOptions.find(function (o) {
                            return taggerToLowerCase(o || {}[taggerOptionsLabelKey]) === taggerToLowerCase($item[taggerOptionsLabelKey]);
                        })) {
                            taggerOptions.push($item);

                            if (typeof ctrl.field.onSelect === 'function') {
                                ctrl.field.onSelect($item, $select);
                            }
                        }
                    };
                    // Remove from options list when the item was new
                    ctrl.onTaggerRemove = function ($item, $select) {
                        if (Number($item[taggerOptionsKey]) < 0) {
                            taggerOptions = taggerOptions.filter(function (o) {
                                return o[taggerOptionsKey] !== $item[taggerOptionsKey];
                            });
                        }

                        if (typeof ctrl.field.onRemove === 'function') {
                            ctrl.field.onRemove($item, $select);
                        }
                    };
                    // Filter out selected options from the options list
                    ctrl.omitSelected = function (option) {
                        try {
                            var opt = (typeof option === 'undefined' ? 'undefined' : _typeof(option)) === 'object' ? option[taggerOptionsKey] : option;
                            if (Array.isArray(ctrl.value) && _typeof(ctrl.value[0]) === 'object') {
                                var value = (ctrl.value || []).map(function (v) {
                                    return v[taggerOptionsKey];
                                });
                            } else {
                                var value = ctrl.value;
                            }
                            return value.indexOf(opt) === -1;
                        } catch (err) {
                            return option;
                        }
                    };
                }

                // Preferred Language (Show ALL)
                if (ctrl.field.type === Gecko.Field.TYPE_PREFERRED_LANGUAGE) {
                    ctrl.languageOptions = geckoTranslateService.languages;
                }
            },

            controller: angular.noop

        };
    }

    angular.module('GeckoEngage').directive('geckoField', geckoField);
})();