(function () {
    'use strict';

    function contactConsents() {
        return {
            restrict: 'E',
            template: '\n                <div class="contact-consents" fields="ctrl.fields" values="ctrl">\n                    \n                    <div ng-if="!ctrl.reasons" class="alert alert-info contact-consents-loading">\n                        Currently loading the list of available options, please wait\n                    </div>\n\n                    <div ng-if="ctrl.reasons && !ctrl.reasons.length" class="alert alert-info contact-consents-no-options">\n                        No consent options have been configured for this field \n                    </div>\n\n                    <gecko-fields \n                        ng-if="ctrl.reasons && ctrl.reasons.length"\n                        fields="ctrl.fields" \n                        values="ctrl">\n                    </gecko-fields>\n\n                    <div class="contact-consents-footer text-right" ng-if="ctrl.reasons && ctrl.reasons.length">\n                        <gecko-btn\n                            btn-title="{{ctrl.footerBtns[0].title}}"\n                            preset="{{ctrl.footerBtns[0].preset}}"\n                            ng-click="ctrl.footerBtns[0].action()"">\n                        </gecko-btn>\n                    </div>\n\n                </div>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {
                contactId: '='
            },
            controller: contactConsentsCtrl
        };
    }

    function contactConsentsCtrl($scope, Gecko) {
        var ctrl = this;

        ctrl.reasons = false;
        ctrl.contact = null;
        ctrl.values = [];
        ctrl.fields = [];

        ctrl.footerBtns = [{
            title: 'Update Consents',
            preset: 'check',
            action: function action() {
                return ctrl.saveConsents();
            }
        }];

        ctrl.buildValues = function (reasons, values, save) {
            // Value for saving
            if (save) {
                values = values.map(function (v) {
                    return v !== null ? Number(v) : null;
                });
                return reasons.map(function (reason) {
                    return {
                        consent: reason.id,
                        granted: values.indexOf(reason.id) !== -1 ? true : null
                    };
                });
            }
            // Value for displaying
            values = values.map(function (v) {
                return v.granted ? v.consent : null;
            });
            return reasons.map(function (r) {
                // This has to be a string for GeckoField issues
                return values.indexOf(r.id) !== -1 ? String(r.id) : null;
            });
        };

        ctrl.buildFields = function (reasons) {
            return [{
                id: 'values',
                type: Gecko.Field.TYPE_CHECKBOX,
                colClass: 'none',
                optionsKey: 'id',
                optionsLabelKey: 'title',
                options: reasons || []
            }];
        };

        ctrl.saveConsents = function () {
            // Build saveable consent values 
            ctrl.contact.consent_data = ctrl.buildValues(ctrl.reasons, ctrl.values, true);

            ctrl.contact.save().then(function () {
                GeckoUI.messenger.success('Updated contact\'s consents');
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);
            });
        };

        ctrl.getData = function () {
            var _query = {};
            // Consent options
            _query.consents = new Gecko.Consent().rfields({ consent: ['title'] }).perPage('all');
            // Contact consent values
            if (ctrl.contactId) _query.contact = new Gecko.Contact().rfields({ contact: ['consent_data'] }).path(ctrl.contactId);

            return new Gecko.multi(_query);
        };

        $scope.$watch('ctrl', function () {

            ctrl.getData().then(function (data) {
                ctrl.reasons = data.consents.toArray();
                ctrl.contact = data.contact;
                ctrl.fields = ctrl.buildFields(ctrl.reasons);

                // TEMP (will come from data obj)
                // ctrl.contact.consent_data = [{consent: 4, granted: true}, {consent: 10, granted: true}];
                ctrl.values = ctrl.buildValues(ctrl.reasons, ctrl.contact.consent_data);
            }).catch(function (e) {
                return console.log(e);
            });
        });
    }

    angular.module('GeckoEngage').directive('contactConsents', contactConsents).controller('contactConsentsCtrl', contactConsentsCtrl);
})();