(function () {
    'use strict';

    var store = {};
    var storeByIds = {};
    var lastFetched = {};
    var limit = 10000;
    // Used with fetchAllOptions
    var optionsAggregate = [];

    var integrationsQuery = function integrationsQuery() {
        var types = [Gecko.Integration.TYPE_CONNECT, Gecko.Integration.TYPE_DOTMAILER, Gecko.Integration.TYPE_EMS, Gecko.Integration.TYPE_NEW_DYNAMICS, Gecko.Integration.TYPE_RADIUS, Gecko.Integration.TYPE_SALEFORCE, Gecko.Integration.TYPE_WEBHOOK, Gecko.Integration.TYPE_ENGAGE_DYNAMICS, Gecko.Integration.TYPE_NOTTINGHAM];
        return new Gecko.Integration().where('type', types).rfields({ 'integration': ['title', 'title_with_date', 'type', 'id', 'created_at'] }).orderBy('type').perPage(limit);
    };

    var fieldsQuery = function fieldsQuery(type) {
        return new Gecko.Field().rfields({ field: ['label', 'type', 'field_type', 'data_type', 'option_id', 'order', 'is_calculated', 'is_social', 'is_uneditable', 'system', 'values'] }).where('field_type', type || Gecko.Field.FIELD_TYPE_CONTACT).perPage(limit);
    };

    var fieldListQuery = function fieldListQuery(type) {
        var obj = new Gecko.Field();
        obj.base_slug = '/fields/list_view';
        return obj.rfields({ field: ['label', 'type', 'data_type', 'system'] }).where('view_type', type || Gecko.Field.FIELD_TYPE_CONTACT);
    };

    function geckoDataService($rootScope) {
        var _this = {

            queries: {
                campaigns: new Gecko.Campaign().rfields({ campaign: ['title', 'module'] }).orderBy('title').perPage(limit),
                campaigns_with_outcomes: new Gecko.Campaign().rfields({ campaign: ['title', 'module'] }).include('outcomes').orderBy('title').perPage(limit),
                categories: new Gecko.Category().where('type', Gecko.Category.TYPE_EVENT).orderBy('title').perPage(limit),
                channels: function () {
                    return Object.assign(new Gecko.BaseModel(), { object_key: 'channels', base_slug: '/chat_channels' });
                }().where('orderBy', 'name').where('sort', 'ASC').where('perPage', limit),
                teams: function () {
                    return Object.assign(new Gecko.BaseModel(), { object_key: 'teams', base_slug: '/chat_teams' });
                }().where('orderBy', 'name').where('sort', 'ASC').where('perPage', limit),
                chat_channels: function () {
                    return Object.assign(new Gecko.BaseModel(), { object_key: 'chat_channels', base_slug: '/geckochat/channels' });
                }(),
                consents: new Gecko.Consent().rfields({ consent: ['title'] }).orderBy('title').perPage(limit),
                contact_fields: fieldsQuery(),
                devices: new Gecko.Device().rfields({ device: ['label', 'udid'] }).perPage(limit),
                fields: fieldsQuery(),
                field_list_contacts: fieldListQuery(Gecko.Field.FIELD_TYPE_CONTACT),
                forms: new Gecko.Form().rfields({ form: ['name', 'internal_name', 'module'] }).orderBy('name').perPage(limit),
                hosts: new Gecko.Host().rfields({ host: ['name', 'email'] }).orderBy('name').perPage(limit),
                imports: new Gecko.Import().rfields({ import: ['title', 'import_to'] }).orderBy('title').perPage(limit),
                integrations: integrationsQuery(),
                labels: new Gecko.Label().rfields({ label: ['name', 'color'] }).orderBy('name').perPage(limit),
                locations: new Gecko.Location().rfields({ location: ['title'] }).orderBy('title').perPage(limit),
                organisations: new Gecko.Organisation().rfields({ organisation: ['title'] }).perPage(limit),
                organisation_types: new Gecko.OrganisationType().rfields({ 'organisationtype': ['title'] }).perPage(limit),
                organisation_fields: fieldsQuery(Gecko.Field.FIELD_TYPE_ORGANISATION),
                options: new Gecko.Option().rfields({ option: ['name', 'values'] }).perPage(limit),
                outcomes: new Gecko.Outcome().rfields({ outcome: ['name', 'group'] }).orderBy('name').perPage(limit),
                senders: new Gecko.Sender().include('verified_domain').rfields({ sender: ['type', 'name', 'email', 'tel', 'outgoing_sms', 'verified', 'chat_channel_id'] }).orderBy('name').perPage(limit),
                tasks: new Gecko.Task().perPage(limit),
                templates: new Gecko.Template().where('type', [Gecko.Template.TYPE_EMAIL, Gecko.Template.TYPE_SMS]).rfields({ template: ['name', 'type'] }).orderBy('name').perPage(limit),
                user_groups: new Gecko.Group().rfields({ group: ['name', 'type'] }).orderBy('name').perPage(limit),
                users: new Gecko.User().rfields({ user: ['full_name', 'email', 'telephone'] }).orderBy('full_name').perPage(limit),
                widgets: function () {
                    return Object.assign(new Gecko.BaseModel(), { object_key: 'widgets', base_slug: '/chat_widgets' });
                }().where('orderBy', 'name').where('sort', 'ASC').where('perPage', limit)
            },
            callbacks: {
                fields: function fields(_fields) {
                    var fn = function fn() {
                        if (storeByIds['options']) {
                            _fields.filter(function (f) {
                                return f.option_id;
                            }).forEach(function (f) {
                                f.option = storeByIds['options'][f.option_id] || null;
                            });
                            try {
                                $rootScope.$digest();
                            } catch (err) {}
                        }
                    };
                    return store['options'] ? fn() : _this.fetch(['options']).then(fn);
                },
                options: function options(_options) {
                    // if (store['fields']) {
                    //     store['fields'].filter(f => f.option_id).forEach(f => {
                    //         f.option = storeByIds['options'][f.option_id] || null;
                    //     });
                    // }
                }
            },
            fetchAllOptions: function fetchAllOptions() {
                var from = 0;
                var to = limit;

                // Unset aggregate before fetching options
                optionsAggregate = [];

                // Only redo after 5 minutes
                if (!_this.shouldFetchAgain('options')) return Promise.resolve([]);

                return _this.fetchOptionsChunk(from, to).catch(function (err) {
                    throw { error: err, successful_options: optionsAggregate };
                }).finally(function () {
                    // Extract Arrays (from GeckoJS)
                    var optionsStored = _this.setStore('options', optionsAggregate);
                    // Add new data to storeByIds Obj
                    _this.setStoreByIds('options', optionsStored);
                    // Set model lastFetched
                    _this.setLastFetched('options');

                    return optionsAggregate;
                });
            },
            fetchOptionsChunk: function fetchOptionsChunk(from, to) {
                return _this.optionsQuery(from, limit).then(function (res) {
                    var nextData = res.data;
                    var nextFrom = res.to;

                    // End of the line...stop chunking
                    if (!Array.isArray(nextData) || nextData.length === 0) {
                        return Promise.resolve(optionsAggregate);
                    }

                    optionsAggregate = [].concat(optionsAggregate, nextData);

                    // Fetch the next chunk
                    return _this.fetchOptionsChunk(nextFrom, to);
                }).catch(function (err) {
                    throw err;
                });
            },
            optionsQuery: function optionsQuery(from, to) {
                return new Gecko.Option().rfields({ option: ['name', 'values'] }).orderBy('created_at', 'ASC').call('/options', 'GET', { chunked: from + ',' + to }, true);
            },

            fetchFromStore: function fetchFromStore(models) {
                // Return empty obj when FALSY
                if (!models) return Promise.resolve({});
                // Convert to array when string
                if (typeof models === 'string') models = [models];

                var existingData = {};

                (models || []).forEach(function (model) {
                    if (store[model]) {
                        existingData[model] = store[model];
                    }
                });

                return Promise.resolve(existingData);
            },

            fetchFromStoreSync: function fetchFromStoreSync(models) {
                // Return empty obj when FALSY
                if (!models) return {};
                // Convert to array when string
                if (typeof models === 'string') models = [models];

                var existingData = {};

                (models || []).forEach(function (model) {
                    if (store[model]) {
                        existingData[model] = store[model];
                    }
                });

                return existingData;
            },

            fetch: function fetch(models, reset) {
                // Return empty obj when FALSY
                if (!models) return Promise.resolve({});
                // Convert to array when string
                if (typeof models === 'string') models = [models];

                // Precaution! ALWAYS remove "options" from models
                if (models.includes('options')) {
                    models = models.filter(function (model) {
                        return model !== 'options';
                    });
                }

                var existingData = {};
                var queries = {};

                (models || []).forEach(function (model) {
                    // Retrieve from store if set (or hasnt been fetched for a while)
                    if (store[model] && !_this.shouldFetchAgain(model) && !reset) {
                        existingData[model] = store[model];
                        // Add fetch query when not in the store
                    } else {
                        queries[model] = _this.queries[model];
                    }
                });

                var cbFn = function cbFn(data) {
                    Object.keys(data).forEach(function (model) {
                        if (_this.callbacks[model]) _this.callbacks[model](store[model]);
                    });
                    return data;
                };

                if (Object.keys(queries).length) {
                    return Gecko.multi(queries).then(function (data) {
                        try {
                            Object.keys(data).forEach(function (model) {
                                // Extract Arrays (from GeckoJS)
                                data[model] = _this.setStore(model, data[model]);
                                // Add new data to storeByIds Obj
                                _this.setStoreByIds(model, data[model]);
                                // Add new data to store obj
                                store[model] = data[model];
                                // Set model lastFetched
                                _this.setLastFetched(model);
                            });
                            // Return data
                            return Object.assign({}, existingData, data);
                        } catch (e) {
                            console.log(e);
                        }
                        // Run custom callback for model after ALL models are added to the store
                    }).then(cbFn);
                }

                return Promise.resolve(existingData).then(cbFn);
            },

            setStore: function setStore(model, data) {
                if (!store[model]) store[model] = [];
                store[model] = data.toArray ? data.toArray() : data;
                return store[model];
            },

            setStoreByIds: function setStoreByIds(model, data) {
                if (!storeByIds[model]) storeByIds[model] = {};
                data.forEach(function (item) {
                    var existingItem = storeByIds[model][item.id] || {};
                    storeByIds[model][item.id] = Object.assign({}, existingItem, item);
                });
                return storeByIds[model];
            },

            setLastFetched: function setLastFetched(model) {
                lastFetched[model] = Number(moment.utc().format('X'));
            },

            shouldFetchAgain: function shouldFetchAgain(model) {
                if (!lastFetched[model]) return true;
                // Wait 5 minutes
                var waitOffset = 5 * 60;
                return Number(moment.utc().format('X')) > lastFetched[model] + waitOffset;
            },

            updateItem: function updateItem(model, obj) {
                if (!storeByIds[model]) storeByIds[model] = {};

                if (storeByIds[model][obj.id]) {
                    storeByIds[model][obj.id] = Object.assign({}, storeByIds[model][obj.id], obj);
                    // Generate flat array
                    store[model] = Object.keys(storeByIds[model]).map(function (key) {
                        return storeByIds[model][key];
                    });
                } else {
                    storeByIds[model][obj.id] = obj;
                    store[model].push(obj);
                }
            },

            removeItem: function removeItem(model, id) {
                if (!storeByIds[model]) return;
                if (!storeByIds[model][id]) return;
                // Remove from store and storeById
                delete storeByIds[model][id];
                store[model] = store[model].filter(function (i) {
                    return i.id !== id;
                });
            }

        };

        return _this;
    }

    angular.module('GeckoEngage').factory('geckoDataService', geckoDataService);
})();