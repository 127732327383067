
(function () {
    'use strict';

    function locationFields() {
        return {
            restrict: 'E',
            template: '\n            \t<gecko-fields fields="ctrl.fields" values="ctrl.location"></gecko-fields>\n            ',
            scope: {
                location: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($scope) {
                var _this = this;

                var ctrl = this;

                $scope.$watch('ctrl', function () {

                    //Build map value obj
                    if (!ctrl.location.map_config) {
                        ctrl.location.map_config = {};
                        // Build map obj from lat/lng
                        if (ctrl.location.lat && ctrl.location.lng) {
                            ctrl.location.map_config.latitude = ctrl.location.lat;
                            ctrl.location.map_config.longitude = ctrl.location.lng;
                        }
                    }

                    ctrl.update = function (data) {
                        var location = (data || {}).value;

                        if (location) {
                            // Check location is already not set
                            if (_this.location.lat == (location.latitude || 0).toFixed(8) && _this.location.lng == (location.longitude || 0).toFixed(8)) return;
                            // Update location model properties
                            _this.location.lat = location.latitude;
                            _this.location.lng = location.longitude;
                            _this.location.google_place_id = location.place_id;
                            _this.location.address = (location.place || {}).formatted_address;
                        } else {
                            _this.location.lat = null;
                            _this.location.lng = null;
                            _this.location.google_place_id = null;
                            _this.location.address = null;
                        }
                    };

                    // Field list
                    ctrl.fields = [{
                        id: 'title',
                        label: 'Title',
                        required: true
                    }, {
                        id: 'map_config',
                        label: 'Select Location',
                        description: 'Search or click on the map to select the desired location. You can click and hold to pan around and scroll up or down to zoom.',
                        type: 'map',
                        onChange: ctrl.update.bind(ctrl)
                    }, {
                        id: 'address',
                        label: 'Display Address',
                        type: Gecko.Field.TYPE_TEXTAREA,
                        hideWhen: function hideWhen() {
                            return !ctrl.location.lat || !ctrl.location.lng;
                        },
                        required: true
                    }, {
                        id: 'lat',
                        label: 'Latitude',
                        hideWhen: function hideWhen() {
                            return true;
                        }
                    }, {
                        id: 'lng',
                        label: 'Longitude',
                        hideWhen: function hideWhen() {
                            return true;
                        }
                        // }, {
                        //     id : 'map_url',
                        //     label: 'Map Url',
                    }];
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('locationFields', locationFields);
})();