(function () {
    'use strict';

    var ONLINE_TYPE_HOSTED = 'online_type_hosted';
    var ONLINE_TYPE_WEBINAR = 'online_type_webinar';

    var EVENT_LINK_TYPE_PORTAL = 'student_portal';
    var EVENT_LINK_TYPE_RSVP = 'rsvp';

    function EventSettingsCtrl($scope, $stateParams, $state, $geckoModal, event, categories, locations, hosts, integrations, widgets, unsavedAlert, fieldErrors, eventRfields, geckoDates, channels) {
        var ctrl = this;
        ctrl.countries = countries;
        ctrl.event = event;
        ctrl.locations = locations;
        ctrl.hosts = hosts;
        ctrl.integrations = integrations;
        ctrl.channels = channels;
        ctrl.widgets = widgets;

        if (!ctrl.event.type) {
            ctrl.event.type = Gecko.Event.TYPE_EVENT;
        }
        if (!ctrl.event.integration_syncs) {
            ctrl.event.integration_syncs = {};
        }
        if (!ctrl.event.delivery_method) {
            ctrl.event.delivery_method = Gecko.Event.DELIVERY_METHOD_VENUE;
        }

        ctrl.eventCanHaveGuests = !!ctrl.event.maximum_guests;

        // Prepare epochs
        ctrl.event.start_date = geckoDates.toEpoch(ctrl.event.start_datetime);
        ctrl.event.end_date = geckoDates.toEpoch(ctrl.event.end_datetime);
        ctrl.event.closing_date = geckoDates.toEpoch(ctrl.event.closing_datetime);

        // Set default color
        if (!ctrl.event.color) ctrl.event.color = Gecko.account.color;

        if ($stateParams.event_id === 'new') {
            // Set allow_clashing_sessions to true by default if no property is set
            if (!ctrl.event.allow_clashing_sessions) ctrl.event.allow_clashing_sessions = true;
        }

        // Hoist video data
        if (ctrl.event.video && !ctrl.event.video_details) {
            ctrl.event.video_details = ctrl.event.video.details;
        }

        // Set online type
        if (ctrl.event.delivery_method === Gecko.Event.DELIVERY_METHOD_ONLINE) {
            if (Gecko.able(Gecko.ABILITY_EVENTS_VIDEO_STREAMING)) {
                if (ctrl.event.video || !(ctrl.event.webinar_url || ctrl.event.webinar_description)) {
                    ctrl.event.online_type = ONLINE_TYPE_HOSTED;
                } else {
                    ctrl.event.online_type = ONLINE_TYPE_WEBINAR;
                }
            } else {
                ctrl.event.online_type = ONLINE_TYPE_WEBINAR;
            }
        } else {
            ctrl.event.online_type = null;
        }

        // Invert SHOW/HIDE video chat setting (for UI purposes)
        ctrl.event.enable_video_chat = ctrl.event.disable_video_chat ? 0 : 1;

        function integrationsToSync() {
            return Object.keys(ctrl.event.integration_syncs);
        }
        // Pre-filling a new event with relevent data
        if ($stateParams.event_id === 'new') {
            // Set timezone to the account timezone
            ctrl.event.timezone = Gecko.account.timezone;

            // Set country to default account country (if it can be matched)
            var accountCountry = GeckoUI.gobk(Gecko.countryCodes, 'iso2', Gecko.account.default_country);
            if (accountCountry && accountCountry.name) {
                ctrl.event.country = accountCountry.name.replace(/\s\(.+\)/g, '');
            }
        }

        // Create individual key/value pairs on the ctrl event for each entry in ctrl.event.integration_syncs
        if (integrationsToSync().length > 0) {
            var int_ids = integrationsToSync();
            for (var i = 0; i < int_ids.length; i++) {
                var key = '_integration_syncs_' + int_ids[i];
                var value = ctrl.event.integration_syncs[int_ids[i]];
                ctrl.event[key] = value;
            }
        }

        // Parse categories
        var prepareCategory = function prepareCategory(category) {
            category.optgroup = 'Existing Categories';
            return category;
        };
        ctrl.categories = categories.map(prepareCategory);

        if (!ctrl.event.registration_type) {
            ctrl.event.registration_type = Gecko.Event.TYPE_SINGLE_EVENT;
        }
        ctrl.event.categories = ctrl.event.related_categories ? ctrl.event.related_categories.map(function (cat) {
            return cat.toObject();
        }) : [];

        // Parse hosts
        ctrl.event.hosts = (ctrl.event.related_hosts || []).map(function (s) {
            return s.id;
        });

        ctrl.event.self_checkin = ctrl.event.checkin_start === undefined || ctrl.event.checkin_start === null ? 0 : 1;

        ctrl.openHostModal = function () {
            return $geckoModal.addHostModal(new Gecko.Host()).result.then(function (host) {
                // Update options
                ctrl.hosts.push({ id: host.id, email: host.email, name: host.name });
                ctrl.customisationFields.find(function (f) {
                    return f.id === 'hosts';
                }).options = ctrl.hosts;
                // Update hosts array on event
                ctrl.event.hosts = (ctrl.event.hosts || []).concat(host.id);
                // Hacky fix, re-render the dropdown :(
                ctrl.hostsAreBeingUpdated = true;
                setTimeout(function () {
                    ctrl.hostsAreBeingUpdated = false;
                });
                // Re-add open host modal listener (the dom has refreshed)
                addHostModalFn();
            }).catch(console.error);
        };

        var addHostModalFn = function addHostModalFn() {
            setTimeout(function () {
                angular.element('#openHostModal').on('click', function () {
                    ctrl.openHostModal();
                });
            }, 300);
        };

        ctrl.openLocationModal = function () {
            return $geckoModal.addLocationModal(new Gecko.Location()).result.then(function (location) {
                // Update options
                ctrl.locations.push(location);
                // Update location variable on event
                ctrl.event.location_id = location.id;
            }).catch(console.error);
        };

        var addLocationModalFn = function addLocationModalFn() {
            setTimeout(function () {
                angular.element('#openLocationModal').on('click', function () {
                    ctrl.openLocationModal();
                });
            }, 300);
        };

        var catId = -1;
        ctrl.detailFields = [{
            id: 'title',
            label: 'Event Name',
            colClass: 'col-xs-12',
            required: true
        }, {
            id: 'internal_title',
            label: 'Internal Event Name',
            colClass: 'col-xs-12'
        }, {
            label: 'Time and Date',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_TITLE
        }, {
            id: 'start_date',
            label: 'Start Date',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_DATETIME,
            required: true,
            utc: true
        }, {
            id: 'end_date',
            label: 'End Date',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_DATETIME,
            required: true,
            utc: true
        }, {
            type: Gecko.Field.TYPE_SELECT,
            noBlank: true,
            id: 'timezone',
            label: 'Timezone',
            options: timezones,
            optionsKey: 'id',
            optionsLabelKey: 'title',
            colClass: 'col-xs-12'
        }, {
            id: 'closing_date',
            label: 'Registration Closing Date (Optional)',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_DATETIME,
            utc: true,
            disabledWhen: function disabledWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT_PRO);
            }
        }];

        var hostedHideWhenFn = function hostedHideWhenFn() {
            return !Gecko.able(Gecko.ABILITY_EVENTS_VIDEO_STREAMING) || [Gecko.Event.DELIVERY_METHOD_VENUE, Gecko.Event.DELIVERY_METHOD_HYBRID].includes(ctrl.event.delivery_method) || ctrl.event.online_type !== ONLINE_TYPE_HOSTED;
        };

        var webinarHideWhenFn = function webinarHideWhenFn() {
            return [Gecko.Event.DELIVERY_METHOD_VENUE, Gecko.Event.DELIVERY_METHOD_HYBRID].includes(ctrl.event.delivery_method) || ctrl.event.online_type !== ONLINE_TYPE_WEBINAR;
        };

        ctrl.typeFields = [{
            id: 'registration_type',
            label: 'What type of event are you holding?',
            description: 'Determines how the event should behave when displayed through event fields and RSVP pages. For more information on the available registration types, click <a href="https://academy.geckoengage.com/en/articles/6935852-event-types#h_10a3c56110" target="_blank">here</a>.',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_SELECT,
            options: Gecko.Event.TYPE_TITLES,
            optionsKey: 'value',
            optionsLabelKey: 'label',
            noBlank: true,
            hideWhen: function hideWhen() {
                return ctrl.event.id && ctrl.event.parent_id !== null;
            },
            disabledWhen: function disabledWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT_PRO);
            },
            onChange: function onChange() {
                if (ctrl.event.registration_type === Gecko.Event.TYPE_SINGLE_EVENT && ctrl.event.delivery_method === Gecko.Event.DELIVERY_METHOD_HYBRID) {
                    ctrl.event.delivery_method = Gecko.Event.DELIVERY_METHOD_VENUE;
                }
            }
        }, {
            id: 'categories',
            label: 'Assign a Category',
            description: 'Allows you to easily track and group your events. For more information, click <a target="_blank" href="https://academy.geckoengage.com/en/articles/6935866-categories#h_749abcbe5c">here</a>.',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_TAGGER,
            newItem: function newItem(string) {
                return {
                    title: string,
                    type: 'event',
                    id: catId--
                };
            },
            optionsKey: 'id',
            optionsLabelKey: 'title',
            options: ctrl.categories,
            hideWhen: function hideWhen() {
                return ctrl.event.id && ctrl.event.parent_id !== null;
            },
            disabledWhen: function disabledWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT_PRO);
            }
        }];

        ctrl.locationFields = [{
            type: Gecko.Field.TYPE_TITLE,
            label: 'Location Management',
            colClass: 'col-xs-12'
        }, {
            id: 'delivery_method',
            label: 'Is your event taking place in a venue, virtually or a hybrid of both?',
            colClass: 'col-xs-12 text-left',
            type: Gecko.Field.TYPE_PANEL_OPTIONS,
            onChange: function onChange() {
                if (ctrl.event.delivery_method === Gecko.Event.DELIVERY_METHOD_ONLINE) {
                    ctrl.event.self_checkin = 1;
                    if (!ctrl.event.checkin_start) ctrl.event.checkin_start = 0;
                    // Set default online type
                    if (!ctrl.event.online_type) {
                        ctrl.event.online_type = Gecko.able(Gecko.ABILITY_EVENTS_VIDEO_STREAMING) ? ONLINE_TYPE_HOSTED : ONLINE_TYPE_WEBINAR;
                    }
                } else {
                    addLocationModalFn();
                }
            },
            options: [{
                value: Gecko.Event.DELIVERY_METHOD_VENUE,
                icon: 'fa-map-marker-alt',
                title: 'Venue'
            }, {
                value: Gecko.Event.DELIVERY_METHOD_ONLINE,
                icon: 'fa-wifi',
                title: 'Virtual'
            }, {
                value: Gecko.Event.DELIVERY_METHOD_HYBRID,
                icon: 'fa-code-merge',
                title: 'Hybrid',
                hideWhen: function hideWhen() {
                    return ctrl.event.registration_type === Gecko.Event.TYPE_SINGLE_EVENT;
                }
            }]
        }, {
            id: 'hybrid_alert',
            type: Gecko.Field.TYPE_SCRIPT,
            break: true,
            help_text: '\n                <div class="alert alert-info" style="margin-bottom:0;">\n                    A hybrid event type requires a virtual or venue location to be configured on each session. Click <a href="https://academy.geckoengage.com/en/articles/5642670-hybrid-event-functionality" title="Hybrid event functionality">here</a> to learn more.\n                </div>\n            ',
            hideWhen: function hideWhen() {
                return ctrl.event.delivery_method !== Gecko.Event.DELIVERY_METHOD_HYBRID;
            }
            // VENUE
        }, {
            id: 'location_title_legacy',
            label: Gecko.able(Gecko.ABILITY_LOCATIONS_VIEW) ? 'Venue Name (Deprecated)' : 'Venue Address',
            colClass: 'col-xs-12',
            description: Gecko.able(Gecko.ABILITY_LOCATIONS_VIEW) ? 'This feature has been depreacted. Please choose a location from the dropdown below.' : '',
            disabledWhen: function disabledWhen() {
                return Gecko.able(Gecko.ABILITY_LOCATIONS_VIEW);
            },
            hideWhen: function hideWhen() {
                return Gecko.able(Gecko.ABILITY_LOCATIONS_VIEW) && (ctrl.event.location_id || !ctrl.event.location_title_legacy) || [Gecko.Event.DELIVERY_METHOD_ONLINE, Gecko.Event.DELIVERY_METHOD_HYBRID].includes(ctrl.event.delivery_method);
            }
        }, {
            id: 'location_id',
            label: 'Venue Address',
            description: 'Select a venue so attendees know where to go. If you are unable to find a venue, add a new <a id="openLocationModal">location</a>.',
            type: Gecko.Field.TYPE_SELECT,
            optionsKey: 'id',
            optionsLabelKey: 'title',
            options: ctrl.locations,
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_LOCATIONS_VIEW) || [Gecko.Event.DELIVERY_METHOD_ONLINE, Gecko.Event.DELIVERY_METHOD_HYBRID].includes(ctrl.event.delivery_method);
            }
        }, {
            id: 'country',
            label: 'Country',
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return [Gecko.Event.DELIVERY_METHOD_ONLINE, Gecko.Event.DELIVERY_METHOD_HYBRID].includes(ctrl.event.delivery_method);
            }
            // ONLINE
        }, {
            id: 'online_type',
            label: 'Are you using Gecko to host or are you using a 3rd party?',
            colClass: 'col-xs-12 text-left',
            type: Gecko.Field.TYPE_PANEL_OPTIONS,
            // onChange     : () => {
            //     if (ctrl.event.delivery_method === Gecko.Event.DELIVERY_METHOD_ONLINE) {
            //         ctrl.event.self_checkin  = 1;
            //         if (!ctrl.event.checkin_start) ctrl.event.checkin_start = 0;
            //     } else {
            //         addLocationModalFn();
            //     }
            // },
            options: [{
                value: ONLINE_TYPE_HOSTED,
                title: 'Hosted',
                disabled: !Gecko.able(Gecko.ABILITY_EVENTS_VIDEO_STREAMING)
            }, {
                value: ONLINE_TYPE_WEBINAR,
                title: '3rd Party'
            }],
            hideWhen: function hideWhen() {
                return [Gecko.Event.DELIVERY_METHOD_VENUE, Gecko.Event.DELIVERY_METHOD_HYBRID].includes(ctrl.event.delivery_method);
            }
        }, {
            type: Gecko.Field.TYPE_TITLE,
            label: 'Webinar Information',
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return webinarHideWhenFn();
            }
        }, {
            id: 'webinar_url',
            label: 'Webinar Link',
            description: 'Add a webinar link so attendees know how to join you. You can use your preferred web conference solution. For more information, click <a href="https://academy.geckoengage.com/en/articles/6935852-event-types#h_3ffe57809d" target="_blank">here</a>.',
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return webinarHideWhenFn() || ctrl.event.registration_type !== Gecko.Event.TYPE_SINGLE_EVENT;
            }
        }, {
            type: Gecko.Field.TYPE_RICHTEXT,
            config: { toolbar: 'simple' },
            colClass: 'col-xs-12',
            label: 'Additional Details',
            id: 'webinar_description',
            id2: 'webinar_description_enabled',
            hideWhen: function hideWhen() {
                return webinarHideWhenFn() || ctrl.event.registration_type !== Gecko.Event.TYPE_SINGLE_EVENT;
            }
        }, {
            id: 'webinar_url',
            label: 'Webinar Link',
            description: 'Please add a webinar link at session level for single/multiple session events. For more information, click <a href="https://academy.geckoengage.com/en/articles/6935852-event-types#h_64316b1a99" target="_blank">here</a>.',
            colClass: 'col-xs-12',
            disabledWhen: function disabledWhen() {
                return true;
            },
            hideWhen: function hideWhen() {
                return webinarHideWhenFn() || ctrl.event.registration_type === Gecko.Event.TYPE_SINGLE_EVENT;
            }
        }, {
            type: Gecko.Field.TYPE_TEXTAREA,
            colClass: 'col-xs-12',
            label: 'Additional Details',
            id: 'webinar_description',
            id2: 'webinar_description_disabled',
            disabledWhen: function disabledWhen() {
                return true;
            },
            hideWhen: function hideWhen() {
                return webinarHideWhenFn() || ctrl.event.registration_type === Gecko.Event.TYPE_SINGLE_EVENT;
            }
        }, {
            type: Gecko.Field.TYPE_TITLE,
            label: 'Webinar Information',
            description: 'You can host an event within Gecko and keep your check-in process as seamless as ever. For more information, click <a href="https://academy.geckoengage.com/en/articles/6935852-event-types#h_3c911c9827" taeget="_blank">here</a>.',
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return hostedHideWhenFn();
            }
        }, {
            type: Gecko.Field.TYPE_BUTTON,
            colClass: 'col-xs-12',
            label: 'Enable Stream Connection',
            btnLabel: 'Connect Stream with Gecko',
            icon: 'fa-play',
            //description : 'Gecko can host a video stream that all you attendees will be able to watch. This will replace any Webinar Link set.',
            hideWhen: function hideWhen() {
                return hostedHideWhenFn() || ctrl.event.video;
            },
            disabledWhen: function disabledWhen() {
                return $stateParams.event_id === 'new';
            },
            action: function action() {
                if ($stateParams.event_id === 'new') {
                    var query = ctrl.saveEvent(true);
                } else {
                    var query = Promise.resolve(ctrl.event);
                }

                return query.then(function (event) {
                    return new Gecko().call('/videos', 'POST', { type: 'mux', event_id: event.id }, true, false).then(function (video) {
                        ctrl.event.video = video;
                        ctrl.event.video_details = video.details;
                    }).then(function () {
                        GeckoUI.messenger.success('Webinar credentials created & event saved.');
                        // Send them to their new event
                        redirectAfterSave(event);
                    }).catch(function (error) {
                        return GeckoUI.messenger.error(GeckoUI.renderError(error));
                    });
                });
            }
        }, {
            type: Gecko.Field.TYPE_BUTTON,
            colClass: 'col-xs-12',
            label: 'Enable Stream Connection',
            btnLabel: 'Disconnect Stream with Gecko',
            btnClass: 'btn-danger',
            icon: 'fa-stop',
            //description : 'This can be enabled again later.',
            hideWhen: function hideWhen() {
                return hostedHideWhenFn() || !ctrl.event.video;
            },
            action: function action() {
                return new Gecko().call('/videos/' + ctrl.event.video.uuid, 'DELETE', null, true, false).then(function () {
                    ctrl.event.video = null;
                    ctrl.event.video_details = null;
                    ctrl.event.video_uuid = null;
                    GeckoUI.messenger.success('Hosted video stream removed');
                }).then(function () {
                    return $scope.$digest();
                }).catch(function (error) {
                    return GeckoUI.messenger.error(GeckoUI.renderError(error));
                });
            }
        }, {
            id: 'video_details',
            type: Gecko.Field.TYPE_STREAM_DETAILS,
            colClass: 'col-xs-12',
            label: 'Set Up Your Zoom Call (or other streaming provider)',
            //description : 'Use these details to configure a video stream.',
            hideWhen: function hideWhen() {
                return hostedHideWhenFn() || !ctrl.event.video;
            }
        }, {
            id: 'enable_video_chat',
            label: 'Video live chat',
            description: 'This will be applied to every session at the event.',
            colClass: 'col-md-6 col-xs-12',
            type: Gecko.Field.TYPE_TOGGLE,
            hideWhen: function hideWhen() {
                return hostedHideWhenFn() || !ctrl.event.video;
            }
            // SELF CHECKIN
        }, {
            label: 'Self Check-in',
            type: Gecko.Field.TYPE_TITLE,
            colClass: 'col-xs-12'
            // VENUE
        }, {
            id: 'self_checkin',
            label: 'Allow check-in by time?',
            description: 'Allow attendees to check themselves into your venue through the RSVP page, and for them to show as attended.',
            colClass: 'col-md-6 col-xs-12',
            type: Gecko.Field.TYPE_TOGGLE,
            trueValue: 1,
            falseValue: 0,
            obj: ctrl.event,
            hideWhen: function hideWhen() {
                return ctrl.event.delivery_method === Gecko.Event.DELIVERY_METHOD_ONLINE;
            },
            action: function action() {
                return ctrl.event.checkin_start = ctrl.event.self_checkin === 0 ? null : 0;
            }
        }, {
            id: 'checkin_start',
            id2: 'checkin_start_venue',
            label: 'Minutes before the event start time',
            description: 'How many minutes before the event start time can attendees check-in?',
            colClass: 'col-md-6 col-xs-12',
            type: Gecko.Field.TYPE_NUMBER,
            hideWhen: function hideWhen() {
                return !ctrl.event.self_checkin || ctrl.event.delivery_method === Gecko.Event.DELIVERY_METHOD_ONLINE;
            }
            // ONLINE
        }, {
            id: 'checkin_start',
            id2: 'checkin_start_online',
            label: 'Minutes before the event start time',
            description: 'How many minutes before the start time can attendees access joining details?',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_NUMBER,
            disabledWhen: function disabledWhen() {
                return ctrl.event.registration_type !== Gecko.Event.TYPE_SINGLE_EVENT;
            },
            hideWhen: function hideWhen() {
                return ctrl.event.delivery_method === Gecko.Event.DELIVERY_METHOD_VENUE;
            }
        }];

        ctrl.attendeeFields = [{
            label: 'Attendee Management',
            type: Gecko.Field.TYPE_TITLE,
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return ctrl.event.delivery_method === Gecko.Event.DELIVERY_METHOD_HYBRID;
            }
        }, {
            id: 'maximum',
            label: 'Maximum number of Attendees',
            colClass: 'col-md-6 col-xs-12',
            type: Gecko.Field.TYPE_NUMBER,
            disabledWhen: function disabledWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT_PRO);
            },
            hideWhen: function hideWhen() {
                return ctrl.event.delivery_method === Gecko.Event.DELIVERY_METHOD_HYBRID;
            }
        }, {
            id: 'auto_waitlist',
            label: 'Enable a waiting list?',
            description: 'Once you define the Maximum Attendees for your event, any registrants over that number will be added to your event’s waiting list.',
            colClass: 'col-md-6 col-xs-12',
            type: Gecko.Field.TYPE_TOGGLE,
            trueValue: true,
            falseValue: false,
            disabledWhen: function disabledWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT_PRO);
            },
            hideWhen: function hideWhen() {
                return ctrl.event.delivery_method === Gecko.Event.DELIVERY_METHOD_HYBRID;
            }
        }, {
            label: 'Guest Management',
            type: Gecko.Field.TYPE_TITLE,
            colClass: 'col-xs-12'
        }, {
            id: 'eventCanHaveGuests',
            label: 'Allow Guests?',
            description: 'This feature lets users select how many guests they intend to bring to your event. Note: this choice can only be made via the event field on a form.',
            colClass: 'col-md-6 col-xs-12',
            type: Gecko.Field.TYPE_TOGGLE,
            trueValue: true,
            falseValue: false,
            obj: ctrl,
            disabledWhen: function disabledWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT_PRO);
            }
        }, {
            id: 'maximum_guests',
            label: 'Maximum number of guests per attendee',
            colClass: 'col-md-6 col-xs-12',
            type: Gecko.Field.TYPE_NUMBER,
            disabledWhen: function disabledWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT_PRO);
            },
            hideWhen: function hideWhen() {
                return !ctrl.eventCanHaveGuests;
            }
        }, {
            id: 'allow_clashing_sessions',
            label: 'Allow clashing sessions?',
            description: 'When enabled users will be able to register for sessions that clash with each other.',
            colClass: 'col-md-12 col-xs-12',
            type: Gecko.Field.TYPE_TOGGLE,
            trueValue: true,
            falseValue: false,
            disabledWhen: function disabledWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_EVENT_PRO);
            }
        }];

        ctrl.customisationFields = [{
            label: 'Event Descriptions',
            type: Gecko.Field.TYPE_TITLE,
            colClass: 'col-xs-12'
        }, {
            type: Gecko.Field.TYPE_RICHTEXT,
            colClass: 'col-xs-12',
            id: 'description',
            label: 'Describe your event',
            config: { toolbar: 'simple' }
        }, {
            id: 'hosts',
            label: 'Event hosts',
            description: 'Display host details within the event. To create a new host, click <a id=\'openHostModal\' >here</a>.',
            colClass: 'col-xs-12',
            type: Gecko.Field.TYPE_MULTI,
            options: ctrl.hosts,
            optionsKey: 'id',
            optionsLabelKey: 'name',
            hideWhen: function hideWhen() {
                return ctrl.hostsAreBeingUpdated || !Gecko.able(Gecko.ABILITY_HOSTS_VIEW);
            }
        }, {
            label: 'Event Page Design',
            type: Gecko.Field.TYPE_TITLE,
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_BRANDING);
            }
        }, {
            id: 'image',
            label: 'Upload your header image',
            colClass: 'col-xs-6',
            type: Gecko.Field.TYPE_FILE,
            description: 'Displayed on the embedded event, event field and RSVP pages -1536px(w) x 500px(h) in <code>JPG</code>.',
            mimetype: 'image/*',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_BRANDING);
            }
        }, {
            id: 'color',
            label: 'Main color',
            colClass: 'col-xs-6',
            type: Gecko.Field.TYPE_COLOR,
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_BRANDING);
            }
        }];

        ctrl.advanceFields = [{
            label: 'Advance Details',
            type: Gecko.Field.TYPE_TITLE,
            colClass: 'col-xs-12'
        }, {
            id: 'id',
            label: 'ID',
            colClass: 'col-xs-6',
            disabledWhen: function disabledWhen() {
                return true;
            }
        }, {
            id: 'cost',
            label: 'Total event cost',
            colClass: 'col-xs-6'
        }, {
            label: 'GeckoChat Integration',
            type: Gecko.Field.TYPE_TITLE,
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return Gecko.able(Gecko.ABILITY_CHAT_WIDGETS_VIEW);
            }
        }, {
            id: 'chat_channel_id',
            label: 'Select GeckoChat Channel',
            type: Gecko.Field.TYPE_SELECT,
            description: 'Select a live chat widget from your instance of GeckoChat to be embedded onto the RSVP page for this event',
            options: ctrl.channels,
            optionsKey: 'widget_id',
            optionsLabelKey: 'name',
            hideWhen: function hideWhen() {
                return Gecko.able(Gecko.ABILITY_CHAT_WIDGETS_VIEW);
            },
            colClass: 'col-xs-12'
        }, {
            label: 'Live Chat Widget',
            type: Gecko.Field.TYPE_TITLE,
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CHAT_WIDGETS_VIEW);
            }
        }, {
            id: 'chat_widget_id',
            label: 'Chat Widget',
            type: Gecko.Field.TYPE_SELECT,
            description: 'Select a live chat widget to be embedded onto the RSVP page for this event',
            options: ctrl.widgets.filter(function (widget) {
                return Array.isArray(widget.assignedChannels) && widget.assignedChannels.length;
            }).map(function (widget) {
                return {
                    id: widget.widgetId,
                    name: widget.name
                };
            }),
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CHAT_WIDGETS_VIEW);
            }
        }, {
            label: 'RSVP Links',
            type: Gecko.Field.TYPE_TITLE,
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_STUDENT_PORTAL);
            }
        }, {
            id: 'rsvp_link_type',
            label: 'Where will you be sending students',
            type: Gecko.Field.TYPE_SELECT,
            description: 'Select where you want to send students when they click the link to RSVP to this event',
            options: [{
                value: EVENT_LINK_TYPE_PORTAL,
                title: 'Student Portal'
            }, {
                value: EVENT_LINK_TYPE_RSVP,
                title: 'RSVP'
            }],
            required: Gecko.able(Gecko.ABILITY_STUDENT_PORTAL),
            optionsKey: 'value',
            optionsLabelKey: 'title',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_STUDENT_PORTAL);
            },
            colClass: 'col-xs-12'
        }, {
            type: Gecko.Field.TYPE_SCRIPT,
            break: true,
            colClass: 'alert alert-info',
            description: 'GeckoEngage integration has not been set up on GeckoChat. <a href="https://app.geckochat.io/#/settings/integrations" target="_blank">Create a GeckoChat Integration</a>',
            hideWhen: function hideWhen() {
                return channels;
            }
        }, {
            label: 'CRM Integration',
            type: Gecko.Field.TYPE_TITLE,
            colClass: 'col-xs-12'
        }, {
            id: 'crm',
            label: 'Enter your CRM value (optional)',
            colClass: 'col-xs-12'
        }, {
            id: 'radius_title',
            label: 'Radius Event',
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return !ctrl.event.radius_title;
            },
            disabledWhen: function disabledWhen() {
                return true;
            }
        }, {
            id: 'radius_id',
            label: 'Radius Event ID',
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return !ctrl.event.radius_id;
            },
            disabledWhen: function disabledWhen() {
                return true;
            }
        }, {
            id: 'dynamics_title',
            label: 'Dynamics Event',
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return !ctrl.event.dynamics_title;
            },
            disabledWhen: function disabledWhen() {
                return true;
            }
        }, {
            id: 'dynamics_id',
            label: 'Dynamics Event ID',
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return !ctrl.event.dynamics_id;
            },
            disabledWhen: function disabledWhen() {
                return true;
            }
        }, {
            id: 'salesforce_title',
            label: 'Salesforce Campaign',
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return !ctrl.event.salesforce_title;
            },
            disabledWhen: function disabledWhen() {
                return true;
            }
        }, {
            id: 'salesforce_id',
            label: 'Salesforce Campaign ID',
            colClass: 'col-xs-12',
            hideWhen: function hideWhen() {
                return !ctrl.event.salesforce_id;
            },
            disabledWhen: function disabledWhen() {
                return true;
            }
        }];

        function getIntegartionToggles() {
            // gets available integrations, creates toggles and adds to ctrl.advanceFields
            var integrations = ctrl.integrations;
            var dynamics_fields = [];
            function getIntegrationToggle(integration) {
                var toggle = {
                    id: '_integration_syncs_' + integration.id,
                    label: 'Sync event to ' + integration.title,
                    help_text: new moment(integration.created_at * 1000).format("MMM Do YYYY"),
                    colClass: 'col-md-4 col-xs-12',
                    type: Gecko.Field.TYPE_TOGGLE,
                    trueValue: true,
                    falseValue: false
                };
                return toggle;
            }
            if (integrations.length > 0) {
                var int_ids = integrationsToSync();
                for (var i = 0; i < int_ids.length; i++) {
                    var integration_id = int_ids[i];
                    var integration = integrations.find(function (int) {
                        return int.id === Number(integration_id);
                    });
                    if (integration) {
                        dynamics_fields.push(getIntegrationToggle(integration));
                    }
                }
            }
            return angular.copy(ctrl.advanceFields.concat(dynamics_fields));
        }

        // Integration field list
        // Ctrl.integrationFields = [{
        //     Id    : 'crm',
        //     Label : 'CRM Value (optional)',
        // }, {
        //     Id       : 'radius_title',
        //     Label    : 'Radius Event',
        //     HideWhen : function() {
        //         Return !ctrl.event.radius_title;
        //     },
        //     DisabledWhen: function() {
        //         Return true;
        //     },
        // }, {
        //     Id       : 'radius_id',
        //     Label    : 'Radius Event ID',
        //     HideWhen : function() {
        //         Return !ctrl.event.radius_id;
        //     },
        //     DisabledWhen: function() {
        //         Return true;
        //     },
        // },{
        //     Id       : 'dynamics_title',
        //     Label    : 'Dynamics Event',
        //     HideWhen : function() {
        //         Return !ctrl.event.dynamics_title;
        //     },
        //     DisabledWhen: function() {
        //         Return true;
        //     },
        // }, {
        //     Id       : 'dynamics_id',
        //     Label    : 'Dynamics Event ID',
        //     HideWhen : function() {
        //         Return !ctrl.event.dynamics_id;
        //     },
        //     DisabledWhen: function() {
        //         Return true;
        //     },
        // }];

        // Transpose the values for the individual _integration_syncs_ keys into the event.integration_syncs
        // object
        ctrl.transposeIntegrationSyncValues = function () {
            var int_ids = integrationsToSync();
            for (var i = 0; i < int_ids.length; i++) {
                var key = '_integration_syncs_' + int_ids[i];
                ctrl.event.integration_syncs[Number(int_ids[i])] = ctrl.event[key];
            }
        };

        // Send them to their new event
        var redirectAfterSave = function redirectAfterSave(event) {
            if ($stateParams.event_id === 'new') {
                return $state.go('event.settings', {
                    event_id: event.id,
                    tab: ctrl.currentTab,
                    ij: 1
                });
            }
        };

        // Save event
        ctrl.saveEvent = function () {
            var ignoreRedirect = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            // Check start date
            if (!ctrl.checkDates()) return Promise.reject();
            if (!ctrl.event.closing_datetime) {
                ctrl.event.closing_datetime = null;
            }
            if (!ctrl.eventCanHaveGuests && ctrl.event.maximum_guests > 0) {
                ctrl.event.maximum_guests = 0;
            }

            // Check waitlisting and maximum is NOT null
            if (ctrl.event.auto_waitlist && ctrl.event.maximum == null) {
                var error = 'To enable Auto Waitlist please specify the Maximum Attendees for this event.';
                fieldErrors.setErrors({
                    'fieldmaximum': [error]
                });
                GeckoUI.messenger.error(error);
                return Promise.reject(error);
            }

            if (integrationsToSync().length > 0) {
                ctrl.transposeIntegrationSyncValues();
            }

            // Check for `empty` description or webinar_description
            if (!GeckoUI.htmlHasContent(ctrl.event.description)) ctrl.event.description = null;
            if (!GeckoUI.htmlHasContent(ctrl.event.webinar_description)) ctrl.event.webinar_description = null;

            // Invert SHOW/HIDE video chat setting
            ctrl.event.disable_video_chat = ctrl.event.enable_video_chat ? 0 : 1;

            return angular.copy(ctrl.event).rfields({ event: eventRfields }).save().then(function (event) {
                unsavedAlert.clear();
                // Send them to their new event
                if (!ignoreRedirect) redirectAfterSave(event);
                ctrl.event.category_id = [ctrl.event.category_id]; // Coerse to an array for use in the multi field

                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Event Save Success', {
                    'event id': event.id,
                    'event title': event.title
                });

                return event;
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);

                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Event Save Error', {
                    'event id': ctrl.event.id || 'New',
                    'event title': ctrl.event.title,
                    'error': GeckoUI.renderError(err.errors)
                });
            });
        };

        $scope.$on('saveEvent', function () {
            return ctrl.saveEvent().then(function (event) {
                if (event) GeckoUI.messenger.success('Event changes have been saved');
            });
        });

        ctrl.checkDates = function () {
            var eventStartDate = ctrl.event.start_date ? Number(ctrl.event.start_date) : null;
            var eventEndDate = ctrl.event.end_date ? Number(ctrl.event.end_date) : null;

            // Empty start date
            if (!eventStartDate) {
                var error = 'The event must have a start date and time.';
                fieldErrors.setErrors({
                    'fieldstart_date': [error]
                });
                GeckoUI.messenger.error(error);
                return false;
            }
            // Empty end date
            if (!eventEndDate) {
                var error = 'The event must have an end date and time.';
                fieldErrors.setErrors({
                    'fieldend_date': [error]
                });
                GeckoUI.messenger.error(error);
                return false;
            }

            // Start and be after event end date
            if (ctrl.event.end_date && eventStartDate > eventEndDate) {
                var error = 'The event start time cannot be after the event end time.';
                fieldErrors.setErrors({
                    'fieldstart_date': [error]
                });
                GeckoUI.messenger.error(error);
                return false;
            }
            // Reset errors
            fieldErrors.setErrors({});
            return true;
        };

        // Only allow sensible dates to be set
        var _tempDates = {
            start_date: ctrl.event.start_date,
            end_date: ctrl.event.end_date,
            closing_date: ctrl.event.closing_date
        };

        ctrl.currentTab;
        var index;

        function getIndex(labelKey) {
            return ctrl.tabs.map(function (e) {
                return e.label;
            }).indexOf(labelKey);
        }

        var changeTab = function changeTab(index) {
            ctrl.currentTab = index;
            index = getIndex(index);
            return Promise.resolve();
        };

        // Tabs
        ctrl.tabs = [{
            label: 'Details',
            fields: ctrl.detailFields,
            activeWhen: function activeWhen() {
                return ctrl.currentTab === 0;
            },
            action: function action() {
                return changeTab(0);
            }
        }, {
            label: 'Type & Category',
            fields: ctrl.typeFields,
            activeWhen: function activeWhen() {
                return ctrl.currentTab === 1;
            },
            action: function action() {
                return changeTab(1);
            }
        }, {
            label: 'Location',
            fields: ctrl.locationFields,
            activeWhen: function activeWhen() {
                return ctrl.currentTab === 2;
            },
            action: function action() {
                return changeTab(2).then(function () {
                    return addLocationModalFn();
                });
            }
        }, {
            label: 'Attendee Options',
            fields: ctrl.attendeeFields,
            activeWhen: function activeWhen() {
                return ctrl.currentTab === 3;
            },
            action: function action() {
                return changeTab(3);
            }
        }, {
            label: 'Styling',
            fields: ctrl.customisationFields,
            activeWhen: function activeWhen() {
                return ctrl.currentTab === 4;
            },
            action: function action() {
                return changeTab(4).then(function () {
                    return addHostModalFn();
                });
            }
        }, {
            label: 'Advanced Settings',
            fields: getIntegartionToggles(),
            activeWhen: function activeWhen() {
                return ctrl.currentTab === 5;
            },
            action: function action() {
                return changeTab(5);
            }
        }];

        // Set initial tab
        changeTab(Number($stateParams.tab) || 0);

        function getModalPopup() {
            if (ctrl.currentTab === 2) {
                return addLocationModalFn();
            } else if (ctrl.currentTab === 4) {
                return addHostModalFn();
            };
        }

        if ($stateParams.event_id === 'new') {
            // Footer buttons
            ctrl.footerBtns = [{
                title: 'Next Step',
                btnClass: 'btn-primary',
                icon: 'fa-arrow-right',
                hideWhen: function hideWhen() {
                    return ctrl.currentTab === 5;
                },
                action: function action() {
                    changeTab(ctrl.currentTab + 1);
                    getModalPopup();
                }
            }, {
                title: 'Create Event',
                btnClass: 'btn-primary',
                icon: 'fa-save',
                safeMode: true,
                hideWhen: function hideWhen() {
                    return ctrl.currentTab !== 5;
                },
                action: function action() {
                    return ctrl.saveEvent().then(function (event) {
                        if (event) GeckoUI.messenger.success('Event changes have been saved');
                    });
                }
            }, {
                title: 'Back a Step',
                icon: 'fa-arrow-left',
                position: 'secondary',
                hideWhen: function hideWhen() {
                    return ctrl.currentTab === 0;
                },
                action: function action() {
                    changeTab(ctrl.currentTab - 1);
                    getModalPopup();
                }
            }];
        }

        geckoDates.initSetters(_tempDates, ctrl.event, [{ get: 'start_date', set: 'start_datetime', related: 'end_date', defaultTime: '09:00:00' }, { get: 'end_date', set: 'end_datetime', related: 'start_date', defaultTime: '17:00:00' }, { get: 'closing_date', set: 'closing_datetime' }]);
    }

    angular.module('GeckoEngage').controller('EventSettingsCtrl', EventSettingsCtrl);
})();