(function () {
    'use strict';

    function geckoSideMenuService($state, $stateParams, geckoStore) {

        var menu = [{
            label: 'Overview',
            state: 'dashboard',
            active: function active() {
                return $state.$current.name == 'dashboard';
            },
            icon: 'far fa-home-alt',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_DASHBOARDS_VIEW);
            }
        }, {
            label: 'Contacts',
            state: 'contacts',
            active: function active() {
                return $state.$current.name == 'contacts' || $state.$current.name == 'contactadd' || $state.$current.name.indexOf('contact.') != -1 && !$stateParams.subscriber_id;
            },
            icon: 'far fa-address-book',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CONTACTS_VIEW);
            }
        }, {
            label: 'Responses',
            state: "responses",
            stateParams: {},
            activeWhen: ['/responses', '/responses/', {
                state: 'forms',
                params: {
                    addresponse: '1'
                }
            }],
            icon: 'far fa-inbox',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_RESPONSES_VIEW);
            }
        }, {
            label: 'Messages',
            state: "messages",
            stateParams: {},
            activeWhen: ['/messages'],
            icon: 'far fa-envelope',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_MESSAGES_VIEW);
            }
        }, {
            label: 'Calls',
            state: 'calls.overview',
            active: function active() {
                return $state.$current.name.toLowerCase().indexOf('calls') !== -1 && $state.$current.name.indexOf('contact.') === -1;
            },
            icon: 'far fa-headset',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CALLS_VIEW);
            }
        }, {
            key: 'conversations',
            label: 'Conversations',
            icon: 'far fa-comment-alt-lines',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CHAT_CONVERSATIONS_VIEW);
            },
            children: [{
                label: 'Inbox', // ie the Conversations page
                state: 'conversations',
                hideWhen: function hideWhen() {
                    return !Gecko.able(Gecko.ABILITY_CHAT_CONVERSATIONS_VIEW);
                }
            }, {
                label: 'Knowledge bases',
                state: 'knowledge_bases',
                hideWhen: function hideWhen() {
                    return !Gecko.able(Gecko.ABILITY_KNOWLEDGE_BASES_VIEW);
                }
            }, {
                label: 'Chatbots',
                state: 'bots',
                hideWhen: function hideWhen() {
                    return !Gecko.able(Gecko.ABILITY_CHAT_BOTS_VIEW);
                }
            }, {
                label: 'Channels',
                state: 'channels',
                hideWhen: function hideWhen() {
                    return !Gecko.able(Gecko.ABILITY_CHAT_CHANNELS_VIEW);
                }
            }, {
                label: 'Widgets',
                state: 'widgets',
                hideWhen: function hideWhen() {
                    return !Gecko.able(Gecko.ABILITY_WIDGETS_VIEW);
                }
            }, {
                label: 'Workflows',
                state: 'chat_workflows',
                hideWhen: function hideWhen() {
                    return !Gecko.able(Gecko.ABILITY_CHAT_WORKFLOWS_VIEW);
                },
                activeWhen: ['/settings/chat_workflows']
            }, {
                label: 'Reporting',
                state: 'chat_reporting',
                hideWhen: function hideWhen() {
                    return !Gecko.able(Gecko.ABILITY_CHAT_CONVERSATIONS_VIEW);
                }
            }]
        }, {
            label: 'Organisations',
            state: 'organisations',
            active: function active() {
                return (/^organisation/.test($state.$current.name) && !/field/.test($state.$current.name) && !/types/.test($state.$current.name)
                );
            },
            icon: 'far fa-building',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_ORGANISATIONS_VIEW);
            }
        }, {
            label: 'Dashboards',
            state: 'dashboards',
            activeWhen: ['/dashboards', '/dashboards/'],
            icon: 'far fa-chart-line',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_DASHBOARDS_VIEW);
            },
            dividerTop: true
        }, {
            label: 'Settings',
            state: 'settings',
            active: function active() {
                if (['bots', 'channels', 'widgets', 'chat_workflows'].includes($state.$current.name)) {
                    return false;
                }
                return ['/settings/', '/import'].some(function (segment) {
                    return $state.$current.self.url.includes(segment);
                });
            },
            icon: 'far fa-cog',
            right: true
        }, {
            label: 'Download apps',
            state: 'download',
            activeWhen: ['/download'],
            icon: 'far fa-download',
            dividerBottom: true
        }, {
            label: 'Events',
            state: "events.list",
            stateParams: { filter: 'active' },
            activeWhen: ['/events', '/events/'],
            icon: 'far fa-calendar-alt',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_EVENTS_VIEW);
            }
        }, {
            label: 'Forms',
            state: 'forms',
            active: function active() {
                return $state.$current.name.toLowerCase().indexOf('form') !== -1 && $state.$current.name.toLowerCase().indexOf('user') === -1;
            },
            icon: 'far fa-clipboard-list-check',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_FORMS_VIEW);
            }
        }, {
            label: 'Broadcasts',
            state: 'broadcasts',
            active: function active() {
                return ['broadcasts', 'broadcast.overview', 'broadcast.settings', 'broadcast.filter', 'broadcast.workflows', 'broadcast.stats', 'broadcast.launch', 'broadcast'].includes($state.$current.name);
            },
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_BROADCASTS_VIEW);
            },
            icon: 'far fa-bullhorn'
        }, {
            label: 'Landing pages',
            state: 'landingpages',
            activeWhen: ['/landing_pages', '/landing_pages/'],
            icon: 'far fa-browser',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_LANDING_PAGES_VIEW);
            }
        }, {
            label: 'Call scripts',
            state: 'scripts',
            active: function active() {
                return $state.$current.name.toLowerCase().indexOf('script') !== -1;
            },
            icon: 'far fa-scroll',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_SCRIPTS_VIEW);
            }
        }, {
            label: 'Call campaigns',
            state: 'campaigns',
            stateParams: { module: Gecko.Campaign.MODULE_CALL, status: 'current' },
            active: function active() {
                return ($state.$current.name == 'campaigns' || $state.$current.name == 'campaignsedit.view' || $state.$current.name == 'campaignsedit' || $state.$current.name == 'campaignsfilter' || $state.$current.name == 'campaignssegments' || $state.$current.name == 'campaignsedit.settings' || $state.$current.name == 'campaignsedit.filter' || $state.$current.name == 'campaignsedit.segments' || $state.$current.name == 'campaignsedit.workflows' || $state.$current.name == 'campaignsworkflows') && $stateParams.module == Gecko.Campaign.MODULE_CALL || $state.$current.name.indexOf('contact.') != -1 && $stateParams.subscriber_id;
            },
            icon: 'far fa-phone-volume',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CALL_CAMPAIGNS_VIEW);
            }
        }];

        var collapsed = geckoStore.get('gecko-side-menu-collapsed');
        var expanded = geckoStore.get('gecko-side-menu-expanded');

        var _this = {
            collapsed: collapsed,
            expanded: expanded || !collapsed,
            get: function get() {
                return menu;
            }
        };

        return _this;
    }

    angular.module('GeckoEngage').factory('geckoSideMenuService', geckoSideMenuService);
})();