(function () {
    'use strict';

    function geckoUserChatControl() {
        return {
            restrict: 'EA',
            templateUrl: '/components/gecko-user-chat-control/gecko-user-chat-control.html',
            scope: {},
            controllerAs: 'ctrl',
            controller: function controller($scope, $state, chatAvailabilityService) {
                var ctrl = this;

                $scope.Gecko = Gecko;

                // Menu
                ctrl.items = [{
                    icon: 'fa-cog',
                    title: 'Chat Settings',
                    action: function action() {
                        $state.go('user.chat-settings', { user_id: Gecko.user.id });
                    }
                }];

                ctrl.showChatToggle = Gecko.able(Gecko.ABILITY_CHAT_CONVERSATIONS_UPDATE);

                ctrl.toggleLabel = function () {
                    return 'Status: ' + (Gecko.user.chat_presence === Gecko.User.PRESENCE_AVAILABLE ? 'Available' : 'Unavailable');
                };

                ctrl.toogleField = {
                    id: 'chat_presence',
                    type: Gecko.Field.TYPE_TOGGLE,
                    trueValue: Gecko.User.PRESENCE_AVAILABLE,
                    falseValue: Gecko.User.PRESENCE_MANUALLY_AWAY,
                    action: function action() {
                        return chatAvailabilityService.toggleLoggedInUserPresence(Gecko.user.chat_presence);
                    }
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoUserChatControl', geckoUserChatControl);
})();