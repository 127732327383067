(function () {
    'use strict';

    function eventSessionModalDescription() {

        return {
            restrict: 'E',
            scope: {
                event: '=',
                session: '='
            },
            template: '\n                <gecko-fields fields="ctrl.fields" values="ctrl.session"></gecko-fields>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            controller: eventSessionModalDescriptionCtrl
        };
    }

    function eventSessionModalDescriptionCtrl($scope) {
        var ctrl = this;

        $scope.$watch('ctrl', function () {

            // Set default color
            if (!ctrl.session.color) ctrl.session.color = Gecko.account.color;

            ctrl.fields = [{
                id: 'image',
                label: 'Cover Image',
                colClass: 'col-xs-12',
                type: Gecko.Field.TYPE_FILE,
                description: 'Displayed on the embedded event, event field and RSVP pages - 677px(w) x 130px(h) in <code>JPG</code>.',
                mimetype: 'image/*',
                hideWhen: function hideWhen() {
                    return !Gecko.able(Gecko.ABILITY_EVENTS_BRANDING);
                }
            }, {
                id: 'color',
                label: 'Color',
                colClass: 'col-xs-12',
                type: Gecko.Field.TYPE_COLOR,
                hideWhen: function hideWhen() {
                    return !Gecko.able(Gecko.ABILITY_EVENTS_BRANDING);
                }
            }];
        });
    }

    angular.module('GeckoEngage').directive('eventSessionModalDescription', eventSessionModalDescription).controller('eventSessionModalDescriptionCtrl', eventSessionModalDescriptionCtrl);
})();