(function () {
    'use strict';

    function geckoFormDesignerPages() {

        return {
            restrict: 'E',
            scope: {
                form: '=',
                pages: '='
            },
            template: '\n                <div class="gecko-form-designer-pages">\n                    <div ng-repeat="page in ctrl.pages | filter:ctrl.pageFilter | orderBy:\'order\'">\n                    <!--\n                    <div class="gecko-form-designer-pages-header">\n                        <h1>{{page.order}}</h1>\n                    </div> -->\n\n                        <gecko-form-designer-fields\n                            form="ctrl.form"\n                            fields="page.fields">\n                        </gecko-form-designer-fields>\n                    </div>\n                </div>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($formBuilderService) {
                var ctrl = this;
                ctrl.$formBuilderService = $formBuilderService;
                //Only show active page
                ctrl.pageFilter = function (page, index) {
                    return index == $formBuilderService.activePage;
                };
            },
            link: function link(scope, element, attributes, ctrl) {

                var attachDropFunc = function attachDropFunc() {
                    jQuery('.gecko-page-drop-zone').droppable({
                        // accept: ".gecko-form-designer-field",
                        tolerance: "pointer",
                        over: function over(e) {
                            jQuery(e.target).addClass("hover");
                        },
                        out: function out(e) {
                            jQuery(e.target).removeClass("hover");
                        },
                        drop: function drop(e, ui) {
                            var pageID = e.target.dataset.pageId;
                            jQuery(e.target).removeClass("hover");

                            if (pageID && pageID != ctrl.$formBuilderService.activePage && ui.draggable[0].children[0].dataset.pageId == undefined) {
                                ui.draggable.sortable.cancel();
                                var field;
                                if (ctrl.$formBuilderService.newFieldRef) {
                                    field = ctrl.$formBuilderService.createField(ctrl.$formBuilderService.newFieldRef);
                                    ctrl.$formBuilderService.newFieldRef = null;
                                    jQuery('.dragged_field').remove();
                                    jQuery('.ui-draggable-placeholder').remove();
                                } else {
                                    field = ctrl.pages[ctrl.$formBuilderService.activePage].fields.splice(ui.draggable.sortable.index, 1)[0];
                                }
                                ui.draggable.remove();
                                ctrl.pages[pageID].fields.push(field);
                                ctrl.$formBuilderService.activePage = pageID;
                            }
                        }
                    });
                };

                scope.$watchCollection(function () {
                    return ctrl.pages;
                }, function () {
                    attachDropFunc();
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoFormDesignerPages', geckoFormDesignerPages);
})();