(function () {
    'use strict';

    function geckoTopBar() {
        return {
            restrict: 'EA',
            templateUrl: '/components/gecko-top-bar/gecko-top-bar.html',
            scope: false,
            controllerAs: 'ctrl',
            controller: function controller($scope, $geckoVoip) {
                $scope.logo = Gecko.account && Gecko.account.logo ? Gecko.account.logo : 'https://images.geckoform.com/logo/geckolabs-logo-white.svg';

                // Show account switcher
                $scope.accountSwitcherEnabled = function () {
                    return Gecko.account && Gecko.user && Gecko.user.related_group && Gecko.user.related_group.type == Gecko.Group.SUPER;
                };

                // Show VOIP control
                $scope.voipEnabled = function () {
                    return Gecko.User.voipOutboundEnabled() || Gecko.User.voipInboundEnabled();
                };

                $scope.chatEnabled = Gecko.able(Gecko.ABILITY_CHAT_CONVERSATIONS_UPDATE);
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoTopBar', geckoTopBar);
})();