(function () {
    'use strict';

    function geckoHistoricDate() {

        return {
            restrict: 'E',
            scope: {
                value: '=',
                field: '='
            },
            templateUrl: '/components/gecko-historic-date/gecko-historic-date.html',
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($scope) {
                var ctrl = this;

                $scope.$watch('ctrl', function () {
                    ctrl.modifiers = [{
                        name: "Hours",
                        id: "hours"
                    }, {
                        name: "Days",
                        id: "days"
                    }, {
                        name: "Weeks",
                        id: "weeks"
                    }];

                    ctrl.modValues = {
                        hours: 3600,
                        days: 86400,
                        weeks: 604800
                    };

                    ctrl.generateValue = function () {
                        return ctrl.number * ctrl.modValues[ctrl.modifier];
                    };

                    ctrl.onChange = function () {
                        ctrl.value.created_within = ctrl.generateValue();
                    };

                    ctrl.number = 1;
                    ctrl.modifier = "days";
                    if (ctrl.value.created_within) {
                        var tmp = ctrl.value.created_within / ctrl.modValues.hours;
                        if (tmp === parseInt(tmp)) {
                            if (tmp / 24 === parseInt(tmp / 24)) {
                                tmp = tmp / 24;
                                if (tmp / 7 === parseInt(tmp / 7)) {
                                    tmp = tmp / 7;
                                    ctrl.number = tmp;
                                    ctrl.modifier = 'weeks';
                                } else {
                                    ctrl.number = tmp;
                                    ctrl.modifier = 'days';
                                }
                            } else {
                                ctrl.number = tmp;
                                ctrl.modifier = 'hours';
                            }
                        }
                    } else {
                        ctrl.value.created_within = ctrl.generateValue();
                    }
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoHistoricDate', geckoHistoricDate);
})();