(function () {

    'use strict';

    function FormRoute($stateProvider) {

        var formStateBase = {
            templateUrl: "/modules/form/views/edit.6a7ba08f.html",
            controller: 'FormCtrl',
            controllerAs: 'ctrl',
            permissionCheck: function permissionCheck($stateParams) {
                if (!$stateParams.form_id) {
                    return Gecko.able(Gecko.ABILITY_FORMS_CREATE);
                }
                return Gecko.able(Gecko.ABILITY_FORMS_VIEW);
            },
            resolve: {}
        };

        var formStateDesigner = {
            url: '/designer',
            templateUrl: '/modules/form/views/designer.825e2ac8.html',
            controller: 'FormDesignerCtrl',
            controllerAs: 'ctrl',
            resolve: {}
        };

        var formStateSettings = {
            url: '/settings',
            templateUrl: '/modules/form/views/settings.1a16ac89.html',
            controller: 'FormSettingsCtrl',
            controllerAs: 'ctrl',
            resolve: {}
        };

        var formStateWorkflows = {
            url: '/workflows',
            templateUrl: '/modules/form/views/workflows.af4625c3.html',
            controller: 'FormWorkflowsCtrl',
            controllerAs: 'ctrl',
            resolve: {}
        };

        var formStatePermissions = {
            url: '/permissions',
            templateUrl: '/modules/form/views/permissions.1ee35f46.html',
            controller: 'FormPermissionsCtrl',
            controllerAs: 'ctrl',
            resolve: {}
        };

        var formStateShare = {
            url: '/share',
            templateUrl: '/modules/form/views/share.cb486932.html',
            controller: 'FormShareCtrl',
            controllerAs: 'ctrl',
            resolve: {}
        };

        var defaultFormSetup = {
            // Name               : 'Your form title',
            assign_template_id: 0,
            success_message: 'Your request has been received, and will be dealt with as soon as possible.',
            submit_text: 'Submit',
            // Module = module;
            pages: [{
                title: 'Page 1',
                order: 0,
                fields: []
            }],
            conditions: [],
            protected: 1
        };

        var formResolveBase = {
            form: function form($stateParams) {
                if ($stateParams.form_id != 'new') {
                    return new Gecko.Form().include('workflows').rfields({ form: ['name', 'internal_name', 'uuid', 'module'], workflow: ['schedule'] }).get($stateParams.form_id);
                } else {
                    // Create new form
                    return new Gecko.Form().create(angular.copy(defaultFormSetup));
                }
            }
        };

        var formResolveDesigner = {
            formIncludesDesigner: function formIncludesDesigner() {
                return ['pages', 'pages.fields:1000',
                // 'pages.fields.option',
                // 'pages.fields.values',
                'pages.fields.integration_fields', 'conditions:1000'];
            },
            formRfieldsDesigner: function formRfieldsDesigner() {
                return {
                    'page': ['title', 'order'],
                    'condition': ['dependency_id', 'field_id', 'type', 'value']
                    //'integrationfield': ['format', 'integration_id']
                };
            },
            form: function form($stateParams, formIncludesDesigner, formRfieldsDesigner, module) {
                if ($stateParams.form_id != 'new') {
                    return new Gecko.Form().include(formIncludesDesigner).rfields(formRfieldsDesigner).call('/forms/' + $stateParams.form_id, null, null, true).then(function (res) {
                        return res.form;
                    });
                } else {
                    var _defaultFormData = angular.copy(defaultFormSetup);
                    _defaultFormData.module = module;
                    _defaultFormData.name = module == Gecko.Form.MODULE_CALL ? 'Your script title' : 'Your form title';
                    _defaultFormData.protected = module === Gecko.Form.MODULE_CALL ? 0 : 1;

                    // Create form object
                    return new Gecko.Form().create(_defaultFormData);
                }
            },
            data: function data() {

                var _query = {};

                // Contact fields
                _query.contact_fields = new Gecko.Field().where('field_type', 'contact').where('editable', 1).perPage('all').orderBy('order', 'asc');

                // Organisation fields
                _query.organisation_fields = new Gecko.Field().rfields({ 'field': ['label'] }).where('field_type', 'organisation').where('editable', 1).perPage('all').orderBy('order', 'asc');

                // Consent reasons
                if (Gecko.able(Gecko.ABILITY_CONSENTS_VIEW)) _query.consents = new Gecko.Consent().orderBy('title', 'asc').perPage('all');

                // Options
                _query.options = new Gecko.Option().rfields({ 'option': ['name'] }).orderBy('name', 'asc').perPage('all');

                // Integrations
                var intergrationsRfields = { 'integration': ['type', 'created_at', 'title', 'title_with_date'], 'integration_field': ['label', 'format', 'external_id', 'module'] };
                _query.integrations = new Gecko.Integration().include('integration_fields:all').rfields(intergrationsRfields).where('type', GeckoUI.getIdArray(GeckoUI.getObjectArrayByKey(Gecko.Integration.type_titles, 'configurable', 1), 'id')).orderBy('type', 'asc').perPage('all');

                return Gecko.multi(_query);
            },
            runAfterPrimaryResolves: function runAfterPrimaryResolves(data, form, $formBuilderService) {
                if (form.id) {
                    return new Gecko.Form().include('workflows').rfields({ form: ['id'], workflow: ['name', 'conditions', 'actions'] }).call('/forms/' + form.id, null, null, true).then(function (res) {
                        $formBuilderService.data.workflows = res.form.workflows;
                    });
                } else {
                    $formBuilderService.data.workflows = [];
                }
                return Promise.resolve();
            }
        };

        var formResolveSettings = {
            data: function data(geckoDataService) {
                if (Gecko.able(Gecko.ABILITY_CHAT_WIDGETS_VIEW)) {
                    return geckoDataService.fetch(['integrations', 'widgets']);
                } else {
                    return geckoDataService.fetch(['integrations']);
                }
            },
            form: function form($stateParams) {
                return new Gecko.Form().include(['fields:1000']).rfields({ 'field': ['label', 'type', 'parent_id', 'option_id', 'values'] }).get($stateParams.form_id);
            },
            widgets: function widgets(data) {
                return data.widgets || [];
            },
            integrations: function integrations(data) {
                return data.integrations;
            },
            groups: function groups() {
                return new Gecko.Form().groups();
            }
        };

        var formResolveWorkflows = {
            storedData: function storedData(geckoDataService) {
                var models = ['fields', 'campaigns', 'consents', 'labels', 'users', 'senders', 'templates', 'integrations', 'tasks'];
                return geckoDataService.fetch(models);
            },
            data: function data($stateParams) {
                var _query = {};

                // Form
                _query.form = new Gecko.Form().include('workflows:all').rfields({ 'form': ['name', 'module'] }).path($stateParams.form_id);

                // Fields
                _query.fields = new Gecko.Field().rfields({ 'field': ['label', 'type', 'is_calculated', 'data_type', 'values', 'option_id', 'parent_id', 'contact_field_id', 'field_type'] }).include('option').where('form_id', $stateParams.form_id).perPage('all');

                return Gecko.multi(_query);
            },
            multiData: function multiData(data, storedData) {
                return {
                    form: data.form,
                    fields: data.fields,
                    contact_fields: storedData.fields,
                    labels: storedData.labels,
                    users: storedData.users,
                    senders: storedData.senders,
                    templates: storedData.templates,
                    campaigns: storedData.campaigns,
                    consents: storedData.consents,
                    integrations: storedData.integrations,
                    tasks: storedData.tasks
                };
            },
            form: function form(multiData) {
                return multiData.form;
            },
            assignableChatAgents: function assignableChatAgents() {
                return new Gecko.Integration().where('type', Gecko.Integration.TYPE_GECKOCHAT).get().then(function (integration) {
                    if (!integration.data[0]) {
                        return Promise.resolve(null);
                    } else {
                        return new Gecko().call('/geckochat/agents/and-teams', 'GET', {}, true).then(function (response) {
                            try {
                                var agents = response.agents.map(function (item, key) {
                                    return {
                                        key: 'agent_' + item.id,
                                        value: item.full_name,
                                        type: 'Agents'
                                    };
                                });

                                var teams = response.teams.map(function (item, key) {
                                    return {
                                        key: 'team_' + item.id,
                                        value: item.name,
                                        type: 'Teams'
                                    };
                                });

                                return agents.concat(teams);
                            } catch (e) {
                                console.error(e);
                            }
                        });
                    }
                });
            }
        };

        var formResolvePermissions = {
            data: function data(geckoDataService) {
                return geckoDataService.fetch(['user_groups', 'users']);
            },
            form: function form($stateParams) {
                return new Gecko.Form().include(['users:10000', 'user_groups:10000']).rfields({ 'form': ['user_id', 'group'], 'user': ['full_name'], 'group': ['name'] }).get($stateParams.form_id);
            },
            user_groups: function user_groups(data) {
                return data.user_groups;
            },
            users: function users(data) {
                return data.users;
            }
        };

        var formResolveShare = {
            formFields: function formFields($stateParams) {
                return new Gecko.Field().rfields({ 'field': ['label', 'type', 'data_type', 'tag', 'option_id', 'values', 'parent_id'] }).where('form_id', $stateParams.form_id).orderBy('label', 'ASC').perPage('all').get();
            }
        };

        $stateProvider
        // FORM ROUTES
        .state('form', angular.extend({}, formStateBase, {
            url: '/form/{form_id}',
            requires: [Gecko.ABILITY_FORMS_VIEW],
            resolve: angular.extend({}, formResolveBase, {
                module: function module() {
                    return Gecko.Form.MODULE_FORM;
                }
            })
        })).state('form.designer', angular.extend({ page_title: 'Form Designer' }, formStateDesigner, {
            requires: [Gecko.ABILITY_FORMS_VIEW],
            resolve: angular.extend({}, formResolveDesigner)
        })).state('form.settings', angular.extend({ page_title: 'Form Settings' }, formStateSettings, {
            requires: [Gecko.ABILITY_FORMS_VIEW],
            resolve: angular.extend({}, formResolveSettings)
        })).state('form.workflows', angular.extend({ page_title: 'Form Workflows' }, formStateWorkflows, {
            requires: [Gecko.ABILITY_FORMS_VIEW],
            resolve: angular.extend({}, formResolveWorkflows)
        })).state('form.permissions', angular.extend({ page_title: 'Form Permissions' }, formStatePermissions, {
            requires: [Gecko.ABILITY_FORMS_VIEW],
            resolve: angular.extend({}, formResolvePermissions)
        })).state('form.share', angular.extend({ page_title: 'Form Share' }, formStateShare, {
            requires: [Gecko.ABILITY_FORMS_VIEW],
            resolve: angular.extend({}, formResolveShare)
        }))
        // SCRIPT ROUTES
        .state('script', angular.extend({}, formStateBase, {
            url: '/script/{form_id}',
            requires: [Gecko.ABILITY_SCRIPTS_VIEW],
            resolve: angular.extend({}, formResolveBase, {
                module: function module() {
                    return Gecko.Form.MODULE_CALL;
                }
            })
        })).state('script.designer', angular.extend({ page_title: 'Script Designer' }, formStateDesigner, {
            requires: [Gecko.ABILITY_SCRIPTS_VIEW],
            resolve: angular.extend({}, formResolveDesigner)
        })).state('script.settings', angular.extend({ page_title: 'Script Settings' }, formStateSettings, {
            requires: [Gecko.ABILITY_SCRIPTS_VIEW],
            resolve: angular.extend({}, formResolveSettings)
        })).state('script.workflows', angular.extend({ page_title: 'Script Workflows' }, formStateWorkflows, {
            requires: [Gecko.ABILITY_SCRIPTS_VIEW],
            resolve: angular.extend({}, formResolveWorkflows)
        })).state('script.permissions', angular.extend({ page_title: 'Script Permissions' }, formStatePermissions, {
            requires: [Gecko.ABILITY_SCRIPTS_VIEW],
            resolve: angular.extend({}, formResolvePermissions)
        })).state('script.share', angular.extend({ page_title: 'Script Share' }, formStateShare, {
            requires: [Gecko.ABILITY_SCRIPTS_VIEW],
            resolve: angular.extend({}, formResolveShare)
        }));
    }

    angular.module('GeckoEngage.routes').config(FormRoute);
})();