(function () {
    'use strict';

    function geckoFormSuccessUrlConditions($filter, $formBuilderService) {
        return {
            restrict: 'E',
            scope: {
                form: '='
            },
            template: '\n                <div>\n\n                    <gecko-form-success-url-default form="ctrl.form"></gecko-form-success-url-default>\n\n                    <div ng-if="!ctrl.conditions.length" class="gecko-form-success-url-alert">\n                        <div class="alert alert-warning">\n                            No redirect rules added to the form.\n                        </div>\n                    </div>\n\n                    <div>\n\n                        <div ng-if="ctrl.conditions.length" ui-sortable="ctrl.sortableOptions" ng-model="ctrl.conditions">\n                            <gecko-form-success-url-condition\n                                ng-repeat="condition in ctrl.conditions"\n                                index="$index"\n                                condition="condition"\n                                form="ctrl.form">\n                            </gecko-form-success-url-condition>\n                        </div>\n                    </div>\n\n                    <div class="gecko-form-success-url-footer text-center">\n                        <gecko-btn btn-title="Add Rule" icon="fa-plus-circle" action="ctrl.add()"></gecko-btn>\n                    </div>\n\n                </div>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($scope, $formBuilderService) {
                var ctrl = this;

                $scope.$watch('ctrl.form', function () {
                    // Set array (if not already set)
                    if (!ctrl.form.success_url_conditions) ctrl.form.success_url_conditions = [];

                    ctrl.conditions = ctrl.form.success_url_conditions;

                    ctrl.add = function () {
                        // Dont add a new condition until the last one is completed
                        if (ctrl.conditions.filter(function (c) {
                            return !$formBuilderService.successUrlConditionIsValid(c);
                        }).length) {
                            return GeckoUI.messenger.error('Please ensure all redirect rules are complete before adding another.');
                        }

                        ctrl.conditions.push({
                            field_id: null,
                            value: [],
                            success_url: null
                        });
                    };

                    ctrl.sortableOptions = {
                        handle: '.gecko-form-success-url-condition-move',
                        revert: 100,
                        helper: 'clone',
                        placeholder: 'ui-sortable-placeholder gecko-field-sortable-placeholder',
                        // https://stackoverflow.com/questions/2451528/jquery-ui-sortable-scroll-helper-element-offset-firefox-issue
                        start: function start(event, ui) {
                            if (ui.helper !== undefined) {
                                ui.helper.css('position', 'absolute').css('margin-top', $(window).scrollTop());
                            }
                        },
                        beforeStop: function beforeStop(event, ui) {
                            if (ui.offset !== undefined) {
                                ui.helper.css('margin-top', 0);
                            }
                        }
                    };
                });
            }
        };
    }

    function geckoFormSuccessUrlDefault($filter, $formBuilderService) {
        return {
            restrict: 'E',
            scope: {
                form: '='
            },
            template: '\n                <div>\n                    <gecko-fields fields="ctrl.fields" values="ctrl.form" class="clearfix"></gecko-fields>\n                </div>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller() {
                var ctrl = this;

                ctrl.fields = [{
                    id: 'auto_redirect',
                    label: 'Auto Redirect',
                    colClass: 'col-xs-12',
                    description: 'Redirects the user to the Default Success URL automatically. Disable to show the thank you message before redirecting (web only).',
                    type: Gecko.Field.TYPE_TOGGLE
                }, {
                    id: 'success_url',
                    label: 'Default Success URL',
                    colClass: 'col-xs-12',
                    description: 'Users will be redirected to this webpage when the form submission does not match any of the Redirect Rules configured below.'
                }, {
                    type: Gecko.Field.TYPE_TITLE,
                    label: 'Redirect Rules',
                    description: 'Redirect Rules allow you to send users to a URL based on the information in their form submission. Rules run in the order they are declared (top to bottom). If the form submission matches no redirect rules the user will be redirected to the Default Success URL instead. For more information on Redirect Rules, click <a href="https://academy.geckoengage.com/en/articles/6967275-redirect-rules" target="_blank">here</a>.',
                    colClass: 'col-xs-12'
                }];
            }
        };
    }

    function geckoFormSuccessUrlCondition($filter, $formBuilderService) {
        return {
            restrict: 'E',
            scope: {
                condition: '=',
                form: '=',
                index: '='
            },
            template: '\n                <div>\n                    <div>\n                        <i class="fa fa-bars moveable gecko-form-success-url-condition-move"></i>\n                    </div>\n                    <div>\n                        <gecko-fields fields="ctrl.fields" values="ctrl.condition" class="clearfix"></gecko-fields>\n                    </div>\n                </div>\n            ',
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($scope, $formBuilderService, geckoDataService) {
                var ctrl = this;

                $scope.$watch('ctrl.form', function () {

                    //Get form fields (and remove current field from array)
                    var fieldOptions = $filter('orderBy')(ctrl.form.related_fields, 'label').filter(function (f) {
                        return [Gecko.Field.TYPE_TEXT, Gecko.Field.TYPE_SELECT, Gecko.Field.TYPE_HIDDEN, Gecko.Field.TYPE_RADIO, Gecko.Field.TYPE_PREFERRED_LANGUAGE].indexOf(f.type) !== -1;
                    });

                    ctrl.isOptionsField = function () {
                        var fieldId = ctrl.condition.field_id;
                        var type = (fieldOptions.find(function (f) {
                            return f.id === fieldId;
                        }) || {}).type;
                        return fieldId && [Gecko.Field.TYPE_SELECT, Gecko.Field.TYPE_RADIO, Gecko.Field.TYPE_PREFERRED_LANGUAGE].indexOf(type) !== -1;
                    };

                    // Update value options if suitable field type
                    var refreshDependencyOptions = function refreshDependencyOptions(reset) {
                        if ($formBuilderService.getDependencyOptions(fieldOptions, ctrl.condition.field_id)) {
                            var field = fieldOptions.find(function (f) {
                                return f.id === ctrl.condition.field_id;
                            }) || {};
                            // Reset condition value when dependency changes
                            if (reset) ctrl.condition.value = null;

                            // Possibly a 'child' field; if so get optionId from the field's top level parent
                            var topLevelField = field.parent_id ? $formBuilderService.fieldOptionUtils.getTopParentField(fieldOptions, field.id) || {} : field;
                            var optionId = field.parent_id ? topLevelField.option_id : field.option_id;

                            optionsRefreshing = true;

                            // Options may not be set on dependency field yet
                            if (optionId && !topLevelField.options) {
                                var getOptionsFn = geckoDataService.fetchFromStore(['options']).then(function (data) {
                                    return data.options.find(function (o) {
                                        return o.id === optionId;
                                    }) || null;
                                }).then(function (option) {
                                    if (option && option.values) {
                                        // If child field; set fetched options on the fields top level parent
                                        if (field.parent_id) {
                                            (fieldOptions.find(function (f) {
                                                return f.id === topLevelField.id;
                                            }) || {}).options = option.values;
                                        } else {
                                            field.options = option.values;
                                        }
                                    }
                                    return option;
                                }).then(function (option) {
                                    return $formBuilderService.getDependencyOptions(fieldOptions, ctrl.condition.field_id, field.option_level);
                                });
                            } else {
                                var getOptionsFn = Promise.resolve($formBuilderService.getDependencyOptions(fieldOptions, ctrl.condition.field_id));
                            }

                            // Set updated options and reactivate the field
                            getOptionsFn.then(function (options) {
                                ctrl.fields.find(function (f) {
                                    return f.id2 === 'value-options';
                                }).options = $formBuilderService.prepareOptions(field, options);
                            }).then(function () {
                                optionsRefreshing = false;
                                $scope.$digest();
                            }).catch(function (err) {
                                optionsRefreshing = false;
                                $scope.$digest();
                            });
                        }
                    };

                    var optionsRefreshing = false;

                    ctrl.fields = [{
                        id: 'field_id',
                        label: 'Form Field',
                        type: Gecko.Field.TYPE_SELECT,
                        options: fieldOptions,
                        optionsKey: 'id',
                        optionsLabelKey: 'label',
                        colClass: 'none',
                        action: function action() {
                            return refreshDependencyOptions(true);
                        }
                    }, {
                        id: 'value',
                        label: 'Value (any of)',
                        type: Gecko.Field.TYPE_MULTI,
                        colClass: 'none',
                        config: { create: true },
                        optionsKey: '',
                        optionsLabelKey: '',
                        hideWhen: function hideWhen() {
                            return ctrl.isOptionsField() && !optionsRefreshing;
                        }
                    }, {
                        id: 'value',
                        id2: 'value-options',
                        label: 'Value (any of)',
                        type: Gecko.Field.TYPE_MULTI,
                        options: [],
                        optionsKey: 'value',
                        optionsLabelKey: 'label',
                        colClass: 'none',
                        hideWhen: function hideWhen() {
                            return !ctrl.isOptionsField() || optionsRefreshing;
                        }
                    }, {
                        id: 'success_url',
                        label: 'Website Address',
                        type: Gecko.Field.TYPE_TEXT,
                        colClass: 'none',
                        autocomplete: false
                    }, {
                        type: Gecko.Field.TYPE_BUTTON,
                        colClass: 'gecko-form-success-url-condition-remove-btn',
                        icon: 'fa-trash-alt',
                        btnClass: 'btn-danger',
                        action: function action() {
                            // Remove condition by index
                            ctrl.form.success_url_conditions.splice(ctrl.index, 1);
                        }

                    }];

                    // Load options
                    refreshDependencyOptions(false);
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoFormSuccessUrlConditions', geckoFormSuccessUrlConditions).directive('geckoFormSuccessUrlDefault', geckoFormSuccessUrlDefault).directive('geckoFormSuccessUrlCondition', geckoFormSuccessUrlCondition);
})();