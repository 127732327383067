function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

(function () {
    'use strict';

    function templateTagReferenceService() {
        var _this = {
            richtext: {
                ref: {},
                update: function update(id, tag) {
                    if (_this.richtext.ref[id] && tag) {
                        try {
                            var editor = _this.richtext.ref[id];
                            var selection = editor.selection.getSel();

                            // Cursor is NOT set: add at the bottom
                            if (!selection.anchorNode) {
                                editor.dom.add(editor.getBody(), 'span', {}, tag);
                                // Cursor is SET: add at position
                            } else {
                                _this.richtext.ref[id].insertContent(tag);
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }
            },
            textarea: {
                ref: {},
                getRef: function getRef(id) {
                    return document.getElementById(_this.textarea.ref[id]);
                },
                focus: function focus(id, pos) {
                    var input = _this.textarea.getRef(id);
                    if (input) {
                        setTimeout(function () {
                            input.focus();
                            // Set cursor at correct position
                            input.selectionStart = pos || 0;
                            input.selectionEnd = pos || 0;
                            input.scrollTop = input.scrollHeight;
                        }, 300);
                    }
                },
                getCursorPos: function getCursorPos(id, contentLength) {
                    var input = _this.textarea.getRef(id);
                    if (input) {
                        try {
                            return input.selectionStart;
                        } catch (e) {
                            return contentLength || 0;
                        }
                    }
                    return contentLength || 0;
                }
            }
        };
        return _this;
    }

    function templateTagReference($rootScope, $filter, $stateParams, $parse, asyncOptions, templateTagReferenceService) {
        return {
            restrict: 'EA',
            templateUrl: '/components/template-tag-reference/template-tag-reference.html',
            scope: {
                linkedModel: '=',
                templateType: '=',
                models: '=',
                inputId: '='
            },
            controller: angular.noop,
            controllerAs: 'ctrl',
            bindToController: true,
            link: function link(scope, iElement, iAttrs, ctrl) {
                var _tagModifiers;

                ctrl.selected = {};
                ctrl.linkedModel = ctrl.linkedModel || '';

                var allowedModels = ctrl.models;

                ctrl.models = [];
                ctrl.tags = {};

                Gecko.Template.Tags.forEach(function (tag) {
                    if (allowedModels.indexOf(tag.model) !== -1) {

                        ctrl.models.push(tag.model);
                        ctrl.tags[tag.model] = tag.tags.filter(function (_ref) {
                            var abilities = _ref.abilities;
                            return Array.isArray(abilities) ? abilities.every(function (ability) {
                                return Gecko.able(ability);
                            }) : true;
                        });

                        if (tag.model == "Contact") {
                            new Gecko.Field().include('option').where('field_type', Gecko.Field.FIELD_TYPE_CONTACT).perPage('all').orderBy('order', 'asc').rfields({ field: ['label', 'type', 'tag'] }).get().then(function (contact_fields) {
                                contact_fields.toArray().map(function (field) {
                                    ctrl.tags[tag.model].push({ name: field.label, tag: "{{contact." + field.tag + "}}", contact: true, field_type: field.type });
                                });
                            });
                        }
                    }
                });

                ctrl.tags_copy = angular.copy(ctrl.tags);

                var filterTags = function filterTags() {
                    ctrl.tags = angular.copy(ctrl.tags_copy);
                    if (ctrl.tags['Contact']) {
                        ctrl.tags['Contact'] = ctrl.tags['Contact'].filter(function (model_tag) {
                            if (model_tag.name === 'short_qr_code') {
                                return ctrl.templateType === Gecko.Template.TYPE_SMS;
                            } else if (model_tag.name === 'qr_code') {
                                return ctrl.templateType === Gecko.Template.TYPE_EMAIL;
                            }

                            return true;
                        });
                        if (ctrl.selected.model) ctrl.fields[2].options = ctrl.tags[ctrl.selected.model];
                    }
                };

                filterTags();

                // A map of modifier types to their field options
                var modifierTypeOptions = {
                    date: [{
                        label: 'Full date (24hr)',
                        value: 'full_24hr'
                    }, {
                        label: 'Full date (12hr)',
                        value: 'full_12hr'
                    }, {
                        label: 'Long date (' + Gecko.dateFormat.long + ')',
                        value: 'date'
                    }, {
                        label: 'Short date (' + Gecko.dateFormat.short + ')',
                        value: 'short'
                    }, {
                        label: 'International date (YYYY/MM/DD)',
                        value: 'international'
                    }, {
                        label: 'Time (24hr)',
                        value: 'time_24hr'
                    }, {
                        label: 'Time (12hr)',
                        value: 'time_12hr'
                    }],
                    name: [{
                        label: 'First name',
                        value: 'first_name'
                    }, {
                        label: 'Last name',
                        value: 'last_name'
                    }],
                    address: [{
                        label: 'Street address',
                        value: 'street_address'
                    }, {
                        label: 'Address Line 2',
                        value: 'address_line_2'
                    }, {
                        label: 'City',
                        value: 'city'
                    }, {
                        label: 'Postal/Zip code',
                        value: 'postal_code'
                    }, {
                        label: 'State/Province/County',
                        value: 'state'
                    }, {
                        label: 'Country',
                        value: 'country'
                    }]

                    // A map of tags to their modifier types
                };var tagModifiers = (_tagModifiers = {
                    start_date: 'date',
                    end_date: 'date',
                    closing_date: 'date',
                    received_at: 'date',
                    start: 'date',
                    end: 'date',
                    started: 'date',
                    ended: 'date',
                    completed: 'date',
                    full_name: 'name'
                }, _defineProperty(_tagModifiers, Gecko.Field.TYPE_NAME, 'name'), _defineProperty(_tagModifiers, Gecko.Field.TYPE_ADDRESS, 'address'), _defineProperty(_tagModifiers, 'contact_created', 'date'), _defineProperty(_tagModifiers, 'last_called', 'date'), _defineProperty(_tagModifiers, 'last_response', 'date'), _defineProperty(_tagModifiers, 'last_geckotalk_response', 'date'), _defineProperty(_tagModifiers, 'last_email', 'date'), _defineProperty(_tagModifiers, 'last_sms', 'date'), _defineProperty(_tagModifiers, 'last_form_response', 'date'), _tagModifiers);

                ctrl.availableModifiers = function () {
                    if (!ctrl.selected.tag) {
                        return [];
                    }

                    // Dont show full_name modifiers for USER model
                    if ([Gecko.Template.TAG_ASSIGNED_USER, Gecko.Template.TAG_CAPTURED_USER, Gecko.Template.TAG_USERS].indexOf(ctrl.selected.model) !== -1 && ctrl.selected.tag.name === 'full_name') return [];

                    // Get modifier types by either tag name or field type
                    var modifierType = tagModifiers[ctrl.selected.tag.name] || tagModifiers[ctrl.selected.tag.field_type];
                    if (!modifierType) {
                        return [];
                    } // If no modifierType was found return no options
                    return modifierTypeOptions[modifierType] || []; // Return the field options for the modifierType or return no options
                };

                scope.$watch('ctrl.templateType', function (oldVal, newVal) {
                    if (oldVal != newVal) {
                        filterTags();
                    }
                });

                ctrl.fields = [{
                    id: 'model',
                    type: Gecko.Field.TYPE_SELECT,
                    options: ctrl.models,
                    optionsLabelKey: 'model',
                    blankLabel: 'Select a Model...',
                    colClass: 'no-class',
                    onChange: function onChange() {
                        // Clear the other fields on Model change
                        ctrl.selected.form = undefined;
                        ctrl.selected.tag = undefined;
                        ctrl.selected.modifier = undefined;
                        ctrl.selected.filter = undefined;
                    }
                }, {
                    id: 'form',
                    type: Gecko.Field.TYPE_SELECT,
                    options: [],
                    getOptions: asyncOptions.create(new Gecko.Form().orderBy('internal_name').rfields({ form: ['name', 'internal_name'] })),
                    optionsKey: 'id',
                    optionsLabelKey: 'name',
                    choiceTemplate: 'form-choice',
                    matchTemplate: 'form-match',
                    hideWhen: function hideWhen() {
                        return ctrl.selected.model !== 'Responses';
                    },
                    placeholder: 'Select a Form...',
                    colClass: 'no-class',
                    onChange: function onChange() {
                        // Clear the other fields on Form change
                        ctrl.selected.tag = undefined;
                        ctrl.selected.modifier = undefined;
                        ctrl.selected.filter = undefined;
                    }
                }, {
                    id: 'tag',
                    type: Gecko.Field.TYPE_SELECT,
                    optionsLabelKey: 'name',
                    disabledWhen: function disabledWhen() {
                        return !ctrl.selected.model;
                    },
                    optionsGroup: function optionsGroup(opt) {
                        return opt.form ? 'Form Fields' : opt.contact ? 'Contact Fields' : undefined;
                    },
                    blankLabel: 'Select a Tag...',
                    colClass: 'no-class',
                    onChange: function onChange() {
                        // Clear the other fields on Tag change
                        ctrl.selected.modifier = undefined;
                        ctrl.selected.filter = undefined;
                    }
                }, {
                    id: 'modifier',
                    type: Gecko.Field.TYPE_SELECT,
                    getOptions: function getOptions() {
                        return this.options = ctrl.availableModifiers();
                    },
                    optionsLabelKey: 'label',
                    optionsKey: 'value',
                    hideWhen: function hideWhen() {
                        return !ctrl.availableModifiers() || !ctrl.availableModifiers().length;
                    },
                    colClass: 'no-class'
                }, {
                    id: 'filter',
                    type: Gecko.Field.TYPE_SELECT,
                    getOptions: function getOptions() {
                        if (ctrl.selected.tag) {
                            var exclude = ['{{event.passbook_link}}', '{{event.ical_link}}', '{{event.rsvp_link}}', '{{event.qr_code}}', '{{contact.qr_code}}', '{{contact.portal_url}}', '{{contact.student_portal_url}}', '{{contact.events_page}}', '{{contact.unsubscribe}}', '{{contact.bookings}}', '{{form.logo}}', '{{response.link}}', '{{event.guests}}'];
                            if (exclude.indexOf(ctrl.selected.tag.tag) !== -1) {
                                return this.options = [];
                            }
                        }
                        if (ctrl.selected.tag && ctrl.selected.tag.field_type === 'repeatable') {
                            return this.options = [{ name: 'Table View', tag: 'table' }];
                        }
                        return this.options = Gecko.Template.Filters;
                    },
                    options: Gecko.Template.Filters,
                    optionsKey: 'tag',
                    optionsLabelKey: 'name',
                    disabledWhen: function disabledWhen() {
                        return !ctrl.selected.tag;
                    },
                    placeholder: 'None',
                    colClass: 'no-class'
                }];

                ctrl.addBtn = function () {
                    var finalTag = '';

                    if (!ctrl.selected.tag) {
                        return;
                    }

                    finalTag += ctrl.selected.tag.tag;
                    if (ctrl.selected.modifier) {
                        finalTag = finalTag.replace('}}', '.' + ctrl.selected.modifier + '}}');
                    }
                    if (ctrl.selected.filter || ctrl.selected.tag.filter === true) {
                        finalTag = finalTag.replace('}}', '|' + ctrl.selected.filter + '}}');
                    }

                    if (finalTag) {
                        if (templateTagReferenceService.richtext.ref[ctrl.inputId]) {
                            templateTagReferenceService.richtext.update(ctrl.inputId, finalTag);
                        } else {
                            if (ctrl.inputId) {
                                var text = ctrl.linkedModel;
                                var pos = templateTagReferenceService.textarea.getCursorPos(ctrl.inputId, (ctrl.linkedModel || '').length);
                                var newText = [text.slice(0, pos), finalTag, text.slice(pos)].join('');
                                // Update model
                                ctrl.linkedModel = newText;
                                // Set focus
                                templateTagReferenceService.textarea.focus(ctrl.inputId, pos + finalTag.length);
                            } else {
                                // Put in the body of any html
                                if (ctrl.linkedModel.indexOf('</body>') !== -1) {
                                    return ctrl.linkedModel = ctrl.linkedModel.replace('</body>', finalTag + '</body>');
                                }
                                return ctrl.linkedModel += " " + finalTag;
                            }
                        }
                    }
                };

                ctrl.disableButton = function () {
                    return ctrl.selected.tag === undefined || ctrl.selected.tag === null;
                };

                scope.$watchCollection(function () {
                    return ctrl.selected;
                }, function (newVal, oldVal) {
                    var tagField = ctrl.fields.find(function (f) {
                        return f.id === 'tag';
                    });
                    var modelField = ctrl.fields.find(function (f) {
                        return f.id === 'model';
                    });

                    if (newVal.model !== oldVal.model) tagField.options = ctrl.tags[ctrl.selected.model];
                    if (newVal.form !== oldVal.form && newVal.form) {
                        if (modelField.options.indexOf('Responses') !== -1) {
                            new Gecko.Field().rfields({ field: ['label', 'tag', 'type'] }).where('form_id', newVal.form).perPage('all').get().then(function (data) {
                                data.toArray().forEach(function (field) {
                                    tagField.options.push({ name: field.label, tag: "{{response." + field.tag + "}}", form: true, field_type: field.type });
                                });
                            }).catch(GeckoUI.messenger.error);
                        }
                    }
                    if (newVal.tag !== oldVal.tag) {
                        // Update the filter options and modifier options to reflect the new tag
                        newVal.filter = undefined;
                        ctrl.fields.find(function (f) {
                            return f.id === 'filter';
                        }).getOptions();
                        ctrl.fields.find(function (f) {
                            return f.id === 'modifier';
                        }).getOptions();
                    }
                });
            }
        };
    }

    angular.module('GeckoEngage').factory('templateTagReferenceService', templateTagReferenceService).directive('templateTagReference', templateTagReference);
})();