
(function () {
    'use strict';

    function geckoCallPaneIncoming() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-call/views/gecko-call-pane-incoming.html',
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {},
            controller: function controller($scope, $geckoCall, $geckoVoip, $geckoQueue) {
                var ctrl = this;

                // Cache call queue length so it doesnt fuck up bindings
                ctrl.getQueueLength = $geckoQueue.getLength;

                // Get active call title
                ctrl.getPaneTitle = function () {
                    if (ctrl.incomingSender && ctrl.hasNoQueuedCalls()) {
                        return ctrl.incomingSender.tel + ' - ' + ctrl.incomingSender.name;
                    }
                    return ctrl.getQueueLength() + ' queued calls';
                };

                // Check if number is anonymous
                ctrl.numberIsAnonymous = Gecko.Number.isAnonymous;

                // Get call number title
                ctrl.getNumberTitle = function () {
                    return '<strong>Unknown</strong> calling from <strong>' + $geckoCall.getNumberTitle(ctrl.incomingNumber) + '</strong>';
                };

                // Is Queue Active
                ctrl.hasActiveQueue = false;
                ctrl.hasNoQueuedCalls = function () {
                    if (ctrl.getQueueLength() > 1 && !ctrl.hasActiveQueue) ctrl.hasActiveQueue = true;
                    return ctrl.incomingNumber && !ctrl.hasActiveQueue;
                };

                // Get incoming call
                ctrl.getCall = function () {
                    if (ctrl.getQueueLength() < 2) {
                        $geckoCall.get($geckoVoip.incomingCallId).then(function (call) {
                            ctrl.incomingCall = call;
                            ctrl.incomingSender = call.related_sender;
                            ctrl.incomingNumber = call.related_number;
                            ctrl.incomingContacts = ctrl.incomingNumber.related_contacts || [];
                            ctrl.open = false;
                            $scope.$digest();
                        }).catch(function (err) {
                            console.log(err.errors);
                        });
                    } else {
                        ctrl.incomingCall = true;
                        ctrl.open = false;
                    }
                };

                // Answer call
                ctrl.answerCall = function () {

                    // Show loading indicator
                    $geckoCall.loadingIndicator(true);

                    // Accept VOIP connection
                    $geckoCall.connect().then(function (call) {

                        // Stop connection if call has dropped during request
                        if (call.status !== Gecko.Call.STARTED) {
                            $geckoVoip.destroy();

                            // Show message saying user has hangup
                            if (call.status === Gecko.Call.ABANDONED) {
                                GeckoUI.messenger.error('Caller has ended their call.');
                            }

                            // Firefox (and maybe others) has issues reseting microphone permission settings
                            if (!window.chrome) {
                                setTimeout(function () {
                                    window.location.reload();
                                }, 500);
                            }
                            // Stop call restore process
                            return;
                        }

                        // Restore call
                        $geckoCall.restore(call);
                        $geckoCall.activate();

                        // Fire Intercom Event
                        GeckoUI.triggerIntercomEvent('Call Incoming Success', {
                            'call id': call.id
                        });
                    }).catch(function (err) {
                        // Destroy VOIP connection if connection fails
                        $geckoVoip.destroy();
                        // Fire Intercom Event
                        GeckoUI.triggerIntercomEvent('Call Incoming Error', {
                            'error': GeckoUI.renderError(err.errors)
                        });
                    }).finally(function () {
                        // Hide loading indicator
                        $geckoCall.loadingIndicator(false);
                        // Refresh scope
                        $scope.$digest();
                    });
                };

                // Ignore incoming call
                // Ctrl.rejectCall = function(){
                //     Return $geckoVoip.accept = false;
                // };

                // Card buttons
                ctrl.cardFooterBtns = [{
                    //     Icon: 'fa-phone fa-rotate-90',
                    //     Action: ctrl.rejectCall,
                    //     Position: 'secondary',
                    //     BtnClass: 'btn-danger',
                    //     Title: 'Ignore'
                    // }, {
                    icon: 'fa-phone',
                    title: 'Accept',
                    btnClass: 'btn-primary',
                    safeMode: true,
                    action: ctrl.answerCall
                }];

                // Set incoming call
                ctrl.getCall();
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCallPaneIncoming', geckoCallPaneIncoming);
})();