(function () {
    'use strict';

    function geckoCardStats() {
        return {
            restrict: 'EA',
            templateUrl: '/components/gecko-card-stats/gecko-card-stats.html',
            transclude: true,
            scope: {
                title: '@title',
                icon: '@icon',
                stats: '=',
                optionsBtn: '=optionsBtn',
                headerBtns: '=headerBtns',
                noHeaderTransclude: '@noHeaderTransclude'
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller($scope) {
                var ctrl = this;
                ctrl.statStyle = {};

                $scope.$watch('ctrl.stats', function (stats) {
                    var statWidth = 100 / (stats ? stats.length : 0);
                    ctrl.statStyle = {
                        width: statWidth + '%'
                    };
                });

                ctrl.statValue = function (stat) {
                    if (typeof stat.aggregate !== 'undefined') return stat.aggregate;

                    if (typeof stat.result === 'number' && stat.unit === 'Percent') stat.result = Math.round(stat.result);
                    var unitAppend = stat.unit === 'Percent' ? '%' : '';

                    return stat.result + unitAppend;
                };
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoCardStats', geckoCardStats);
})();