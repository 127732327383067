(function () {
    'use strict';

    function geckoSearchConditionsAddBtn() {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-search-conditions/gecko-search-conditions-add-btn.html',
            scope: {
                conditions: '=',
                direction: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: angular.noop,
            require: ['geckoSearchConditionsAddBtn', '^?form'],
            link: function link(scope, iElement, iAttrs, ctrls) {
                var ctrl = ctrls[0];
                var formCtrl = ctrls[1];

                // Open up or down
                ctrl.btnDirection = ctrl.direction == undefined || ctrl.direction == 'down' ? 'down' : 'up';

                // Button type options
                ctrl.condtionTypes = [{
                    key: Gecko.Condition.MODEL_CONTACT_FIELD,
                    label: 'Contact Field',
                    order: 0
                }, {
                    key: 'contact_created_at',
                    label: 'Contact Created At',
                    order: 1
                }, {
                    key: 'contact_updated_at',
                    label: 'Contact Updated At',
                    order: 1
                }, {
                    key: Gecko.Condition.MODEL_LABEL,
                    label: 'Contact Label',
                    order: 2
                }, {
                    key: Gecko.Condition.MODEL_IMPORT,
                    label: 'Import',
                    order: 3
                }, {
                    key: Gecko.Condition.MODEL_RESPONSE,
                    label: 'Response',
                    order: 4
                }, {
                    key: Gecko.Condition.MODEL_CALL_OUTCOME,
                    label: 'Call Campaign Outcome',
                    order: 5,
                    hide: !Gecko.User.hasOutboundOrInbound()
                }, {
                    key: Gecko.Condition.MODEL_EMAIL_OUTCOME,
                    label: 'Email Broadcast Outcome',
                    order: 6,
                    hide: !Gecko.able(Gecko.ABILITY_EMAIL_BROADCASTS_VIEW)
                }, {
                    key: Gecko.Condition.MODEL_SMS_OUTCOME,
                    label: 'SMS Broadcast Outcome',
                    order: 7,
                    hide: !Gecko.able(Gecko.ABILITY_SMS_BROADCASTS_VIEW)
                }, {
                    key: Gecko.Condition.MODEL_CALL_COUNT,
                    label: 'Call Count',
                    order: 8,
                    hide: !Gecko.User.hasOutboundOrInbound()
                }, {
                    key: Gecko.Condition.MODEL_FILTER,
                    label: 'Saved Search',
                    order: 9
                }, {
                    key: Gecko.Condition.MODEL_ATTENDANCE,
                    label: 'Event',
                    order: 10,
                    hide: !Gecko.has(Gecko.Package.FEATURE_EVENT)
                }, {
                    key: Gecko.Condition.MODEL_ENROLMENT,
                    label: 'Organisation',
                    order: 11,
                    hide: !Gecko.has(Gecko.Package.FEATURE_ORGANISATION)
                }, {
                    key: Gecko.Condition.MODEL_CONSENT,
                    label: 'Consent',
                    order: 12,
                    hide: !Gecko.able(Gecko.ABILITY_CONSENTS_VIEW)
                }, {
                    key: Gecko.Condition.MODEL_CONTACT_TASK,
                    label: 'Task & Objective',
                    order: 13,
                    hide: !Gecko.able(Gecko.ABILITY_TASKS_VIEW)
                }];

                // Add Condition
                ctrl.addCondition = function (model) {
                    var last = ctrl.conditions[ctrl.conditions.length - 1];

                    if (model === 'contact_created_at' || model === 'contact_updated_at') {
                        var newFilter = {
                            model: Gecko.Condition.MODEL_CONTACT_DATES
                        };
                        if (model === 'contact_created_at') {
                            newFilter.property = 'created_at';
                        } else if (model === 'contact_updated_at') {
                            newFilter.property = 'updated_at';
                        }
                    } else {
                        var newFilter = { model: model };
                    }

                    // Prevent adding a new condition if the last one is incomplete
                    if (ctrl.conditions.length > 0) {
                        switch (last.model) {
                            case 'filter':
                                // Filter takes an in_filter field unlike the other conditions
                                if (!last.in_filter) return false;
                                break;
                            default:
                                if (last.model && (last.type === '--' || last.type === '!--')) {
                                    break;
                                }
                                if (!last.model || !last.type || !(last.value || last.import_id)) return false;
                                break;
                        }
                    }

                    // Default filter condition to equals as others make little sense
                    if (model === 'filter') {
                        newFilter.type = '=';
                    }

                    // Add the new condition
                    ctrl.conditions.push(newFilter);

                    if (formCtrl) formCtrl.$setDirty(); // When a new condition is added make the form dirty
                };
            }
        };
    }

    function geckoSearchConditionsEventField() {
        return {
            restrict: 'E',
            template: '<tiered-select \n                            ng-model="ctrl.value" \n                            field="ctrl.field">\n                        </tiered-select>',
            scope: {
                value: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: function controller(OptionsPresets) {
                var ctrl = this;

                ctrl.field = {
                    type: Gecko.Field.TYPE_TIERED_SELECT,
                    optionsInit: OptionsPresets.events.init,
                    options: [[], []],
                    getOptions: OptionsPresets.events.get,
                    placeholder: ['– Choose Event –', '– Choose Session –', '– Choose Session Time –'],
                    optionsKey: 'id',
                    optionsLabelKey: 'nice_title',
                    choiceTemplate: 'event-choice'
                };
            }
        };
    }

    function geckoSearchConditions($state, $matchTypes, $filter, geckoDataService, $formBuilderService) {
        return {
            restrict: 'E',
            templateUrl: '/components/gecko-search-conditions/gecko-search-conditions.html',
            scope: {
                conditions: '=',
                logicOperator: '=',
                hideLogicOperator: '=',
                isExports: '='
            },
            controllerAs: 'ctrl',
            bindToController: true,
            controller: angular.noop,
            require: ['geckoSearchConditions', '?^form'],
            link: function link(scope, iElement, iAttrs, ctrls) {
                var ctrl = ctrls[0];
                var formCtrl = ctrls[1];

                scope.Gecko = Gecko;
                scope.GeckoUI = GeckoUI;

                // 'ALL' = AND, 'ANY' = OR
                if (!ctrl.logicOperator) ctrl.logicOperator = 'ALL';

                // Ctrl.conditions = ctrl.data;
                ctrl.matchTypes = $matchTypes;

                // Lookup table for labels on conditions
                ctrl.conditionLabels = {};
                ctrl.conditionLabels[Gecko.Condition.MODEL_CONTACT_FIELD] = 'Contact Field';
                ctrl.conditionLabels[Gecko.Condition.MODEL_CONTACT_DATES] = 'Contact Dates';
                ctrl.conditionLabels[Gecko.Condition.MODEL_IMPORT] = 'Import';
                ctrl.conditionLabels[Gecko.Condition.MODEL_LABEL] = 'Contact Label';
                ctrl.conditionLabels[Gecko.Condition.MODEL_RESPONSE] = 'Response';
                ctrl.conditionLabels[Gecko.Condition.MODEL_CALL_OUTCOME] = 'Call Campaign Outcome';
                ctrl.conditionLabels[Gecko.Condition.MODEL_CAMPAIGN_OUTCOME] = 'Call Campaign Outcome';
                ctrl.conditionLabels[Gecko.Condition.MODEL_SMS_OUTCOME] = 'SMS Broadcast Outcome';
                ctrl.conditionLabels[Gecko.Condition.MODEL_CALL_COUNT] = 'Call Count';
                ctrl.conditionLabels[Gecko.Condition.MODEL_FILTER] = 'Saved Search';
                ctrl.conditionLabels[Gecko.Condition.MODEL_ATTENDANCE] = 'Attendance';
                ctrl.conditionLabels[Gecko.Condition.MODEL_ENROLMENT] = 'Organisation';
                ctrl.conditionLabels[Gecko.Condition.MODEL_CONTACT_TASK] = 'Task & Objective';

                // Response type options
                ctrl.contactDatesTypes = [{
                    id: 'created_at',
                    label: 'Created At'
                }, {
                    id: 'updated_at',
                    label: 'Updated At'
                }];

                // Remove C operator when NOT exports
                if (!ctrl.isExports) ctrl.matchTypes.contactDates = ctrl.matchTypes.contactDates.slice(0, 2);

                ctrl.showGreaterThanDatePicker = function (condition) {
                    if (condition.model === Gecko.Condition.MODEL_CONTACT_DATES && condition.type === '<') return true;
                    if (condition.model === 'contact_field' && GeckoUI.getObjectByKey(ctrl.fields, 'id', condition.contact_field_id).data_type == Gecko.Field.DATA_TYPE_TIMESTAMP && condition.type !== '>') return true;
                    return false;
                };

                ctrl.showLessThanDatePicker = function (condition) {
                    if (condition.model === Gecko.Condition.MODEL_CONTACT_DATES && condition.type === '>') return true;
                    if (condition.model === 'contact_field' && GeckoUI.getObjectByKey(ctrl.fields, 'id', condition.contact_field_id).data_type == Gecko.Field.DATA_TYPE_TIMESTAMP && condition.type === '>') return true;
                    return false;
                };

                ctrl.isUtcDatePicker = function (c) {
                    return c.model === 'contact_field' && GeckoUI.getObjectByKey(ctrl.fields, 'id', c.contact_field_id).type === 'date';
                };

                // Response type options
                ctrl.responseTypes = [{
                    id: 'form_id',
                    label: 'Form'
                }, {
                    id: 'assigned_id',
                    label: 'Assigned User'
                }, {
                    id: 'event_id',
                    label: 'Event'
                }, {
                    id: 'import_id',
                    label: 'Import'
                }, {
                    id: 'user_id',
                    label: 'Captured By'
                }];

                // Remove Condition
                ctrl.removeCondition = function (index) {
                    ctrl.conditions.splice(index, 1);
                    if (formCtrl) formCtrl.$setDirty(); // When a new condition is added make the form dirty
                    if (!ctrl.conditions.length && $state.$current.name != 'contacts') {
                        ctrl.contacts = [];
                        ctrl.hasRefreshedContacts = false;
                    }
                };

                ctrl.outcomeValuesDropdownInit = true;

                // Reset array values on change
                ctrl.resetSelectize = function (condition) {
                    condition.value = [];

                    // Reload custom outcomes on call campaigns
                    if ([Gecko.Condition.MODEL_CALL_OUTCOME].indexOf(condition.model) !== -1 && condition.campaign_id) {
                        ctrl.outcomeValuesDropdownInit = false;
                        setTimeout(function () {
                            ctrl.outcomeValuesDropdownInit = true;
                        }, 300);
                    }
                };

                var optionsMap = {};

                ctrl.getFieldOptions = function (condition) {
                    if (!optionsMap[condition.contact_field_id]) {
                        var field = ctrl.fields.find(function (f) {
                            return f.id === condition.contact_field_id;
                        });
                        optionsMap[condition.contact_field_id] = $formBuilderService.prepareOptions(field, GeckoUI.getFieldOptions(ctrl.fields, condition.contact_field_id));
                    }
                    // For selectize its options must be assignable. Wrapping in an object here is the solution.
                    return { options: optionsMap[condition.contact_field_id] };
                };

                // ctrl.eventField = {
                //     type          : Gecko.Field.TYPE_TIERED_SELECT,
                //     optionsInit   : OptionsPresets.events.init,
                //     options       : [[],[]],
                //     getOptions    : OptionsPresets.events.get,
                //     placeholder   : ['– Choose Event –', '– Choose Session –', '– Choose Session Time –'],
                //     optionsFilter : [null, function(event) {
                //         if (ctrl.attendances.indexOf(event.id) === -1) return true;
                //         return false;
                //     }],
                //     optionsKey      : 'id',
                //     optionsLabelKey : 'title',
                // };

                // // Needs a separate options array
                // ctrl.eventFieldResponse = {
                //     type          : Gecko.Field.TYPE_TIERED_SELECT,
                //     optionsInit   : OptionsPresets.events.init,
                //     options       : [[],[]],
                //     getOptions    : OptionsPresets.events.get,
                //     placeholder   : ['– Choose Event –', '– Choose Session –', '– Choose Session Time –'],
                //     optionsFilter : [null, function(event) {
                //         if (ctrl.attendances.indexOf(event.id) === -1) return true;
                //         return false;
                //     }],
                //     optionsKey      : 'id',
                //     optionsLabelKey : 'title',
                // };

                ctrl.filterValueChanged = function () {
                    ctrl.conditions.forEach(function (condition) {
                        if (condition.model === Gecko.Condition.MODEL_FILTER) {
                            geckoDataService.fetchFromStore('filters').then(function (fromStore) {
                                var filter = null;
                                if (fromStore && fromStore.filters) {
                                    filter = fromStore.filters.find(function (filter) {
                                        return filter.id === parseInt(condition.in_filter);
                                    });
                                }
                                return (filter || {}).ulid;
                            }).then(function (savedSearchId) {
                                condition.in_filter_ulid = savedSearchId;
                            });
                        }
                    });
                };

                Promise.all([geckoDataService.fetch(['fields', 'forms', 'imports', 'labels', 'outcomes', 'users', 'organisations', 'consents', 'tasks']), geckoDataService.fetch(['campaigns_with_outcomes']), // Requset campaigns (with outcomes) seperately for performance.
                geckoDataService.fetchFromStore(['filters'])]).then(function (multiData) {
                    var main = multiData[0] || {};
                    var campaignsRequest = multiData[1] || {};
                    var filters = multiData[2] || {};

                    ctrl.call_outcomes = main.outcomes.filter(function (o) {
                        return o.group === Gecko.Outcome.GROUP_GLOBAL;
                    });
                    ctrl.sms_outcomes = main.outcomes.filter(function (o) {
                        return o.group === Gecko.Outcome.GROUP_SMS;
                    });
                    ctrl.email_outcomes = main.outcomes.filter(function (o) {
                        return o.group === Gecko.Outcome.GROUP_EMAIL;
                    });
                    ctrl.campaigns = campaignsRequest.campaigns_with_outcomes;
                    ctrl.call_campaigns = campaignsRequest.campaigns_with_outcomes.filter(function (c) {
                        return c.module === Gecko.Campaign.MODULE_CALL;
                    });
                    ctrl.sms_broadcasts = campaignsRequest.campaigns_with_outcomes.filter(function (c) {
                        return c.module === Gecko.Campaign.MODULE_SMS;
                    });
                    ctrl.email_broadcasts = campaignsRequest.campaigns_with_outcomes.filter(function (c) {
                        return c.module === Gecko.Campaign.MODULE_EMAIL;
                    });
                    ctrl.fields = main.fields;
                    ctrl.filters = filters.filters;
                    ctrl.forms = main.forms;
                    ctrl.imports = main.imports;
                    ctrl.labels = main.labels;
                    ctrl.outcomes = main.outcomes;
                    ctrl.organisations = main.organisations;
                    ctrl.users = main.users;
                    ctrl.consents = main.consents;
                    ctrl.tasks = main.tasks;
                    // Populate calls count dropdown
                    ctrl.callOutcomeField.options = ctrl.call_campaigns;

                    // Stopped loading options
                    ctrl.loading = false;

                    scope.$apply();
                }).catch(function (e) {
                    // Stopped loading options
                    ctrl.loading = false;
                });

                ctrl.filterImports = function (imports, type) {
                    return $filter('filter')(imports, { import_to: type });
                };

                ctrl.callOutcomeField = {
                    id: 'campaign_id',
                    type: 'select',
                    options: [], //ctrl.call_campaigns,
                    optionsKey: 'id',
                    optionsLabelKey: 'title',
                    noBlank: true
                };

                ctrl.switchAndOr = function (operator) {
                    ctrl.logicOperator = operator;

                    // Fire Intercom Event
                    try {
                        var intercomData = {};
                        intercomData['Page'] = $state.$current.page_title;
                        intercomData['Requirement'] = operator === 'ALL' ? 'AND' : 'OR';
                        GeckoUI.triggerIntercomEvent('Advanced Search AND/OR Click', intercomData);
                    } catch (e) {} // eslint-disable-line no-empty
                };

                ctrl.formSelectizeConfig = {
                    valueField: 'id',
                    labelField: 'name',
                    searchField: ['name', 'internal_name'],
                    sortField: {
                        field: 'name',
                        direction: 'asc'
                    },
                    render: {
                        item: function item(form, escape) {
                            return '<div class="item">' + escape(form.internal_name || form.name) + '</div>';
                        },
                        option: function option(form, escape) {
                            return '<div>' + escape(form.internal_name || form.name) + '</div>';
                        }
                    }
                };

                ctrl.taskStatuses = Object.keys(Gecko.Task.task_statuses).map(function (status) {
                    return {
                        value: status,
                        label: Gecko.Task.task_statuses[status]
                    };
                });
            }
        };
    }

    angular.module('GeckoEngage').directive('geckoSearchConditions', geckoSearchConditions).directive('geckoSearchConditionsAddBtn', geckoSearchConditionsAddBtn).directive('geckoSearchConditionsEventField', geckoSearchConditionsEventField);
})();