(function () {
    'use strict';

    var generateSettingsController = function generateSettingsController($scope, $state, $stateParams, $filter, $geckoModal, unsavedAlert, ctrl, vars) {
        if ($stateParams.campaign_id == 'new') {
            // Prefill selected outcomes
            ctrl.campaign.related_outcomes = ctrl.outcomes;
        } else {
            ctrl.campaign.related_outcomes = (ctrl.campaign.related_outcomes || []).filter(function (o) {
                return [Gecko.Outcome.GROUP_GLOBAL, Gecko.Outcome.GROUP_CAMPAIGN].indexOf(o.group) !== -1;
            });
        }

        // Verify that dnc_period is set (default 1 day)
        if (ctrl.campaign.dnc_period == null) {
            ctrl.campaign.dnc_period = 24 * 60 * 60;
        }

        // Set sort directions for contact display
        ctrl.sortDirections = [{ display: 'Ascending', value: 'asc' }, { display: 'Descending', value: 'desc' }];

        // Build default campaign fields
        if (!ctrl.campaign.related_campaign_fields) {
            new Gecko.Field().where('field_type', Gecko.Field.FIELD_TYPE_CONTACT).orderBy('order').getArray('editable').then(function (fields) {
                ctrl.campaign.related_campaign_fields = fields.map(function (field, index) {
                    return {
                        field_id: field.id,
                        list_view: index < 6 ? 1 : 0,
                        read_only: 0,
                        system: field.system,
                        related_field: field
                    };
                });
            });
        }

        // Prepare field selects
        ctrl.checkFieldSelect = function (item) {
            return !item.system || item.is_calculated;
        };

        // Dont allow more tan 6 list view checks
        ctrl.watchListviewCheckboxes = function () {
            // Set initial count to 0
            ctrl.listViewTotal = 0;
            // Check there are campaign fields
            if (!ctrl.campaign.related_campaign_fields) return false;
            // Count checked fields
            angular.forEach(ctrl.campaign.related_campaign_fields, function (field) {
                if (field.list_view) ctrl.listViewTotal++;
            });
        };
        ctrl.watchListviewCheckboxes();

        // Add campaign field
        ctrl.addCampaignField = function () {
            ctrl.campaign.related_campaign_fields.push({
                field_id: null,
                list_view: 0,
                read_only: 0,
                order: ctrl.campaign.related_campaign_fields.length
            });
            // Refresh array order values
            GeckoUI.refreshOrder(ctrl.campaign.related_campaign_fields, undefined, 'order');
        };

        // Save Campaign
        var campaignSave = function campaignSave() {
            ctrl.campaign.outcomes = ctrl.campaign.related_outcomes;
            ctrl.campaign.campaign_fields = ctrl.campaign.related_campaign_fields || [];
            ctrl.campaign.users = ctrl.campaign.related_users;

            // Sanatize list view fields
            ctrl.campaign.related_campaign_fields.reduce(function (total, field) {
                if (field.list_view) total++;
                if (total > Gecko.DEFAULT_LIST_FIELD_TOTAL) {
                    field.list_view = 0;
                }
                return total;
            }, 0);

            // Validation
            if (!vars.saveValidation()) {
                return false;
            }
            ctrl.campaign.include('outcomes', 'campaign_fields', 'campaign_fields.field', 'users').save().then(function (campaign) {

                // Tell the user it was ok
                GeckoUI.messenger.success(vars.saveSuccessMessage);

                // Apply orderby to campaign fields
                ctrl.campaign.related_campaign_fields = $filter('orderBy')(ctrl.campaign.related_campaign_fields, 'order');

                // Convert
                ctrl.campaign.related_outcomes = ctrl.campaign.relationIDs('outcomes');
                ctrl.campaign.related_users = ctrl.campaign.relationIDs('users');

                unsavedAlert.clear();

                // Go to subscriber filters
                if ($stateParams.campaign_id == 'new') $state.go('campaignsedit.filter', { campaign_id: campaign.id, module: $stateParams.module }, true);

                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Campaign Save Success', {
                    'campaign id': campaign.id || 'New',
                    'campaign title': campaign.title
                });
            }).catch(function (err) {
                GeckoUI.messenger.error(err.errors);

                // Fire Intercom Event
                GeckoUI.triggerIntercomEvent('Campaign Save Error', {
                    'campaign id': ctrl.campaign.id || 'New',
                    'campaign title': ctrl.campaign.title,
                    'error': GeckoUI.renderError(err.errors)
                });
            });
        };

        // Apply campaign fields order
        ctrl.campaign.related_campaign_fields = $filter('orderBy')(ctrl.campaign.related_campaign_fields, 'order');

        // Refresh campaign fields order after drop
        ctrl.sortableOptions = {
            handle: '.myHandle',
            stop: function stop() {
                GeckoUI.refreshOrder($scope.campaign.related_campaign_fields, undefined, 'order');
            }
        };

        // Save Campaign
        $scope.$on('saveCampaign', function () {
            return campaignSave();
        });

        // Breadcrumbs
        ctrl.breadcrumbs = [{
            label: 'Campaigns',
            click: function click() {
                $state.go('campaigns', { module: $stateParams.module });
            }
        }, {
            label: $stateParams.campaign_id != 'new' ? ctrl.campaign.title : vars.addMessage,
            active: true
        }];

        // Columns setup
        ctrl.cols = [{
            title: 'Field',
            key: 'related_field.label',
            colClass: 'col-xs-12'
        }, {
            input_type: Gecko.Field.TYPE_CHECKBOX,
            title: 'Read Only',
            key: 'read_only',
            colWidth: '60px',
            checkedWhen: function checkedWhen(campaign_field) {
                return campaign_field.read_only || campaign_field.related_field && campaign_field.related_field.is_calculated;
            },
            disabledWhen: function disabledWhen(campaign_field) {
                return campaign_field.related_field.is_calculated;
            }
        }, {
            input_type: Gecko.Field.TYPE_CHECKBOX,
            title: 'Show in Contact List on Overview',
            key: 'list_view',
            colWidth: '60px',
            checkedWhen: function checkedWhen(campaign_field) {
                return campaign_field.list_view;
            },
            disabledWhen: function disabledWhen(campaign_field) {
                return !campaign_field.list_view && ctrl.listViewTotal > 5;
            },
            onChange: function onChange() {
                return ctrl.watchListviewCheckboxes();
            }
        }];
        // Needed to hard refresh the directive...sigh
        ctrl.showFieldTable = true;
        // Header buttons
        ctrl.headerBtns = [{
            title: 'Add Field',
            icon: 'fa-plus',
            action: function action() {
                $geckoModal.selectorModal({
                    title: 'Add a Field',
                    model: 'field',
                    optionsKey: 'id',
                    optionsLabel: 'label',
                    wheres: {
                        key: 'field_type',
                        value: 'contact'
                    },
                    postFunc: function postFunc() {
                        this.options = this.options.filter(function (option) {
                            return GeckoUI.getIdArray(ctrl.campaign.related_campaign_fields, 'field_id').indexOf(option.id) === -1;
                        });
                    }
                }).result.then(function (id) {
                    new Gecko.Field().get(id).then(function (field) {
                        ctrl.showFieldTable = false;
                        ctrl.campaign.related_campaign_fields.push({
                            field_id: id,
                            list_view: 0,
                            read_only: field.is_calculated,
                            order: ctrl.campaign.related_campaign_fields.length,
                            related_field: field
                        });
                        GeckoUI.refreshOrder(ctrl.campaign.related_campaign_fields, undefined, 'order');
                        // Needed to hard refresh the directive...sigh
                        setTimeout(function () {
                            ctrl.showFieldTable = true;
                            $scope.$digest();
                        }, 300);
                    });
                });
            }
        }];

        // Reorder options
        ctrl.reorderOptions = {
            stop: function stop() {
                GeckoUI.refreshOrder(ctrl.campaign.related_campaign_fields, undefined, 'order');
            }
        };

        // Row remove button
        ctrl.rowRemoveBtn = {
            disabledWhen: function disabledWhen(campaign_field) {
                return campaign_field.related_field && campaign_field.related_field.system && !campaign_field.related_field.is_calculated;
            }
        };
    };

    function CampaignsSettingsCtrl($scope, $state, $stateParams, $filter, $geckoModal, asyncOptions, selectedData, campaign, fields, outcomes, users, consents, unsavedAlert) {
        var ctrl = this;

        ctrl.campaign = campaign;
        ctrl.campaign.consent_ids = (ctrl.campaign.related_consents || []).map(function (c) {
            return c.id;
        });
        ctrl.campaign.related_users = ctrl.campaign.relationIDs('users');
        ctrl.module = ctrl.campaign.module;
        ctrl.fields = fields.toArray();
        ctrl.moduleCap = 'Campaign';
        ctrl.outcomes = outcomes.toArray();
        ctrl.users = users.toArray();

        generateSettingsController($scope, $state, $stateParams, $filter, $geckoModal, unsavedAlert, ctrl, {
            saveSuccessMessage: 'Campaign saved',
            addMessage: 'Add Campaign',
            saveValidation: function saveValidation() {
                if (ctrl.campaign.outcomes.length === 0) {
                    GeckoUI.messenger.error('You cannot save a campaign without specifying at least one outcome.');
                    return false;
                } else {
                    return true;
                }
            }
        });

        ctrl.settingsFields = [{
            label: 'Title',
            key: 'title',
            required: true,
            colClass: 'col-xs-12',
            description: 'The title for this campaign. This will identify it throughout Gecko.'
        }, {
            label: 'Description',
            key: 'description',
            type: Gecko.Field.TYPE_TEXTAREA,
            colClass: 'col-xs-12',
            description: '(Optional) a brief description of your campaign. For internal use only.'
        }, {
            label: 'Check the Following Consent Reasons',
            id: 'consent_ids',
            type: Gecko.Field.TYPE_MULTI,
            colClass: 'col-xs-12',
            options: (consents ? consents.toArray() : []).filter(function (c) {
                return c.group_call || c.group_general;
            }),
            optionsKey: 'id',
            optionsLabelKey: 'title',
            required: true,
            description: '<i class="fa fa-warning"></i> Contacts will be added to your campaign only if they have consented to the above.',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CONSENTS_VIEW) || Gecko.able(Gecko.ABILITY_SKIP_GDPR_BLOCK);
            }
        }, {
            label: 'Ignore Consent Preferences',
            id: 'ignore_consents',
            type: Gecko.Field.TYPE_TOGGLE,
            colClass: "col-xs-12",
            description: 'When enabled, contacts will be added to the campaign without checking their consent preferences.',
            hideWhen: function hideWhen() {
                return !Gecko.able(Gecko.ABILITY_CONSENTS_VIEW) || Gecko.able(Gecko.ABILITY_SKIP_GDPR_BLOCK);
            }
        }];

        ctrl.dateFields = [{
            label: 'Start Time',
            key: 'start_date',
            type: Gecko.Field.TYPE_DATETIME,
            labeled: true,
            colClass: 'col-xs-12',
            description: 'The date and time that you wish this campaign to start.'
        }, {
            label: 'End Time',
            key: 'end_date',
            type: Gecko.Field.TYPE_DATETIME,
            labeled: true,
            colClass: 'col-xs-12',
            description: 'The date and time that you wish this campaign to end.'
        }, {
            label: 'Call Back Timeout Period',
            key: 'dnc_period',
            type: Gecko.Field.TYPE_DURATION,
            labeled: true,
            colClass: 'col-xs-12',
            description: 'The minimum amount of time before it will be possible to call a contact back after finishing a call with them.'
        }];

        ctrl.campaignOptionsFields = [{
            label: 'Script',
            key: 'form_id',
            type: Gecko.Field.TYPE_SELECT,
            colClass: 'col-xs-12',
            options: asyncOptions.pick(selectedData, 'forms'),
            getOptions: asyncOptions.create(new Gecko.Form().where('module', Gecko.Campaign.MODULE_CALL).perPage('all').orderBy('internal_name').rfields({ form: ['name', 'internal_name'] })),
            choiceTemplate: 'form-choice',
            matchTemplate: 'form-match',
            description: 'The script that call agents will be able to use when on calls in this campaign - <a ui-sref="scripts">view and edit available scripts here</a>.'
        }, {
            label: 'Verified Caller ID',
            key: 'call_sender_id',
            colClass: 'col-xs-12',
            description: 'Select the number to be used to make outbound calls for this campaign. Add/ edit numbers <a ui-sref="voipnumbers">here</a>.',
            type: Gecko.Field.TYPE_SELECT,
            optionsKey: 'id',
            optionsLabelKey: 'name',
            options: asyncOptions.pick(selectedData, 'senders'),
            getOptions: asyncOptions.create(new Gecko.Sender().where('outgoing_voice', 1).orderBy('name').perPage('all').rfields({ sender: ['name', 'type', 'tel'] }), null, { search: 'keywordSearch' }),
            choiceTemplate: 'sender-choice',
            matchTemplate: 'sender-match',
            optionsGroup: function optionsGroup(item) {
                return item && item.type && item.type == Gecko.Sender.GECKOCHAT ? "GeckoChat" : undefined;
            }
        }, {
            label: 'Assigned Users',
            key: 'related_users',
            type: Gecko.Field.TYPE_MULTI,
            colClass: 'col-xs-12',
            options: ctrl.users,
            optionsKey: 'id',
            optionsLabelKey: 'full_name',
            description: 'Select the call agents who will be able to make calls as part of this campaign.'
        }, {
            label: 'Number to Call',
            id: 'contact_field_id',
            type: Gecko.Field.TYPE_SELECT,
            options: asyncOptions.pick(selectedData, 'tel_fields'),
            getOptions: asyncOptions.create(new Gecko.Field().rfields({ field: ['label'] }).where('field_type', Gecko.Field.FIELD_TYPE_CONTACT).where('type', Gecko.Field.TYPE_TEL).orderBy('label'), null, { search: 'keywordSearch' }),
            optionsKey: 'id',
            optionsLabelKey: 'label',
            colClass: "col-xs-12",
            description: 'The preferred contact field to call.',
            placeholder: 'Automatically choose number'
        }, {
            type: Gecko.Field.TYPE_TOGGLE,
            key: 'record_calls',
            colClass: 'col-xs-12',
            label: 'Record Calls',
            description: 'When enabled, all calls made and received in this campaign will be recorded.',
            hideWhen: function hideWhen() {
                return !Gecko.has(Gecko.Package.FEATURE_CALL) && !Gecko.User.isGroup([Gecko.Group.ADMIN, Gecko.Group.SUPER]);
            },
            checkedWhen: function checkedWhen() {
                return ctrl.campaign.record_calls;
            }
        }];

        ctrl.campaign.related_outcomes = ctrl.campaign.relationIDs('outcomes');
        // Prepare outcome dropdown items
        ctrl.outcomes = ctrl.outcomes.map(function (o) {
            o.action_title = Gecko.Call.actions.find(function (a) {
                return a.id === o.action;
            }).title;
            return o;
        });

        ctrl.outcomesFields = [{
            label: 'Outcomes for Call',
            key: 'related_outcomes',
            type: Gecko.Field.TYPE_MULTI,
            options: ctrl.outcomes,
            getOptions: function getOptions() {},
            choiceTemplate: 'outcome-choice',
            colClass: 'col-xs-12',
            description: 'Select which call outcomes will be available for your call agents to use for this campaign. Outcomes can be edited <a ui-sref="settingsoutcomes"> here</a>. For more information click <a href="https://academy.geckoengage.com/en/articles/6930194-outcomes">here</a>.',
            optionsKey: 'id',
            optionsLabelKey: 'name'
        }];

        ctrl.sortContactsFields = [{
            label: 'Sort Contacts By',
            key: 'sort_field_id',
            type: Gecko.Field.TYPE_SELECT,
            options: asyncOptions.pick(selectedData, 'fields'),
            getOptions: asyncOptions.create(new Gecko.Field().where('field_type', 'contact').orderBy('label').perPage('all').rfields({ field: ['label'] })),
            optionsLabelKey: 'label',
            colClass: 'col-xs-12',
            description: 'The default field for sorting contacts in this campaign.',
            optionsGroup: function optionsGroup(f) {
                return !f.is_calculated ? 'Contact Fields' : 'System Fields';
            }
        }, {
            label: 'Sort Contacts Order',
            key: 'sort_direction',
            type: Gecko.Field.TYPE_SELECT,
            colClass: 'col-xs-12',
            description: 'Whether contacts will be sorted by ascending or descending value of the default field.',
            options: ctrl.sortDirections,
            optionsKey: 'value',
            optionsLabelKey: 'display'
        }];

        ctrl.currentTab = 'Basic Settings';
        // Tabs
        ctrl.tabs = [{
            label: 'Basic Settings',
            fields: ctrl.settingsFields,
            activeWhen: function activeWhen() {
                return ctrl.currentTab === this.label;
            },
            action: function action() {
                ctrl.currentTab = this.label;
            }
        }, {
            label: 'Schedule & Date',
            fields: ctrl.dateFields,
            activeWhen: function activeWhen() {
                return ctrl.currentTab === this.label;
            },
            action: function action() {
                ctrl.currentTab = this.label;
            }
        }, {
            // Call
            label: 'Campaign Options',
            fields: ctrl.campaignOptionsFields,
            activeWhen: function activeWhen() {
                return ctrl.currentTab === this.label;
            },
            action: function action() {
                ctrl.currentTab = this.label;
            }
        }, {
            label: 'Campaign Fields',
            fields: [],
            activeWhen: function activeWhen() {
                return ctrl.currentTab === this.label;
            },
            action: function action() {
                ctrl.currentTab = this.label;
            }
        }, {
            label: 'Sort Contacts',
            fields: ctrl.sortContactsFields,
            activeWhen: function activeWhen() {
                return ctrl.currentTab === this.label;
            },
            action: function action() {
                ctrl.currentTab = this.label;
            }
        }, {
            label: 'Outcomes',
            fields: ctrl.outcomesFields,
            activeWhen: function activeWhen() {
                return ctrl.currentTab === this.label;
            },
            action: function action() {
                ctrl.currentTab = this.label;
            }
        }];

        // Fields help text
        ctrl.fieldsHelpText = "Choose which options to display on the Campaigns Overview screen. Making a value Read Only will prevent your call agents from being able to edit that field on the Contact Profile screen.";

        // Last thing to do is clear unsaved alerts from any potential changes the controller has made.
        unsavedAlert.clear();
    }

    angular.module('GeckoEngage').controller('CampaignsSettingsCtrl', CampaignsSettingsCtrl);
})();